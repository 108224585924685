import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField, Box, Checkbox, CircularProgress,
} from '@material-ui/core';
import is from 'is_js';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AdminServices from 'src/services/AdminServices';
import { debounce } from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const UserSearch = ({ handleUserSelected, disabled = false, defaultValue }) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const request = debounce((value) => handleUserSearch(value), 500);

  const debounceRequest = useCallback((value) => request(value), []);

  function handleUserSearch(query) {
    if (query.length === 0) {
      setUsers([]);
      return;
    }
    setSearchLoading(true);
    return AdminServices.getUsers(query)
      .then((response) => setUsers(response.data))
      .catch((error) => console.log(error))
      .finally(() => setSearchLoading(false));
  }

  useEffect(() => {
    if (is.not.empty(defaultValue)) {
      setUsersSelected(defaultValue);
    }
  }, [defaultValue]);

  const localSelect = (selected) => {
    setUsersSelected(selected);
    handleUserSelected(selected);
  };

  return (
    <Box className={classes.root}>
      <Autocomplete
        multiple
        fullWidth
        freeSolo
        disabled={disabled}
        id="search-autocomplete-outlined"
        options={users}
        value={usersSelected || []}
        loading={searchLoading}
        getOptionLabel={(option) => (option.name ? `${option?.name} ${option?.lastname}` : option)}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(_, newValue) => {
          localSelect(newValue);
        }}
        onInputChange={(event) => (event ? debounceRequest(event.target.value) : {})}
        renderOption={(option, { selected }) => {
          return (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.name}
              {' '}
              {option?.lastname}
              {' '}
              {option?.email}
            </>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Users"
            placeholder="Users"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {searchLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
export default UserSearch;
