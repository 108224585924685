import { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import is from 'is_js';

import { DAYS } from 'src/utils/constants';

const states = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'Diable',
  },
];

const ServiceVariantAreaAvailability = ({ handleSave, defaultValue, availabilityLoading }) => {
  const [values, setValues] = useState({});
  const [editTime, setEitTime] = useState(false);

  useEffect(() => {
    if (!editTime) {
      setValues({
        ...values,
        start_time: null,
        end_time: null,
      });
    }
  }, [editTime]);

  const handleLocalSave = () => {
    handleSave(values);
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (defaultValue) {
      setValues({
        ...defaultValue[0],
        start_time: defaultValue[0]?.start_time ? moment(defaultValue[0].start_time).utc().format('HH:mm') : null,
        end_time: defaultValue[0]?.end_time ? moment(defaultValue[0].end_time).utc().format('HH:mm') : null,
        active: defaultValue[0]?.active,
      });
    }
  }, [defaultValue]);

  const toggleEditTime = () => {
    setEitTime((prev) => !prev);
  };

  return (
    <form>
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Availability"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Select Status"
                name="active"
                key={values?.active}
                placeholder="Select Status"
                onChange={handleChange}
                select
                required
                defaultValue={values.active}
                value={values.active}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {states.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <Switch
                color="primary"
                size="small"
                checked={editTime}
                onChange={toggleEditTime}
              />
              <TextField
                style={{ marginRight: 8 }}
                disabled={!editTime}
                id="start-time"
                label="Start Time"
                type="time"
                defaultValue={values.start_time}
                name="start_time"
                onChange={handleChange}
                value={values.start_time}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="end-time"
                disabled={!editTime}
                label="End Time"
                name="end_time"
                type="time"
                defaultValue={values.end_time}
                onChange={handleChange}
                value={values.end_time}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            onClick={handleLocalSave}
            color="primary"
            variant="contained"
            disabled={availabilityLoading}
          >
            {availabilityLoading ? <CircularProgress /> : 'Save details'}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default ServiceVariantAreaAvailability;
