import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import AdminServices from 'src/services/AdminServices';
import { useNavigate } from 'react-router';
import moment from 'moment';
import AddAPhotoAlternateOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import BlankProfile from 'src/assets/blank-profile.png';
import { createStyles } from '@material-ui/styles';
import is from 'is_js';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const REQUIRED_FIELD_ERROR = 'This field is required';

const DEFAULT_DOC = {
  title: '',
  image: '',
  description: '',
  start_date: null,
  end_date: null,
  section: 'home_images',
  status: 'active',
  url: '',
  order: 0,
};

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}),
);

function BannerDetails({ currentInfo = {}, action }) {
  const classes = useStyles();
  const [changes, setChanges] = useState({});
  const [editOn, setEditOn] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [errors, setErrors] = useState(null);
  const [bannerInfo, setBannerInfo] = useState({ ...DEFAULT_DOC, ...currentInfo });
  const [image, setImage] = useState(null);
  const [areas, setAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState(false);
  const navigate = useNavigate();

  function handleChange(prop, value) {
    setChanges({ ...changes, [prop]: value });
  }

  useEffect(() => {
    if (action === 'edit' && bannerInfo.image) {
      setImage(bannerInfo.image.url);
    }
  }, [bannerInfo]);

  useEffect(() => {
    fetchAreas();
  }, []);

  function fetchAreas() {
    return AdminServices.getAreas()
      .then((res) => {
        console.info('areas', res.data);
        setAreas(res.data);
      })
      .catch((err) => console.log(err));
  }

  function onSave() {
    const doc = {
      ...bannerInfo,
      ...changes,
      image: changes.image || '',
      ...(selectedAreas && { areas_id: selectedAreas.map((area) => area.id) }),
      order: changes.order ? Number(changes.order) : bannerInfo.order,
    };
    setUpdateLoading(true);
    if (!validateData(doc)) {
      setUpdateLoading(false);
      return;
    }
    if (action === 'edit') {
      return AdminServices.updateBanner(doc.id, doc)
        .then((res) => {
          setBannerInfo(res.data);
          setSuccessToast(true);
          onCancel();
        })
        .catch((err) => { console.log(err); setUpdateLoading(false); });
    }
    if (action === 'add') {
      return AdminServices.createBanner(doc)
        .then(() => {
          setBannerInfo(DEFAULT_DOC);
          setSuccessToast(true);
          onCancel();
        })
        .catch((err) => { console.log(err); setUpdateLoading(false); })
        .finally(() => {
          navigate('/app/banners');
        });
    }
  }

  function validateData(data) {
    let validated;
    const errorsAcum = {};

    if (!data.title) errorsAcum.name = REQUIRED_FIELD_ERROR;
    if (!data.start_date) errorsAcum.country = REQUIRED_FIELD_ERROR;
    if (!data.end_date) errorsAcum.role = REQUIRED_FIELD_ERROR;
    if (!data.section) errorsAcum.section = REQUIRED_FIELD_ERROR;
    if (action === 'add') {
      if (data.section && data.section === 'home_images' && !data.image) {
        errorsAcum.image = REQUIRED_FIELD_ERROR;
      }
    }

    if (is.empty(errorsAcum)) validated = true;
    if (is.not.empty(errorsAcum)) {
      setErrors(errorsAcum);
      validated = false;
    }

    return validated;
  }

  function onCancel() {
    setChanges({});
    setUpdateLoading(false);
    setErrors(null);
    setEditOn(false);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleAddBannerImage = (event) => {
    getBase64(event.target.files[0]).then((file64) => {
      setChanges({
        ...changes,
        [event.target.name]: file64,
      });
    });
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      <Snackbar
        open={errorToast}
        autoHideDuration={6000}
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorToast(false)} severity="error">
          Passwords do not match
        </Alert>
      </Snackbar>
      <Snackbar
        open={successToast}
        autoHideDuration={6000}
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessToast(false)} severity="success">
          All done!
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader title={action === 'edit' ? 'Edit banner' : 'Add banner'} />
        <Divider />
        <CardContent>
          <Grid container spacing={3} justify="center">
            <Grid item md={12} xs={12}>
              <Box
                style={{
                  backgroundImage: image ? `url(${image})` : `url(${BlankProfile})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: '500px',
                  height: '320px',
                  margin: '0 auto',
                  borderRadius: '8px',
                  border: errors?.image ? '5px solid red' : '5px solid #ddd',
                }}
              >
                <input
                  accept="image/jpeg,image/png"
                  className={classes.input}
                  id="image"
                  type="file"
                  name="image"
                  disabled={action === 'edit' ? !editOn : false}
                  onChange={handleAddBannerImage}
                />
                <label htmlFor="image">
                  <IconButton
                    style={{ position: 'relative' }}
                    component="span"
                    disabled={action === 'edit' ? !editOn : false}
                  >
                    <AddAPhotoAlternateOutlinedIcon />
                  </IconButton>
                </label>
              </Box>
            </Grid>
            <Box boxShadow={5} style={{ padding: '25px', marginTop: '15px', marginLeft: '25px' }}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Title"
                    name="title"
                    value={'title' in changes ? changes.title : bannerInfo.title || ''}
                    error={errors?.title}
                    helperText={errors?.title}
                    onChange={(event) => handleChange('title', event.target.value)}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Description"
                    name="description"
                    value={'description' in changes ? changes.description : bannerInfo.description || ''}
                    helperText={errors?.description}
                    onChange={(event) => handleChange('description', event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    id="start"
                    type="date"
                    label="Start Date"
                    disabled={action === 'edit' ? !editOn : false}
                    error={errors?.start_date}
                    helperText={errors?.start_date}
                    name="start_date"
                    value={
                      moment('start_date' in changes ? changes.start_date : bannerInfo.start_date || null)
                        .utc().format('YYYY-MM-DD')
                    }
                    onChange={
                      (event) => handleChange(
                        'start_date', moment(event.target.value).utc().startOf('day').toISOString())
                    }
                    InputLabelProps={{ shrink: true }}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    id="start"
                    type="date"
                    label="End Date"
                    error={errors?.end_date}
                    helperText={errors?.end_date}
                    disabled={action === 'edit' ? !editOn : false}
                    name="end_date"
                    value={
                      moment('end_date' in changes ? changes.end_date : bannerInfo.end_date || null)
                        .utc().format('YYYY-MM-DD')
                    }
                    onChange={
                      (event) => handleChange('end_date', moment(event.target.value).utc().endOf('day').toISOString())
                    }
                    InputLabelProps={{ shrink: true }}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Url"
                    name="url"
                    value={'url' in changes ? changes.url : bannerInfo.url || ''}
                    onChange={(event) => handleChange('url', event.target.value)}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Section"
                    name="section"
                    disabled={action === 'edit' ? !editOn : false}
                    value={'section' in changes ? changes.section : bannerInfo.section || ''}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    onChange={(event) => handleChange('section', event.target.value)}
                    required
                    select
                    variant="outlined"
                    error={errors?.section}
                  >
                    <option
                      value="home_images"
                    >
                      Home images
                    </option>
                    <option
                      value="home_text"
                    >
                      Home text
                    </option>
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="status"
                    disabled={action === 'edit' ? !editOn : false}
                    value={'status' in changes ? changes.status : bannerInfo.status || ''}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    onChange={(event) => handleChange('status', event.target.value)}
                    required
                    select
                    variant="outlined"
                  >
                    <option
                      value="active"
                    >
                      Active
                    </option>
                    <option
                      value="paused"
                    >
                      Paused
                    </option>
                  </TextField>
                </Grid>
                <Grid item md={8} xs={8}>
                  <Box>
                    <Autocomplete
                      multiple
                      fullWidth
                      freeSolo
                      disabled={action === 'edit' ? !editOn : false}
                      id="professisonal-list"
                      options={areas}
                      disableClearable
                      getOptionLabel={(option) => (
                        typeof option !== 'string'
                          ? option.name : option)}
                      value={selectedAreas || currentInfo.areas || []}
                      renderInput={(params) => <TextField {...params} label="Areas" variant="outlined" />}
                      getOptionSelected={(option, value) => option.id === value.id}
                      onChange={(_, value) => setSelectedAreas(value)}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <Box {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.name}
                          </Box>
                        );
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Order"
                    name="order"
                    type="number"
                    value={'order' in changes ? changes.order : bannerInfo.order || ''}
                    error={errors?.order}
                    helperText={errors?.order}
                    onChange={(event) => handleChange('order', event.target.value)}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {action === 'edit'
                    && (
                    <Box style={{
                      width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '15px 0px 0px 0px',
                    }}
                    >
                      <Box>
                        {editOn
                          && (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={onCancel}
                            style={{ margin: '0px 15px' }}
                          >
                            Cancel
                          </Button>
                          )}
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => { editOn ? onSave() : setEditOn(true); }}
                        >
                          {updateLoading ? <CircularProgress color="secondary" size={20} /> : editOn ? 'Save' : 'Edit'}
                        </Button>
                      </Box>
                    </Box>
                    )}
              {action === 'add'
                    && (
                    <Box style={{
                      width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '15px 0px 0px 0px',
                    }}
                    >
                      <Button color="primary" variant="contained" onClick={onSave}>
                        {updateLoading ? <CircularProgress color="secondary" size={20} /> : 'Save'}
                      </Button>
                    </Box>
                    )}
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default BannerDetails;
