import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import AdminServices from 'src/services/AdminServices';
import is from 'is_js';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';

const REQUIRED_FIELD_ERROR = 'This field is required';

const CURRENCIES = [
  {
    id: 1,
    name: 'ARS',
  },
  {
    id: 2,
    name: 'CLP',
  },
  {
    id: 3,
    name: 'MXN',
  },
  {
    id: 4,
    name: 'PEN',
  },
  {
    id: 5,
    name: 'COP',
  },
];

const STATUS = [
  {
    value: 'purchase',
    name: 'Purchase',
  },
  {
    value: 'referred',
    name: 'Referred',
  },
  {
    value: 'refund',
    name: 'Refund',
  },
  {
    value: 'compensation',
    name: 'Compensation',
  },
  {
    value: 'ameizer_payment',
    name: 'Ameizer payment',
  },
  {
    value: 'ameizer_withdraw',
    name: 'Ameizer withdraw',
  },
  {
    value: 'customer_withdraw',
    name: 'Customer withdraw',
  },
];

function TransactionDetails({ defaultData, handleClose }) {
  const { userId } = useParams();
  const [changes, setChanges] = useState({});
  const [successToast, setSuccessToast] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (defaultData) {
      return;
    }
    setLoadingUser(true);
    return AdminServices.getUser(userId)
      .then((response) => {
        setUser(response.data);
        setLoadingUser(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingUser(false);
      });
  }, [userId]);

  useEffect(() => {
    if (defaultData) {
      setChanges(defaultData);
    }
  }, [defaultData]);

  function handleChange(prop, value) {
    setChanges({ ...changes, [prop]: value });
  }

  function onSave() {
    const doc = {
      ...changes,
      user_id: Number(userId),
    };
    if (!validateData(doc)) {
      return;
    }
    setLoading(true);
    if (defaultData) {
      return AdminServices.updateTransaction(doc.id, doc)
        .then(() => {
          setSuccessToast(true);
          onCancel();
        })
        .catch((err) => { setLoading(false); console.log(err); })
        .finally(() => {
          handleClose();
        });
    }
    return AdminServices.createTransaction(doc)
      .then(() => {
        setSuccessToast(true);
        onCancel();
      })
      .catch((err) => { setLoading(false); console.log(err); })
      .finally(() => {
        navigate(`/app/wallet/user/${userId}/transactions`);
      });
  }

  function validateData(data) {
    let validated;
    const errorsAcum = {};

    if (!data.amount) errorsAcum.amount = REQUIRED_FIELD_ERROR;
    if (!defaultData) {
      if (!data.currency_id) errorsAcum.currency_id = REQUIRED_FIELD_ERROR;
    }
    if (!data.description) errorsAcum.description = REQUIRED_FIELD_ERROR;
    if (!data.concept) errorsAcum.concept = REQUIRED_FIELD_ERROR;
    if (!('type' in data)) errorsAcum.type = REQUIRED_FIELD_ERROR;
    if (is.empty(errorsAcum)) validated = true;
    if (is.not.empty(errorsAcum)) {
      setErrors(errorsAcum);
      validated = false;
    }
    return validated;
  }

  function onCancel() {
    setChanges({});
    setErrors(null);
    setLoading(false);
  }

  return (
    <>
      <Snackbar
        open={successToast}
        autoHideDuration={6000}
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessToast(false)} severity="success">
          All done!
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader title={defaultData ? 'Edit user transaction' : 'Add user transaction'} />
        <Divider />
        <CardContent>
          <Grid container spacing={3} justify="center">
            <Box
              boxShadow={5}
              style={{
                padding: '25px', marginTop: '15px', marginLeft: '25px', width: '100%',
              }}
            >
              <Grid container spacing={3}>
                {!defaultData && (
                <Grid item xs={12}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    {!loadingUser && user.name ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle2">
                          {`User ID: ${user.id}`}
                        </Typography>
                        <Typography variant="h3">
                          {`${user.name} ${user.lastname}`}
                        </Typography>
                        <Typography variant="subtitle2">
                          {`User email: ${user.email}`}
                        </Typography>
                      </Box>
                    ) : <></>}
                    {loadingUser && <CircularProgress size={20} />}
                  </Box>
                </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Amount"
                    name="amount"
                    value={'amount' in changes ? changes.amount : 0}
                    error={!!errors?.amount}
                    helperText={errors?.amount}
                    onChange={(event) => handleChange('amount', Number(event.target.value))}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="currency-simple-select-outlined-label">Currency</InputLabel>
                    <Select
                      fullWidth
                      label="Currency"
                      value={'currency_id' in changes ? changes.currency_id : 'currency' in changes ? changes.currency.id : ''}
                      onChange={(event) => handleChange('currency_id', Number(event.target.value))}
                      id="currency-simple-select-outlined"
                      labelId="currency-simple-select-outlined-label"
                      error={!!errors?.currency_id}
                      required
                    >
                      {CURRENCIES.map((currency) => {
                        return (
                          <MenuItem key={currency.id} value={currency.id}>
                            {currency.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="type-simple-select-outlined-label">Type</InputLabel>
                    <Select
                      fullWidth
                      label="Type"
                      value={'type' in changes ? changes.type : ''}
                      onChange={(event) => handleChange('type', Number(event.target.value))}
                      id="type-simple-select-outlined"
                      labelId="type-simple-select-outlined-label"
                      error={!!errors?.type}
                      required
                    >
                      <MenuItem key="outcome" value="0">
                        Outcome
                      </MenuItem>
                      <MenuItem key="income" value="1">
                        Income
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    placeholder="description"
                    value={'description' in changes ? changes.description : ''}
                    error={!!errors?.description}
                    helperText={errors?.description}
                    onChange={(event) => handleChange('description', event.target.value)}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="concept-simple-select-outlined-label">Concept</InputLabel>
                    <Select
                      fullWidth
                      label="Concept"
                      value={'concept' in changes ? changes.concept : ''}
                      onChange={(event) => handleChange('concept', event.target.value)}
                      id="concept-simple-select-outlined"
                      labelId="concept-simple-select-outlined-label"
                      error={!!errors?.concept}
                      required
                    >
                      {STATUS.map((status) => {
                        return (
                          <MenuItem key={status.value} value={status.value}>
                            {status.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    id="due_date"
                    type="date"
                    label="Due Date"
                    name="due_date"
                    value={'due_date' in changes ? moment(changes.due_date).utc().format('YYYY-MM-DD') : ''}
                    onChange={
                      (event) => handleChange('due_date', moment(event.target.value).utc().endOf('day').toISOString())
}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box style={{
                width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '15px 0px 0px 0px',
              }}
              >
                <Button color="primary" variant="contained" onClick={onSave}>
                  {loading ? <CircularProgress color="secondary" size={20} /> : 'Save'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default TransactionDetails;
