import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import AdminServices from 'src/services/AdminServices';

const RATING_VALUES = ['1.00', '2.00', '3.00', '4.00', '5.00'];

const ReviewDetails = ({ review, onclose }) => {
  const [changes, setChanges] = useState({});
  const [loading, setLoading] = useState(false);

  function handleSave() {
    setLoading(true);
    return AdminServices.updateReview(review.id, changes)
      .then(() => {
        setChanges({});
        setLoading(false);
        onclose();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextField
                fullWidth
                label="Comments"
                value={'comment' in changes ? changes.comment : review.comment || ''}
                onChange={(e) => setChanges({ comment: e.target.value })}
                placeholder="Write a comment"
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="mutiple-rating-label">Rating</InputLabel>
                <Select
                  defaultValue={null}
                  labelId="mutiple-rating-label"
                  id="mutiple-rating"
                  value={'rating' in changes ? changes.rating : review.rating || null}
                  onChange={(event) => setChanges({ rating: event.target.value })}
                  label="Country"
                  name="country"
                >
                  <MenuItem key="country_null" value={null}>
                    NONE
                  </MenuItem>
                  {RATING_VALUES.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <Box style={{
                display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', height: '100%',
              }}
              >
                {loading && <CircularProgress />}
                <Button
                  sx={{ mx: 1 }}
                  disabled={Object.keys(changes).length === 0}
                  color="primary"
                  onClick={handleSave}
                  variant="outlined"
                  load
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReviewDetails;
