import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AuthService from 'src/services/authService';
import { useNavigate } from 'react-router';

import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import AreaDetails from 'src/components/area/add/AreaDetail';
import AreaPoligonMap from 'src/components/area/AreaPoligonMap';
import AreaListPoints from 'src/components/area/AreaListPoints';

function AreaEdit() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [points, setPoints] = useState([]);
  const [center, setCenter] = useState([]);
  const [relativeAreas, setRelativeAreas] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/area/${id}`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        setDefaultValue(response.data);
        console.log(response.data);
        polygonsPolygonStringsToObject(response.data.polygon);
        // console.log({lat:response.data.center.lat, lng: response.data.center.lng})
        // console.log('{lat:response.data.center.lat, lng: response.data.center.lng}')
        response.data.center
          ? setCenter({ lat: response.data.center.lat, lng: response.data.center.lng })
          : setCenter({ lat: response.data.polygon[0][0], lng: response.data.polygon[0][1] });
        console.log(response.data);
        setIsLoad(true);
      });
  }, []);

  const handlePointsAdd = (event) => {
    setPoints([...points, { point: points.length, latlng: { lat: event.latLng.lat(), lng: event.latLng.lng() } }]);
  };

  function handleDeletePoint(indexPoint) {
    const newPoints = points.filter((p) => (p !== indexPoint));
    setPoints(newPoints);
  }

  async function polygonsPolygonStringsToObject(polygons) {
    const transformedPoints = polygons.map((point, index) => ({
      latlng: { lat: point[0], lng: point[1] },
      point: index,
    }));
    console.log(transformedPoints);
    setPoints(transformedPoints);
  }

  function polygosObjetToPolygonStrings(polygons) {
    return polygons.map((point) => [point.latlng.lat, point.latlng.lng]);
  }

  function handleMovePoint(lat, lng, index) {
    const newpoints = points.map((p) => {
      if (p.point === index) {
        return { point: index, latlng: { lat, lng } };
      }
      return p;
    });
    setPoints(newpoints);
  }

  const handleDeleteAllPoint = () => {
    setPoints([]);
  };

  function handleSave(details) {
    const sendPolygons = polygosObjetToPolygonStrings(points);
    console.log('details', details);
    axios.patch(`${process.env.REACT_APP_MODERN_API_URL}/admin/area/${id}`,
      {
        name: details.name,
        transport_price: details.transport_price,
        active: details.active,
        parent_id: details.parent_id,
        polygon: sendPolygons,
      },
      {
        headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` },
      })
      .then((response) => {
        alert('Success');
        navigate('/app/areas');
        console.log(response);
      }).catch((e) => { console.log(e); });
  }

  const handleRelativeAreas = (areas) => {
    const poligons = areas.filter(
      (a) => a.id !== defaultValue.id).map((a) => relativePolygonsPolygonStringsToObject(a.polygon));
    setRelativeAreas(poligons);
  };

  function relativePolygonsPolygonStringsToObject(polygons) {
    const transformedPoints = polygons.map((point, index) => ({
      latlng: { lat: point[0], lng: point[1] },
      point: index,
    }));
    return transformedPoints;
  }

  return (
    <>
      <Helmet>
        <title>Edit Area </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/areas">
              Areas
            </Link>
            <Typography color="textPrimary">Edit Area</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {isLoad ? (
                <AreaDetails
                  handleSave={handleSave}
                  defaultValue={defaultValue}
                  action="edit"
                  setRelativeAreas={handleRelativeAreas}
                />
              ) : <></>}
            </Grid>
            <Grid item lg={10} md={10} xs={10}>
              {isLoad ? (
                <AreaPoligonMap
                  points={points}
                  center={center}
                  handleMovePoint={handleMovePoint}
                  handleDeletePoint={handlePointsAdd}
                  relativeAreas={relativeAreas}
                />
              ) : <></>}
            </Grid>
            <Grid item lg={2} md={2} xs={2}>
              {isLoad ? (
                <AreaListPoints
                  points={points}
                  handleDeletePoint={handleDeletePoint}
                  handleDeleteAllPoint={handleDeleteAllPoint}
                />
              ) : <> </>}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default AreaEdit;
