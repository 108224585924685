import axios from 'axios';

export const saveCard = (auth_token, card_token, ip, device_id) => axios.request({
  url: `${process.env.REACT_APP_PAYMENTS_API_URL}/dlocal/secure-card`,
  method: 'POST',
  headers: { Authorization: `Bearer ${auth_token}` },
  data: {
    token: card_token,
    save_card: true,
    ip,
    device_id,
  },
});

export const getPublicIP = () => axios
  .request({
    url: 'https://api.ipify.org?format=json',
    method: 'GET',
  })
  .then((response) => response.data.ip || '')
  .catch((e) => {
    console.log(e);
  });
