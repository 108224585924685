import { Helmet } from 'react-helmet';
import { useState } from 'react';
import Service from 'src/services/authService';

import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router';

const emailValidation = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    )
  ) {
    return null;
  }
  if (email.trim() === '') {
    return 'Email is required';
  }
  return 'Please enter a valid email';
};

const passwordValidation = (password) => {
  if (password.trim() === '') {
    return 'password is required';
  }
  if (password.trim().length < 6) {
    return 'password needs to be at least six characters';
  }
  return null;
};

const Login = () => {
  const validate = {
    password: passwordValidation,
    email: emailValidation,

  };
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});

  const [touched, setTouched] = useState({});
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleBlur = (evt) => {
    const { name, value } = evt.target;

    // remove whatever error was there previously
    const { [name]: removedError, ...rest } = errors;

    // check for a new error
    const error = validate[name](value);

    // // validate the field if the value has been touched
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error }),
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    // validate the form
    const formValidation = Object.keys(values).reduce(
      (acc, key) => {
        const newError = validate[key](values[key]);
        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          },
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched },
      },
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (
      !Object.values(formValidation.errors).length // errors object is empty
      && Object.values(formValidation.touched).length
        === Object.values(values).length // all fields were touched
      && Object.values(formValidation.touched).every((t) => t === true) // every touched field is true
    ) {
      Service.auth(values.email, values.password).then((response) => {
        window.localStorage.setItem('userData', JSON.stringify(response));
        navigate('/', { replace: true });
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">

          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Sign in
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Sign in on the internal platform
              </Typography>
            </Box>
            <TextField
              errors={errors}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            {touched.firstName && errors.firstName}
            <TextField
              errors={errors}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {touched.firstName && errors.firstName}
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign in now
              </Button>
            </Box>
          </form>
          {/* <Debug values={values} errors={errors} touched={touched} /> */}
        </Container>
      </Box>
    </>
  );
};

export default Login;
