import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Checkbox,
  FormControlLabel,
  Divider,
  Grid,
  TextField,
  Autocomplete,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import AdminServices from 'src/services/AdminServices';
import BookingMap from './BookingMap';

const status = [
  {
    value: 'searching',
    label: 'Searching (Esperando al Ameizer)',
  },
  {
    value: 'created',
    label: 'Created (No pagado)',
  },
  {
    value: 'accepted',
    label: 'Accepted (Aceptado por el Ameizer)',
  },
  {
    value: 'ameizer_paid',
    label: 'Paid (Pagado al Ameizer)',
  },
  {
    value: 'on_my_way',
    label: 'On the way (Ameizer en camino al servicio)',
  },
  {
    value: 'arrived_to_destination',
    label: 'Arrived to destination (Ameizer llego al servicio)',
  },
  {
    value: 'done',
    label: 'Done (Realizado)',
  },
  {
    value: 'cancelled',
    label: 'Cancelled (Cancelado)',
  },
  {
    value: 'refunded',
    label: 'Refunded (Devolución realizada)',
  },
];

const BookingDetails = ({
  handleSave, defaultValue, handleSaveProfessional, handleSaveUser,
}) => {
  const [ameizerSelected, setAmeizerSelected] = useState({});
  const [ameizerList, setAmeizerList] = useState([]);
  const [values, setValues] = useState({});
  const [user, setUser] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [isLoadEdit, setIsLoadEdit] = useState(true);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    fetchProfessionals();
    fetchCustomerAddresses();
  }, []);

  function fetchProfessionals() {
    AdminServices.getProfessionals({ status: 'accepted' })
      .then((response) => {
        console.log('Get accepted professionals ');
        setAmeizerList(response.data);
        setIsLoadEdit(true);
      })
      .catch((error) => console.log(error));
  }

  function fetchCustomerAddresses() {
    const customerId = defaultValue.shoppingCart.user.id;
    AdminServices.getUserAddresses(customerId)
      .then((response) => {
        console.log('addresses', response.data);
        setAddresses(response.data);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (defaultValue) {
      setValues(
        { ...defaultValue, date: moment(defaultValue.date).utc() },
      );
      if (defaultValue.shoppingCart.user.id) {
        setAmeizerSelected(defaultValue.professional);
        setUser({ buyer_user_email: defaultValue.shoppingCart.user.email });
      }
      setIsLoad(true);
    } else {
      setIsLoad(true);
    }
  }, []);

  const localHandleUserSave = (prop) => {
    console.log(prop);
    handleSaveUser(user[prop]);
  };
  const handleChangeUser = (event) => {
    setUser({
      ...values,
      [event.target.name]: event.target.value,
    });
    console.log(event.target.value);
  };

  const localHandleSave = (name) => {
    console.log(name);
    console.log(values[name]);
    if (name === 'date') {
      handleSave({ [name]: moment(values[name]).utc() });
    } else {
      handleSave({ [name]: values[name] });
    }
  };

  const saveAll = () => {
    // console.log("saveAll", values)
    handleSave(values);
  };

  const handleAmeizerChange = (ameizer) => {
    setAmeizerSelected(ameizer);
    console.log(ameizer);
  };

  const handleSaveAmeizer = () => {
    if (!ameizerSelected || !ameizerSelected.id) {
      alert("Can't save this without an ameizer");
      setAmeizerSelected(defaultValue.professional || {});
      return;
    }
    handleSaveProfessional(ameizerSelected.id);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeStartDate = (date) => {
    setValues({
      ...values,
      date: moment(date).utc(),
    });
  };

  const transportPrice = ((defaultValue.transport_price || 0) * Number(values.transport_percentage_cost)) / 100;

  return (
    <form style={{ marginBottom: '30px' }}>
      { isLoad && isLoadEdit
        ? (
          <Card>

            <CardHeader
              subheader="The information can be edited"
              title="Bookings details"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={8}>
                  <Alert
                    severity="error"
                  >
                    Attention: assigning to an Ameizer automatically creates a Reservation Request in accepted
                    status assigning it to the selected Ameizer.
                  </Alert>
                </Grid>
                <Grid item md={8} xs={8}>
                  <Box>
                    <Autocomplete
                      id="professisonal-list"
                      options={ameizerList}
                      disableClearable
                      getOptionLabel={(option) => (
                        typeof option !== 'string'
                          ? `${`${option.id} - ${option.user.name} ${option.user.lastname}`}` : option)}
                      value={ameizerSelected && ameizerSelected.user
                        ? `${`${ameizerSelected.id} - ${ameizerSelected.user.name} ${ameizerSelected.user.lastname}`}`
                        : ''}
                      renderInput={(params) => <TextField {...params} label="Search amaizer" variant="outlined" />}
                      getOptionSelected={(option, value) => option.id === value.id}
                      onChange={(_, value) => handleAmeizerChange(value)}
                      renderOption={(props, option) => (
                        <MenuItem key={option.id} value={option.id} {...props}>
                          {`${option.id} - ${option.user.name} ${option.user.lastname}`}
                        </MenuItem>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    style={{ height: '100%' }}
                    onClick={handleSaveAmeizer}
                    variant="outlined"
                  >
                    Save Ameizer
                  </Button>
                </Grid>
                <Grid item md={8} xs={8}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="atatus-select"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      label="Status"
                    >
                      {status.map((state) => (
                        <MenuItem key={state.value} value={state.value}>
                          {state.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    onClick={() => localHandleSave('status')}
                    variant="outlined"
                    style={{ height: '100%' }}
                  >
                    Save Status
                  </Button>
                </Grid>
                <Grid item md={8} xs={8}>
                  <MuiPickersUtilsProvider utils={MomentUtils} style={{ width: '100%' }}>
                    <KeyboardDateTimePicker
                      variant="outlined"
                      style={{ width: '100%' }}
                      ampm={false}
                      label="Reserved Date"
                      value={moment(values.date).utc()}
                      onChange={(date) => handleChangeStartDate(date)}
                      InputAdornmentProps={{ position: 'start' }}
                      format="DD/MM/yyyy HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    name="date"
                    style={{ height: '100%' }}
                    onClick={() => localHandleSave('date')}
                    variant="outlined"
                  >
                    Save Reserved Date
                  </Button>
                </Grid>
                <Grid item md={5} xs={5}>
                  <TextField
                    fullWidth
                    label="Transport Percentage Cost (%)"
                    name="transport_percentage_cost"
                    onChange={handleChange}
                    required
                    value={values.transport_percentage_cost}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={3}>
                  <TextField
                    fullWidth
                    label="Transport Price"
                    name="transport_percentage_cost"
                    disabled
                    required
                    value={transportPrice}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={4}>
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    name="transport_price"
                    style={{ height: '100%' }}
                    onClick={() => localHandleSave('transport_percentage_cost')}
                    variant="outlined"
                  >
                    Save Transport Price
                  </Button>
                </Grid>
                <Grid item md={8} xs={8}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="mutiple-address-id-label">Address</InputLabel>
                    <Select
                      defaultValue={null}
                      labelId="mutiple-address-id-label"
                      id="mutiple-address-id"
                      value={values.address_id || values.address?.id}
                      onChange={handleChange}
                      label="Address"
                      name="address_id"
                    >
                      <MenuItem key="address_null" value={null}>
                        NONE
                      </MenuItem>
                      {addresses.map((a, i) => {
                        return (
                          <MenuItem key={`address_${i}`} value={a.id}>
                            {a.formatted_address}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    name="address_id"
                    style={{ height: '100%' }}
                    onClick={() => localHandleSave('address_id')}
                    variant="outlined"
                  >
                    Save Address
                  </Button>
                </Grid>
                <Grid item md={4} xs={4}>
                  <FormControlLabel
                    control={<Checkbox checked={values.address?.has_parking} name="has_parking" />}
                    label="Parking"
                  />
                  {/* <Button
                  sx={{ mx: 1 }}
                  color="primary"
                  style={{ height: '100%' }}
                  name="address"
                  // onClick={() => localHandleSave('address')}
                  variant="outlined"
                >
                  Save Address
                </Button> */}
                </Grid>
                <Grid item md={12} xs={12}>
                  <BookingMap center={values.address?.coordinate} />
                </Grid>
                <Grid item md={8} xs={8}>
                  <Alert
                    severity="error"
                  >
                    Attention: changing a buyer user changes all the bookings associated with the cart.
                    If you are going to change the buyer, make sure you know well what you are doing and write your
                    email well (obviously you have to have a user created to be able to associate a purchase with it).
                  </Alert>
                </Grid>
                <Grid item md={8} xs={8}>
                  <TextField
                    fullWidth
                    label="Buyer User"
                    name="buyer_user_email"
                    onChange={handleChangeUser}
                    required
                    value={user.buyer_user_email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={4}>
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    name="buyer_user_email"
                    style={{ height: '100%' }}
                    onClick={() => localHandleUserSave('buyer_user_email')}
                    variant="outlined"
                  >
                    Save Buyer User
                  </Button>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box style={{ width: '100%', textAlign: 'center', marginTop: '24px' }}>
                    <Button
                      style={{ margin: '0 auto' }}
                      color="primary"
                      onClick={saveAll}
                      variant="contained"
                    >
                      Save Booking
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
        : null}
    </form>
  );
};

export default BookingDetails;
