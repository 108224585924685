import { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Checkbox, ListItemText } from '@material-ui/core';

const AreaSelectTree = ({
  areas, handelslected, defaultareasSelected, multiple,
}) => {
  const [localAreas, setLocalAreas] = useState();
  const [isLoadlocalArea, setIsLoadlocalArea] = useState(false);

  const handleChange = (event, child) => {
    handelslected(areas.filter((area) => event.target.value.includes(area.name)), child);
    setLocalAreas(event.target.value);
  };

  const handleSingleChange = (event) => {
    handelslected(areas.filter((area) => event.target.value === area.name));
    setLocalAreas(event.target.value);
  };

  useEffect(() => {
    if (defaultareasSelected) {
      if (defaultareasSelected.length > 0) {
        setLocalAreas(defaultareasSelected.map((x) => x?.name));
      } else {
        setLocalAreas(defaultareasSelected);
      }
    } else {
      setLocalAreas([[areas[0]].name]);
    }
    setIsLoadlocalArea(true);
  }, []);

  return (
    <>
      {multiple && isLoadlocalArea
        ? (
          <>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-outlined-label">Asociated area</InputLabel>
              <Select
                labelId="Areas-multiple-select-outlined-label"
                id="Areas-multiple-select-outlined"
                value={localAreas}
                multiple
                onChange={(event, child) => handleChange(event, child)}
                renderValue={(selected) => selected.join('| ')}
                label="Asociated area"
              >
                {areas.map((area) => (
                  <MenuItem key={area.id} value={area.name}>
                    <Checkbox checked={localAreas.indexOf(area.name) > -1} />
                    <ListItemText primary={area.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )
        : isLoadlocalArea
          ? (
            <>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Areas</InputLabel>
                <Select
                  labelId="Areas-simple-select-outlined-label"
                  id="Areas-simple-select-outlined"
                  value={localAreas}
                  onChange={handleSingleChange}
                  label="Areas"
                >
                  {areas.map((area) => (
                    <MenuItem key={area.id} value={area.name}>
                      {area.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )
          : null}
    </>
  );
};
export default AreaSelectTree;
