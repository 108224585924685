import { Helmet } from 'react-helmet';
import {
  Box, Container, Typography,
} from '@material-ui/core';

const ResetPasswordSuccess = () => (
  <>
    <Helmet>
      <title>Reset Password | Ameiz</title>
    </Helmet>
    <Box sx={{
      backgroundColor: 'background.default',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    }}
    >
      <Container maxWidth="sm">
        <Box sx={{ mb: 3 }}>
          <Typography color="textPrimary" variant="h2">Nueva contraseña guardada correctamente</Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Ingresa al App o Web con tus nuevas credenciales
          </Typography>
        </Box>
      </Container>
    </Box>
  </>
);

export default ResetPasswordSuccess;
