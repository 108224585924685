import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const MultipleFileUpload = ({ handleUpload }) => {
  const [showImages, setShowImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setShowImages((currentValues) => [...currentValues, URL.createObjectURL(file)]);
      getBase64(file).then((file64) => { handleUpload(file64); });
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <form>
      <div
        {...getRootProps({ className: 'dropzone' })}
        style={{ border: '2px dashed rgb(238, 238, 238)', padding: '80px', backgroundColor: 'rgb(250, 250, 250)' }}
      >
        <input {...getInputProps()} />
        <p>Drag n drop some files here, or click to select files</p>
      </div>
      <div className="form-group multi-preview" style={{ marginTop: '24px' }}>
        {(showImages || []).map((url) => (
          <img
            src={url}
            key={url.name}
            width="25%"
            style={{ paddingRight: '8px' }}
          />
        ))}
      </div>
    </form>
  );
};
export default MultipleFileUpload;
