import { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import PackDetails from 'src/components/packs/PackDetails';
import AdminServices from 'src/services/AdminServices';
import { useNavigate } from 'react-router';

const PackCreate = () => {
  const [saveLoading, setSaveLoading] = useState(false);
  const navigate = useNavigate();

  const handleCreatePack = async (pack, items, packAreas) => {
    setSaveLoading(true);
    const pack_item_ids = [];

    const newItemsList = items.map((item) => ({
      service_variant_id: item?.service_variant_id, sessions: item?.sessions, cost_percentage: item?.cost_percentage, price_percentage: item?.price_percentage,
    }));

    try {
      await Promise.all(newItemsList.map(async (item, i) => {
        const response = await AdminServices.createPackItem(item);
        pack_item_ids.push(response.data.id);
      }));

      const newPack = {
        ...pack,
        pack_item_id: pack_item_ids,
      };

      const responseCreatePack = await AdminServices.createPack(newPack);

      const newPackAreas = packAreas.map((p) => ({
        name: p?.name, pack_id: responseCreatePack.data.id, description: p?.description, image: p.image, area_id: p.area_id,
      }));

      await Promise.all(newPackAreas.map(async (item, i) => {
        const packAreaResponse = await AdminServices.createPackArea(item);
      }));

      navigate('/app/packs');
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Session Pack </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/packs">
              Session Packs
            </Link>
            <Typography color="textPrimary">Create Session Pack</Typography>
          </Breadcrumbs>

          <Grid
            container
            spacing={3}
          >
            <Grid item lg={12} md={12} xs={12}>
              {saveLoading && (
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
              {!saveLoading && <PackDetails handleCreatePack={handleCreatePack} />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default PackCreate;
