import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthService from 'src/services/authService';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';

const ACTIVE_OPTIONS = [
  { id: 1, label: 'True', value: true },
  { id: 2, label: 'False', value: false },
];

const AreaProfileDetails = ({
  handleSave,
  defaultValue,
  setRelativeAreas,
}) => {
  const [values, setValues] = useState({});
  const [localAreas, setLocalAreas] = useState([]);
  const [selectedParent, setSelectedParent] = useState();
  useEffect(() => {
    if (selectedParent && Number(selectedParent) !== 0 && localAreas.length > 0) {
      const localRelativeAreas = localAreas.filter((a) => (a.parent ? a.parent.id === Number(selectedParent) : null));
      setRelativeAreas(localRelativeAreas);
    }
  }, [selectedParent, localAreas]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/areas`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        const emptyArea = {
          id: 0, name: 'With out Parents', transport_price: '', children_title: '', children: [],
        };
        setLocalAreas([emptyArea, ...response.data]);
      });
  }, []);
  useEffect(() => {
    setValues(defaultValue);
    if (defaultValue.parent) {
      setValues({ ...defaultValue, parent_id: defaultValue.parent.id });
      setSelectedParent(defaultValue.parent.id);
    } else {
      setSelectedParent('With out Parent');
    }
  }, []);

  const handleChangeParent = (event) => {
    console.log(event.target.value);
    setValues({ ...values, parent_id: event.target.value });
    setSelectedParent(event.target.value);
  };

  const handleLocalSave = () => {
    handleSave(values);
  };

  const handleChange = (event) => {
    const updatedValue = event.target.value === 'false' || event.target.value === 'true' ? JSON.parse(event.target.value) : event.target.value;
    setValues({
      ...values,
      [event.target.name]: updatedValue,
    });
  };

  return (
    <form>
      <Card>
        <CardHeader
          subheader="The information can be edited"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Transport price"
                name="transport_price"
                type="number"
                onChange={handleChange}
                required
                value={values.transport_price}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Parent"
                name="parent_id"
                value={selectedParent}
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                onChange={handleChangeParent}
                required
                select
                variant="outlined"
              >
                {localAreas.map((area) => (
                  <option
                    key={area.id}
                    value={area.id}
                  >
                    {area.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Operative"
                name="active"
                value={values.active}
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                onChange={handleChange}
                required
                select
                variant="outlined"
              >
                {ACTIVE_OPTIONS.map((o) => <option key={o.id} value={o.value}>{o.label}</option>)}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            onClick={handleLocalSave}
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AreaProfileDetails;
