import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import AdminServices from 'src/services/AdminServices';
import TransactionsListToolbar from 'src/components/transactions/TransactionsListToolbar';
import TransactionsListResult from 'src/components/transactions/TransactionsListResult';
import DeleteModal from 'src/components/comon/Alert';
import TransactionDetails from 'src/components/transactions/TransactionDetails';
import Balances from 'src/components/transactions/Balances';

const DELETE_MODAL_TITLE = 'Do you want to remove this transaction ?';

const DEFAULT_FILTERS = {
  from: null,
  to: null,
  page_size: 10,
  page_number: 1,
};

const TransactionsList = () => {
  const { userId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS, user_id: Number(userId) });
  const [transactionSelected, setTransactionSelected] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(null);
  const [balances, setBalances] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);

  async function getTransactions(params = {}) {
    setLoading(true);
    try {
      const response = await AdminServices.getUserTransactions(userId, params);
      setTransactions(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function getBalance() {
    setLoadingBalance(true);
    return AdminServices.getUserBalance(userId)
      .then((response) => setBalances(response.data))
      .then(() => setLoadingBalance(false))
      .catch((err) => console.timeLog(err));
  }

  useEffect(() => {
    Promise.all([getTransactions(filters), getBalance()]);
  }, [filters]);

  function handleFilters(updated) {
    setFilters({ ...filters, ...updated });
  }

  function handleClearFilters() {
    setFilters({ ...DEFAULT_FILTERS, user_id: Number(userId) });
  }

  const handleCloseDeleteModal = () => {
    setTransactionSelected(null);
  };

  const handleAcceptDelete = async () => {
    setDeleteLoading(true);
    try {
      await AdminServices.deleteTransaction(transactionSelected.id);
      getTransactions();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
      handleCloseDeleteModal();
    }
  };

  const handleCloseEditModal = () => {
    setTransactionToEdit(null);
    getTransactions();
  };

  return (
    <>
      <Helmet>
        <title>User Transactions | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/wallets">
              Wallets
            </Link>
            <Typography color="textPrimary">Transactions</Typography>
          </Breadcrumbs>
          <TransactionsListToolbar
            handleFilters={(f) => handleFilters(f)}
            isLoading={isLoading}
            filters={filters}
            handleClearFilters={handleClearFilters}
          />
          <Balances balances={balances} loadingBalance={loadingBalance} />
          <Box sx={{ pt: 3 }}>
            <TransactionsListResult
              handleFilters={(f) => handleFilters(f)}
              filters={filters}
              transactions={transactions}
              setTransactionSelected={setTransactionSelected}
              userId={userId}
              setTransactionToEdit={setTransactionToEdit}
            />
          </Box>
          <DeleteModal
            title={DELETE_MODAL_TITLE}
            content={`ID: ${transactionSelected?.id}`}
            handleClose={handleCloseDeleteModal}
            open={transactionSelected}
            Agree={handleAcceptDelete}
            loading={deleteLoading}
          />
          <EditModal
            content={transactionToEdit}
            handleClose={handleCloseEditModal}
            open={transactionToEdit}
          />
        </Container>
      </Box>
    </>
  );
};

const EditModal = ({
  content, handleClose, open,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <TransactionDetails defaultData={content} handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default TransactionsList;
