import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  CircularProgress,
} from '@material-ui/core';
import { debounce } from 'lodash';
import { useState, useCallback, useEffect } from 'react';

import { Search as SearchIcon } from 'react-feather';
import { NavLink as RouterLink } from 'react-router-dom';

const CustomerListToolbar = (props) => {
  const { isLoading, toSearch } = props;
  const [search, setSearch] = useState('');
  const request = debounce((value) => props.onSearchChange(value), 500);

  const debounceRequest = useCallback((value) => request(value), []);

  const onChange = (event) => {
    debounceRequest(event);
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (!toSearch) {
      setSearch('');
    }
  }, [toSearch]);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          component={RouterLink}
          to="/app/customer/add"
          color="primary"
          variant="contained"
        >
          Add customer
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px',
          }}
          >
            <Box sx={{ maxWidth: 500 }} style={{ flex: 'auto' }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search customer"
                variant="outlined"
                onChange={onChange}
                value={search}
              />
            </Box>
            {isLoading && <Box><CircularProgress /></Box>}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default CustomerListToolbar;
