import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import AdminServices from 'src/services/AdminServices';
import Collapse from '@material-ui/core/Collapse';

const WalletsListResult = ({ users, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [currentViews, setViews] = useState([0, 10]);

  useEffect(() => { setPage(0); setViews([0, limit]); }, [rest.tosearch]);

  const handleLimitChange = (event) => {
    setViews([currentViews[0], currentViews[0] + event.target.value]);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleNextButton = () => {
    setViews([currentViews[1], currentViews[1] + limit]);
  };

  const handleBackButton = () => {
    if (currentViews[0] - limit < 0) {
      setViews([0, limit]);
      return;
    }
    setViews([currentViews[0] - limit, currentViews[1] - limit]);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Customer
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    Balance
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    Options
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.slice(...currentViews).map((user) => { return <UserRow key={user.id} user={user} />; })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={users.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        labelDisplayedRows={() => `${currentViews[0]} - ${currentViews[1] > users.length
          ? users.length : currentViews[1]} of ${users.length}`}
        rowsPerPageOptions={[5, 10, 25]}
        backIconButtonProps={{ onClick: handleBackButton, disabled: currentViews[0] === 0 }}
        nextIconButtonProps={{ onClick: handleNextButton }}
      />
    </Card>
  );
};

WalletsListResult.propTypes = {
  users: PropTypes.array.isRequired,
};

function UserRow({ user }) {
  const [balances, setBalances] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [open, setOpen] = useState(false);

  function getBalance(id) {
    setLoadingBalance(true);
    setOpen(true);
    return AdminServices.getUserBalance(id)
      .then((response) => setBalances(response.data))
      .then(() => setLoadingBalance(false))
      .catch((err) => console.timeLog(err));
  }

  function handleHideBalances() {
    setBalances(false);
    setOpen(false);
  }

  return (
    <>
      <TableRow
        hover
        key={user.id}
      >
        <TableCell>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Avatar
              src={user.avatarUrl}
              sx={{ mr: 2 }}
            >
              {getInitials(user.name)}
            </Avatar>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {`${user.name} ${user.lastname}`}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          {user.email}
        </TableCell>
        <TableCell>
          {!balances
          && (
          <Box style={{ justifyContent: 'center', display: 'flex' }}>
            <Button
              color="primary"
              variant="contained"
              value={user.id}
              sx={{ mx: 1 }}
              onClick={() => getBalance(user.id)}
            >
              View Balances
            </Button>
          </Box>
          )}
          {open && !loadingBalance && (
          <Box style={{ justifyContent: 'center', display: 'flex' }}>
            <Button
              color="primary"
              variant="contained"
              value={user.id}
              sx={{ mx: 1 }}
              onClick={() => handleHideBalances()}
            >
              Hide Balances
            </Button>
          </Box>
          )}
        </TableCell>
        <TableCell>
          <Box style={{ justifyContent: 'center', display: 'flex' }}>
            <Button
              color="primary"
              variant="contained"
              value={user.id}
              sx={{ mx: 1 }}
              component={RouterLink}
              onClick={() => { console.log(`wallet/user/${user.id}/transaction/add`); }}
              to={`/app/wallet/user/${user.id}/transaction/add`}
            >
              Create Transaction
            </Button>
            <Button
              variant="contained"
              color="warning"
              sx={{ mx: 1 }}
              component={RouterLink}
              onClick={() => { console.log(`wallet/user/${user.id}/transactions/`); }}
              to={`/app/wallet/user/${user.id}/transactions`}
            >
              View transactions
            </Button>
            <Button
              color="success"
              style={{ color: '#FFFFFF' }}
              variant="contained"
              sx={{ mx: 1 }}
              value={user.id}
              component={RouterLink}
              onClick={() => { console.log(`customers/${user.id}/edit`); }}
              to={`/app/customer/${user.id}/edit`}
            >
              view profile
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      {loadingBalance && (
      <TableRow>
        <TableCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      )}
      {open && !loadingBalance && balances.length === 0 && (
      <TableRow>
        <TableCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography variant="button">Without balances</Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      )}
      {open && !loadingBalance
        && balances.length > 0
          && (
            <TableRow>
              <TableCell colSpan={1} />
              <TableCell colSpan={2}>
                <Collapse in={open} timeout="auto" unmountOnExit colSpan={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Balance ID
                          </TableCell>
                          <TableCell>
                            Currency
                          </TableCell>
                          <TableCell>
                            Balance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {balances.map((balance) => <BalanceRow key={balance.id} balance={balance} />)}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
              <TableCell colSpan={1} />
            </TableRow>
          )}
    </>
  );
}

function BalanceRow({ balance }) {
  return (
    <TableRow>
      <TableCell>
        {balance.id}
      </TableCell>
      <TableCell>
        {balance.currency.name}
      </TableCell>
      <TableCell>
        {balance.amount}
      </TableCell>
    </TableRow>
  );
}

export default WalletsListResult;
