import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  TablePagination,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const ReviewsListResult = ({
  reviews, filters, handleFilter, loading, setSelected,
}) => {
  function handlePageFiltersChange(prop, value) {
    handleFilter({ ...filters, [prop]: value });
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small">
            <ReviewsTableHead />
            <TableBody>
              {reviews.map((review) => (
                <ReviewRow
                  key={`${review.booking.id + review.created_date}`}
                  setSelected={setSelected}
                  review={review}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        onPageChange={(_, page) => handlePageFiltersChange('page_number', page)}
        onRowsPerPageChange={(event) => handlePageFiltersChange('page_size', event.target.value)}
        page={filters.page_number}
        rowsPerPage={filters.page_size}
        rowsPerPageOptions={[20, 50, 100]}
        labelDisplayedRows={() => console.log('next page')}
        ActionsComponent={(props) => TablePaginationActions({ ...props, loading })}
      />
    </Card>
  );
};

function TablePaginationActions(props) {
  const {
    page, onPageChange, loading,
  } = props;
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {loading && <CircularProgress size={20} />}
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

function ReviewRow({ review, setSelected }) {
  return (
    <TableRow
      hover
    >
      <TableCell>
        {review.booking.id}
      </TableCell>
      <TableCell>
        {moment(review.created_date).local().format('YYYY-MM-DD HH:mm')}
      </TableCell>
      <TableCell>
        <Typography>{`${review.user.name} ${review.user.lastname}`}</Typography>
        <Typography>{review.user.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{`${review.professional.user.name} ${review.professional.user.lastname}`}</Typography>
        <Typography>{review.professional.user.email}</Typography>
      </TableCell>
      <TableCell>
        {`${review.booking.serviceVariantArea.name}`}
      </TableCell>
      <TableCell>
        {review.reviewer_type}
      </TableCell>
      <TableCell>
        {review.rating}
      </TableCell>
      <TableCell>
        {review.comment}
      </TableCell>
      <TableCell>
        <Button
          value={review.id}
          onClick={() => setSelected(review)}
          variant="contained"
        >
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}

function ReviewsTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          Booking ID
        </TableCell>
        <TableCell>
          Date
        </TableCell>
        <TableCell>
          Customer
        </TableCell>
        <TableCell>
          Ameizer
        </TableCell>
        <TableCell>
          SVA
        </TableCell>
        <TableCell>
          Reviewer Type
        </TableCell>
        <TableCell>
          Rating
        </TableCell>
        <TableCell>
          Comments
        </TableCell>
        <TableCell>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            Options
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default ReviewsListResult;
