import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import PromocodeToolBar from 'src/components/promocodes/PromocodeToolBar';
import PromocodeListResults from 'src/components/promocodes/PromocodeListResults';

import AdminServices from 'src/services/AdminServices';
import DeleteModal from 'src/components/comon/Alert';

const DELETE_MODAL_TITLE = 'Do you want to remove this promocode ?';

const DEFAULT_FILTERS = {
  code: '',
  page_size: 10,
  page_number: 1,
};

const PromocodeList = () => {
  const [promocodes, setPromocodes] = useState([]);
  const [promocodeToDelete, setPromocodeToDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPromocodeList(filters);
  }, [filters]);

  const getPromocodeList = async (filter) => {
    setLoading(true);
    try {
      const response = await AdminServices.getPromocodes(filter);
      console.log('getPromocodes', response.data);
      setLoading(false);
      setPromocodes(response.data);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  function deletePromocode() {
    setDeleteLoading(true);
    return AdminServices.deletePromocode(promocodeToDelete)
      .then(() => {
        const filteredList = promocodes.filter((c) => c.id !== promocodeToDelete);
        setPromocodes(filteredList);
        setOpen(false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  }

  const handleClickOpenDeleteModal = (promocodeId) => {
    setPromocodeToDelete(promocodeId);
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  function handleFiltersChange(changes) {
    setFilters({ ...filters, ...changes });
  }

  return (
    <>
      <Helmet>
        <title> PromoCodes | Ameiz </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Promocodes</Typography>
          </Breadcrumbs>
          {/* Todo add filter */}
          {/* <PromocodeToolBar handlefilter={handlefilter} /> */}
          <PromocodeToolBar handleFiltersChange={handleFiltersChange} filters={filters} isLoading={loading} />
          <Box sx={{ pt: 3 }}>
            <PromocodeListResults
              promocodes={promocodes}
              handleClickOpenDeleteModal={handleClickOpenDeleteModal}
              handleFiltersChange={handleFiltersChange}
              filters={filters}
            />
          </Box>
        </Container>
        <DeleteModal
          handleClose={handleCloseDeleteModal}
          open={open}
          Agree={deletePromocode}
          title={DELETE_MODAL_TITLE}
          content={`ID: ${promocodeToDelete}`}
          loading={deleteLoading}
        />
      </Box>
    </>
  );
};

export default PromocodeList;
