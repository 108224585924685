import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { CONFIG_VALUE_TYPES, CONFIG_VALUE_CATEGORIES } from 'src/utils/constants';
import AdminServices from 'src/services/AdminServices';
import { debounce } from 'lodash';

const ConfigValuesToolbar = ({ filters, handleFilter }) => {
  const [areas, setAreas] = useState([]);
  const [values, setValues] = useState({ ...filters });
  const request = debounce((value) => handleFilter(value), 500);
  const debounceRequest = useCallback((value) => request(value), []);

  useEffect(() => {
    getAreas();
  }, []);

  const getAreas = async () => {
    try {
      const response = await AdminServices.getAreas();
      setAreas(response.data.filter((a) => !a.parent));
    } catch (error) {
      console.log(error);
    }
  };

  const handlechange = (prop, value) => {
    setValues({
      ...values,
      [prop]: value,
    });
    debounceRequest({ ...filters, [prop]: value });
  };

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="category-select-id-label">Categories</InputLabel>
                  <Select
                    key={values?.category}
                    defaultValue={null}
                    size="small"
                    labelId="category-select-id-label"
                    id="category-select"
                    value={values?.category}
                    onChange={(event) => handlechange('category', event.target.value)}
                    label="Categories"
                    name="category"
                  >
                    <MenuItem key="category_null" value={null}>
                      None
                    </MenuItem>
                    {CONFIG_VALUE_CATEGORIES.map((c, i) => (
                      <MenuItem key={`category_${i}`} value={c.value}>
                        {c.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="type-select-id-label">Types</InputLabel>
                  <Select
                    key={values?.type}
                    defaultValue={null}
                    size="small"
                    labelId="type-select-id-label"
                    id="type-select"
                    value={values?.type}
                    onChange={(event) => handlechange('type', event.target.value)}
                    label="Types"
                    name="type"
                  >
                    <MenuItem key="type_null" value={null}>
                      None
                    </MenuItem>
                    {CONFIG_VALUE_TYPES.map((c, i) => (
                      <MenuItem key={`type_${i}`} value={c.value}>
                        {c.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="country-select-id-label">Countries</InputLabel>
                  <Select
                    key={values?.country}
                    defaultValue={null}
                    size="small"
                    labelId="country-select-id-label"
                    id="country-select"
                    value={values?.country}
                    onChange={(event) => handlechange('country_name', event.target.value ? event.target.value.name : null)}
                    label="Countries"
                    name="country"
                  >
                    <MenuItem key="country_null" value={null}>
                      None
                    </MenuItem>
                    {areas.map((c, i) => (
                      <MenuItem key={`country_${i}`} value={c}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ConfigValuesToolbar;
