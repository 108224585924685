import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import AddonDetails from 'src/components/addon/addonDetails';
import AdminServices from 'src/services/AdminServices';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

function EditAddon() {
  const { addonId } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [addon, setAddon] = useState({});

  useEffect(() => {
    getAddonData();
  }, []);

  const getAddonData = async () => {
    try {
      const response = await AdminServices.getAddon(addonId);
      setAddon(response.data);
      setIsLoad(true);
    } catch (error) {
      console.log(error);
      setIsLoad(false);
    }
  };

  const handleUpdateNewAddon = async (newAddon, addonAreas) => {
    console.log(newAddon, addonAreas);
  };

  return (
    <>
      <Helmet>
        <title>Add Addon </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">

          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/addons">
              Addons
            </Link>
            <Typography color="textPrimary">Edit Addon</Typography>
          </Breadcrumbs>

          {isLoad && (
          <AddonDetails
            handleSaveNewAddon={handleUpdateNewAddon}
            saveLoading={false}
            defaultValue={addon}
            action="edit"
          />
          )}

        </Container>
      </Box>
    </>
  );
}

export default EditAddon;
