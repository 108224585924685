import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Briefcase as BriefcaseIcon,
  BookOpen,
  Globe,
  File,
  Gift,
  User,
  CreditCard,
  Heart,
  MessageCircle,
  DollarSign,
  MapPin,
  MessageSquare,
  Package,
} from 'react-feather';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import NavItem from './NavItem';

const user = {
  avatar: {
    url: '/static/images/avatars/avatar_6.png',
  },
  jobTitle: 'Senior Developer',
  name: 'Katarina',
  lastname: 'Smith',
};

const items = [
  {
    href: '/app/addons',
    icon: BookOpen,
    title: 'Addons',
  },
  {
    href: '/app/bookings',
    icon: BookOpen,
    title: 'Bookings',
  },
  {
    href: '/app/reviews',
    icon: MessageSquare,
    title: 'Reviews',
  },
  {
    href: '/app/service/variant',
    icon: File,
    title: 'Service Variants',
  },
  {
    href: '/app/packs',
    icon: Package,
    title: 'Session Packs',
  },
  {
    href: '/app/booking-packs',
    icon: Package,
    title: 'Booking Packs',
  },
  {
    href: '/app/professionals',
    icon: UsersIcon,
    title: 'Professionals',
  },
  {
    href: '/app/professional/tracking',
    icon: MapPin,
    title: 'Professionals Tracking',
  },
  {
    href: '/app/service',
    icon: BarChartIcon,
    title: 'Services',
  },
  {
    href: '/app/categories',
    icon: BriefcaseIcon,
    title: 'Categories',
  },
  {
    href: '/app/areas',
    icon: Globe,
    title: 'Areas',
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error',
  },
  {
    href: '/app/promocodes',
    icon: Gift,
    title: 'Promocodes',
  },
  {
    href: '/app/customers',
    icon: User,
    title: 'Customers',
  },
  {
    href: '/app/settlements',
    icon: DollarSign,
    title: 'Payments',
  },
  {
    href: '/app/banners',
    icon: Heart,
    title: 'Banners',
  },
  {
    href: '/app/conversations',
    icon: MessageCircle,
    title: 'Conversations',
  },
  {
    href: '/app/wallets',
    icon: CreditCard,
    title: 'Wallets',
  },
  {
    href: '/app/config-values',
    icon: SettingsOutlinedIcon,
    title: 'Config values',
  },
];

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));
const DashboardSidebar = ({
  setOpenMenu, openMenu,
}) => {
  const classes = useStyles();
  const [userData, setUserData] = useState(user);

  useEffect(() => {
    if (window.localStorage.getItem('userData')) {
      setUserData(JSON.parse(window.localStorage.getItem('userData')));
    }
  }, []);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => setOpenMenu((currentValue) => !currentValue)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          src={userData.avatar?.url}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
          }}
          to="/app/account"
        />
        <Typography
          color="textPrimary"
          variant="h5"
          style={{ marginTop: '12px' }}
        >
          {`${userData.name} ${userData.lastname}`}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={() => setOpenMenu((currentValue) => !currentValue)}
        open={openMenu}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 256,
          },
        }}
      >
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false,
};

export default DashboardSidebar;
