import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useState, useEffect } from 'react';

import CustomerListResults from 'src/components/customer/CustomerListResults';
import CustomerListToolbar from 'src/components/customer/CustomerListToolbar';
import AdminServices from 'src/services/AdminServices';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [toSearch, setSearch] = useState('');

  async function getUsersList(query = null) {
    setLoading(true);
    try {
      const response = await AdminServices.getUsers(query);
      setCustomers(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getUsersList(toSearch);
  }, [toSearch]);

  function handleSearch(event) {
    setSearch(event.target.value);
  }

  function handleDeletion() {
    setSearch('');
  }

  return (
    <>
      <Helmet>
        <title>Customers | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Customers</Typography>
          </Breadcrumbs>
          <CustomerListToolbar onSearchChange={handleSearch} isLoading={isLoading} toSearch={toSearch} />
          <Box sx={{ pt: 3 }}>
            <CustomerListResults customers={customers} tosearch={toSearch} handleDeletion={handleDeletion} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerList;
