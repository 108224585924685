import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { Delete } from '@material-ui/icons';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import DeleteModal from 'src/components/comon/Alert';
import AdminServices from 'src/services/AdminServices';

const DELETE_MODAL_TITLE = 'Do you want to remove this user ?';

const CustomerListResults = ({ customers, ...rest }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [currentViews, setViews] = useState([0, 10]);
  const [open, setOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(false);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  useEffect(() => { setPage(0); setViews([0, limit]); }, [rest.tosearch]);

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1),
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setViews([currentViews[0], currentViews[0] + event.target.value]);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.info(event.target.value);

    setPage(newPage);
  };

  const handleNextButton = () => {
    setViews([currentViews[1], currentViews[1] + limit]);
  };

  const handleBackButton = () => {
    if (currentViews[0] - limit < 0) {
      setViews([0, limit]);
      return;
    }
    setViews([currentViews[0] - limit, currentViews[1] - limit]);
  };

  const handleCloseDeleteModal = () => {
    setCustomerToDelete(false);
    setOpen(false);
  };

  useEffect(() => {
    if (!customerToDelete) {
      return;
    }
    setOpen(true);
  }, [customerToDelete]);

  const handleDeleteCustomer = () => {
    setOpen(false);
    setCustomerToDelete(false);
    console.info('deleting user...', customerToDelete);
    AdminServices.deleteCustomer(customerToDelete)
      .then(() => rest.handleDeletion())
      .catch((error) => console.log(error));
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Last Name
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Phone
                </TableCell>
                <TableCell>
                  Affiliation code
                </TableCell>
                <TableCell>
                  Referred by
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    Options
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.slice(...currentViews).map((customer) => (
                <TableRow
                  hover
                  key={customer.id}
                  selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Avatar
                        src={customer.avatarUrl}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(customer.name)}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {customer.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {customer.lastname}
                  </TableCell>
                  <TableCell>
                    {customer.email}
                  </TableCell>
                  <TableCell>
                    {customer.phone}
                  </TableCell>
                  <TableCell>
                    {customer?.affiliation_code}
                  </TableCell>
                  <TableCell>
                    {customer?.referred_by ? customer?.referred_by : '-'}
                  </TableCell>
                  <TableCell>
                    <Box style={{ justifyContent: 'center', display: 'flex' }}>
                      <ButtonGroup>
                        <Button
                          color="primary"
                          variant="contained"
                          value={customer.id}
                          style={{ marginRight: 4 }}
                          component={RouterLink}
                          onClick={() => { console.log(`customers/${customer.id}/edit`); }}
                          to={`/app/customer/${customer.id}/edit`}
                        >
                          View info
                        </Button>
                        <Button
                          color="success"
                          variant="contained"
                          value={customer.id}
                          style={{ marginRight: 4 }}
                          component={RouterLink}
                          onClick={() => { console.log(`wallet/user/${customer.id}/transactions`); }}
                          to={`/app/wallet/user/${customer.id}/transactions`}
                        >
                          View transactions
                        </Button>
                        <Button
                          variant="contained"
                          style={{ marginLeft: 4 }}
                          color="secondary"
                          children={<Delete />}
                          onClick={() => setCustomerToDelete(customer.id)}
                        />
                      </ButtonGroup>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <DeleteModal
        handleClose={handleCloseDeleteModal}
        open={open}
        Agree={handleDeleteCustomer}
        title={DELETE_MODAL_TITLE}
        content={`ID: ${customerToDelete}`}
      />
      <TablePagination
        component="div"
        count={customers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        labelDisplayedRows={() => `${currentViews[0]} - ${currentViews[1] > customers.length
          ? customers.length : currentViews[1]} of ${customers.length}`}
        rowsPerPageOptions={[5, 10, 25]}
        backIconButtonProps={{ onClick: handleBackButton, disabled: currentViews[0] === 0 }}
        nextIconButtonProps={{ onClick: handleNextButton }}
      />
    </Card>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired,
};

export default CustomerListResults;
