import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import axios from 'axios';
import ServicesListResults from 'src/components/service/ServiceListResult';
import ServicesListToolbar from 'src/components/service/ServiceToolBar';
import AuthService from 'src/services/authService';

const ServicesList = () => {
  const [services, setServices] = useState([]);
  const [servicesAll, setServicesAll] = useState([]);

  const handlefilter = (filter) => {
    filter.length < 3
      ? setServices(servicesAll)
      : setServices(servicesAll.filter((service) => service.name.toLowerCase().match(filter.toLowerCase())));
  };

  const handleCategoryFilter = (value) => {
    value
      ? setServices(servicesAll.filter((service) => service.categories.find((c) => c.id === value)))
      : setServices(servicesAll);
  };

  useEffect(() => {
    handleGetServices();
  }, []);

  const handleGetServices = () => {
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/service`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        setServices(response.data);
        setServicesAll(response.data);
      });
  };

  function handledeleteservice(value) {
    console.log(value);
    axios.delete(`${process.env.REACT_APP_MODERN_API_URL}/admin/service/${value}`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then(() => {
        setServices(services.filter((Service) => Service.id !== value));
      }).catch((e) => {
        console.log(e);
      });
  }
  return (
    <>
      <Helmet>
        <title> Services | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Services</Typography>
          </Breadcrumbs>
          <ServicesListToolbar handlefilter={handlefilter} handleCategoryFilter={handleCategoryFilter} />
          <Box sx={{ pt: 3 }}>
            <ServicesListResults services={services} handledeleteservice={handledeleteservice} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ServicesList;
