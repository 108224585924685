import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const ConfigValues = ({ configValues, setConfigValueToEdit }) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Category
                </TableCell>
                <TableCell align="center">
                  Type
                </TableCell>
                <TableCell align="center">
                  Value
                </TableCell>
                <TableCell align="center">
                  Area
                </TableCell>
                <TableCell align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configValues.sort((a, b) => a.area.id - b.area.id).map((configValue, i) => (
                <TableRow key={`configValue_${i}`} hover>
                  <TableCell component="th" scope="row" align="center">
                    {configValue?.category}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {configValue?.type}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center" style={{ maxWidth: '250px' }}>
                    {configValue?.value}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {configValue?.area?.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <IconButton aria-label="edit" size="small" onClick={() => setConfigValueToEdit(configValue)}>
                      <EditOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default ConfigValues;
