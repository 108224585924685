// import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

const SevicesListResults = ({ services, handledeleteservice, ...rest }) => {
  const handleDelete = (value) => () => {
    handledeleteservice(value);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Id
                </TableCell>
                <TableCell align="center">
                  Order
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  Category
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    Options
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.sort((a, b) => a.order - b.order).map((service) => (
                <TableRow
                  hover
                  key={service.id}
                >
                  <TableCell>
                    {service.id}
                  </TableCell>
                  <TableCell align="center">
                    {service.order}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {service.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {service.description}
                  </TableCell>
                  <TableCell>
                    -
                    {/* {service.categories[0]?.name} */}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        value={service.id}
                        component={RouterLink}
                        to={`/app/service/edit/${service.id}`}
                        sx={{ mx: 1 }}
                        color="primary"
                        variant="contained"
                      >
                        Edit service
                      </Button>
                      <Button
                        color="warning"
                        variant="contained"
                        onClick={handleDelete(service.id)}
                      >
                        Remove service
                      </Button>
                    </Box>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

SevicesListResults.propTypes = {
  services: PropTypes.array.isRequired,
};

export default SevicesListResults;
