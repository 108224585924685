import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search as SearchIcon } from 'react-feather';
import { debounce } from 'lodash';

import AdminServices from 'src/services/AdminServices';

const AddonToolbar = ({ setQuery, handleFilter }) => {
  const [variants, setVariants] = useState([]);
  const [areas, setAreas] = useState([]);
  const request = debounce((name, value) => handleFilter(name, value), 500);
  const debounceRequest = useCallback((name, value) => request(name, value), []);

  useEffect(() => {
    Promise.all([getVariants(), getAreas()]);
  }, []);

  const getVariants = async () => {
    try {
      const response = await AdminServices.getServiceVariants();
      setVariants(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAreas = async () => {
    try {
      const response = await AdminServices.getAreas();
      setAreas(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          component={RouterLink}
          to="/app/addon/add"
          color="primary"
          variant="contained"
        >
          Add Addon
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box style={{ display: 'flex' }}>
              <TextField
                fullWidth
                style={{ margin: '0px 12px' }}
                onChange={(e) => debounceRequest('query', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Addon"
                variant="outlined"
              />
              <Autocomplete
                id="sv-select"
                options={variants}
                groupBy={(option) => option.service.name}
                fullWidth
                onChange={(event, newValue) => {
                  handleFilter('service_variant_id', newValue);
                }}
                style={{ margin: '0px 12px' }}
                getOptionLabel={(option) => option.internal_name}
                renderInput={(params) => <TextField {...params} label="Service Variant" variant="outlined" />}
              />
              <Autocomplete
                id="area-select"
                options={areas}
                fullWidth
                onChange={(event, newValue) => {
                  handleFilter('area_id', newValue);
                }}
                style={{ margin: '0px 12px' }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Areas" variant="outlined" />}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default AddonToolbar;
