import { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import AuthService from 'src/services/authService';

import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';

import ServiceFrame from 'src/assets/service_frame.png';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}),
);

const ServiceDetails = ({ handleSave, defaultValue }) => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [showImage, setShowImage] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    console.log(defaultValue);
    if (defaultValue) {
      setShowImage(defaultValue?.image?.url);
      setValues({
        id: defaultValue.id,
        name: defaultValue.name,
        order: defaultValue.order,
        category_ids: defaultValue.category_ids,
        description: defaultValue.description,
      });
      setSelectedCategories(defaultValue.categories.map((category) => { return category.id; }));
      setShowImage(defaultValue?.image?.url);
    }
    // setSelectedCategories()
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/category`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        setCategories(response.data);

        setIsLoad(true);
      });
  }, []);

  const handleServiceCheck = (id) => {
    const selectedIndex = selectedCategories.indexOf(id);
    let newSelectedServiceNames = [];

    if (selectedIndex === -1) {
      newSelectedServiceNames = newSelectedServiceNames.concat(selectedCategories, id);
    } else if (selectedIndex === 0) {
      newSelectedServiceNames = newSelectedServiceNames.concat(selectedCategories.slice(1));
    } else if (selectedIndex === selectedCategories.length - 1) {
      newSelectedServiceNames = newSelectedServiceNames.concat(selectedCategories.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedServiceNames = newSelectedServiceNames.concat(
        newSelectedServiceNames.slice(0, selectedIndex),
        newSelectedServiceNames.slice(selectedIndex + 1),
      );
    }
    setSelectedCategories(newSelectedServiceNames);
    setValues({
      ...values,
      category_ids: newSelectedServiceNames,
    });
  };

  const handleLocalSave = () => {
    // console.log(values)
    handleSave(values);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = new window.Image();
        image.src = e.target.result;

        image.onload = () => {
          const { height, width } = image;
          if (height !== 660 || width !== 1029) {
            alert('The measures must be Width = 1029px and Height = 660px');
            return false;
          }
          resolve(reader.result);
        };
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    getBase64(event.target.files[0]).then((file64) => {
      setValues({
        ...values,
        [event.target.name]: file64,
      });
      if (file64) {
        setShowImage(URL.createObjectURL(file));
      }
    });
  };

  return (

    <form>
      {isLoad
        ? (
          <Card>
            <CardHeader
              subheader="The information can be edited"
              title="Service"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    helperText="Please specify the name"
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Order"
                    name="order"
                    value={values.order}
                    onChange={handleChange}
                    required
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    placeholder="Description"
                    margin="normal"
                    onChange={handleChange}
                    required
                    rows={4}
                    multiline
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Categories</FormLabel>
                    <FormGroup>
                      {categories.map((category) => {
                        return (
                          <FormControlLabel
                            key={category.id}
                            control={(
                              <Checkbox
                                checked={selectedCategories.indexOf(category.id) !== -1}
                                onChange={() => handleServiceCheck(category.id)}
                              />
)}
                            label={category.name}
                          />
                        );
                      })}
                    </FormGroup>
                    <FormHelperText>Select </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box
                    style={{
                      backgroundImage: showImage ? `url(${showImage})` : `url(${ServiceFrame})`,
                      backgroundSize: 'cover',
                      width: '514px',
                      height: '334px',
                      borderRadius: '8px',
                      border: '5px solid #ddd',
                      margin: 'auto',
                    }}
                  >
                    <input
                      accept="image/jpeg,image/png"
                      className={classes.input}
                      id="image"
                      type="file"
                      name="image"
                      onChange={handleUploadClick}
                    />
                    <label htmlFor="image">
                      <IconButton style={{ position: 'relative' }} component="span">
                        <AddAPhotoOutlinedIcon />
                      </IconButton>
                    </label>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button
                onClick={handleLocalSave}
                color="primary"
                variant="contained"
              >
                Save details
              </Button>
            </Box>
          </Card>
        )
        : null}
    </form>
  );
};

export default ServiceDetails;
