import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  CircularProgress,
  Button,
} from '@material-ui/core';
import AdminServices from 'src/services/AdminServices';

function ConversationResult({ data, unreads = [] }) {
  const [open, setOpen] = useState(false);
  const [professionalName, setProfessionalName] = useState(null);
  const [loading, setLoading] = useState(false);
  const read = unreads.find(
    (message) => message.professional_id === data[0].ameizer
    && data.find((d) => d.ameiz === `user_${message.user_id}`));

  useEffect(async () => {
    setLoading(true);
    if (data.find((d) => d.professional_name)) {
      setProfessionalName(`${data.find((d) => d.professional_name).professional_name}`);
      setLoading(false);
    } else {
      const response = await AdminServices.getProfessional(data[0].ameizer.replace('professional_', ''));
      setLoading(false);
      setProfessionalName(`${response.data.user.name} ${response.data.user.lastname}`);
    }
  }, []);

  return (
    <Card sx={{ mb: 2 }}>
      <Box sx={{ minWidth: 1050 }}>
        <Toolbar>
          <IconButton sx={{ mr: 2 }} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {loading && <CircularProgress />}
          {professionalName
          && (
          <>
            <Typography variant="h4" id="tableTitle" component="div">
              {`${professionalName}'s conversations`}
            </Typography>
            { read
            && (
            <Box display="flex" flex="1 1 auto" justifyContent="flex-end">
              <Button
                sx={{ mx: 1 }}
                color="secondary"
                variant="outlined"
              >
                Sin Leer
              </Button>
            </Box>
            )}
          </>
          )}
        </Toolbar>
        {open
          && (
          <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
            {data.map((conv) => {
              const notRead = unreads.find(
                (message) => message.professional_id === conv.ameizer && `user_${message.user_id}` === conv.ameiz);
              return (
                <ConversationItem
                  key={`${conv.ameiz + conv.ameizer}`}
                  conversation={conv}
                  notRead={notRead}
                />
              );
            })}
          </Collapse>
          )}
      </Box>
    </Card>
  );
}

function ConversationItem({ conversation = false, notRead = false }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerName, setCustomerName] = useState(false);

  useEffect(async () => {
    if (!conversation) {
      return;
    }
    if (!conversation.client_name) {
      setLoading(true);
      const response = await AdminServices.getUser(conversation.ameiz.replace('user_', ''));
      setLoading(false);
      setCustomerName({ name: response.data.name, lastname: response.data.lastname });
    } else {
      setCustomerName({ name: conversation.client_name, lastname: '' });
    }
  }, [conversation]);

  const messages = conversation
    ? Object.keys(conversation).filter(
      (key) => !key.includes('ameiz') && !key.includes('client_name') && !key.includes('professional_name'))
    : [];

  return (
    <Card>
      <Box sx={{ minWidth: 1050 }}>
        <Toolbar>
          <IconButton sx={{ mr: 2 }} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {loading && <CircularProgress />}
          {customerName
          && (
          <>
            <Typography variant="h4" id="tableTitle" component="div">
              {`Conversation with ${customerName.name} ${customerName?.lastname}`}
            </Typography>
            { notRead
            && (
            <Box display="flex" flex="1 1 auto" justifyContent="flex-end">
              <Button
                sx={{ mx: 1 }}
                color="secondary"
                variant="outlined"
              >
                Sin Leer
              </Button>
            </Box>
            )}
          </>
          )}
        </Toolbar>
        {open
          && (
          <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Sender
                    </TableCell>
                    <TableCell>
                      Date
                    </TableCell>
                    <TableCell>
                      Message
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages.map((key) => <Message message={conversation[key]} key={key} />)}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Collapse>
          )}
      </Box>
    </Card>
  );
}

function Message({ message }) {
  const date = moment(new Date(message?.created_at * 1000)).format('YYYY-MM-DD, HH:mm');

  return (
    <>
      <TableRow>
        <TableCell>
          {message?.sender}
        </TableCell>
        <TableCell>
          {date}
        </TableCell>
        <TableCell>
          {message?.message}
        </TableCell>
      </TableRow>
    </>
  );
}

export default ConversationResult;
