import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import AuthService from 'src/services/authService';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import BookingDetails from 'src/components/booking/BookingDetails';
import AdminServices from 'src/services/AdminServices';
import BookingPaymentDetails from 'src/components/booking/BookingPaymentDetails';

const BookingEdit = () => {
  const { bookingId } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});

  useEffect(() => {
    getBooking();
  }, []);

  const getBooking = async () => {
    try {
      const response = await AdminServices.getBooking(bookingId);
      setDefaultValue(response.data);
      setIsLoad(true);
    } catch (error) {
      console.log(error);
    }
  };

  function handleSaveProfessional(professional_id) {
    console.log(bookingId, professional_id);
    if (professional_id) {
      axios.post(`${process.env.REACT_APP_MODERN_API_URL}/admin/booking/${bookingId}/professional/`,
        {
          booking_id: bookingId,
          professional_id,
        },
        {
          headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` },

        })
        .then(() => {
          alert('success');
          // navigate('/app/booking');
        }).catch((e) => { console.log(e); alert('error'); });
    } else {
      alert('Please Complete Ameizer');
    }
  }

  function handleSave(bookingUpdated) {
    return AdminServices.updateBooking(bookingId, bookingUpdated)
      .then(() => {
        alert('Success');
      })
      .catch((error) => console.log(error),
      );
  }

  function handleSaveUser(email) {
    return AdminServices.getUserWithEmail(email)
      .then(() => {
        const bookingUpdated = { user_email: email };
        return AdminServices.updateBooking(bookingId, bookingUpdated)
          .then(() => alert('Success'))
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        alert('Error');
      });
  }

  return (
    <>
      <Helmet>
        <title>Edit Booking </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/bookings">
              Bookings
            </Link>
            <Typography color="textPrimary">Edit booking</Typography>
          </Breadcrumbs>

          <Grid
            container
            spacing={3}
          >
            <Grid item lg={12} md={12} xs={12}>
              {isLoad ? (
                <>
                  <BookingDetails
                    handleSaveUser={handleSaveUser}
                    handleSave={handleSave}
                    handleSaveProfessional={handleSaveProfessional}
                    defaultValue={defaultValue}
                  />
                  {defaultValue.shoppingCart.status === 'paid' && (
                  <BookingPaymentDetails
                    refetch={() => getBooking()}
                    booking={defaultValue}
                  />
                  )}
                </>
              ) : (
                <Box style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default BookingEdit;
