import { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';

import CategoryWebFrame from 'src/assets/category_web_frame.png';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}),
);

const CategoryDetails = ({ handleSave, defaultValue }) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [showWebImage, setShowWebImage] = useState();
  const [showMobileImage, setShowMobileImage] = useState();

  useEffect(() => {
    console.log(defaultValue);
    console.log('defaultValue');
    if (defaultValue) {
      setValues({
        name: defaultValue.name,
        order: defaultValue.order,
        description: defaultValue.description,
      });
      setShowWebImage(defaultValue?.mobile_image?.url);
      setShowMobileImage(defaultValue?.web_image?.url);
    }
    console.log(values);
  }, []);

  const handleLocalSave = () => {
    handleSave(values);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = new window.Image();
        image.src = e.target.result;

        image.onload = () => {
          const { height, width } = image;
          if (height !== 480 || width !== 1125) {
            alert('The measures must be Width = 1125px and Height = 480px');
            return false;
          }
          resolve(reader.result);
        };
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    getBase64(event.target.files[0]).then((file64) => {
      setValues({
        ...values,
        [event.target.name]: file64,
      });
      if (file64) {
        if (event.target.name === 'mobile_image') {
          setShowMobileImage(URL.createObjectURL(file));
        }
        if (event.target.name === 'web_image') {
          setShowWebImage(URL.createObjectURL(file));
        }
      }
    });
  };

  return (
    <form>
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title=""
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the name"
                label="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Order"
                name="order"
                value={values.order}
                onChange={handleChange}
                required
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                style={{ margin: 8 }}
                placeholder="Description"
                margin="normal"
                value={values.description}
                onChange={handleChange}
                required
                rows={4}
                multiline
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12} container justify="center">
              <Box style={{ width: '100%', textAlign: 'center' }}>
                <Typography variant="h3" component="h3">
                  Web Image
                </Typography>
              </Box>
              <Box
                style={{
                  backgroundImage: showWebImage ? `url(${showWebImage})` : `url(${CategoryWebFrame})`,
                  backgroundSize: 'cover',
                  width: '562.5px',
                  height: '240px',
                  borderRadius: '8px',
                  border: '5px solid #ddd',
                  margin: 'auto',
                }}
              >
                <input
                  accept="image/jpeg,image/png"
                  className={classes.input}
                  id="web_image"
                  type="file"
                  name="web_image"
                  onChange={handleUploadClick}
                />
                <label htmlFor="web_image">
                  <IconButton style={{ position: 'relative' }} component="span">
                    <AddAPhotoOutlinedIcon />
                  </IconButton>
                </label>
              </Box>
            </Grid>
            <Grid item md={6} xs={12} container justify="center">
              <Box style={{ width: '100%', textAlign: 'center' }}>
                <Typography variant="h3" component="h3">
                  Mobile Image
                </Typography>
              </Box>
              <Box
                style={{
                  backgroundImage: showMobileImage ? `url(${showMobileImage})` : `url(${CategoryWebFrame})`,
                  backgroundSize: 'cover',
                  width: '562.5px',
                  height: '240px',
                  borderRadius: '8px',
                  border: '5px solid #ddd',
                  margin: 'auto',
                }}
              >
                <input
                  accept="image/jpeg,image/png"
                  className={classes.input}
                  id="mobile_image"
                  type="file"
                  name="mobile_image"
                  onChange={handleUploadClick}
                />
                <label htmlFor="mobile_image">
                  <IconButton style={{ position: 'relative' }} component="span">
                    <AddAPhotoOutlinedIcon />
                  </IconButton>
                </label>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            onClick={() => { handleLocalSave(values); }}
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default CategoryDetails;
