import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// import axios from "axios";
import { useEffect, useState } from 'react';
import AdminServices from 'src/services/AdminServices';
import is from 'is_js';
import { useNavigate } from 'react-router';
import CustomerAddresses from './CustomerAddresses';
import CustomerBookings from './CustomerBookings';

const REQUIRED_FIELD_ERROR = 'This field is required';

const DEFAULT_DOC = {
  name: '',
  lastname: '',
  summary: '',
  identification_number: '',
  phone: '',
  authentication_provider_id: '',
  email: '',
  birthdate: '',
  role: 'user',
  identification_attachment_front: '',
  identification_attachment_back: '',
  minutes_offset: 0,
  avatar: '',
};

function CustomerDetails({ currentInfo = {}, action }) {
  const [changes, setChanges] = useState({});
  const [editOn, setEditOn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [countries, setCountries] = useState([false]);
  const [errorToast, setErrorToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [errors, setErrors] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({ ...DEFAULT_DOC, ...currentInfo });
  const [sendingEmail, setSendingEmail] = useState(false);
  const navigate = useNavigate();

  function handleChange(prop, value) {
    setChanges({ ...changes, [prop]: value });
  }

  const handleClickShowPassword = () => {
    setShowPassword((currentValue) => !currentValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword((currentValue) => !currentValue);
  };

  const handleMouseDownRepeatPassword = (event) => {
    event.preventDefault();
  };

  const getCountryList = async () => {
    try {
      const response = await AdminServices.getCountries();
      setCountries(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => getCountryList(), []);

  function onSave() {
    const doc = {
      ...customerInfo,
      ...changes,
    };
    setUpdateLoading(true);
    if (!validateData(doc)) {
      setUpdateLoading(false);
      return;
    }
    if (action === 'edit') {
      return AdminServices.updateUser(doc.id, doc)
        .then(() => {
          setCustomerInfo(doc);
          setSuccessToast(true);
          onCancel();
        })
        .catch((err) => { console.log(err); setUpdateLoading(false); });
    }
    if (action === 'add') {
      return AdminServices.createUser(doc)
        .then(() => {
          setCustomerInfo(doc);
          setSuccessToast(true);
          onCancel();
        })
        .catch((err) => { console.log(err); setUpdateLoading(false); })
        .finally(() => {
          navigate('/app/customers');
        });
    }
  }

  function validateData(data) {
    let validated;
    const errorsAcum = {};

    if (!data.name) errorsAcum.name = REQUIRED_FIELD_ERROR;
    if (!data.lastname) errorsAcum.lastname = REQUIRED_FIELD_ERROR;
    if (!data.country) errorsAcum.country = REQUIRED_FIELD_ERROR;
    if (!data.role) errorsAcum.role = REQUIRED_FIELD_ERROR;
    if (!data.email) errorsAcum.email = REQUIRED_FIELD_ERROR;
    if (data.email) errorsAcum.email = emailValidation(data.email);
    if (!data.identification_number) errorsAcum.identification_number = REQUIRED_FIELD_ERROR;
    if (action === 'add') {
      if (!data.password) errorsAcum.password = REQUIRED_FIELD_ERROR;
      if (data.password) errorsAcum.password = passwordValidation(data.password);
      if (!data.repeat_password) errorsAcum.repeat_password = REQUIRED_FIELD_ERROR;
      if (data.repeat_password && data.password) {
        if (data.password !== data.repeat_password) {
          errorsAcum.repeat_password = 'Passwords do not match';
          setErrorToast(true);
        }
      }
    }
    if (!data.birthdate) errorsAcum.birthdate = REQUIRED_FIELD_ERROR;
    if (!data.phone) errorsAcum.phone = REQUIRED_FIELD_ERROR;

    if (errorsAcum.email === 'ok') {
      delete errorsAcum.email;
    }
    if (errorsAcum.password === 'ok') {
      delete errorsAcum.password;
    }

    if (is.empty(errorsAcum)) validated = true;
    if (is.not.empty(errorsAcum)) {
      setErrors(errorsAcum);
      validated = false;
    }

    return validated;
  }

  const emailValidation = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return 'ok';
    }

    if (email.trim() === '') {
      return 'Email is required';
    }

    return 'Please enter a valid email';
  };

  const passwordValidation = (password) => {
    if (password.trim() === '') {
      return 'password is required';
    }
    if (password.trim().length < 6) {
      return 'password needs to be at least six characters';
    }
    return 'ok';
  };

  function onCancel() {
    setChanges({});
    setUpdateLoading(false);
    setErrors(null);
    setEditOn(false);
  }

  function resetPassword() {
    setSendingEmail(true);
    return AdminServices.resetUserPassword(customerInfo.email)
      .then(() => { setSendingEmail(false); setSuccessToast(true); });
  }

  return (
    <>
      <Snackbar
        open={errorToast}
        autoHideDuration={6000}
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorToast(false)} severity="error">
          Passwords do not match
        </Alert>
      </Snackbar>
      <Snackbar
        open={successToast}
        autoHideDuration={6000}
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessToast(false)} severity="success">
          All done!
        </Alert>
      </Snackbar>
      <Card style={{ maxWidth: '1200px', margin: 'auto' }}>
        <CardHeader title={action === 'edit' ? 'User info' : 'Add user'} />
        <Divider />
        <CardContent>
          <Grid container spacing={3} justify="center">
            <Box boxShadow={5} style={{ padding: '25px', marginTop: '15px', marginLeft: '25px' }}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Name"
                    name="name"
                    value={'name' in changes ? changes.name : customerInfo.name || ''}
                    error={!!errors?.name}
                    helperText={errors?.name}
                    onChange={(event) => handleChange('name', event.target.value)}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Last Name"
                    name="lastname"
                    value={'lastname' in changes ? changes.lastname : customerInfo.lastname || ''}
                    error={!!errors?.lastname}
                    helperText={errors?.lastname}
                    onChange={(event) => handleChange('lastname', event.target.value)}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    disabled={action === 'edit' ? !editOn : false}
                    name="email"
                    placeholder="Email"
                    value={'email' in changes ? changes.email : customerInfo.email || ''}
                    error={!!errors?.email}
                    helperText={errors?.email}
                    onChange={(event) => handleChange('email', event.target.value)}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="RUT"
                    disabled={action === 'edit' ? !editOn : false}
                    name="identification_number"
                    placeholder="RUT"
                    value={'identification_number' in changes
                      ? changes.identification_number : customerInfo.identification_number || ''}
                    error={!!errors?.identification_number}
                    helperText={errors?.identification_number}
                    onChange={(event) => handleChange('identification_number', event.target.value)}
                    required
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    id="date"
                    type="date"
                    label="Birthdate"
                    disabled={action === 'edit' ? !editOn : false}
                    error={!!errors?.birthdate}
                    helperText={errors?.birthdate}
                    name="birthdate"
                    value={'birthdate' in changes ? changes.birthdate : customerInfo.birthdate || ''}
                    onChange={(event) => handleChange('birthdate', event.target.value)}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    disabled={action === 'edit' ? !editOn : false}
                    placeholder="Phone"
                    value={'phone' in changes ? changes.phone : customerInfo.phone || ''}
                    error={!!errors?.phone}
                    helperText={errors?.phone}
                    onChange={(event) => handleChange('phone', event.target.value)}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="country-simple-select-outlined-label">Country</InputLabel>
                    <Select
                      fullWidth
                      label="Country"
                      value={'country' in changes ? changes.country : customerInfo.country || ''}
                      onChange={(event) => handleChange('country', event.target.value)}
                      disabled={action === 'edit' ? !editOn : false}
                      id="country-simple-select-outlined"
                      labelId="country-simple-select-outlined-label"
                      error={!!errors?.country}
                      required
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.id} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="role-simple-select-outlined-label">Role</InputLabel>
                    <Select
                      fullWidth
                      label="Role"
                      value={'role' in changes ? changes.role : customerInfo.role || ''}
                      onChange={(event) => handleChange('role', event.target.value)}
                      disabled={action === 'edit' ? !editOn : false}
                      id="role-simple-select-outlined"
                      labelId="role-simple-select-outlined-label"
                      error={!!errors?.role}
                      required
                    >
                      <MenuItem key="user" value="user">
                        User
                      </MenuItem>
                      <MenuItem key="ameizer" value="ameizer">
                        Ameizer
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {action === 'edit' && (
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Affiliation code"
                      name="affiliation_code"
                      disabled={action === 'edit'}
                      value={'affiliation_code' in changes ? changes.affiliation_code : customerInfo.affiliation_code || ''}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}
                {action === 'edit' && (
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Referred by"
                      name="referred_by"
                      disabled={action === 'edit'}
                      value={'referred_by' in changes ? changes.referred_by : customerInfo.referred_by || ''}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}
                {action === 'add' && (
                <>
                  {' '}
                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        required
                        fullWidth
                        disabled={action === 'edit' ? !editOn : false}
                        placeholder="Password"
                        label="Password"
                        name="password"
                        error={!!errors?.password}
                        helperText={errors?.password}
                        type={showPassword ? 'text' : 'password'}
                        value={'password' in changes ? changes.password : customerInfo.password || ''}
                        onChange={(event) => handleChange('password', event.target.value)}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                          )}
                        labelWidth={70}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-repeat-password">Repeat Password</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-repeat-password"
                        required
                        fullWidth
                        disabled={action === 'edit' ? !editOn : false}
                        placeholder="Repeat Password"
                        error={!!errors?.repeat_password}
                        label="Repeat Password"
                        name="repeat_password"
                        type={showRepeatPassword ? 'text' : 'password'}
                        value={'repeat_password' in changes
                          ? changes.repeat_password : customerInfo.repeat_password || ''}
                        onChange={(event) => handleChange('repeat_password', event.target.value)}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRepeatPassword}
                              onMouseDown={handleMouseDownRepeatPassword}
                              edge="end"
                            >
                              {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                          )}
                        labelWidth={70}
                      />
                    </FormControl>
                  </Grid>
                </>
                )}
              </Grid>
              {action === 'edit'
                    && (
                    <Box style={{
                      width: '100%', display: 'flex', justifyContent: 'space-between', margin: '15px 0px 0px 0px',
                    }}
                    >
                      <Button color="primary" variant="contained" onClick={resetPassword}>
                        {sendingEmail ? (
                          <CircularProgress
                            color="secondary"
                            size={20}
                          />
                        ) : 'Send email to reset Password'}
                      </Button>
                      <Box>
                        {editOn
                          && (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={onCancel}
                            style={{ margin: '0px 15px' }}
                          >
                            Cancel
                          </Button>
                          )}
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => { editOn ? onSave() : setEditOn(true); }}
                        >
                          {updateLoading ? <CircularProgress color="secondary" size={20} /> : editOn ? 'Save' : 'Edit'}
                        </Button>
                      </Box>
                    </Box>
                    )}
              {action === 'add'
                    && (
                    <Box style={{
                      width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '15px 0px 0px 0px',
                    }}
                    >
                      <Button color="primary" variant="contained" onClick={onSave}>
                        {updateLoading ? <CircularProgress color="secondary" size={20} /> : 'Save'}
                      </Button>
                    </Box>
                    )}
            </Box>
          </Grid>
        </CardContent>
      </Card>
      {action === 'edit'
      && (
        <>
          <Box sx={{ mt: 3, marginX: 'auto' }} style={{ maxWidth: '1200px' }}>
            <CustomerAddresses customerId={currentInfo.id} />
          </Box>
          <Box sx={{ mt: 3, marginX: 3 }}>
            <CustomerBookings customerId={currentInfo.id} />
          </Box>
        </>
      )}
    </>
  );
}

export default CustomerDetails;
