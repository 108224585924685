import { useState, useRef } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Box,
  Button,
  Grid,
  Card,
  FormControl,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import moment from 'moment';

const status = [
  {
    value: 'searching',
    label: 'Searching (Esperando al Ameizer)',
  },
  {
    value: 'created',
    label: 'Created (No pagado)',
  },
  {
    value: 'accepted',
    label: 'Accepted (Aceptado por el Ameizer)',
  },
  {
    value: 'ameizer_paid',
    label: 'Paid (Pagado al Ameizer)',
  },
  {
    value: 'on_my_way',
    label: 'On the way (Ameizer en camino al servicio)',
  },
  {
    value: 'arrived_to_destination',
    label: 'Arrived to destination (Ameizer llego al servicio)',
  },
  {
    value: 'done',
    label: 'Done (Realizado)',
  },
  {
    value: 'cancelled',
    label: 'Cancelled (Cancelado)',
  },
  {
    value: 'refunded',
    label: 'Refunded (Devolución realizada)',
  },
];

function SettlementsToolbar({ handleFilter, handleClearFilter, filters }) {
  const [values, setValues] = useState({ ...filters });
  const fromDateInput = useRef(null);
  const toDateInput = useRef(null);
  const professionalIdInput = useRef(null);

  const handlechange = (filter) => {
    setValues({
      ...values,
      ...filter,
    });
  };

  const handleLocalFilter = () => {
    handleFilter(values);
  };

  const clearFilter = () => {
    fromDateInput.current.value = '';
    toDateInput.current.value = '';
    professionalIdInput.current.value = '';

    setValues({
      from: moment().day(-30).toISOString(),
      to: new Date().toISOString(),
      status: ['done'],
      professional_id: null,
    });
    handleClearFilter();
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs>
            <TextField
              fullWidth
              label="From date"
              name="FROM"
              id="date-datetime"
              type="date"
              inputRef={fromDateInput}
              value={moment(values?.from).utc().format('YYYY-MM-DD')}
              onChange={(event) => handlechange(
                { from: moment(event.target.value).utc().startOf('day').toISOString() },
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search FROM date"
              variant="outlined"
            />
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              label="To date"
              name="TO"
              id="date-datetime"
              type="date"
              inputRef={toDateInput}
              value={moment(values?.to).utc().format('YYYY-MM-DD')}
              onChange={(event) => handlechange({ to: moment(event.target.value).utc().endOf('day').toISOString() })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search TO date"
              variant="outlined"
            />
          </Grid>
          <Grid item xs>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="mutiple-status-id-label">Status</InputLabel>
              <Select
                key={values?.STATUS}
                labelId="mutiple-status-id-label"
                id="mutiple-status-id"
                multiple
                value={values?.status}
                defaultValue={null}
                onChange={(event) => handlechange({ status: event.target.value })}
                label="Status"
                name="STATUS"
                renderValue={(selected) => selected.join(', ')}
              >
                {status.map((state) => (
                  <MenuItem key={state.value} value={state.value}>
                    <Checkbox checked={values?.status.indexOf(state.value) > -1} />
                    <ListItemText primary={state.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              label="Professional ID"
              name="professional_id"
              id="professional-id"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={professionalIdInput}
              value={values.professional_id}
              onChange={(event) => handlechange({ professional_id: Number(event.target.value) })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs>
            <Box style={{
              display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', height: '100%',
            }}
            >
              <Button
                sx={{ mx: 1 }}
                color="primary"
                onClick={handleLocalFilter}
                variant="outlined"
              >
                Filter
              </Button>
              <Button
                sx={{ mx: 1 }}
                color="primary"
                onClick={clearFilter}
                variant="outlined"
              >
                Clear Filter
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>

  );
}

export default SettlementsToolbar;
