import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import is from 'is_js';

import AreaSelectTree from 'src/components/comon/AreaSelectTree';
import ServiceVariantTree from 'src/components/comon/ServiceVariantTree';
import PercentageNumberDisplay from 'src/components/comon/PercentageNumberDisplay';
import NumberDisplay from 'src/components/comon/NumberDisplay';
import MoneyDisplay from 'src/components/comon/MoneyDisplay';
import AdminServices from 'src/services/AdminServices';
import UserSearch from 'src/components/comon/UserSearch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PromocodeDetails = ({
  handleSave, action, defaultValue, errors, saveLoading,
}) => {
  const [values, setValues] = useState({
    id: null,
    all_service_variants: false,
    all_users: false,
    areas_ids: null,
    code: '',
    description: '',
    discount_amount: 0,
    discount_percentage: 0,
    free_transport: false,
    max_total_uses: 0,
    max_uses_per_user: 0,
    promocode_type: 'percentage',
    service_variant_ids: [],
    start: '',
    total_uses: 0,
    users_ids: null,
  });
  const [isLoad, setIsLoad] = useState(false);
  const [titles, setTitles] = useState('');
  const [serviceVariants, setServiceVariants] = useState([]);
  const [areas, setAreas] = useState([]);
  const [serviceVariantSorted, setserviceVariantSorted] = useState([]);

  useEffect(() => {
    if (is.not.empty(serviceVariants)) {
      const options = serviceVariants.map((option) => {
        return {
          serviceName: option.service.name,
          ...option,
        };
      });
      setserviceVariantSorted(options.sort((a, b) => -b.serviceName.localeCompare(a.serviceName)));
    }
  }, [serviceVariants]);

  useEffect(() => {
    if (action === 'add') {
      setTitles('Add Promocode');
    }
    getSelectData();
  }, []);

  const getSelectData = async () => {
    await getServiceVariantList();
    await getAreaList();
    setIsLoad(true);
  };

  useEffect(() => {
    console.log('defaultValue', defaultValue);
    if (is.not.empty(defaultValue) && action === 'edit') {
      setValues({
        ...defaultValue,
        users_ids: defaultValue?.users.map((u) => u.id),
        service_variant_ids: defaultValue?.serviceVariants.map((u) => u.id),
        areas_ids: defaultValue?.areas.map((a) => a.id),
      });
      setIsLoad(true);
    }
  }, [defaultValue]);

  const locaLhandleSave = () => {
    handleSave(values);
  };

  const handleChange = (event) => {
    let newValue;
    if (
      event.target.name === 'discount_percentage'
      || event.target.name === 'discount_amount'
      || event.target.name === 'max_uses_per_user'
      || event.target.name === 'total_uses'
      || event.target.name === 'max_total_uses') {
      newValue = parseFloat(event.target.value);
    } else if (event.target.name === 'end') {
      newValue = moment(event.target.value).utc().endOf('day').toISOString();
    } else if (event.target.name === 'start') {
      newValue = moment(event.target.value).utc().startOf('day').toISOString();
    } else {
      newValue = event.target.value;
    }
    setValues({
      ...values,
      [event.target.name]: newValue,
    });
  };

  const handleChangeCheckBox = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };

  const getAreaList = async () => {
    try {
      const response = await AdminServices.getAreas();
      setAreas(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getServiceVariantList = async () => {
    try {
      const response = await AdminServices.getServiceVariants();
      setServiceVariants(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelectServiceVariants = (variants) => {
    setValues({
      ...values,
      service_variant_ids: variants.map((s) => s.id),
    });
  };

  const handleAreaSlected = async (areasSelected) => {
    const areaIds = areasSelected.map((a) => a.id);
    setValues({
      ...values,
      areas_ids: areaIds,
    });
  };

  const handleUserSelected = (selected) => {
    const userIds = selected.map((s) => s.id);
    setValues({
      ...values,
      users_ids: userIds,
    });
  };

  return (
    <form>
      {!isLoad && (
      <Box style={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </Box>
      )}
      {isLoad && (
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title={titles}
          action={(
            <TextField
              fullWidth
              label="Total uses"
              name="total_uses"
              color="secondary"
              disabled
              InputLabelProps={{ shrink: true }}
              value={values.total_uses}
              variant="outlined"
              InputProps={{
                inputComponent: NumberDisplay,
              }}
            />
          )}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Code"
                name="code"
                error={!!errors?.code}
                helperText={errors?.code}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                required
                value={values?.code}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                error={!!errors?.description}
                helperText={errors?.description}
                onChange={handleChange}
                required
                InputLabelProps={{ shrink: true }}
                value={values?.description}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="promocode_type-simple-select-outlined-label">Promocode type</InputLabel>
                <Select
                  labelId="promocode_type-simple-select-outlined-label"
                  id="promocode_type-simple-select-outlined"
                  value={values?.promocode_type}
                  onChange={handleChange}
                  name="promocode_type"
                  label="Promocode type"
                >
                  {CODE_TYPE.map((c, i) => (
                    <MenuItem
                      key={`codeType_${i}`}
                      name="promocode_type"
                      value={c}
                    >
                      {c}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                disabled={!values?.promocode_type || values?.promocode_type === 'fixed_amount'}
                label="Discount Percentage"
                name="discount_percentage"
                error={!!errors?.discount_percentage}
                helperText={errors?.discount_percentage}
                value={values?.discount_percentage}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                required
                variant="outlined"
                InputProps={{
                  inputComponent: PercentageNumberDisplay,
                }}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Discount Amount"
                name="discount_amount"
                disabled={!values?.promocode_type || values?.promocode_type === 'percentage'}
                onChange={handleChange}
                required
                error={!!errors?.discount_amount}
                helperText={errors?.discount_amount}
                InputLabelProps={{ shrink: true }}
                value={values?.discount_amount}
                variant="outlined"
                InputProps={{
                  inputComponent: MoneyDisplay,
                }}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Max uses per user"
                name="max_uses_per_user"
                error={!!errors?.max_uses_per_user}
                helperText={errors?.max_uses_per_user}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                required
                value={values?.max_uses_per_user}
                variant="outlined"
                InputProps={{
                  inputComponent: NumberDisplay,
                }}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Max total uses"
                name="max_total_uses"
                error={!!errors?.max_total_uses}
                helperText={errors?.max_total_uses}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                required
                value={values?.max_total_uses}
                variant="outlined"
                InputProps={{
                  inputComponent: NumberDisplay,
                }}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <AreaSelectTree
                areas={areas}
                handelslected={handleAreaSlected}
                multiple
                defaultareasSelected={values?.areas}
              />
              <span style={{ color: '#f44336' }}>{errors.area_id}</span>
            </Grid>
            <Grid item md={3} xs={6}>
              <FormControlLabel
                control={<Checkbox checked={values?.all_users} onChange={handleChangeCheckBox} name="all_users" />}
                label="All users"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <UserSearch
                handleUserSelected={handleUserSelected}
                disabled={values.all_users}
                defaultValue={action === 'edit' && values?.users}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                id="start"
                type="date"
                label="Start Date"
                error={!!errors?.start}
                helperText={errors?.start}
                name="start"
                value={moment(values?.start).utc().format('YYYY-MM-DD')}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                id="end"
                type="date"
                label="End Date"
                name="end"
                error={!!errors?.end}
                helperText={errors?.end}
                InputLabelProps={{ shrink: true }}
                value={moment(values?.end).utc().format('YYYY-MM-DD')}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FormControlLabel
                fullWidth
                control={(
                  <Checkbox
                    checked={values?.all_service_variants}
                    onChange={handleChangeCheckBox}
                    name="all_service_variants"
                  />
)}
                label="All service variants"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FormControlLabel
                fullWidth
                control={(
                  <Checkbox
                    checked={values?.free_transport}
                    onChange={handleChangeCheckBox}
                    name="free_transport"
                  />
)}
                label="Free Transport"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                id="promocode-sv-select"
                options={serviceVariantSorted}
                groupBy={(option) => option.serviceName}
                fullWidth
                multiple
                disableCloseOnSelect
                size="small"
                defaultValue={defaultValue?.serviceVariants || []}
                disabled={values?.all_service_variants}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event, newValue, reason) => {
                  handleSelectServiceVariants(newValue);
                }}
                freeSolo
                getOptionLabel={(option) => option.internal_name}
                renderInput={(params) => <TextField {...params} label="Service Variant" variant="outlined" />}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.internal_name}
                  </>
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  sx={{ mx: 1 }}
                  color="primary"
                  variant="contained"
                  onClick={locaLhandleSave}
                >
                  {saveLoading ? <CircularProgress color="secondary" /> : action === 'add' ? 'Add promocode' : 'Save'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      )}
    </form>
  );
};

export default PromocodeDetails;

const CODE_TYPE = [
  'fixed_amount',
  'percentage',
];
