import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import is from 'is_js';

import AddonDetails from 'src/components/addon/addonDetails';
import AdminServices from 'src/services/AdminServices';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

function ServiceVariantAddAddon() {
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSaveNewAddon = async (newAddon, addonAreas) => {
    setSaveLoading(true);
    try {
      const response = await AdminServices.createAddon(newAddon);
      if (is.not.empty(addonAreas)) {
        for (const i in addonAreas) {
          const newAddonArea = {
            ...addonAreas[i],
            add_on_id: response.data.id,
          };
          delete newAddonArea.viewId;
          const responseAddOnArea = await AdminServices.createAddonArea(newAddonArea);
        }
      }
      navigate('/app/addons');
    } catch (e) {
      console.log(e);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Add Addon </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">

          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/addons">
              Addons
            </Link>
            <Typography color="textPrimary">New Addon</Typography>
          </Breadcrumbs>

          <AddonDetails handleSaveNewAddon={handleSaveNewAddon} saveLoading={saveLoading} />

        </Container>
      </Box>
    </>
  );
}

export default ServiceVariantAddAddon;
