/* eslint-disable max-len */
import axios from 'axios';

axios.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('token');

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (err) => Promise.reject(err));

const AdminServices = {

  getServiceVariants: (area_id) => {
    console.log('AdminServices.getServiceVariants()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant`;
    return axios.get(url, { params: { area_id } });
  },
  createServiceVariantArea: (serviceVariantArea) => {
    console.log('AdminServices.createServiceVariantArea()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area`;
    return axios.post(url, serviceVariantArea);
  },
  getServiceVariantArea: (serviceVariantAreaId) => {
    console.log(`AdminServices.deleteServiceVariantArea(${serviceVariantAreaId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${serviceVariantAreaId}`;
    return axios.get(url);
  },
  deleteServiceVariantArea: (serviceVariantAreaId) => {
    console.log(`AdminServices.deleteServiceVariantArea(${serviceVariantAreaId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${serviceVariantAreaId}`;
    return axios.delete(url);
  },
  getServiceVariant: (serviceId, variantId) => {
    console.log(`AdminServices.getServiceVariant(${serviceId}-${variantId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service/${serviceId}/variant/${variantId}`;
    return axios.get(url);
  },
  deleteServiceVariant: (serviceId, serviceVariantId) => {
    console.log(`AdminServices.deleteServiceVariant(${serviceId}-${serviceVariantId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service/${serviceId}/variant/${serviceVariantId}`;
    return axios.delete(url);
  },
  getAddons: ({
    page_number, page_size, from, to, service_variant_id, area_id, query,
  }) => {
    console.log('AdminServices.getAddons()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon`;
    return axios.get(url, {
      params: {
        page_number, page_size, from, to, service_variant_id, area_id, query,
      },
    });
  },
  getAddon: (addonId) => {
    console.log(`AdminServices.getAddon(${addonId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon/${addonId}`;
    return axios.get(url);
  },
  createAddon: (addon) => {
    console.log('AdminServices.createAddon()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon`;
    return axios.post(url, addon);
  },
  deleteAddon: (addonId) => {
    console.log('AdminServices.deleteAddon()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon/${addonId}`;
    return axios.delete(url);
  },
  edditAddon: (addonId, addon) => {
    console.log('AdminServices.EditAddon()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon/${addonId}`;
    return axios.patch(url, addon);
  },
  createAddonArea: (addon) => {
    console.log('AdminServices.createAddonArea()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon-area`;
    return axios.post(url, addon);
  },
  getAddonArea: (addonAreaId) => {
    console.log('AdminServices.getAddonArea()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon-area/${addonAreaId}`;
    return axios.get(url);
  },
  updateAddon: (addonId, addon) => {
    console.log(`AdminServices.updateAddon(${addonId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon/${addonId}`;
    return axios.patch(url, addon);
  },
  updateAddonArea: (addOnAreaId, addOnArea) => {
    console.log(`AdminServices.updateAddonArea(${addOnAreaId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon-area/${addOnAreaId}`;
    return axios.patch(url, addOnArea);
  },
  deleteAddonArea: (addOnAreaId) => {
    console.log(`AdminServices.deleteAddonArea(${addOnAreaId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/addon-area/${addOnAreaId}`;
    return axios.delete(url);
  },
  getAreas: () => {
    console.log('AdminServices.getAreas()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/areas`;
    return axios.get(url);
  },
  getCountries: () => {
    console.log('AdminServices.getCountries()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/area`;
    return axios.get(url);
  },
  getArea: (areaId) => {
    console.log(`AdminServices.getArea(${areaId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/area/${areaId}`;
    return axios.get(url);
  },
  deleteArea: (areaId) => {
    console.log(`AdminServices.deleteArea(${areaId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/area/${areaId}`;
    return axios.delete(url);
  },
  // Users
  getUser: (id) => {
    console.log(`AdminServices.getUser(${id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${id}`;
    return axios.get(url);
  },
  getUsers: (query = null) => {
    console.log(`AdminServices.getUsers(${query})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/search`;
    return axios.post(url, { name: query });
  },
  getUserAddresses: (id) => {
    console.log(`AdminServices.getUserAddresses(${id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/${id}/address`;
    return axios.get(url);
  },
  updateUserAddress: (userId, addressId, address) => {
    console.log(`AdminServices.updateUserAddress(${addressId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/${userId}/address/${addressId}`;
    return axios.patch(url, address);
  },
  createUserAddress: (userId, address) => {
    console.log(`AdminServices.createUserAddress(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/${userId}/address`;
    return axios.post(url, address);
  },
  deleteUserAddress: (userId, addressId) => {
    console.log(`AdminServices.deleteUserAddress(${addressId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/${userId}/address/${addressId}`;
    return axios.delete(url);
  },
  getUserWithEmail: (email) => {
    console.log(`AdminServices.getUserWithEmail(${email})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/${email}`;
    return axios.get(url);
  },
  updateUser: (userId, updatedUser) => {
    console.info(`AdminServices.updateUser(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/${userId}`;
    return axios.patch(url, updatedUser);
  },
  createUser: (userDoc) => {
    console.info('AdminServices.createUser()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/`;
    return axios.post(url, userDoc);
  },
  resetUserPassword: (email) => {
    console.info('AdminServices.resetUserPassword()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/password/reset`;
    return axios.post(url, { email });
  },
  getUserBalance: (userId) => {
    console.log(`AdminServices.getUserBalance(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/${userId}/balance`;
    return axios.get(url);
  },
  getAllBalances: (userId) => {
    console.log(`AdminServices.getUserBalance(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/balance`;
    return axios.get(url);
  },
  getBalances: (userId) => {
    console.log(`AdminServices.getUserBalance(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/${userId}/balance`;
    return axios.get(url);
  },
  getUserTransactions: (userId, params) => {
    console.log(`AdminServices.getUserTransactions(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/${userId}/transaction/search`;
    return axios.post(url, params);
  },
  createTransaction: (data) => {
    console.info('AdminServices.createTransaction()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/wallet/transaction`;
    return axios.post(url, data);
  },
  updateTransaction: (transactionId, data) => {
    console.info('AdminServices.updateTransaction()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/wallet/${transactionId}`;
    return axios.patch(url, data);
  },
  deleteTransaction: (transactionId) => {
    console.info('AdminServices.deleteTransaction()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/wallet/${transactionId}`;
    return axios.delete(url);
  },
  importWalletFile: (file) => {
    console.info('AdminServices.importWalletFile()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/wallet/import/xslx`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    return axios.post(url, file, { headers });
  },
  // Promocodes
  getPromocodes: (filters = {}) => {
    console.log('AdminServices.getPromocodes()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/promocode/search`;
    return axios.post(url, filters);
  },
  getPromocode: (promocodeID) => {
    console.log(`AdminServices.getPromocode(${promocodeID})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/promocode/${promocodeID}`;
    return axios.get(url);
  },
  createPromocode: (promocode) => {
    console.log('AdminServices.createPromocode()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/promocode`;
    return axios.post(url, promocode);
  },
  deletePromocode: (promocodeId) => {
    console.log(`AdminServices.deletePromocode(${promocodeId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/promocode/${promocodeId}`;
    return axios.delete(url);
  },
  updatePromocode: (promocodeId, promocode) => {
    console.log(`AdminServices.updatePromocode(${promocodeId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/promocode/${promocodeId}`;
    return axios.patch(url, promocode);
  },
  getProfessionals: (filter = {}) => {
    console.log('AdminServices.getProfessionals()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/search`;
    return axios.post(url, filter);
  },
  getProfessional: (professionalId) => {
    console.log(`AdminServices.getProfessional(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}`;
    return axios.get(url);
  },
  createProfessional: (professional) => {
    console.log('AdminServices.createProfessional()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional`;
    return axios.post(url, professional);
  },
  deleteProfessional: (professionalId) => {
    console.log(`AdminServices.deleteProfessional(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}`;
    return axios.delete(url);
  },
  updateProfessional: (professionalId, professionalUpdated) => {
    console.log('AdminServices.updateProfessional()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}`;
    return axios.patch(url, professionalUpdated);
  },
  addProfessionalAvatar: (userId, avatar) => {
    console.log('AdminServices.addProfessionalAvatar()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/${userId}/avatar`;
    return axios.post(url, avatar);
  },
  addProfessionalWorkingArea: (professionalId, area) => {
    console.log(`AdminServices.addProfessionalWorkingArea(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/working-area`;
    return axios.post(url, area);
  },
  deleteProfessionalWorkingArea: (professionalId, workingAreaId) => {
    console.log(`AdminServices.deleteProfessionalWorkingArea(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/working-area/${workingAreaId}`;
    return axios.delete(url);
  },
  addProfessionalServiceVariant: (professionalId, variant) => {
    console.log(`AdminServices.addProfessionalServiceVariant(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/service-variant`;
    return axios.post(url, variant);
  },
  deleteProfessionalServiceVariant: (professionalId, serviceVariantId) => {
    console.log(`AdminServices.deleteProfessionalServiceVariant(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/service-variant/${serviceVariantId}`;
    return axios.delete(url);
  },
  addProfessionalAvailability: (professionalId, availability) => {
    console.log(`AdminServices.addProfessionalAvailability(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/availability`;
    return axios.post(url, availability);
  },
  deleteProfessionalAvailability: (professionalId, availabilityId) => {
    console.log(`AdminServices.deleteProfessionalAvailability(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/availability/${availabilityId}`;
    return axios.delete(url);
  },
  updateProfessionalAvailability: (professionalId, availabilities) => {
    console.log(`AdminServices.updateProfessionalAvailability(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/availabilities`;
    return axios.post(url, availabilities);
  },
  addProfessionalIdentification: (userId, identification) => {
    console.log(`AdminServices.addProfessionalIdentification(${userId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/${userId}/identification`;
    return axios.post(url, identification);
  },
  getProfessionalBlockedPeriods: (professionalId) => {
    console.log(`AdminServices.getProfessionalBlockedPeriods(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/professional-blocked-period`;
    return axios.get(url);
  },
  addProfessionalBlockedPeriods: (professionalId, data = {}) => {
    console.log(`AdminServices.addProfessionalBlockedPeriods(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/professional-blocked-period`;
    return axios.post(url, data);
  },
  editProfessionalBlockedPeriods: (professionalId, periodId, data = {}) => {
    console.log(`AdminServices.editProfessionalBlockedPeriods(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/professional-blocked-period/${periodId}`;
    return axios.patch(url, data);
  },
  deleteProfessionalBlockedPeriods: (professionalId, periodId) => {
    console.log(`AdminServices.deleteProfessionalBlockedPeriods(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/professional-blocked-period/${periodId}`;
    return axios.delete(url);
  },
  // Services
  getServices: () => {
    console.log('AdminServices.addProfessionalIdentification()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service`;
    return axios.get(url);
  },
  // Service Variant
  createServiceVariant: (serviceId, newVariant) => {
    console.log('AdminServices.createServiceVariant()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service/${serviceId}/variant`;
    return axios.post(url, newVariant);
  },
  updateServiceVariant: (serviceId, variantId, variantUpdated) => {
    console.log('AdminServices.updateServiceVariant()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service/${serviceId}/variant/${variantId}`;
    return axios.patch(url, variantUpdated);
  },
  createServiceVariantCountry: (newVariantCountry) => {
    console.log('AdminServices.createServiceVariantCountry()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-country`;
    return axios.post(url, newVariantCountry);
  },
  deleteVariantCountry: (variantCountryId) => {
    console.log(`AdminServices.deleteVariantCountry(${variantCountryId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-country/${variantCountryId}`;
    return axios.delete(url);
  },
  updateVariantCountry: (variantCountryId, variantCountryUpdated) => {
    console.log(`AdminServices.updateVariantCountry(${variantCountryId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-country/${variantCountryId}`;
    return axios.patch(url, variantCountryUpdated);
  },
  // Bookings
  getBookings: (filters) => {
    console.log(`AdminServices.getBookings(${JSON.stringify(filters)})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/booking/search`;
    return axios.post(url, filters);
  },
  getBooking: (bookingId) => {
    console.log(`AdminServices.getBooking(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/booking/${bookingId}`;
    return axios.get(url);
  },
  updateBooking: (bookingId, bookingUpdated) => {
    console.log(`AdminServices.updateBooking(${bookingId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/booking/${bookingId}`;
    return axios.patch(url, bookingUpdated);
  },
  // category
  updateCAtegory: (categoryId, categoryUpdated) => {
    console.log(`AdminServices.updateCAtegory(${categoryId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/category/${categoryId}`;
    return axios.patch(url, categoryUpdated);
  },
  getCategories: () => {
    console.log('AdminServices.getCategories()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/category`;
    return axios.get(url);
  },
  // Customers
  deleteCustomer: (id) => {
    console.log(`AdminServices.deleteCustomer(${id})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/user/${id}`;
    return axios.delete(url);
  },
  // funding
  funding: (data) => {
    console.log('AdminServices.funding()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/funding`;
    return axios.post(url, data);
  },
  // Banners
  getBanners: (params) => {
    console.log('AdminServices.getBanners()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/banner/search`;
    return axios.post(url, params);
  },
  getBanner: (bannerId) => {
    console.log(`AdminServices.getBanner(${bannerId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/banner/${bannerId}`;
    return axios.get(url);
  },
  createBanner: (data) => {
    console.log('AdminServices.createBanner()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/banner`;
    return axios.post(url, data);
  },
  updateBanner: (bannerId, updated) => {
    console.log(`AdminServices.updateBanner(${bannerId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/banner/${bannerId}`;
    return axios.patch(url, updated);
  },
  createServiceVarianAreaPricingPeriod: (variantarea, pricingPeriod) => {
    console.log('AdminServices.createServiceVarianAreaPricingPeriod()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${variantarea}/pricing-period`;
    return axios.post(url, pricingPeriod);
  },
  getServiceVarianAreaPricingPeriods: (variantarea) => {
    console.log('AdminServices.getServiceVarianAreaPricingPeriods()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${variantarea}/pricing-period`;
    return axios.get(url);
  },
  deletePricingPeriod: (variantarea, pricingPeriodId) => {
    console.log('AdminServices.getServiceVarianAreaPricingPeriods()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${variantarea}/pricing-period/${pricingPeriodId}`;
    return axios.delete(url);
  },
  getServiceVarianAreaPricingPeriod: (variantarea, pricingPeriodId) => {
    console.log('AdminServices.getServiceVariantAreaPricingPeriod()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${variantarea}/pricing-period/${pricingPeriodId}`;
    return axios.get(url);
  },
  updateServiceVarianAreaPricingPeriod: (variantarea, pricingPeriodId, data) => {
    console.log('AdminServices.updateServiceVariantAreaPricingPeriod()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${variantarea}/pricing-period/${pricingPeriodId}`;
    return axios.patch(url, data);
  },
  createServiceVarianAreaAvailability: (serviceVariantAreaId, availability) => {
    console.log('AdminServices.createServiceVarianAreaAvailability()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${serviceVariantAreaId}/availability`;
    return axios.post(url, availability);
  },
  updateServiceVarianAreaAvailability: (serviceVariantAreaId, availability, availabilityId) => {
    console.log('AdminServices.updateServiceVarianAreaAvailability()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${serviceVariantAreaId}/availability/${availabilityId}`;
    return axios.patch(url, availability);
  },
  // config values
  getConfigValues: (filters) => {
    console.log('AdminServices.getConfigValue()');
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/config-value`;
    return axios.get(url, { params: { type: filters.type, country_name: filters.country_name, category: filters.category } });
  },
  editConfigValue: (configValueId, valueUpdated) => {
    console.log(`AdminServices.editConfigValue(${configValueId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/config-value/${configValueId}`;
    return axios.patch(url, valueUpdated);
  },
  // professional portfolio
  getProfessionalPortfolio: (professionalId) => {
    console.log(`AdminServices.getProfessionalPortfolio(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/professional-image`;
    return axios.get(url);
  },
  addProfessionalImage: (professionalId, image) => {
    console.log(`AdminServices.addProfessionalImage(${professionalId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/professional-image`;
    return axios.post(url, image);
  },
  deleteProfessionalImage: (professionalId, professionalImageId) => {
    console.log(`AdminServices.deleteProfessionalImage(${professionalId} - ${professionalImageId})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/professional/${professionalId}/professional-image/${professionalImageId}`;
    return axios.delete(url);
  },
  // Reviews
  getReviews: (filters) => {
    console.log(`AdminServices.getReviews(${JSON.stringify(filters)})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/review/search`;
    return axios.post(url, filters);
  },
  updateReview: (reviewId, changes) => {
    console.log(`AdminServices.updateReview(${JSON.stringify(changes)})`);
    const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/review/${reviewId}`;
    return axios.patch(url, changes);
  },
  // Packs
  getPacks: () => {
    console.log('AdminServices.getPacks()');
    const url = 'https://ameiz-new-api.herokuapp.com/admin/pack';
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/pack`;
    return axios.get(url);
  },
  createPack: (pack) => {
    console.log(`AdminServices.createPack(${JSON.stringify(pack)})`);
    const url = 'https://ameiz-new-api.herokuapp.com/admin/pack';
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/pack`;
    return axios.post(url, pack);
  },
  updatePack: (pack, packId) => {
    console.log(`AdminServices.updatePack(ID:${packId} - ${JSON.stringify(pack)})`);
    const url = `https://ameiz-new-api.herokuapp.com/admin/pack/${packId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/pack`;
    return axios.patch(url, pack);
  },
  getPackById: (packId) => {
    console.log(`AdminServices.getPackById(${packId})`);
    const url = `https://ameiz-new-api.herokuapp.com/admin/pack/${packId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/pack/${packId}`;
    return axios.get(url);
  },
  deletePack: (packId) => {
    console.log(`AdminServices.deletePack(${packId})`);
    const url = `https://ameiz-new-api.herokuapp.com/admin/pack/${packId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/pack/${packId}`;
    return axios.delete(url);
  },
  getPackItems: () => {
    console.log('AdminServices.getPackItems()');
    const url = 'https://ameiz-new-api.herokuapp.com/admin/packItems';
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/packItems`;
    return axios.get(url);
  },
  getPackItemById: (packItemId) => {
    console.log(`AdminServices.getPackItemById(${packItemId})`);
    const url = `https://ameiz-new-api.herokuapp.com/admin/packItems/${packItemId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/packItems/${packItemId}`;
    return axios.get(url);
  },
  createPackItem: (packItem) => {
    console.log(`AdminServices.createPackItem(${JSON.stringify(packItem)})`);
    const url = 'https://ameiz-new-api.herokuapp.com/admin/packItem';
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/packItem`;
    return axios.post(url, packItem);
  },
  updatePackItem: (packItemId, data) => {
    console.log(`AdminServices.updatePackItem(${packItemId})`);
    const url = `https://ameiz-new-api.herokuapp.com/admin/packItem/${packItemId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/packItem/${packItemId}`;
    return axios.patch(url, data);
  },
  deletePackItem: (packItemId) => {
    console.log(`AdminServices.deletePackItem(${packItemId})`);
    const url = `https://ameiz-new-api.herokuapp.com/admin/packItem/${packItemId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/packItem/${packItemId}`;
    return axios.delete(url);
  },
  createPackArea: (packArea) => {
    console.log(`AdminServices.createPackArea(${JSON.stringify(packArea)})`);
    const url = 'https://ameiz-new-api.herokuapp.com/admin/pack-area';
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/pack-area`;
    return axios.post(url, packArea);
  },
  updatePackArea: (packArea, packAreaId) => {
    console.log(`AdminServices.updatePackArea(ID: ${packAreaId} - ${JSON.stringify(packArea)})`);
    const url = `https://ameiz-new-api.herokuapp.com/admin/pack-area/${packAreaId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/pack-area`;
    return axios.patch(url, packArea);
  },
  deletePackAreaItem: (packAreaId) => {
    console.log(`AdminServices.deletePackAreaItem(${packAreaId})`);
    const url = `https://ameiz-new-api.herokuapp.com/admin/pack-area/${packAreaId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-area-area/${packAreaId}`;
    return axios.delete(url);
  },
  getPackPurchased: (filter) => {
    console.log(`AdminServices.getPackPurchased(${JSON.stringify(filter)})`);
    const url = 'https://ameiz-new-api.herokuapp.com/admin/booking-pack/search';
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/service-area-area/${packAreaId}`;
    return axios.post(url, filter);
  },
  updatePackPurchased: (bookingPackId, data) => {
    console.log('AdminServices.updatePackPurchased()');
    const url = `https://ameiz-new-api.herokuapp.com/admin/booking-pack/${bookingPackId}`;
    // const url = `${process.env.REACT_APP_MODERN_API_URL}/admin/booking-pack/${bookingPackId}`;
    return axios.patch(url, data);
  },
};

export default AdminServices;
