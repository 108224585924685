import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Container } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import AuthService from 'src/services/authService';
import axios from 'axios';

import BookingHeader from 'src/components/booking/BookingHeader';
import BookingListRequest from 'src/components/booking/BookingListRequest';

const BookingList = () => {
  const { bookingId, date } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/booking/${bookingId}/booking-request`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        console.log(response.data);
        setRequests(response.data);
        setIsLoad(true);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> Bookings | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/bookings">
              Bookings
            </Link>
            <Typography color="textPrimary">Booking request</Typography>
          </Breadcrumbs>
          <BookingHeader booking={bookingId} date={date} requests={requests.length} />
          <Box sx={{ pt: 3 }}>
            {isLoad
              ? <BookingListRequest Request={requests} />
              : null}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BookingList;
