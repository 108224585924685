import { Helmet } from 'react-helmet';

import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import ServiceVariantAddonDetails from 'src/components/serviceVariant/ServiceVariantAddonDetails';

function ServiceVariantAddAddon() {
  const handleSaveNewAddon = async (newAddon, addonAreas) => {
    console.log(newAddon, addonAreas);
    // setSaveLoading(true)
    // try {
    //   const response = await AdminServices.createAddon(variantId, newAddon);
    //   if (is.not.empty(addonAreas)) {
    //     for (let i in addonAreas) {
    //       let newAddonArea = {
    //         ...addonAreas[i],
    //         add_on_id: response.data.id
    //       }
    //       delete newAddonArea.viewId;
    //       const responseAddOnArea = await AdminServices.createAddonArea(newAddonArea);
    //     }
    //   }
    //   navigate('/app/service/variant');
    // } catch (e) {
    //   console.log(e);
    // } finally {
    //   setSaveLoading(false);
    // }
  };

  return (
    <>
      <Helmet>
        <title>Add Addon </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <ServiceVariantAddonDetails handleSaveNewAddon={handleSaveNewAddon} saveLoading={false} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ServiceVariantAddAddon;
