import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import ProfessionalsTrackingToolbar from 'src/components/professional/ProfessionalsTrackingToolbar';
import ProfessionalsTrackingMap from 'src/components/professional/ProfessionalsTrackingMap';
import { db } from 'src/utils/firebase';

function ProfessionalsTracking() {
  const [selectedCountry, setSelectedCountry] = useState(2);
  const [activeProfessionals, setActiveProfessionals] = useState([]);

  function handleCountryChange(option) {
    setSelectedCountry(option);
  }

  function getData() {
    return db.ref('tracking/').on('value', (snapshot) => {
      const data = snapshot.val();
      transformData(data);
    });
  }

  function transformData(data) {
    const dataKeys = Object.keys(data);
    const newOrder = dataKeys.reduce((acc, key) => {
      const info = { ...data[key], id: key };
      return acc.concat([info]);
    }, []).filter((item) => item.active);
    setActiveProfessionals(newOrder);
  }

  useEffect(() => { getData(); }, []);

  return (
    <>
      <Helmet>
        <title>Professionals | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Professional tracking</Typography>
          </Breadcrumbs>
          <ProfessionalsTrackingToolbar onchange={handleCountryChange} selected={selectedCountry} />
          <Box sx={{ pt: 3 }}>
            <ProfessionalsTrackingMap activeProfessionals={activeProfessionals} countryId={selectedCountry} />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default ProfessionalsTracking;
