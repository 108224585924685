import { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import is from 'is_js';

import MultipleFileUpload from 'src/components/comon/MultipleFilesUpload';
import AdminServices from 'src/services/AdminServices';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(({
  children, classes, onClose, ...other
}) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddImagesModal = ({
  open, handleClose, category, professionalId, getPortfolio, categoryList,
}) => {
  const [images, setImages] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const classes = useStyles();

  const handleUploadImages = (image) => {
    setImages((currentValues) => [...currentValues, image]);
  };

  const handleDeleteImage = () => {
    console.log('delete');
  };

  const saveChanges = async () => {
    if (is.not.empty(images)) {
      setSaveLoading(true);
      for (const i in images) {
        const newImage = {
          name: '',
          order: 0,
          category_id: categorySelected ? categorySelected.id : category.id,
          file: images[i],
          professional_id: professionalId,
        };
        try {
          const response = await AdminServices.addProfessionalImage(professionalId, newImage);
        } catch (error) {
          console.log(error);
        }
      }
    }
    getPortfolio();
    handleClose();
    setSaveLoading(false);
  };

  const handleChange = (event) => {
    setCategorySelected(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      {category !== 'noCategory' && (
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {category?.name}
        </DialogTitle>
      )}
      {category === 'noCategory' && (
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add images
        </DialogTitle>
      )}
      <DialogContent dividers>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="category-select-outlined-label">Category</InputLabel>
          <Select
            labelId="category-select-outlined-label"
            id="category-select-outlined"
            value={categorySelected}
            onChange={handleChange}
            label="Category"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categoryList.map((c, i) => <MenuItem key={`category_${i}`} value={c}>{c.name}</MenuItem>)}
          </Select>
        </FormControl>
        <MultipleFileUpload handleUpload={handleUploadImages} handleDelete={handleDeleteImage} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={saveChanges} color="primary">
          { saveLoading ? <CircularProgress /> : 'Save changes' }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddImagesModal;

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
