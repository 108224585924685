import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Box, Container, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import ProfessionalListResults from 'src/components/professional/ProfessionalListResult';
import ProfessionalListToolbar from 'src/components/professional/ProfessionalToolBar';
import AdminServices from 'src/services/AdminServices';
import DeleteModal from 'src/components/comon/Alert';

const DELETE_MODAL_TITLE = 'Do you want to remove this professional ?';

const DEFAULT_FILTER = {
  status: null,
  email: null,
  priority: null,
  country: null,
};

const ProfessionalList = () => {
  const [open, setOpen] = useState(false);
  const [professionalToDelete, setProfessionalToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [professionals, setProfessionals] = useState([]);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTER });

  useEffect(() => {
    getProfessionalList();
  }, [filters]);

  function getProfessionalList() {
    setIsLoading(true);
    return AdminServices.getProfessionals(filters)
      .then((response) => {
        if (filters.country) {
          setProfessionals(response.data.filter((p) => p.country?.name.toLowerCase().match(filters.country.toLowerCase())));
        } else {
          setProfessionals(response.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }

  function handleDeleteProfessional() {
    return AdminServices.deleteProfessional(professionalToDelete)
      .then(() => {
        const filteredList = professionals.filter((p) => p.id !== professionalToDelete);
        setProfessionals(filteredList);
      })
      .catch((error) => console.log(error))
      .finally(() => handleCloseDeleteModal());
  }

  const handleClickOpenDeleteModal = (professionalId) => {
    setProfessionalToDelete(professionalId);
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  function handleChangeStatus(event, professionalId) {
    const status = {
      id: professionalId,
      status: event.target.value,
    };
    return AdminServices.updateProfessional(professionalId, status)
      .then(() => {
        const professionalUpdated = professionals.find((p) => p.id === professionalId);
        professionalUpdated.status = event.target.value;
        const filteredList = professionals.filter((ps) => ps.id !== professionalId);
        setProfessionals([...filteredList, professionalUpdated]);
      })
      .catch((error) => console.log(error));
  }

  function handleCompleteBoardingSteps(professionalId, value, step) {
    if (value) {
      const boarding = {
        id: professionalId,
        boarding_step: value ? 'completed' : step,
      };
      return AdminServices.updateProfessional(professionalId, boarding)
        .then(() => {
          const professionalUpdated = professionals.find((p) => p.id === professionalId);
          professionalUpdated.boarding_step = 'completed';
          const filteredList = professionals.filter((ps) => ps.id !== professionalId);
          setProfessionals([...filteredList, professionalUpdated]);
        })
        .catch((error) => console.log(error));
    }
  }

  function handleFiltersChange(filter) {
    setFilters((prev) => ({ ...prev, ...filter }));
  }

  function handleClearFilters() {
    setFilters({
      status: null,
      email: null,
      priority: null,
      country: null,
    });
  }

  return (
    <>
      <Helmet>
        <title>Professionals | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Professionals</Typography>
          </Breadcrumbs>
          <ProfessionalListToolbar
            filters={filters}
            setFilters={handleFiltersChange}
            handleClearFilters={handleClearFilters}
          />
          <Box sx={{ pt: 3 }}>
            {isLoading && <Box style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></Box>}
            {!isLoading && (
            <ProfessionalListResults
              handleCompleteBoardingSteps={handleCompleteBoardingSteps}
              handleChangeStatus={handleChangeStatus}
              handleClickOpenDeleteModal={handleClickOpenDeleteModal}
              professionals={professionals}
            />
            )}
          </Box>
          <DeleteModal
            handleClose={handleCloseDeleteModal}
            open={open}
            Agree={handleDeleteProfessional}
            title={DELETE_MODAL_TITLE}
            content={`ID: ${professionalToDelete}`}
          />
        </Container>
      </Box>
    </>
  );
};

export default ProfessionalList;
