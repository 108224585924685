import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Checkbox,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import is from 'is_js';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AdminServices from 'src/services/AdminServices';
import AreaSelectTree from 'src/components/comon/AreaSelectTree';
import MoneyDisplay from 'src/components/comon/MoneyDisplay';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const PAGE_TITLE = 'Service Variant Addon';

const ServiceVariantAddon = ({
  handleSaveNewAddon, saveLoading, defaultValue,
}) => {
  const navigate = useNavigate();
  const { serviceId, variantId, addonId } = useParams();
  const [values, setValues] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [addonAreas, setAddonAreas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [servicesVariant, setServiceVariant] = useState([]);
  const [serviceVariantSelected, setServiceVariantSelected] = useState([]);
  const [defaultValueIsLoad, setDefaultValueIsLoad] = useState(false);

  const [serviceVariantSorted, setserviceVariantSorted] = useState([]);

  useEffect(() => {
    if (is.not.empty(servicesVariant)) {
      const options = servicesVariant.map((option) => {
        return {
          serviceName: option.service.name,
          ...option,
        };
      });
      setserviceVariantSorted(options.sort((a, b) => -b.serviceName.localeCompare(a.serviceName)));
    }
  }, [servicesVariant]);

  useEffect(() => {
    if (defaultValue) {
      setValues(defaultValue);
      setServiceVariantSelected(defaultValue.serviceVariants);
      setAddonAreas(defaultValue.addOnAreas);
      setDefaultValueIsLoad(true);
    } else {
      setDefaultValueIsLoad(true);
    }
  }, []);

  useEffect(() => {
    getAreaList();
    getServiceVariantList();
  }, []);

  useEffect(() => {
    if (variantId) {
      getVariant();
    }
  }, [variantId]);

  const getVariant = async () => {
    setIsloading(true);
    try {
      const response = await AdminServices.getServiceVariant(serviceId, variantId);
      const addOn = response.data.addOns.find((ad) => ad.id === parseInt(addonId));
      setValues(addOn);
      if (is.not.empty(addOn.addOnAreas)) {
        const addonAreasList = [];
        for (const i in addOn.addOnAreas) {
          // const addonAreaResponse = await AdminServices.getAddonArea(addOn.addOnAreas[i].id);
          // addonAreasList.push(addonAreaResponse.data);
          addonAreasList.push(addOn.addOnAreas[i]);
        }
        console.log('addonAreasList', addonAreasList);
        setAddonAreas(addonAreasList);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsloading(false);
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = async () => {
    if (addonId) {
      setUpdateLoading(true);
      try {
        await AdminServices.updateAddon(addonId, values);
        for (const i in addonAreas) {
          // save new addOnAreas
          if (addonAreas[i].viewId) {
            const newAddonArea = {
              ...addonAreas[i],
              add_on_id: addonId,
            };
            delete newAddonArea.viewId;
            await AdminServices.createAddonArea(newAddonArea);
          } else {
            // update old addOnAreas
            const updatedAddOnArea = {
              add_on_area_id: addonAreas[i].id,
              name: addonAreas[i].name,
              description: addonAreas[i].description,
              cost: parseFloat(addonAreas[i].cost),
              price: parseFloat(addonAreas[i].price),
              active: addonAreas[i].active ? addonAreas[i].active : false,
              area_id: addonAreas[i].area_id ? addonAreas[i].area_id : null,
            };
            await AdminServices.updateAddonArea(addonAreas[i].id, updatedAddOnArea);
          }
        }
        navigate('/app/addons');
      } catch (e) {
        console.log(e);
        alert('There was an error.');
      } finally {
        setUpdateLoading(false);
      }
    } else {
      handleSaveNewAddon(values, addonAreas);
    }
  };

  const [viewId, setViewId] = useState(1);

  const handleAddAddonAreaBox = () => {
    setAddonAreas((currentValues) => [...currentValues, {
      viewId,
      name: null,
      description: null,
      cost: null,
      price: null,
      active: false,
      area_id: null,
      add_on_id: null,
    }]);
    setViewId((currentValue) => currentValue + 1);
  };

  const handleDeleteAddonAreaBox = (addonAreaId) => {
    const filteredList = addonAreas.filter((a) => a.viewId !== addonAreaId);
    setAddonAreas(filteredList);
  };

  function handleDeleteAddonAreaBoxOld(addonAreaId) {
    const filteredList = addonAreas.filter((a) => a.id !== addonAreaId);
    setAddonAreas(filteredList);
    return AdminServices.deleteAddonArea(addonAreaId)
      .catch((error) => console.log(error));
  }

  const getAreaList = async () => {
    try {
      const response = await AdminServices.getAreas();
      setAreas(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getServiceVariantList = async () => {
    try {
      const response = await AdminServices.getServiceVariants();
      setServiceVariant(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeAddonArea = (addonAreaId, attrName, value) => {
    const filteredList = addonAreas.filter((a) => a.viewId !== addonAreaId);
    const addonAreaToEdit = addonAreas.find((a) => a.viewId === addonAreaId);
    addonAreaToEdit[attrName] = value;
    setAddonAreas([...filteredList, addonAreaToEdit]);
  };

  const handleChangeOldAddonArea = (addonAreaId, attrName, value) => {
    const filteredList = addonAreas.filter((a) => a.id !== addonAreaId);
    const addonAreaToEdit = addonAreas.find((a) => a.id === addonAreaId);
    addonAreaToEdit[attrName] = value;
    setAddonAreas([...filteredList, addonAreaToEdit]);
  };

  const handleChangeServiceVariant = (variants) => {
    setValues({ ...values, service_variant_ids: variants.map((v) => v.id) });
    setServiceVariantSelected(variants);
  };

  if (isLoading) {
    return (
      <Box style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleCloneAddonArea = (addonAreaId, type) => {
    let newAddonArea;
    if (type === 'old') {
      newAddonArea = JSON.parse(JSON.stringify(addonAreas.find((a) => a.id === addonAreaId)));
      delete newAddonArea.id;
    } else {
      newAddonArea = JSON.parse(JSON.stringify(addonAreas.find((a) => a.viewId === addonAreaId)));
    }
    newAddonArea.viewId = viewId;
    newAddonArea.name += ' - CLONED';
    newAddonArea.active = false;
    setAddonAreas((currentValues) => [...currentValues, newAddonArea]);
    setViewId((currentValue) => currentValue + 1);
  };

  return (
    <form>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '24px',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddAddonAreaBox}
        >
          Add Area
        </Button>
        <Button
          sx={{ mx: 1 }}
          color="secondary"
          variant="contained"
          onClick={handleSave}
        >
          {updateLoading || saveLoading ? <CircularProgress /> : addonId ? 'Update Addon' : 'Save Addon'}
        </Button>
      </Box>
      <Card>
        <CardHeader
          subheader="The information is associated with the variant"
          title={PAGE_TITLE}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="Name"
                name="internal_name"
                onChange={handleChange}
                required
                value={values?.internal_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="Order"
                name="order"
                onChange={handleChange}
                required
                value={values?.order}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label="Duration"
                name="duration"
                onChange={handleChange}
                required
                value={values?.duration}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              {defaultValueIsLoad && (
                <Autocomplete
                  id="addon-sv-select"
                  options={serviceVariantSorted}
                  groupBy={(option) => option.serviceName}
                  fullWidth
                  freeSolo
                  multiple
                  disableCloseOnSelect
                  size="small"
                  defaultValue={serviceVariantSelected}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    handleChangeServiceVariant(newValue);
                  }}
                  getOptionLabel={(option) => option.internal_name}
                  renderInput={(params) => <TextField {...params} label="Service Variant" variant="outlined" />}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.internal_name}
                    </>
                  )}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Divider />
      </Card>

      {is.not.empty(addonAreas) && addonAreas.sort((a, b) => a.id - b.id).filter((v) => v.id).map((a, i) => (
        <Card key={`addonAreaBox_${i}`} style={{ marginTop: 16 }}>
          <CardHeader
            action={(
              <>
                <Button size="small" onClick={() => handleCloneAddonArea(a.id, 'old')}>Clone</Button>
                <IconButton aria-label="settings" onClick={() => handleDeleteAddonAreaBoxOld(a.id)}>
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </>
          )}
            title="Addon Area"
          />
          <Divider />
          <CardContent>
            <Grid container>
              <Grid container spacing={3} md={12}>
                <Grid item md={4} xs={6}>
                  <TextField
                    fullWidth
                    helperText="Name"
                    label="Name"
                    name="name"
                    onChange={(e) => handleChangeOldAddonArea(a.id, e.target.name, e.target.value)}
                    required
                    value={a?.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    fullWidth
                    helperText="Description"
                    label="Description"
                    name="description"
                    onChange={(e) => handleChangeOldAddonArea(a.id, e.target.name, e.target.value)}
                    required
                    value={a?.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    id={`addonAreaCost_${i}`}
                    label="Cost"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    name="cost"
                    helperText="Cost"
                    required
                    value={a?.cost}
                    onChange={(e) => handleChangeOldAddonArea(a.id, e.target.name, e.target.value)}
                    InputProps={{
                      inputComponent: MoneyDisplay,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} md={12} style={{ marginTop: '16px' }}>
                <Grid item md={4} xs={6}>
                  <TextField
                    id={`addonAreaPrice_${i}`}
                    label="Price"
                    helperText="Price"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    name="price"
                    required
                    value={a?.price}
                    onChange={(e) => handleChangeOldAddonArea(a.id, e.target.name, e.target.value)}
                    InputProps={{
                      inputComponent: MoneyDisplay,
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  <Box style={{ width: '100%', textAlign: 'center' }}>
                    <label>
                      Active
                    </label>
                    <Checkbox
                      name="active"
                      checked={a?.active}
                      onChange={(e) => handleChangeOldAddonArea(a.id, e.target.name, e.target.checked)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={6}>
                  <Box style={{ width: '100%' }}>
                    <AreaSelectTree
                      areas={areas}
                      defaultareasSelected={[a?.area]}
                      handelslected={(area) => handleChangeOldAddonArea(a.id, 'area_id', area[0]?.id)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Divider />
        </Card>
      ))}

      {is.not.empty(addonAreas) && addonAreas.sort(
        (a, b) => a.viewId - b.viewId).filter((v) => v.viewId).map((a, i) => (
          <Card key={`addonAreaBox_${i}`} style={{ marginTop: 16 }}>
            <CardHeader
              action={(
                <>
                  <Button size="small" onClick={() => handleCloneAddonArea(a.viewId, 'new')}>Clone</Button>
                  <IconButton aria-label="settings" onClick={() => handleDeleteAddonAreaBox(a.viewId)}>
                    <HighlightOffOutlinedIcon />
                  </IconButton>
                </>
          )}
              title="Addon Area"
            />
            <Divider />
            <CardContent>
              <Grid
                container
              >
                <Grid
                  container
                  spacing={3}
                  md={12}
                >
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <TextField
                      fullWidth
                      helperText="Name"
                      label="Name"
                      name="name"
                      onChange={(e) => handleChangeAddonArea(a.viewId, e.target.name, e.target.value)}
                      required
                      value={a?.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <TextField
                      fullWidth
                      helperText="Description"
                      label="Description"
                      name="description"
                      onChange={(e) => handleChangeAddonArea(a.viewId, e.target.name, e.target.value)}
                      required
                      value={a?.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <TextField
                      id={`addonAreaCost_${i}`}
                      label="Cost"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      name="cost"
                      helperText="Cost"
                      required
                      value={a?.cost}
                      onChange={(e) => handleChangeAddonArea(a.viewId, e.target.name, e.target.value)}
                      InputProps={{
                        inputComponent: MoneyDisplay,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  md={12}
                  style={{ marginTop: '16px' }}
                >
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <TextField
                      id={`addonAreaPrice_${i}`}
                      label="Price"
                      helperText="Price"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      name="price"
                      required
                      value={a?.price}
                      onChange={(e) => handleChangeAddonArea(a.viewId, e.target.name, e.target.value)}
                      InputProps={{
                        inputComponent: MoneyDisplay,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <Box style={{ width: '100%', textAlign: 'center' }}>
                      <label>
                        Active
                      </label>
                      <Checkbox
                        name="active"
                        checked={a?.active}
                        onChange={(e) => handleChangeAddonArea(a.viewId, e.target.name, e.target.checked)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <Box style={{ width: '100%' }}>
                      <AreaSelectTree
                        areas={areas}
                        defaultareasSelected={[a?.area]}
                        handelslected={(area) => handleChangeAddonArea(a.viewId, 'area_id', area[0]?.id)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Divider />
          </Card>
      ))}

    </form>
  );
};

export default ServiceVariantAddon;
