import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import AdminServices from 'src/services/AdminServices';

const PROFESSIONAL_STATES = [
  'created',
  'accepted',
  'rejected',
];

function ProfessionalsListToolbar({ filters, setFilters, handleClearFilters }) {
  const [values, setValues] = useState({});
  const [countries, setCountries] = useState([]);
  const request = debounce((value) => setFilters(value), 300);
  const debounceRequest = useCallback((value) => request(value), []);

  const handlechange = (filter) => {
    setValues({ ...values, ...filter });
    debounceRequest(filter);
  };

  useEffect(() => {
    return AdminServices.getCountries().then((response) => setCountries(response.data));
  }, []);

  function handleClear() {
    setValues({});
    handleClearFilters();
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          component={RouterLink}
          to="/app/professional/add"
          color="primary"
          variant="contained"
        >
          Add Professional
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextField
                  fullWidth
                  onChange={(e) => handlechange({ email: e.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Professional"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="mutiple-country-id-label">Country</InputLabel>
                  <Select
                    fullWidth
                    key={values?.country}
                    defaultValue={null}
                    labelId="mutiple-country-id-label"
                    id="mutiple-country-id"
                    value={values?.country}
                    onChange={(e) => handlechange({ country: e.target.value })}
                    label="Country"
                    name="country"
                  >
                    <MenuItem key="country_null" value="">
                      NONE
                    </MenuItem>
                    {countries.map((c, i) => (
                      <MenuItem key={`country_${i}`} value={c.name}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="mutiple-status-id-label">Status</InputLabel>
                  <Select
                    fullWidth
                    key={values?.status}
                    defaultValue={null}
                    labelId="mutiple-status-id-label"
                    id="mutiple-status-id"
                    value={values?.status}
                    onChange={(e) => handlechange({ status: e.target.value })}
                    label="status"
                    name="status"
                  >
                    <MenuItem key="status_null" value="">
                      NONE
                    </MenuItem>
                    {PROFESSIONAL_STATES.map((s, i) => (
                      <MenuItem key={`status_${i}`} value={s}>
                        {s}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  type="number"
                  onChange={(e) => handlechange({ priority: Number(e.target.value) })}
                  placeholder="Priority"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs>
                <Box style={{
                  display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', height: '100%',
                }}
                >
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    onClick={handleClear}
                    variant="outlined"
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default ProfessionalsListToolbar;
