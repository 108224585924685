import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  NavLink as RouterLink,
  useParams,
} from 'react-router-dom';

import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import { DAYS } from 'src/utils/constants';

const PricingPeriodsListResults = ({ pricingPeriodsList, handleDeletePricingPeriod }) => {
  const { variantarea } = useParams();

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell colSpan={3}>

                  Pricing Periods
                </TableCell>
                <TableCell />
                <TableCell />

                <TableCell>
                  <Button
                    component={RouterLink}
                    to={`/app/variantarea/${variantarea}/pricing-period/add`}
                    // onClick={console.log()}
                    sx={{ mx: 1 }}
                    color="error"
                    variant="contained"
                  >
                    Add Pricing
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Id
                </TableCell>
                <TableCell>
                  Cost
                </TableCell>
                <TableCell>
                  Price
                </TableCell>
                <TableCell>
                  From
                </TableCell>
                <TableCell>
                  To
                </TableCell>
                <TableCell>
                  Start Time
                </TableCell>
                <TableCell>
                  End Time
                </TableCell>
                <TableCell>
                  Day
                </TableCell>
                <TableCell>
                  Active
                </TableCell>
                <TableCell align="center">
                  Options
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricingPeriodsList.map((pricingPeriod) => (
                <TableRow
                  hover
                  key={pricingPeriod.id}
                >
                  <TableCell>
                    {pricingPeriod.id}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {pricingPeriod.cost}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {pricingPeriod.price}
                  </TableCell>
                  <TableCell>
                    {pricingPeriod.from}
                  </TableCell>
                  <TableCell>
                    {pricingPeriod.to}
                  </TableCell>
                  <TableCell>
                    {pricingPeriod?.start_time}
                  </TableCell>
                  <TableCell>
                    {pricingPeriod?.end_time}
                  </TableCell>
                  <TableCell>
                    {DAYS.find((d) => d.value === pricingPeriod?.day_number).label}
                  </TableCell>
                  <TableCell>
                    {pricingPeriod.active ? 'Active' : 'Disable'}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        value={pricingPeriod.id}
                        component={RouterLink}
                        to={`/app/variantarea/${variantarea}/pricing-period/${pricingPeriod.id}/edit`}
                        sx={{ mx: 1 }}
                        color="primary"
                        variant="contained"
                      >
                        Edit Pricing
                      </Button>
                      <Button
                        color="warning"
                        variant="contained"
                        onClick={() => handleDeletePricingPeriod(pricingPeriod.id)}
                      >
                        Remove Pricing
                      </Button>
                    </Box>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default PricingPeriodsListResults;
