import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Checkbox,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import is from 'is_js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useState } from 'react';
import AdminServices from 'src/services/AdminServices';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { v4 as uuidv4 } from 'uuid';

import PackAreaForm from 'src/components/packs/PackAreaForm';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function PackDetails({
  pack, handleCreatePack, handleEditPack, loading, handleDeletePackItem, handleDeletePackArea,
}) {
  const [serviceVariantSorted, setserviceVariantSorted] = useState([]);
  const [servicesVariant, setServiceVariant] = useState([]);
  const [changes, setChanges] = useState({});
  const [items, setItems] = useState(pack ? pack?.items : []);
  const [itemOrder, setItemOrder] = useState(20);
  const [packAreas, setPackAreas] = useState(pack ? pack?.packAreas : []);
  const [packAreaOrder, setpackAreaOrder] = useState(20);
  const [areas, setAreas] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceSelected, setServiceSelected] = useState(pack ? pack?.services : []);

  useEffect(() => {
    Promise.all([getAreaList(), getServiceVariantList(), getServices()]);
  }, []);

  const getServiceVariantList = async () => {
    try {
      const response = await AdminServices.getServiceVariants();
      setServiceVariant(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAreaList = async () => {
    try {
      const response = await AdminServices.getAreas();
      setAreas(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getServices = async () => {
    try {
      const response = await AdminServices.getServices();
      console.log('getServices', response.data);
      setServices(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  function handleChange(name, value) {
    setChanges({ ...changes, [name]: value });
  }

  useEffect(() => {
    if (is.not.empty(servicesVariant)) {
      const options = servicesVariant.map((option) => {
        return {
          serviceName: option.service.name,
          ...option,
        };
      });
      setserviceVariantSorted(options.sort((a, b) => -b.serviceName.localeCompare(a.serviceName)));
    }
  }, [servicesVariant]);

  function onCancel() {
    setChanges({});
  }

  const handleSave = () => {
    if (handleCreatePack) {
      handleCreatePack(changes, items, packAreas);
    } else {
      handleEditPack(changes, items, packAreas);
    }
  };

  const handleAddItem = () => {
    setItems((currentValues) => (
      [...currentValues, {
        viewId: uuidv4(),
        service_variant_id: 0,
        sessions: 0,
        cost_percentage: 0,
        price_percentage: 0,
        order: itemOrder,
      }]
    ));
    setItemOrder(itemOrder + 1);
  };

  const handleChangeItem = (itemId, name, value) => {
    const filteredList = items.filter((item) => item.id !== itemId && item.viewId !== itemId);
    const itemToEdit = items.find((item) => item.id === itemId || item.viewId === itemId);
    itemToEdit[name] = value;
    setItems([...filteredList, itemToEdit]);
  };

  const deleteItem = (itemId) => {
    const filteredList = items.filter((item) => item.id !== itemId && item.viewId !== itemId);
    setItems(filteredList);
    if (handleDeletePackItem) {
      handleDeletePackItem(itemId);
    }
  };

  const handleAddArea = () => {
    setPackAreas((currentValues) => (
      [...currentValues, {
        viewId: uuidv4(),
        name: '',
        description: '',
        image: '',
        area_id: null,
        order: packAreaOrder,
      }]
    ));
    setpackAreaOrder(packAreaOrder + 1);
  };

  const handleLocalDeletePackArea = (packAreaId) => {
    const filteredList = packAreas.filter((item) => item.id !== packAreaId && item.viewId !== packAreaId);
    setPackAreas(filteredList);
    if (handleDeletePackArea) {
      handleDeletePackArea(packAreaId);
    }
  };

  const handleChangePackArea = (packAreaId, name, value) => {
    const filteredList = packAreas.filter((item) => item.id !== packAreaId && item.viewId !== packAreaId);
    const areaToEdit = packAreas.find((item) => item.id === packAreaId || item.viewId === packAreaId);
    areaToEdit[name] = value;
    setPackAreas([...filteredList, areaToEdit]);
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <form>
          <Card>
            <CardHeader
              subheader="Pack Details"
              action={(
                <>
                  <Button variant="contained" color="primary" onClick={onCancel}>Cancel</Button>
                  <Button
                    variant="contained"
                    style={{ color: '#FFF', marginLeft: '15px' }}
                    color="success"
                    onClick={handleSave}
                  >
                    {loading ? <CircularProgress /> : 'Save'}
                  </Button>
                </>
              )}
            />
            <CardContent>
              <TextField
                fullWidth
                label="Internal Name"
                name="internal_name"
                onChange={(event) => handleChange(event.target.name, event.target.value)}
                required
                value={'internal_name' in changes ? changes?.internal_name : pack?.internal_name || ''}
                variant="outlined"
                style={{ marginBottom: 24 }}
              />
              <Autocomplete
                id="service-select"
                options={services}
                fullWidth
                freeSolo
                disableCloseOnSelect
                multiple
                size="small"
                value={serviceSelected || []}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  setServiceSelected(newValue);
                  if (newValue) {
                    handleChange('service_id', newValue.map((v) => v.id));
                  } else {
                    handleChange('service_id', []);
                  }
                }}
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => <TextField {...params} label="Services" variant="outlined" />}
                renderOption={(option, { selected }) => {
                  return (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </>
                  );
                }}
              />
            </CardContent>
          </Card>
        </form>
      </Box>
      {!handleEditPack && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginBottom: '24px',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddItem}
          >
            Add Item
          </Button>
        </Box>
      )}
      {items && items.length > 0 && items.sort((a, b) => a.order - b.order).map((item, i) => (
        <ItemForm key={`packItem_${i}`} item={item} serviceVariants={serviceVariantSorted} handleChangeItem={handleChangeItem} deleteItem={deleteItem} />
      ),
      )}
      {items.length === 0 && <Card><CardContent><Typography>No items</Typography></CardContent></Card>}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '24px',
          marginTop: '24px',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddArea}
        >
          Add Area
        </Button>
      </Box>
      {packAreas && packAreas.length > 0 && packAreas.sort((a, b) => a.order - b.order).map((item, i) => (
        <PackAreaForm key={`packArea_${i}`} packArea={item} handleDeletePackArea={handleLocalDeletePackArea} areas={areas} handleChangePackArea={handleChangePackArea} />
      ),
      )}
      {packAreas.length === 0 && <Card><CardContent><Typography>No Areas</Typography></CardContent></Card>}

    </>
  );
}

function ItemForm({
  item, serviceVariants, handleChangeItem, deleteItem,
}) {
  const [values, setValues] = useState({});
  const [SVAselected, setSelected] = useState(item.serviceVariant);
  const id = item.id ? item.id : item.viewId;

  function handleChange(name, value) {
    setValues({ ...values, [name]: value });
    handleChangeItem(id, name, Number(value));
  }

  return (
    <Box sx={{ mb: 3 }}>
      <form>
        <Card>
          <CardHeader
            subheader="Info about the item"
            action={(
              <>
                <IconButton
                  onClick={() => deleteItem(id)}
                >
                  <DeleteForeverRoundedIcon />
                </IconButton>
              </>
)}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs>
                <Autocomplete
                  id="addon-sv-select"
                  options={serviceVariants}
                  groupBy={(option) => option.serviceName}
                  fullWidth
                  freeSolo
                  disableCloseOnSelect
                  size="small"
                  value={SVAselected || {}}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setSelected(newValue);
                    if (newValue) {
                      handleChange('service_variant_id', newValue.id);
                    } else {
                      handleChange('service_variant_id', null);
                    }
                  }}
                  getOptionLabel={(option) => option.internal_name || ''}
                  renderInput={(params) => <TextField {...params} label="Service Variant" variant="outlined" />}
                  renderOption={(option, { selected }) => {
                    return (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.internal_name}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item sx>
                <TextField
                  fullWidth
                  label="Sessions"
                  name="sessions"
                  type="number"
                  onChange={(event) => handleChange(event.target.name, event.target.value)}
                  required
                  value={'sessions' in values ? values.sessions : item.sessions || 0}
                  variant="outlined"
                />
              </Grid>
              <Grid item sx>
                <TextField
                  fullWidth
                  label="Price percentage"
                  name="price_percentage"
                  type="number"
                  onChange={(event) => handleChange(event.target.name, event.target.value)}
                  required
                  value={'price_percentage' in values ? values.price_percentage : item.price_percentage || 0}
                  variant="outlined"
                />
              </Grid>
              <Grid item sx>
                <TextField
                  fullWidth
                  label="Cost percentage"
                  name="cost_percentage"
                  type="number"
                  onChange={(event) => handleChange(event.target.name, event.target.value)}
                  required
                  value={'cost_percentage' in values ? values.cost_percentage : item.cost_percentage || 0}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Box>
  );
}

export default PackDetails;
