import {
  Box,
  CardContent,
  Typography,
  makeStyles,
  Card,
} from '@material-ui/core';

function SaveCardSuccess() {
  const classes = useStyles();
  return (
    <Box border={1} className={classes.cardBoxContainer} borderRadius={2}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h3" className={classes.title} color="textPrimary" gutterBottom>
            Error al Guardar Tarjeta (intenta nuevamente)
          </Typography>
          <Typography variant="h5" className={classes.subTitle} color="textPrimary" gutterBottom>
            Transacción segura
          </Typography>
          <img width="150" src="/static/images/secure-connection.png" />
        </CardContent>
      </Card>
    </Box>
  );
} export default SaveCardSuccess;

const useStyles = makeStyles({
  cardBoxContainer: {
    marginTop: 30,
    margin: 10,
  },
  cardNumberBoxContainer: {
    padding: 10,
  },
  saveCardButton: {
    marginTop: 20,
  },
  secureTransaction: {
    flexDirection: 'row',
  },
  title: {
    marginBottom: 20,
  },
  subTitle: {
    minHeight: 30,
  },
  cardInput: {
    marginTop: 25,
  },
  cardHolderName: {
    marginTop: 20,
  },
  cardHolderNameInput: {
    width: '100%',
  },
});
