import {
  Alert,
  Button,
  Card,
  CardHeader,
  Divider,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import AdminServices from 'src/services/AdminServices';
import { useState } from 'react';

function BookingPaymentDetails({ booking, refetch }) {
  const [successToast, setSuccess] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  function creaditRefund() {
    return AdminServices.updateBooking(booking.id, { refund_type: 'credits', status: 'refunded' })
      .then(() => { setSuccess(true); refetch(); })
      .catch((error) => {
        console.info(error);
        setErrorToast(true);
      });
  }

  function moneyRefund() {
    return AdminServices.updateBooking(booking.id, { status: 'refunded' })
      .then(() => { setSuccess(true); refetch(); })
      .catch((error) => {
        console.info(error);
        setErrorToast(true);
      });
  }

  return (
    <>
      <Snackbar
        open={successToast}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Booking successfully refunded
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorToast}
        autoHideDuration={6000}
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorToast(false)} severity="error">
          Something went wrong
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader
          title="Payment details"
          action={(
            <>
              <Button variant="contained" onClick={() => creaditRefund()} color="primary">Refund in credit</Button>
              <Button
                variant="contained"
                style={{ color: '#FFF', marginLeft: '15px' }}
                color="success"
                onClick={() => moneyRefund()}
              >
                Refund in money
              </Button>
            </>
)}
        />
        <Divider />
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Date
                </TableCell>
                <TableCell>
                  Payment Method
                </TableCell>
                <TableCell>
                  Promocode
                </TableCell>
                <TableCell>
                  Wallet paid amount
                </TableCell>
                <TableCell>
                  Payment method paid amount
                </TableCell>
                <TableCell>
                  Booking Final price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
              >
                <TableCell>
                  {moment(booking.payment_date).local().format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell>
                  {booking.paymentMethod?.name || '-'}
                </TableCell>
                <TableCell>
                  {`${booking.shoppingCart?.promocode_code || 'N/A'} - 
                  ${booking.shoppingCart.discount_amount} ${booking.currency.name}`}
                </TableCell>
                <TableCell>
                  {`${booking.shoppingCart?.wallet_paid_amount} ${booking.currency.name}`}
                </TableCell>
                <TableCell>
                  {`${booking.shoppingCart?.payment_method_paid_amount} ${booking.currency.name}`}
                </TableCell>
                <TableCell>
                  {`${booking.final_price} ${booking.currency.name}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Card>
    </>
  );
}

export default BookingPaymentDetails;
