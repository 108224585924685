import {
  Box, Card, Toolbar, Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

const CURRENCIES = {
  Argentina: 'ARS',
  Chile: 'CLP',
  Colombia: 'COP',
  Perú: 'PEN',
  México: 'MXN',
};

function SettlementsCountryAmount({ currentList = [] }) {
  const [list, setList] = useState(() => new window.Map());

  useEffect(() => {
    console.log('list', list);
  }, [list]);

  function groupByCountry() {
    const newList = (currentList).reduce((acc, booking) => {
      const country = booking?.country;
      const mapKey = country === 'Peru' ? 'Perú' : country || '-';
      const bookingsByCountry = acc.get(mapKey) || [];
      bookingsByCountry.push(booking);
      acc.set(mapKey, bookingsByCountry);
      return acc;
    }, new window.Map());
    setList(newList);
  }

  useEffect(() => { groupByCountry(); }, [currentList]);

  function getAmount(id) {
    const amount = list.get(id).reduce((acc, next) => {
      const result = acc + (Number(next.final_cost) || 0) + (Number(next?.tip?.amount) || 0);
      return result;
    }, 0);
    return amount;
  }

  return (
    <Card sx={{ mb: 2 }}>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h4" id="tableTitle" component="div" sx={{ mt: 2 }}>
          Total to pay by country
        </Typography>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {[...list.keys()].map((country, i) => {
          return (
            <Toolbar key={`total_count_${i}`}>
              <Typography variant="h5" id="tableTitle" component="div" sx={{ mr: 1 }}>
                {`${country}:`}
              </Typography>
              <Box display="flex" flex="1 1 auto" justifyContent="flex-end">
                <NumberFormat
                  value={getAmount(country)}
                  displayType="text"
                  thousandSeparator
                  suffix={` ${CURRENCIES[country]}`}
                />
              </Box>
            </Toolbar>
          );
        })}
      </Box>
    </Card>
  );
}

export default SettlementsCountryAmount;
