export const DAYS = [
  {
    value: null,
    label: 'None',
  },
  {
    value: 1,
    label: 'Lunes',
  },
  {
    value: 2,
    label: 'Martes',
  },
  {
    value: 3,
    label: 'Miércoles',
  },
  {
    value: 4,
    label: 'Jueves',
  },
  {
    value: 5,
    label: 'Viernes',
  },
  {
    value: 6,
    label: 'Sábado',
  },
  {
    value: 7,
    label: 'Domingo',
  },
];

export const CONFIG_VALUE_TYPES = [
  {
    value: 'referred-amount',
    label: 'Referred amount',
  },
  {
    value: 'sharing-text',
    label: 'Sharing text',
  },
  {
    value: 'referer-text',
    label: 'Referer text',
  },
  {
    value: 'referer-amount',
    label: 'Referer amount',
  },
];

export const CONFIG_VALUE_CATEGORIES = [
  {
    value: 'refer-a-friend',
    label: 'Refer a friend',
  },
];
