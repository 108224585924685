import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import AdminServices from 'src/services/AdminServices';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Delete } from '@material-ui/icons';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import DeleteModal from 'src/components/comon/Alert';

const DELETE_MODAL_TITLE = 'Do you want to remove this user address ?';

function CustomerAddresses({ customerId }) {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState('');

  useEffect(() => {
    getAddresses();
  }, [customerId]);

  function getAddresses() {
    setLoading(true);
    return AdminServices.getUserAddresses(customerId)
      .then((res) => {
        console.log('addresses', res.data);
        setAddresses(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function onDelete() {
    return AdminServices.deleteUserAddress(customerId, addressToDelete)
      .then(() => {
        setOpen(false);
        getAddresses();
        alert('Delete success');
      });
  }

  function handleDeleteModal(id) {
    setAddressToDelete(id);
    setOpen(true);
  }

  return (
    <>
      <Card>
        <CardHeader
          title="User addresses"
          action={(
            <Button
              aria-label="addAddress"
              variant="contained"
              // value={address.id}
              component={RouterLink}
              onClick={() => { console.log(`customers/${customerId}/address/add`); }}
              to={`/app/customer/${customerId}/address/add`}
            >
              Add Address
            </Button>
          )}
        />
        <Divider />
        <PerfectScrollbar>
          <Box sx={{ padding: 2 }}>
            {loading
              && <CircularProgress color="inherit" size={20} />}
            {addresses.length === 0 && !loading && (
            <Typography
              style={{ textAlign: 'center' }}
            >
              Without saved addresses
            </Typography>
            )}
            {addresses.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Addres ID
                  </TableCell>
                  <TableCell>
                    Address
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      Options
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addresses.map((address) => (
                  <>
                    <TableRow
                      hover
                      key={address.id}
                    >
                      <TableCell>
                        {address.id}
                      </TableCell>
                      <TableCell>
                        {address.formatted_address}
                      </TableCell>
                      <TableCell>
                        <Box style={{ justifyContent: 'center', display: 'flex' }}>
                          <ButtonGroup>
                            <Button
                              color="primary"
                              variant="contained"
                              value={address.id}
                              component={RouterLink}
                              onClick={() => { console.log(`customers/${customerId}/address/${address.id}/edit`); }}
                              to={`/app/customer/${customerId}/address/${address.id}/edit`}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleDeleteModal(address.id)}
                              children={<Delete />}
                            />
                          </ButtonGroup>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
            )}
            <DeleteModal
              handleClose={() => {
                setOpen(false);
                setAddressToDelete('');
              }}
              open={open}
              Agree={onDelete}
              title={DELETE_MODAL_TITLE}
              content={`ID: ${addressToDelete}`}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
}

export default CustomerAddresses;
