import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import AdminServices from 'src/services/AdminServices';
import BannersListResult from 'src/components/banner/BannersListResult';
import BannersToolbar from 'src/components/banner/BannersToolbar';

const DEFAULT_FILTERS = {
  from: null,
  to: null,
  page_size: 10,
  page_number: 1,
  status: ['active', 'paused'],
  sections: ['home_images', 'home_text'],
  order_by: 'id',
};

const BannersList = () => {
  const [banners, setBanners] = useState([]);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBanners(filters);
  }, [filters]);

  async function fetchBanners(filter) {
    setLoading(true);
    try {
      const response = await AdminServices.getBanners(filter);
      console.log('fetchBanners', response.data);
      setLoading(false);
      setBanners(response.data);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  function handleFiltersChange(changes) {
    setFilters({ ...filters, ...changes });
  }

  function handleClearFilters() {
    setFilters(DEFAULT_FILTERS);
  }

  return (
    <>
      <Helmet>
        <title>Banners | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Banners</Typography>
          </Breadcrumbs>
          <BannersToolbar
            handleFiltersChange={handleFiltersChange}
            filters={filters}
            isLoading={loading}
            handleClearFilters={handleClearFilters}
          />
          <Box sx={{ pt: 3 }}>
            <BannersListResult
              banners={banners}
              handleFiltersChange={handleFiltersChange}
              filters={filters}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BannersList;
