import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import is from 'is_js';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import PromocodeDetails from 'src/components/promocodes/PromocodeDetails';
import AdminServices from 'src/services/AdminServices';

const REQUIRED_FIELD_ERROR = 'This field is required';

function PromocodeAdd() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [addLoading, setAddLoading] = useState(false);

  const handleSave = (promocode) => {
    console.log('promocode', promocode);
    setErrors({});

    if (validateData(promocode)) {
      setAddLoading(true);
      return AdminServices.createPromocode(promocode)
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setAddLoading(false);
          navigate('/app/promocodes');
        });
    }
  };

  const validateData = (promocode) => {
    let validated;
    const errorAcum = {};
    if (!promocode.code) errorAcum.code = REQUIRED_FIELD_ERROR;
    if (!promocode.description) errorAcum.description = REQUIRED_FIELD_ERROR;
    if (promocode.promocode_type === 'percentage') {
      if (!promocode.discount_percentage) errorAcum.discount_percentage = REQUIRED_FIELD_ERROR;
    }
    if (promocode.promocode_type === 'fixed_amount') {
      if (!promocode.discount_amount) errorAcum.discount_amount = REQUIRED_FIELD_ERROR;
    }
    if (!promocode.max_uses_per_user) errorAcum.max_uses_per_user = REQUIRED_FIELD_ERROR;
    if (!promocode.max_total_uses) errorAcum.max_total_uses = REQUIRED_FIELD_ERROR;
    if (!promocode.all_service_variants) {
      if (!promocode.service_variant_ids) errorAcum.service_variant_ids = REQUIRED_FIELD_ERROR;
    }
    if (!promocode.start) errorAcum.start = REQUIRED_FIELD_ERROR;
    if (!promocode.end) errorAcum.end = REQUIRED_FIELD_ERROR;
    if (!promocode.areas_ids) errorAcum.areas_ids = REQUIRED_FIELD_ERROR;
    if (!promocode.all_users) {
      if (is.empty(promocode.users_ids)) {
        errorAcum.users_ids = REQUIRED_FIELD_ERROR;
      }
    }
    if (is.empty(errorAcum)) validated = true;

    if (is.not.empty(errorAcum)) {
      validated = false;
      setErrors(errorAcum);
    }

    return validated;
  };

  return (
    <>
      <Helmet>
        <title> Add Promocode </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/promocodes">
              Promocodes
            </Link>
            <Typography color="textPrimary">New promocode</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <PromocodeDetails handleSave={handleSave} action="add" errors={errors} saveLoading={addLoading} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PromocodeAdd;
