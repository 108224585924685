import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';

const ConversationsToolbar = ({ handleFilter, filters }) => {
  const handleLocalFilter = (fil) => {
    handleFilter(fil);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid container md={12} xs={12} style={{ marginTop: '24px' }}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Button
                sx={{ mx: 1 }}
                color={filters.status === 'unread' ? 'primary' : 'secondary'}
                onClick={() => {
                  handleLocalFilter({ status: filters.status === 'unread' ? '' : 'unread' });
                }}
                variant="outlined"
              >
                {filters.status === 'unread' ? 'Show all' : 'Show unread' }
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>

  );
};

export default ConversationsToolbar;
