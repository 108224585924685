import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import ProfessionalDetails from 'src/components/professional/ProfessionalDetails';
import AdminServices from 'src/services/AdminServices';

function ProfessionalEdit() {
  const { professionalId } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [serviceVariants, setServiceVariants] = useState([]);

  useEffect(() => {
    fetchData();
  }, [professionalId]);

  async function fetchData() {
    return Promise.all([AdminServices.getProfessional(professionalId), AdminServices.getServiceVariants()])
      .then(([professionalResponse, serviceVariantsResponse]) => {
        setDefaultValue(professionalResponse.data);
        setServiceVariants(serviceVariantsResponse.data);
        setIsLoad(true);
      })
      .catch((err) => console.log(err));
  }

  function handleSave(updatedData) {
    setSaveIsLoading(true);
    return AdminServices.updateProfessional(professionalId, updatedData)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSaveIsLoading(false);
      });
  }

  return (
    <>
      <Helmet>
        <title>Edit Professional | Ameiz</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/professionals">
              Professionals
            </Link>
            <Typography color="textPrimary">Edit Professional</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {isLoad ? (
                <ProfessionalDetails
                  serviceVariants={serviceVariants}
                  updateLoading={saveIsLoading}
                  handleSave={handleSave}
                  defaultValue={defaultValue}
                  action="edit"
                />
              )
                : <Box style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></Box>}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ProfessionalEdit;
