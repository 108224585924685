import { useState } from 'react';
import {
  Box, Card, CardHeader, IconButton, Divider, CardContent, Grid, Checkbox, TextField, makeStyles, createStyles, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import BlankProfile from 'src/assets/blank-profile.png';
import AddAPhotoAlternateOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}),
);

const PackAreaForm = ({
  packArea, handleDeletePackArea, areas, handleChangePackArea,
}) => {
  const classes = useStyles();
  const id = packArea.id ? packArea.id : packArea.viewId;
  const [values, setValues] = useState({});
  const [image, setImage] = useState(packArea.image ? packArea.image.full_url : null);
  const [areaSelected, setAreaSelected] = useState(packArea.area ? packArea.area : {});

  function handleChange(name, value) {
    setValues({ ...values, [name]: value });
    handleChangePackArea(id, name, value);
  }

  const handleAddBannerImage = (event) => {
    getBase64(event.target.files[0]).then((file64) => {
      handleChangePackArea(id, event.target.name, file64);
      setValues({
        ...values,
        [event.target.name]: file64,
      });
    });
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Box sx={{ mb: 3 }}>
      <form>
        <Card>
          <CardHeader
            subheader="Info about the pack area"
            action={(
              <>
                <IconButton onClick={() => handleDeletePackArea(id)}>
                  <DeleteForeverRoundedIcon />
                </IconButton>
              </>
            )}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Box
                  style={{
                    backgroundImage: image ? `url(${image})` : `url(${BlankProfile})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '500px',
                    height: '320px',
                    margin: '0 auto',
                    borderRadius: '8px',
                    border: '5px solid #ddd',
                  }}
                >
                  <input
                    accept="image/jpeg,image/png"
                    className={classes.input}
                    id="image"
                    type="file"
                    name="image"
                    onChange={handleAddBannerImage}
                  />
                  <label htmlFor="image">
                    <IconButton
                      style={{ position: 'relative' }}
                      component="span"
                    >
                      <AddAPhotoAlternateOutlinedIcon />
                    </IconButton>
                  </label>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={(event) => handleChange(event.target.name, event.target.value)}
                  required
                  value={'name' in values ? values.name : packArea.name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  onChange={(event) => handleChange(event.target.name, event.target.value)}
                  required
                  value={'description' in values ? values.description : packArea.description || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="packArea-areas-select"
                  options={areas}
                  fullWidth
                  freeSolo
                  disableCloseOnSelect
                  size="small"
                  value={areaSelected || {}}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setAreaSelected(newValue);
                    if (newValue) {
                      handleChange('area_id', newValue.id);
                    } else {
                      handleChange('area_id', null);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => <TextField {...params} label="Area" variant="outlined" />}
                  renderOption={(option, { selected }) => {
                    return (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="pack-active-id-label">Active</InputLabel>
                  <Select
                    labelId="pack-active-id-label"
                    id="pack-active-id"
                    defaultValue={null}
                    value={'active' in values ? values.active : packArea.active}
                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                    label="Active"
                    name="active"
                  >
                    {STATUS.map((s, i) => (
                      <MenuItem key={i} value={s.value}>
                        {s.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Box>
  );
};

export default PackAreaForm;

const STATUS = [
  { label: 'True', value: true },
  { label: 'False', value: false },
];
