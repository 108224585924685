import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import AdminServices from 'src/services/AdminServices';
import { useEffect, useState } from 'react';

function ProfessionalsTrackingToolbar({ selected, onchange }) {
  const [countryOptions, setCountryOptions] = useState([]);

  const getCountryList = async () => {
    try {
      const response = await AdminServices.getCountries();
      setCountryOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  function handlechange(countryId) {
    onchange(countryId);
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="mutiple-country-id-label">Country</InputLabel>
              <Select
                key={selected}
                defaultValue={null}
                labelId="mutiple-country-id-label"
                id="mutiple-country-id"
                value={selected}
                onChange={(event) => handlechange(event.target.value)}
                label="Country"
                name="COUNTRY"
              >
                {countryOptions.map((c, i) => (
                  <MenuItem key={`country_${i}`} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ProfessionalsTrackingToolbar;
