import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const PromocodeListResults = ({
  promocodes, handleClickOpenDeleteModal, handleFiltersChange, filters,
}) => {
  function handlePageFiltersChange(prop, value) {
    console.info(prop, value);
    handleFiltersChange({ ...filters, [prop]: value });
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Id
                </TableCell>
                <TableCell>
                  Code
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  Start Date
                </TableCell>
                <TableCell>
                  End Date
                </TableCell>
                <TableCell align="center">
                  All Users
                </TableCell>
                <TableCell align="center">
                  All Service Variants
                </TableCell>
                <TableCell align="center">
                  Total uses
                </TableCell>
                <TableCell align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promocodes.map((promocode, i) => (
                <TableRow key={`promocodeRow_${i}`} hover>
                  <TableCell component="th" scope="row" align="center">
                    {promocode.id}
                  </TableCell>
                  <TableCell>
                    {promocode?.code}
                  </TableCell>
                  <TableCell>
                    {promocode?.description}
                  </TableCell>
                  <TableCell>
                    {moment(promocode.start).utc().format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(promocode.end).utc().format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell align="center">
                    {promocode?.all_users ? <CheckIcon color="primary" /> : <ClearIcon color="secondary" />}
                  </TableCell>
                  <TableCell align="center">
                    {promocode?.all_service_variants ? <CheckIcon color="primary" /> : <ClearIcon color="secondary" />}
                  </TableCell>
                  <TableCell align="center">
                    {promocode?.total_uses}
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        value={promocode.id}
                        component={RouterLink}
                        to={`/app/promocodes/${promocode.id}/edit`}
                        sx={{ mx: 1 }}
                        style={{ color: '#FFF' }}
                        size="small"
                        color="success"
                        variant="contained"
                      >
                        Edit
                      </Button>
                      <Button
                        value={promocode.id}
                        sx={{ mx: 1 }}
                        size="small"
                        color="error"
                        onClick={() => handleClickOpenDeleteModal(promocode.id)}
                        variant="contained"
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        page={filters.page_number - 1}
        onPageChange={(_, page) => handlePageFiltersChange('page_number', page + 1)}
        onRowsPerPageChange={(event) => handlePageFiltersChange('page_size', event.target.value)}
        rowsPerPage={filters.page_size}
        rowsPerPageOptions={[5, 10, 25]}
        labelDisplayedRows={() => console.log('next page')}
      />
    </Card>
  );
};

export default PromocodeListResults;
