import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Container, CircularProgress } from '@material-ui/core';

import AddonToolbar from 'src/components/addon/AddonToolbar';
import AddonListResult from 'src/components/addon/AddonListResult';
import AdminServices from 'src/services/AdminServices';
import DeleteModal from 'src/components/comon/Alert';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const DELETE_MODAL_TITLE = 'Do you want to remove this addon ?';

const DEFAULT_FILTERS = {
  page_number: 1,
  page_size: 20,
  service_variant_id: null,
  area_id: null,
  query: null,
};

const AddonList = () => {
  const [addons, setAddons] = useState([]);
  const [allAddons, setAllAddons] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [addonToDelete, setaddonToDelete] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });

  useEffect(() => {
    getAddonList(filters);
  }, [filters]);

  const getAddonList = async (filters) => {
    try {
      const response = await AdminServices.getAddons(filters);
      setAddons(response.data);
      setAllAddons(response.data);
      setIsLoad(true);
    } catch (error) {
      console.log(error);
      setIsLoad(false);
    }
  };

  function handleDeleteAddon() {
    return AdminServices.deleteAddon(addonToDelete)
      .then(() => {
        setOpenModal(false);
        alert('Success.');
        setAddons(addons.filter((addon) => addon.id !== addonToDelete));
      })
      .catch((error) => console.log(error)
        .finally(() => setOpenModal(false)));
  }

  const handleClickOpenDeleteModal = (addonId) => {
    setaddonToDelete(addonId);
    setOpenModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenModal(false);
  };

  const handleFilter = (type, value) => {
    setFilters({ ...filters, [type]: value ? type === 'query' ? value : value.id : null });
  };

  const handlePagination = (filterSelected) => {
    setFilters({ ...filters, ...filterSelected });
  };

  return (
    <>
      <Helmet>
        <title> Addon | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>

          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Addon</Typography>
          </Breadcrumbs>

          <AddonToolbar
            handleFilter={handleFilter}
          />
          <Box sx={{ pt: 3 }}>
            {isLoad
              ? (
                <AddonListResult
                  addons={addons}
                  handleClickOpenDeleteModal={handleClickOpenDeleteModal}
                  filters={filters}
                  isLoad={isLoad}
                  handlePagination={handlePagination}
                />
              )
              : (
                <Box style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
                >
                  <CircularProgress />
                </Box>
              )}
          </Box>
        </Container>
        <DeleteModal
          handleClose={handleCloseDeleteModal}
          open={openModal}
          Agree={handleDeleteAddon}
          title={DELETE_MODAL_TITLE}
          content={`ID: ${addonToDelete}`}
        />
      </Box>
    </>
  );
};

export default AddonList;
