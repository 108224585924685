import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useState, useEffect } from 'react';

import AdminServices from 'src/services/AdminServices';
import WalletsListResult from 'src/components/wallets/WalletListResult';
import WalletsListToolbar from 'src/components/wallets/WalletsListToolbar';
import WalletCountryAmount from 'src/components/wallets/WalletCountryAmount';

const WalletsList = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [toSearch, setSearch] = useState('');
  const [totalBalances, setTotalBalances] = useState(null);

  useEffect(() => {
    Promise.all([getUsersList(toSearch), getBalances()]);
  }, [toSearch]);

  const getBalances = () => {
    AdminServices.getAllBalances()
      .then((resp) => setTotalBalances(resp.data))
      .catch((err) => console.log(err));
  };

  async function getUsersList(query = null) {
    setLoading(true);
    try {
      const response = await AdminServices.getUsers(query);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handleSearch(event) {
    setSearch(event.target.value);
  }

  return (
    <>
      <Helmet>
        <title>Wallets | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Wallets</Typography>
          </Breadcrumbs>
          <WalletsListToolbar onSearchChange={handleSearch} isLoading={isLoading} />
          {totalBalances && <Box style={{ marginTop: 24 }}><WalletCountryAmount totals={totalBalances} /></Box>}
          <Box sx={{ pt: 3 }}>
            <WalletsListResult users={users} tosearch={toSearch} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default WalletsList;
