import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import PricingPeriodDetail from 'src/components/pricingPeriod/PrecingPeriodDetails';
import AdminServices from 'src/services/AdminServices';

function PricingEdit() {
  const navigate = useNavigate();
  const { variantarea, pricingPeriodId } = useParams();
  const [defaultValue, setDefaultValue] = useState({});
  const [isLoad, setIsLoad] = useState(false);

  const handleSave = async (details) => {
    const data = {
      active: details.active,
      cost: Number(details.cost),
      from: details.from,
      price: Number(details.price),
      to: details.to,
      service_variant_area_id: variantarea,
      day_number: details.day_number,
      start_time: details.start_time,
      end_time: details.end_time,
    };
    try {
      const response = await AdminServices.updateServiceVarianAreaPricingPeriod(variantarea, pricingPeriodId, data);
      console.log('response', response.data);
      navigate(`/app/variantarea/${variantarea}/edit`, { replace: true });
      alert('succes');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return AdminServices.getServiceVarianAreaPricingPeriod(variantarea, pricingPeriodId)
      .then((response) => {
        setDefaultValue(response.data);
        console.log(response.data);
        setIsLoad(true);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Edit Pricing Period </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/service/variant">
              Service variants
            </Link>
            <Typography color="textPrimary">Edit Pricing Period</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {isLoad ? <PricingPeriodDetail handleSave={handleSave} defaultValues={defaultValue} /> : null}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PricingEdit;
