import { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Switch,
} from '@material-ui/core';

import { DAYS } from 'src/utils/constants';

const states = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'Diable',
  },
];

const PricingPeriodDetail = ({ handleSave, defaultValues, svaName }) => {
  const [values, setValues] = useState({});
  const [editTime, setEitTime] = useState(false);
  const [editDates, setEditDates] = useState(false);

  useEffect(() => {
    if (!editTime) {
      setValues({
        ...values,
        start_time: null,
        end_time: null,
      });
    }
  }, [editTime]);

  useEffect(() => {
    if (!editDates) {
      setValues({
        ...values,
        from: null,
        to: null,
      });
    }
  }, [editDates]);

  const handleLocalSave = () => {
    handleSave(values);
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    console.log(defaultValues);
    if (defaultValues) {
      setValues({
        ...defaultValues,
        from: moment(defaultValues.from).format('YYYY-MM-DDTHH:MM'),
        to: moment(defaultValues.to).format('YYYY-MM-DDTHH:MM'),
      });
      if (defaultValues.from || defaultValues.to) {
        setEditDates(true);
      }
      if (defaultValues.start_time || defaultValues.end_time) {
        setEitTime(true);
      }
    }
  }, []);

  const toggleEditTime = () => {
    setEitTime((prev) => !prev);
  };

  return (
    <form>
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title={`Pricing period - ${svaName}`}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the cost"
                label="Cost"
                name="cost"
                onChange={handleChange}
                required
                value={values.cost}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the price"
                label="Price"
                name="price"
                value={values.price}
                onChange={handleChange}
                required
                type="number"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <TextField
                fullWidth
                label="Select Status"
                name="active"
                placeholder="Select Status"
                onChange={handleChange}
                select
                required
                defaultValue={defaultValues ? defaultValues?.active : values.day_number}
                value={values.active}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {states.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box style={{ display: 'flex', width: '100%' }}>
                <Switch
                  color="primary"
                  size="small"
                  checked={editDates}
                  onChange={() => setEditDates((prev) => !prev)}
                />
                <TextField
                  disabled={!editDates}
                  style={{ minWidth: '150px', marginRight: 8 }}
                  id="from-datetime-local"
                  label="From"
                  type="datetime-local"
                  defaultValue={values.from}
                  name="from"
                  onChange={handleChange}
                  value={values.from}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  disabled={!editDates}
                  style={{ minWidth: '150px' }}
                  id="to-datetime-local"
                  label="To"
                  name="to"
                  type="datetime-local"
                  defaultValue={values.to}
                  onChange={handleChange}
                  value={values.to}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Switch
                color="primary"
                size="small"
                checked={editTime}
                onChange={toggleEditTime}
              />
              <TextField
                style={{ marginRight: 8 }}
                disabled={!editTime}
                id="start-time"
                label="Start Time"
                type="time"
                defaultValue={values.start_time}
                name="start_time"
                onChange={handleChange}
                value={values.start_time}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="end-time"
                disabled={!editTime}
                label="End Time"
                name="end_time"
                type="time"
                defaultValue={values.end_time}
                onChange={handleChange}
                value={values.end_time}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Select day"
                name="day_number"
                placeholder="Select day"
                onChange={handleChange}
                select
                required
                defaultValue={defaultValues ? defaultValues?.day_number : values.day_number}
                value={values.day_number}
                variant="outlined"
              >
                {DAYS.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            onClick={handleLocalSave}
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default PricingPeriodDetail;
