import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AuthService from 'src/services/authService';
import { useNavigate } from 'react-router';

import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import moment from 'moment';

import ServiceVariantAreaDetails from 'src/components/serviceVariantsAreas/ServiceVariantsAreasDetails';
import PricingPeriodsListResults from 'src/components/pricingPeriod/PricingPeriodsListResults';
import AdminServices from 'src/services/AdminServices';
import ServiceVariantAreaAvailability from 'src/components/serviceVariantsAreas/ServiceVarianAreaAvailability';

function ServiceVariantAreaEdit() {
  const navigate = useNavigate();
  const { variantarea } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [isLoadPricing, setIsLoadPricing] = useState(true);
  const [pricings, setPricings] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [availabilityLoading, setAvailabilityLoading] = useState(false);

  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    Promise.all([getServiceVariantAreaById(), getPricingPeriodList()]);
  }, []);

  const getServiceVariantAreaById = async () => {
    try {
      const response = await AdminServices.getServiceVariantArea(variantarea);
      setDefaultValue(response.data);
      setIsLoad(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getPricingPeriodList = async () => {
    try {
      const response = await AdminServices.getServiceVarianAreaPricingPeriods(variantarea);
      setPricings(response.data);
      setIsLoadPricing(true);
    } catch (error) {
      console.log(error);
    }
  };

  function handleSave(details) {
    setIsSaving(true);

    let savedetails = {
      service_variant_area_id: variantarea,
      name: details.name,
      description: details.description,
      mobile_description: details.mobile_description,
      quantity_message: details.quantity_message,
      cost: details.cost,
      price: details.price,
      transport_price: details.transport_price,
      active: details.active,
      area_id: details.area_id,
      duration: details.duration,
    };

    if (details.image) {
      savedetails = { ...savedetails, image: details.image };
    }

    axios.patch(`${process.env.REACT_APP_MODERN_API_URL}/admin/service-variant-area/${variantarea}`,
      savedetails,
      {
        headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` },

      })
      .then(() => {
        setIsSaving(false);
        alert('Success');
        navigate('/app/service/variant');
      }).catch((e) => {
        console.log(e);
        setIsSaving(false);
        alert('error');
      });
  }

  const handleDeletePricingPeriod = (id) => {
    AdminServices.deletePricingPeriod(variantarea, id)
      .then(() => setPricings(pricings.filter((p) => p.id !== id)))
      .catch((err) => console.log(err));
  };

  const handleSaveAvailability = async (values) => {
    setAvailabilityLoading(true);
    const availability = {
      availability_id: values.id || null,
      active: values.active,
      service_variant_area_id: variantarea,
      start_time: moment().utc().set('hour', values.start_time.slice(0, 2)).set('minute', values.start_time.slice(3))
        .set('second', '00')
        .format(),
      end_time: moment().utc().set('hour', values.end_time.slice(0, 2)).set('minute', values.end_time.slice(3))
        .set('second', '00')
        .format(),
    };
    try {
      const response = await AdminServices.createServiceVarianAreaAvailability(variantarea, availability);
    } catch (error) {
      console.log(error);
    } finally {
      setAvailabilityLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Add Service Variant </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/service/variant">
              Service variants
            </Link>
            <Typography color="textPrimary">Edit variant area</Typography>
          </Breadcrumbs>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              {isSaving ? <CircularProgress /> : isLoad ? (
                <ServiceVariantAreaDetails
                  handleSave={handleSave}
                  defaultValue={defaultValue}
                />
              ) : <></>}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              {isSaving ? <CircularProgress /> : isLoadPricing ? (
                <PricingPeriodsListResults
                  pricingPeriodsList={pricings}
                  handleSave={handleSave}
                  handleDeletePricingPeriod={handleDeletePricingPeriod}
                />
              ) : <></>}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <ServiceVariantAreaAvailability
                defaultValue={defaultValue ? defaultValue.availabilities : null}
                handleSave={handleSaveAvailability}
                availabilityLoading={availabilityLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ServiceVariantAreaEdit;
