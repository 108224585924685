import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import {
  Box, CircularProgress, Container, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import SettlementsToolbar from 'src/components/Settlements/SettlementsToolbar';
import AdminServices from 'src/services/AdminServices';
import SettlementsListResult from 'src/components/Settlements/SettlementsListResult';
import SettlementsCountryAmount from 'src/components/Settlements/SettlementsCountryAmount';

const DEFAULT_FILTERS = {
  from: moment().day(-30).utc().startOf('day')
    .toISOString(),
  to: moment().utc().endOf('day').toISOString(),
  status: ['done'],
  professional_id: null,
};

function SettlementsList() {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [bookings, setBookings] = useState([]);
  const [allBookings, setAllBookings] = useState([]);
  const [list, setList] = useState(() => new window.Map());
  const [isLoad, setIsLoad] = useState(false);
  const [currentList, setCurrentList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currentCountry, setCurrentCountry] = useState(null);

  useEffect(() => {
    Promise.all([fetchBookings(), getCountriesInfo()]);
  }, []);

  function getCountriesInfo() {
    return AdminServices.getCountries()
      .then((response) => setCountries(response.data))
      .catch((error) => console.log(error));
  }

  function fetchBookings(filter = filters) {
    const newFilter = {
      ...filter,
      page_number: 0,
      page_size: 10000,
    };
    return AdminServices.getBookings(newFilter)
      .then((response) => {
        setAllBookings(response.data);
        setCurrentList(response.data);
        setBookings(response.data);
        if (currentCountry) {
          filterBookingsByCountry(currentCountry, response.data);
        } else {
          filterByProfessional(response.data);
        }
        setIsLoad(true);
      })
      .catch((err) => {
        setIsLoad(true);
        console.log(err);
      });
  }

  function filterByProfessional(data) {
    const newMap = (data || []).reduce((acc, booking) => {
      const professional = booking?.professional?.user;
      const mapKey = professional ? `${professional.name + professional.lastname}` : '-';
      const professionalBoookings = acc.get(mapKey) || [];
      professionalBoookings.push(booking);
      acc.set(mapKey, professionalBoookings);
      return acc;
    }, new window.Map());
    setList(newMap);
  }

  const filterBookingsByCountry = (country, newBookings) => {
    let newList = JSON.parse(JSON.stringify(newBookings || bookings));
    newList = newList.filter((book) => book.country === country);
    setCurrentList(newList);
    filterByProfessional(newList);
  };

  const handleFilter = (filter) => {
    if (filter.to && filter.from) {
      if (moment(filter.to).isAfter(moment(filter.from))
      || moment(filter.from).format('DD-MM-YYYY') === moment(filter.to).format('DD-MM-YYYY')) {
        fetchBookings(filter);
        setFilters(filter);
      } else {
        alert('The date from must be before the date to');
      }
    } else {
      fetchBookings(filter);
    }
  };

  const handleClearFilter = () => {
    setFilters(DEFAULT_FILTERS);
    fetchBookings(DEFAULT_FILTERS);
  };

  function handleFetch() {
    fetchBookings();
  }

  const handleChangeCurrenCountry = (country) => {
    setCurrentCountry(country);
    if (country) {
      filterBookingsByCountry(country);
    } else {
      filterByProfessional(allBookings);
      setCurrentList(allBookings);
    }
  };

  return (
    <>
      <Helmet>
        <title> Bookings | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box style={{
            width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 12,
          }}
          >
            <Breadcrumbs aria-label="booking-breadcrumb">
              <Link color="inherit" href="/">
                Home
              </Link>
              <Typography color="textPrimary">Settlements</Typography>
            </Breadcrumbs>
            <FormControl variant="outlined" style={{ width: '250px' }}>
              <InputLabel id="mutiple-country-id-label">Country</InputLabel>
              <Select
                labelId="mutiple-country-id-label"
                id="mutiple-country-id"
                value={currentCountry}
                size="small"
                defaultValue={null}
                onChange={(event) => handleChangeCurrenCountry(event.target.value)}
                label="Country"
                name="COUNTRY"
              >
                <MenuItem key="country_null" value={null}>
                  NONE
                </MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.name} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <SettlementsToolbar
            handleFilter={handleFilter}
            filters={filters}
            handleClearFilter={handleClearFilter}
          />
          {!isLoad && <Box justifyContent="center" display="flex" sx={{ mt: 2 }}><CircularProgress /></Box>}
          {isLoad ? <SettlementsCountryAmount currentList={currentList} /> : null}
          <Box sx={{ pt: 3 }}>
            {isLoad
              ? [...list.keys()].sort().map((key) => {
                const data = list.get(key) || [];
                return (
                  <SettlementsListResult key={key} data={data} handlefetch={handleFetch} />
                );
              })
              : null}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default SettlementsList;
