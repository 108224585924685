import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  IconButton,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import { useState, useCallback } from 'react';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import { Search as SearchIcon } from 'react-feather';
import AdminServices from 'src/services/AdminServices';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}),
);

const WalletsListToolbar = (props) => {
  const classes = useStyles();
  const { isLoading } = props;
  const [search, setSearch] = useState('');
  const request = debounce((value) => props.onSearchChange(value), 500);
  const [file, setFile] = useState('');
  const [fileLoading, setFileLoading] = useState(false);

  const debounceRequest = useCallback((value) => request(value), []);

  const onChange = (event) => {
    debounceRequest(event);
    setSearch(event.target.value);
  };

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleSaveFile = async () => {
    setFileLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await AdminServices.importWalletFile(formData);
      console.log('importWalletFile response', response.data);
      alert('Success.');
    } catch (error) {
      console.log('importWalletFile error', error.response.data.message || errror);
      alert(`${error.response.data.message || errror}`);
    } finally {
      setFileLoading(false);
      setFile('');
    }
  };

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px',
          }}
          >
            <Box sx={{ maxWidth: 500 }} style={{ flex: 'auto' }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search customer"
                variant="outlined"
                onChange={onChange}
                value={search}
              />
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {file.name && (
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography>{file.name}</Typography>
                  <IconButton component="span" onClick={() => setFile('')}>
                    <HighlightOffOutlinedIcon fontSize="small" color="secondary" />
                  </IconButton>
                </Box>
              )}
              {!file && (
                <>
                  <input
                    accept=".xlsx, .xls, .csv"
                    className={classes.input}
                    id="AttachFile"
                    type="file"
                    name="AttachFile"
                    onChange={handleUploadClick}
                  />
                  <label htmlFor="AttachFile">
                    <IconButton component="span" style={{ borderRadius: 0 }}>
                      <AttachFileOutlinedIcon />
                      <span style={{ fontSize: '1.2rem' }}>IMPORT</span>
                    </IconButton>
                  </label>
                </>
              )}
              {file && (
                <Button variant="contained" onClick={handleSaveFile}>
                  {fileLoading ? <CircularProgress /> : 'Confirm'}
                </Button>
              )}
            </Box>
            {isLoading && <Box><CircularProgress /></Box>}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default WalletsListToolbar;
