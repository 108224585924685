import { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';
import AdminServices from 'src/services/AdminServices';

const ServiceVariantListToolbar = ({
  handlefilter, getServiceVariantList,
}) => {
  const [filters, setFilters] = useState({});
  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState(null);

  useEffect(() => {
    getAreas();
  }, []);

  const getAreas = () => {
    AdminServices.getAreas()
      .then((resp) => setAreas(resp.data))
      .catch((err) => console.log(err));
  };

  const handlechange = (filter) => {
    setFilters({ ...filters, ...filter });
    handlefilter({ ...filters, ...filter });
  };

  function handleClear() {
    setFilters({});
    handlefilter({});
    setAreaSelected(null);
    getServiceVariantList();
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          component={RouterLink}
          to="/app/service/variant/add"
          color="primary"
          variant="contained"
        >
          Add Service Variant
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextField
                  fullWidth
                  label="Service name or variant name"
                  value={filters.service || ''}
                  onChange={(e) => handlechange({ service: e.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Variant Name or Service Name"
                  variant="outlined"
                  helperText="Type a string with at least 3 characters"
                />
              </Grid>
              <Grid item xs>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="areas-id-label">Areas</InputLabel>
                  <Select
                    labelId="areas-id-label"
                    id="areas-id"
                    value={areaSelected}
                    defaultValue={null}
                    onChange={(event) => { getServiceVariantList(event.target.value.id); setAreaSelected(event.target.value); }}
                    label="Areas"
                    name="areas"
                  >
                    <MenuItem value={null}>
                      None
                    </MenuItem>
                    {areas.map((area) => (
                      <MenuItem key={area.id} value={area}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <Box style={{
                  display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', height: '100%',
                }}
                >
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    onClick={handleClear}
                    variant="outlined"
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ServiceVariantListToolbar;
