import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
  NavLink as RouterLink,
} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function BookingHeader({ booking, date, requests }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          Booking Request from Boking Id
          {' '}
          {booking}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Reserver Date
          {' '}
          {moment(date).format('dddd DD MMMM YYYY')}
        </Typography>
        <Typography variant="body2" component="p">
          {requests}
          {' '}
          request were sent
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          component={RouterLink}
          to="/app/bookings"
          color="primary"
          variant="contained"
        >
          Volver
        </Button>
      </CardActions>
    </Card>
  );
}
