import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  IconButton,
} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useNavigate } from 'react-router';

const types = {
  0: 'Outcome',
  1: 'Income',
};

const TransactionsListResult = ({
  transactions, filters, handleFilters, setTransactionSelected, setTransactionToEdit,
}) => {
  const navigate = useNavigate();
  function handlePageFiltersChange(prop, value) {
    handleFilters({ ...filters, [prop]: value });
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  ID
                </TableCell>
                <TableCell>
                  Currency
                </TableCell>
                <TableCell>
                  Amount
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  Concept
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell align="center">
                  Created date
                </TableCell>
                <TableCell align="center">
                  Due Date
                </TableCell>
                <TableCell align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction, i) => (
                <TableRow key={`transactionRow_${i}`} hover>
                  <TableCell component="th" scope="row" align="center">
                    {transaction.id}
                  </TableCell>
                  <TableCell>
                    {transaction.currency.name}
                  </TableCell>
                  <TableCell>
                    {transaction.amount}
                  </TableCell>
                  <TableCell>
                    {transaction.description}
                  </TableCell>
                  <TableCell>
                    {transaction.concept}
                  </TableCell>
                  <TableCell>
                    {types[transaction.type]}
                  </TableCell>
                  <TableCell align="center">
                    {moment(transaction.created_date).utc().format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell align="center">
                    {transaction.due_date ? moment(transaction.due_date).utc().format('DD-MM-YYYY') : '-'}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton color="secondary" aria-label="Delete" onClick={() => setTransactionSelected(transaction)}>
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="Edit" onClick={() => setTransactionToEdit(transaction)}>
                      <EditOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        page={filters.page_number - 1}
        onPageChange={(_, page) => handlePageFiltersChange('page_number', page + 1)}
        onRowsPerPageChange={(event) => handlePageFiltersChange('page_size', event.target.value)}
        rowsPerPage={filters.page_size}
        rowsPerPageOptions={[5, 10, 25]}
        labelDisplayedRows={() => console.log('next page')}
      />
    </Card>
  );
};

export default TransactionsListResult;
