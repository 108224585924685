import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import AdminServices from 'src/services/AdminServices';
import PortfolioDetails from 'src/components/portfolio/PortfolioDetails';
import AddImagesModal from 'src/components/portfolio/AddImagesModal';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(({
  children, classes, onClose, ...other
}) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PortfolioEdit = () => {
  const { professionalId } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [portfolio, setPortfolio] = useState([]);
  const [professional, setProfessional] = useState({});
  const [imageSelected, setImageSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openAddImageModal, setOpenAddImageModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [categoryList, setCategoryList] = useState([]);

  const handleOpenAddImageModal = (category) => {
    setOpenAddImageModal(true);
    setModalTitle(category);
  };

  const handleCloseAddImageModal = () => {
    setOpenAddImageModal(false);
  };

  const handleClose = () => {
    setImageSelected(null);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    try {
      const response = await AdminServices.getCategories();
      setCategoryList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (professionalId) {
      Promise.all([getPortfolio(), getUserData()]).then(() => setIsLoad(true));
    }
  }, [professionalId]);

  const getUserData = async () => {
    try {
      const response = await AdminServices.getProfessional(professionalId);
      setProfessional(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPortfolio = async () => {
    try {
      const response = await AdminServices.getProfessionalPortfolio(professionalId);
      setPortfolio(response.data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePhoto = (imageId, categoryId) => {
    setImageSelected(imageId);
    setCategorySelected(categoryId);
  };

  const handleConfirmDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await AdminServices.deleteProfessionalImage(professionalId, imageSelected);
      getPortfolio();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
      setImageSelected(null);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit Professional Portfolio | Ameiz</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/professionals">
              Professionals
            </Link>
            <Typography color="textPrimary">Portfolio</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {isLoad ? (
                <>
                  <Typography variant="h2" component="h2" gutterBottom>
                    Portfolio de
                    {` ${professional.user.name} ${professional.user.lastname}`}
                  </Typography>
                  <PortfolioDetails
                    handleDeletePhoto={handleDeletePhoto}
                    portfolio={portfolio}
                    handleOpenAddImageModal={handleOpenAddImageModal}
                  />
                  <Box style={{
                    width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '24px',
                  }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleOpenAddImageModal('noCategory')}
                    >
                      Add photo
                    </Button>
                  </Box>
                </>
              )
                : <Box style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></Box>}
            </Grid>
          </Grid>
        </Container>
        {imageSelected && (
          <DeleteDialog
            portfolio={portfolio}
            imageSelected={imageSelected}
            handleClose={handleClose}
            categorySelected={categorySelected}
            handleConfirmDelete={handleConfirmDelete}
            deleteLoading={deleteLoading}
          />
        )}
        {openAddImageModal && (
          <AddImagesModal
            professionalId={professionalId}
            category={modalTitle}
            open={handleOpenAddImageModal}
            handleClose={handleCloseAddImageModal}
            getPortfolio={getPortfolio}
            categoryList={categoryList}
          />
        )}
      </Box>
    </>
  );
};

export default PortfolioEdit;

const DeleteDialog = ({
  imageSelected, handleClose, categorySelected, portfolio, handleConfirmDelete, deleteLoading,
}) => {
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={imageSelected}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        ¿ Seguro que quiere eliminar esta foto ?
      </DialogTitle>
      <DialogContent dividers>
        <Box style={{ textAlign: 'center' }}>
          <img
            src={portfolio.find(
              (p) => p.category.id === categorySelected).images.find((i) => i.id === imageSelected).url}
            alt="portfolio"
            width="75%"
            height="75%"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          autoFocus
          onClick={handleConfirmDelete}
          color="primary"
          style={{ margin: '0 auto' }}
        >
          {deleteLoading ? <CircularProgress color="inherit" /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
