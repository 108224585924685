import { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import is from 'is_js';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import AdminServices from 'src/services/AdminServices';
import CountrySelect from 'src/components/comon/CountrySelect';

const ServiceVariantDetails = ({ handleSave, defaultValue, saveLoading }) => {
  const [servicesSelected, setServicesSelected] = useState('');
  const [serviceVariants, setServiceVariants] = useState([]);
  const [values, setValues] = useState({});

  const [isLoad, setIsLoad] = useState(false);
  const [isLoadedit, setIsLoadEdit] = useState(false);

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    console.log('countries', countries);
  }, [countries]);

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    await getCountries();
    await getServiceList();
    setIsLoadEdit(true);
  };

  const getServiceList = async () => {
    try {
      const response = await AdminServices.getServices();
      setServiceVariants(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCountries = async () => {
    try {
      const response = await AdminServices.getCountries();
      setCountriesOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValues({
        internal_name: defaultValue.internal_name,
        order: defaultValue.order,
        service_id: defaultValue.service.id,
      });
      setServicesSelected(defaultValue.service.id);
      setCountries(defaultValue.countries);
      setIsLoad(true);
    } else {
      setIsLoad(true);
    }
  }, []);

  const locaLhandleSave = () => {
    console.log(values);
    handleSave(values, countries);
  };

  const handleServiceChange = (event) => {
    setServicesSelected(event.target.value);
    setValues({
      ...values,
      service_id: event.target.value,
    });
  };

  const handleChange = (event) => {
    console.log(event);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddServiceVariantCountry = () => {
    setCountries((currentValues) => [...currentValues, {
      viewId: uuidv4(),
      name: null,
      service_variant_id: null,
      area_id: null,
    }]);
  };

  const handleChangeServiceVariantCountry = (variantCountryId, attrName, value) => {
    const filteredList = countries.filter((a) => a.viewId !== variantCountryId);
    const countryToEdit = countries.find((a) => a.viewId === variantCountryId);
    countryToEdit[attrName] = value;
    setCountries([...filteredList, countryToEdit]);
  };

  const handleChangeOldServiceVariantCountry = (variantCountryId, attrName, value) => {
    const filteredList = countries.filter((a) => a.id !== variantCountryId);
    const countryToEdit = countries.find((a) => a.id === variantCountryId);
    countryToEdit[attrName] = value;
    setCountries([...filteredList, countryToEdit]);
  };

  const deleteVariantCountry = (variantCountryId) => {
    const filteredList = countries.filter((a) => a.viewId !== variantCountryId);
    setCountries(filteredList);
  };

  const deleteOldVariantCountry = async (variantCountryId) => {
    const filteredList = countries.filter((a) => a.id !== variantCountryId);
    setCountries(filteredList);
    try {
      const response = await AdminServices.deleteVariantCountry(variantCountryId);
      console.log('delete', response.data);
    } catch (error) {
      console.log(error);
      console.log('error', error.response.data.message);
    }
  };

  return (
    <form>
      {

        isLoad && isLoadedit ? (
          <>
            <Box sx={{
              display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: '24px',
            }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddServiceVariantCountry}
              >
                Add Country
              </Button>
              <Button
                sx={{ mx: 1 }}
                color="secondary"
                variant="contained"
                onClick={locaLhandleSave}
              >
                {saveLoading ? <CircularProgress /> : 'Save Service Variant'}
              </Button>
            </Box>
            <Card>
              <CardHeader
                subheader="The information can be edited"
                title="Service variant details"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="internal_name"
                      onChange={handleChange}
                      required
                      value={values.internal_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label="Order"
                      name="order"
                      onChange={handleChange}
                      required
                      value={values.order}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel id="service-id-mutiplelabel">Service</InputLabel>
                      <Select
                        labelId="service-id-mutiplelabel"
                        id="mutiple-service-id"
                        value={servicesSelected}
                        onChange={handleServiceChange}
                        label="Service"
                        name="service_id"
                      >
                        {serviceVariants.map((service) => (
                          <MenuItem key={service.id} value={service.id}>
                            {service.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Divider />
            </Card>

            {is.not.empty(countries) && countries.sort(
              (a, b) => a.viewId - b.viewId).filter((v) => v.viewId).map((c, i) => (
                <Card key={`serviceVarianCountry_${i}`} style={{ marginTop: 16 }}>
                  <CardHeader
                    action={(
                      <IconButton aria-label="settings" onClick={() => deleteVariantCountry(c.viewId)}>
                        <HighlightOffOutlinedIcon />
                      </IconButton>
              )}
                    title="Service Varian Country"
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={3} xs={6}>
                        <CountrySelect
                          countries={countriesOptions}
                          handleSelected={
                            (country) => { handleChangeServiceVariantCountry(c.viewId, 'area_id', country[0].id); }
}
                          multiple={false}
                          editOn={false}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Name"
                          name="name"
                          onChange={
                          (e) => { handleChangeServiceVariantCountry(c.viewId, e.target.name, e.target.value); }
}
                          required
                          value={c.name}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
            ))}

            {is.not.empty(countries) && countries.sort((a, b) => a.id - b.id).filter((v) => v.id).map((c, i) => (
              <Card key={`serviceVarianCountry_${i}`} style={{ marginTop: 16 }}>
                <CardHeader
                  action={(
                    <IconButton aria-label="settings" onClick={() => deleteOldVariantCountry(c.id)}>
                      <HighlightOffOutlinedIcon />
                    </IconButton>
              )}
                  title="Service Varian Country"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={3} xs={6}>
                      <CountrySelect
                        defaultareasSelected={c.area}
                        countries={countriesOptions}
                        handleSelected={
                          (country) => { handleChangeOldServiceVariantCountry(c.id, 'area_id', country[0].id); }
}
                        multiple={false}
                        editOn={false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        onChange={(e) => { handleChangeOldServiceVariantCountry(c.id, e.target.name, e.target.value); }}
                        required
                        value={c.name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </>
        )
          : null
      }
    </form>
  );
};

export default ServiceVariantDetails;
