import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import is from 'is_js';

import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import ServiceVariantDetails from 'src/components/serviceVariant/ServiceVariantDetails';
import AdminServices from 'src/services/AdminServices';

function ServiceVariantEdit() {
  const navigate = useNavigate();
  const { serviceId, variantId } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});

  useEffect(() => {
    getServiceVariant();
  }, []);

  const getServiceVariant = async () => {
    try {
      const response = await AdminServices.getServiceVariant(serviceId, variantId);
      setDefaultValue(response.data);
      console.log(response.data);
      setIsLoad(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (details, countries) => {
    setIsSaving(true);

    const variantUpdated = {
      id: parseInt(variantId),
      order: details.order,
      internal_name: details.internal_name,
      service_id: details.service_id,
    };

    try {
      const newServiceId = details.service_id ? details.service_id : serviceId;
      await AdminServices.updateServiceVariant(newServiceId, variantId, variantUpdated);

      if (is.not.empty(countries)) {
        for (const i in countries) {
          if (countries[i].viewId) {
            const newVariantCountry = {
              ...countries[i],
              service_variant_id: variantId,
            };
            await AdminServices.createServiceVariantCountry(newVariantCountry);
          }

          if (countries[i].id) {
            const updatedVariantCountry = {
              service_variant_area_id: countries[i].id,
              name: countries[i].name,
              area_id: countries[i].area_id || null,
            };
            await AdminServices.updateVariantCountry(countries[i].id, updatedVariantCountry);
          }
        }
      }
      setIsSaving(false);
      alert('success');
      navigate('/app/service/variant');
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      alert('error');
    }
  };

  return (
    <>
      <Helmet>
        <title>Add Service Variant </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/service/variant">
              Service variants
            </Link>
            <Typography color="textPrimary">Edit variant</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {isSaving ? <CircularProgress /> : isLoad ? (
                <ServiceVariantDetails
                  handleSave={handleSave}
                  defaultValue={defaultValue}
                  saveLoading={isSaving}
                />
              ) : <></>}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ServiceVariantEdit;
