import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import ServiceVariantAreaDetails from 'src/components/serviceVariantsAreas/ServiceVariantsAreasDetails';
import AdminServices from 'src/services/AdminServices';

function ServiceVariantAreaAdd() {
  const navigate = useNavigate();
  const { variantId } = useParams();
  const [isSaving, setIsSaving] = useState(false);

  function handleSave(details) {
    setIsSaving(true);
    const newServiceVariantArea = {
      service_variant_id: variantId,
      name: details.name,
      description: details.description,
      mobile_description: details.mobile_description,
      quantity_message: details.quantity_message,
      image: details.image,
      cost: parseFloat(details.cost),
      price: parseFloat(details.price),
      transport_price: parseFloat(details.transport_price),
      active: details.active,
      area_id: details.area_id,
      duration: parseInt(details.duration),
    };
    return AdminServices.createServiceVariantArea(newServiceVariantArea)
      .then(() => {
        navigate('/app/service/variant', { state: { reload: true } });
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message || 'There was an error');
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  return (
    <>
      <Helmet>
        <title>Add Service Variant Area</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/service/variant">
              Service variants
            </Link>
            <Typography color="textPrimary">Add variant area</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {isSaving ? <CircularProgress /> : (
                <ServiceVariantAreaDetails
                  handleSave={handleSave}
                  defaultValue={null}
                  loading={isSaving}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ServiceVariantAreaAdd;
