import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const AddonListResult = ({
  addons, handleClickOpenDeleteModal, filters, isLoad, handlePagination,
}) => {
  function handlePageFiltersChange(prop, value) {
    console.info(prop, value);
    handlePagination({ ...filters, [prop]: value });
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Id
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Order
                </TableCell>
                <TableCell>
                  Duration
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    Options
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addons.map((addon) => (
                <TableRow
                  hover
                  key={addon.id}
                >
                  <TableCell>
                    {addon.id}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {addon.internal_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {addon.order}
                  </TableCell>
                  <TableCell>
                    {addon.duration}
                  </TableCell>

                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        value={addon.id}
                        component={RouterLink}
                        to={`/app/addon/edit/${addon.id}`}
                        sx={{ mx: 1 }}
                        color="primary"
                        variant="contained"
                      >
                        Edit Addon
                      </Button>
                      <Button
                        color="warning"
                        variant="contained"
                        value={addon.id}
                        onClick={() => { handleClickOpenDeleteModal(addon.id); }}
                      >
                        Remove Adddon
                      </Button>
                    </Box>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        onPageChange={(_, page) => handlePageFiltersChange('page_number', page)}
        onRowsPerPageChange={(event) => handlePageFiltersChange('page_size', event.target.value)}
        page={filters.page_number}
        rowsPerPage={filters.page_size}
        rowsPerPageOptions={[20, 50, 100]}
        labelDisplayedRows={() => console.log('next page')}
        ActionsComponent={(props) => TablePaginationActions({ ...props, isLoad })}
      />
    </Card>
  );
};

function TablePaginationActions(props) {
  const {
    page, onPageChange, isLoad,
  } = props;
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!isLoad && <CircularProgress size={20} />}
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

export default AddonListResult;
