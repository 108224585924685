import axios from 'axios';

const auth = (email, password) => {
  const authData = {
    username: email,
    password,
    minutes_offset: 0,

  };
  // console.log(`${process.env.REACT_APP_MODERN_API_URL}`+'/auth/login')
  return axios.post(`${process.env.REACT_APP_MODERN_API_URL}/auth/login`, authData)
    .then((response) => {
      window.localStorage.setItem('token', response.data.access_token);
      return response.data;
    })
    .catch(() => {
      alert('error login');
      console.log('error');
      console.log(encodeURI);
      return false;
    });
};

const resetPassword = (token, password) => {
  console.log(`${process.env.REACT_APP_MODERN_API_URL}/user/password/change`);
  return axios.post(`${process.env.REACT_APP_MODERN_API_URL}/user/password/change`, {
    password,
    token,
  });
};

const logout = () => {
  window.localStorage.removeItem('token');
  return ('loogout');
};

const getCurrentToken = () => window.localStorage.getItem('token');

const isLoged = () => {
  if (window.localStorage.getItem('token') !== null) {
    return true;
  }
  return false;
};

export default {
  isLoged,
  auth,
  logout,
  getCurrentToken,
  resetPassword,
};
