import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  Box,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { saveCard, getPublicIP } from '../../services/DlocalServices';

let dlocalInstance; let
  dlocalFields;

// GENERATE DEVICE ID FOR DLOCAL TRANSACTION
const generateDeviceId = () => {
  const nav = window.navigator;
  const { screen } = window;
  let deviceId = nav.mimeTypes.length;
  Object.values(window.navigator.mimeTypes).forEach((p) => deviceId += p.type);
  deviceId += nav.userAgent.replace(/\D+/g, '');// Only use digits
  deviceId += nav.plugins.length;
  Object.values(window.navigator.plugins).forEach((p) => deviceId += p.filename);
  deviceId += screen.height || '';
  deviceId += screen.width || '';
  deviceId += screen.pixelDepth || '';
  return checksum(deviceId);
};

const checksum = (s) => {
  let hash = 0; const strlen = s.length; let i; let
    c;
  if (strlen === 0) {
    return hash;
  }
  for (i = 0; i < strlen; i++) {
    c = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + c;
    hash &= hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

function DlocalSaveCard() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const { auth_token } = useParams();

  useEffect(() => {
    loadDLocalScripts();
  }, []);

  const handlePay = async (card_token) => {
    setIsLoading(true);
    const device_id = `${generateDeviceId()}`;
    let ip = '';
    try {
      ip = await getPublicIP();
    } catch (e) {
      console.log(e);
    }
    saveCard(auth_token, card_token, ip, device_id)
      .then((response) => {
        console.log(response.data);
        handlePaymentSuccess();
      })
      .catch((e) => {
        console.log(e);
        handlePaymentSuccess();
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handlePaymentSuccess = () => {
    navigate('/process-payment/save-card-success', { replace: true });
  };

  function loadDLocalScripts() {
    const dlocalScript = document.createElement('script');
    dlocalScript.setAttribute('src', process.env.REACT_APP_DLOCAL_JS_IMPORT);
    dlocalScript.async = true;
    dlocalScript.defer = true;
    dlocalScript.onload = () => {
      dlocalInstance = window.dlocal(process.env.REACT_APP_DLOCAL_PUBLIC_KEY);
      dlocalFields = dlocalInstance.fields({
        locale: 'es',
        country: 'AR',
      });
      const style = {
        base: {
          fontSize: '15px',
          fontFamily: "'Inter UI medium', sans-serif",
          lineHeight: '28px',
          fontSmoothing: 'antialiased',
          fontWeight: '500',
          color: '#666',
          '::placeholder': {
            color: '#aab7c4',
          },
          iconColor: '#adbfd3',
        },
      };
      const card = dlocalFields.create('card', { style });
      card.addEventListener('change', (event) => {
        const displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
      card.mount(document.getElementById('card-field'));
      const form = document.getElementById('payment-form');
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        setIsLoading(true);
        const cardHolderName = document.getElementById('card-holder').value;
        if (!cardHolderName) {
          const displayError = document.getElementById('card-errors');
          displayError.textContent = 'Debe ingresar el nombre del titular de la tarjeta.';
          setIsLoading(false);
          return;
        }
        dlocalInstance
          .createToken(card, {
            name: cardHolderName,
          })
          .then((result) => {
            handlePay(result.token);
          })
          .catch((result) => {
            if (result.error) {
              const errorField = document.getElementById('card-errors');
              errorField.textContent = result.error.message;
              setIsLoading(false);
            }
          });
      });
    };
    document.head.appendChild(dlocalScript);
  }

  return (
    <Box border={1} className={classes.cardBoxContainer} borderRadius={2}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h3" className={classes.title} color="textPrimary" gutterBottom>
            Guardar tarjeta
          </Typography>
          <form method="post" id="payment-form" className={classes.cardInput}>
            <Box border={1} borderColor="grey.500" className={classes.cardNumberBoxContainer}>
              <div>
                <label htmlFor="card-field" />
                <div id="card-field" />
                <div id="card-errors" role="alert" />
              </div>
            </Box>
            <div className={classes.cardHolderName}>
              <TextField className={classes.cardHolderNameInput} id="card-holder" label="Titular de la tarjeta" />
            </div>
            <CardActions className={classes.saveCardButton}>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5" className={classes.subTitle} color="textPrimary" gutterBottom>
                    Transacción segura
                  </Typography>
                  <img width="100" src="/static/images/secure-connection.png" />
                </Grid>
                <Grid item xs={6}>
                  {isLoading ? <CircularProgress />
                    : <Button type="submit" variant="contained" color="primary">Guardar Tarjeta</Button>}
                </Grid>
              </Grid>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
} export default DlocalSaveCard;

const useStyles = makeStyles({
  cardBoxContainer: {
    marginTop: 20,
    margin: 10,
  },
  cardNumberBoxContainer: {
    padding: 10,
  },
  saveCardButton: {
    marginTop: 20,
  },
  secureTransaction: {
    flexDirection: 'row',
  },
  title: {
    marginBottom: 20,
  },
  subTitle: {
    minHeight: 30,
  },
  cardInput: {
    marginTop: 25,
  },
  cardHolderName: {
    marginTop: 20,
  },
  cardHolderNameInput: {
    width: '100%',
  },
});
