import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import AreaListResults from 'src/components/area/AreaListResult';
import AreaListToolbar from 'src/components/area/AreaListToolbar';
import AdminServices from 'src/services/AdminServices';

const AreaList = () => {
  const [areas, setAreas] = useState([]);
  const [areasAll, setAreasAll] = useState([]);

  const handlefilter = (filter) => {
    filter.length < 3
      ? setAreas(areasAll)
      : setAreas(areasAll.filter((area) => area.name.toLowerCase().match(filter.toLowerCase())));
  };

  useEffect(() => {
    getAreaList();
  }, []);

  const getAreaList = async () => {
    try {
      const response = await AdminServices.getAreas();
      setAreas(response.data);
      setAreasAll(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  function handleDeleteArea(value) {
    return AdminServices.deleteArea(value)
      .then(() => {
        setAreas(areas.filter((areas) => areas.id !== value));
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <Helmet>
        <title> Areas | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Areas</Typography>
          </Breadcrumbs>
          <AreaListToolbar handlefilter={handlefilter} />
          <Box sx={{ pt: 3 }}>
            <AreaListResults areas={areas} handleDeleteArea={handleDeleteArea} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AreaList;
