import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Search as SearchIcon } from 'react-feather';
import moment from 'moment';

const PAYMENT_STATUS = [
  { label: 'PENDING', value: 'pending' },
  { label: 'APPROVED', value: 'approved' },
  { label: 'REJECTED', value: 'rejected' },
  { label: 'REFUNDED', value: 'refunded' },
  { label: 'IN PROCESS', value: 'in_process' },
];

const BookingPackEditModal = ({
  content, handleClose, open, Agree, loading,
}) => {
  const [localValue, setLocalValue] = useState(null);

  useEffect(() => {
    if (content) {
      setLocalValue(content);
    }
  }, [content]);

  const handlechange = (values) => {
    setLocalValue((currentValues) => ({
      ...currentValues,
      ...values,
    }));
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Edit Booking Pack </DialogTitle>
      <DialogContent dividers>
        <Box style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <FormControl variant="outlined" style={{ width: '100%', marginTop: 8 }}>
            <InputLabel id="mutiple-status-id-label">Payment status</InputLabel>
            <Select
              labelId="mutiple-status-id-label"
              id="mutiple-status-id"
              value={localValue?.paymeny_status}
              onChange={(event) => handlechange({ [event.target.name]: event.target.value })}
              label="Payment status"
              name="paymeny_status"
            >
              {PAYMENT_STATUS.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Payment date"
            name="payment_date"
            id="payment_date"
            type="date"
            style={{ marginTop: 24 }}
            value={localValue?.paymeny_status === 'approved' ? moment().format('YYYY-MM-DD') : moment(localValue?.payment_date).format('YYYY-MM-DD')}
            onChange={(event) => handlechange({ [event.target.name]: event.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Payment date"
            variant="outlined"
          />

          <TextField
            fullWidth
            label="User email"
            name="user_email"
            id="user_email"
            style={{ marginTop: 24 }}
            InputLabelProps={{ shrink: true }}
            defaultValue={localValue?.shoppingCart?.user?.email}
            value={localValue?.user_email}
            onChange={(event) => handlechange({ [event.target.name]: event.target.value })}
            placeholder="User email"
            variant="outlined"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => Agree(localValue)} color="primary" autoFocus variant="contained">
          {loading ? <CircularProgress color="secondary" /> : 'Accept'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingPackEditModal;
