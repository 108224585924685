import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Collapse,
  Link,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useNavigate } from 'react-router';

const PacksListResult = ({ packs, handleDeletePack }) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow hover>
                <TableCell />
                <TableCell>
                  Pack Id
                </TableCell>
                <TableCell>
                  Internal Name
                </TableCell>
                <TableCell align="center">
                  Options
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packs.sort((a, b) => a.id - b.id).map((pack) => <PackBody pack={pack} key={pack.id} handleDeletePack={handleDeletePack} />)}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

function PackBody({ pack, handleDeletePack }) {
  const { items } = pack;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openPackItems, setOpenPackItems] = useState(false);
  const [openPackAreas, setOpenPackAreas] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {pack.id}
        </TableCell>
        <TableCell>
          {pack?.internal_name}
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{ mx: 1 }}
              color="primary"
              variant="outlined"
              onClick={() => navigate(`/app/packs/${pack.id}/edit`)}
            >
              Editar
            </Button>
            <Button
              sx={{ mx: 1 }}
              color="secondary"
              variant="outlined"
              onClick={() => handleDeletePack(pack.id)}
            >
              Delete
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow style={{ width: '100%' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>

            {pack.items.length > 0 && (
            <Box style={{ margin: '12px 0px' }}>
              <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenPackItems((currentValue) => !currentValue)}
                >
                  {openPackItems ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <Typography variant="h6" gutterBottom component="div">
                  Pack Items
                </Typography>
              </Box>
              <Collapse in={openPackItems} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <PackItemTable items={pack.items} />
                </Box>
              </Collapse>
            </Box>
            )}

            {pack.packAreas.length > 0 && (
            <Box style={{ margin: '12px 0px' }}>
              <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenPackAreas((currentValue) => !currentValue)}
                >
                  {openPackAreas ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <Typography variant="h6" gutterBottom component="div">
                  Pack Areas
                </Typography>
              </Box>
              <Collapse in={openPackAreas} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <PackAreaTable areas={pack.packAreas} />
                </Box>
              </Collapse>
            </Box>
            )}

          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const PackItemTable = ({ items }) => {
  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>
            Item Id
          </TableCell>
          <TableCell>
            Service Variant
          </TableCell>
          <TableCell align="center">
            Sessions
          </TableCell>
          <TableCell align="center">
            Cost Percentage
          </TableCell>
          <TableCell align="center">
            Price Percentage
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          items.map((item) => (
            <TableRow>
              <TableCell>
                {item.id}
              </TableCell>
              <TableCell>
                {item?.serviceVariant?.internal_name}
              </TableCell>
              <TableCell align="center">
                {item?.sessions}
              </TableCell>
              <TableCell align="center">
                {item?.cost_percentage}
              </TableCell>
              <TableCell align="center">
                {item?.price_percentage}
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
};
const PackAreaTable = ({ areas }) => {
  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>
            Pack Area Id
          </TableCell>
          <TableCell>
            Name
          </TableCell>
          <TableCell align="center">
            Description
          </TableCell>
          <TableCell align="center">
            Active
          </TableCell>
          <TableCell align="center">
            Image
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          areas.map((item) => (
            <TableRow>
              <TableCell>
                {item.id}
              </TableCell>
              <TableCell>
                {item?.name}
              </TableCell>
              <TableCell align="center">
                {item?.description}
              </TableCell>
              <TableCell align="center">
                {item?.active ? 'True' : 'False'}
              </TableCell>
              <TableCell align="center">
                <Link
                  href={item?.image?.url}
                  target="_blank"
                  rel="noopener"
                >
                  Image
                </Link>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
};

export default PacksListResult;
