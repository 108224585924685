import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import is from 'is_js';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import ServiceVariantDetails from 'src/components/serviceVariant/ServiceVariantDetails';
import AdminServices from 'src/services/AdminServices';

function ServiceVariantAdd() {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async (details, countries) => {
    setIsSaving(true);

    const newVariant = {
      internal_name: details.internal_name,
      order: details.order,
      duration: details.duration,
      service_id: details.service_id,
    };

    try {
      const response = await AdminServices.createServiceVariant(details.service_id, newVariant);

      if (is.not.empty(countries)) {
        for (const i in countries) {
          const newVariantCountry = {
            ...countries[i],
            service_variant_id: response.data.id,
          };
          await AdminServices.createServiceVariantCountry(newVariantCountry);
        }
      }

      navigate('/app/service/variant', { replace: true });
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Add Servicea </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/service/variant">
              Service variants
            </Link>
            <Typography color="textPrimary">New variant</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {isSaving ? <CircularProgress /> : (
                <ServiceVariantDetails
                  handleSave={handleSave}
                  saveLoading={isSaving}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ServiceVariantAdd;
