import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';
import AdminServices from 'src/services/AdminServices';

const ServicesListToolbar = ({ handlefilter, handleCategoryFilter }) => {
  const [value, setValue] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    AdminServices.getCategories()
      .then((resp) => setCategories(resp.data))
      .catch(() => console.log());
  };

  const handlechange = (event) => {
    setValue(event.target.value);
    handlefilter(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          component={RouterLink}
          to="/app/service/add"
          color="primary"
          variant="contained"
        >
          Add Service
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box style={{ display: 'flex' }}>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  value={value}
                  name="query"
                  onChange={handlechange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Service"
                  variant="outlined"
                />
              </Box>
              <Box style={{ marginLeft: 24, width: '350px' }}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="category-select-id-label">Categories</InputLabel>
                  <Select
                    defaultValue={null}
                    labelId="category-select-id-label"
                    id="category-select"
                    onChange={(e) => handleCategoryFilter(e.target.value)}
                    label="Categories"
                    name="category"
                  >
                    <MenuItem key="category_null" value={null}>
                      None
                    </MenuItem>
                    {categories.map((c, i) => (
                      <MenuItem key={`category_${i}`} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ServicesListToolbar;
