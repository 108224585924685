import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';

const CategoriesListToolbar = ({ handlefilter }) => {
  const [value, setValue] = useState('');

  const handlechange = (event) => {
    setValue(event.target.value);
    handlefilter(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          component={RouterLink}
          to="/app/categories/add"
          color="primary"
          variant="contained"
        >
          Add Category
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                value={value}
                onChange={handlechange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Category"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default CategoriesListToolbar;
