import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';

import {
  Box, Container, Typography, makeStyles, CircularProgress,
} from '@material-ui/core';
import Rating from 'src/pages/review/Rating';
import TextField from 'src/pages/review/TextField';
import Button from 'src/pages/review/Button';
import { useParams } from 'react-router';
import ReviewServices from './ReviewServices';

const labels = {
  0.5: '0.5',
  1: '1',
  1.5: '1.5',
  2: '2',
  2.5: '2.5',
  3: '3',
  3.5: '3.5',
  4: '4',
  4.5: '4.5',
  5: '5',
};

const useStyles = makeStyles({
  ratingContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px',
  },
  reviewComment: {
    marginTop: '24px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    marginTop: '24px',
  },
  header: {
    backgroundImage: 'url("https://s3.amazonaws.com/ameizproduction/site/client-form-bg.jpg")',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '300px',
    marginTop: '0px',
  },
  titleContainer: {
    textAlign: 'center',
    marginTop: '24px',
  },
});

const ClientReview = () => {
  const classes = useStyles();
  const { bookingId, token } = useParams();
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(-1);
  const [comment, setComment] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setBooking] = useState({});
  const [reviewError, setReviewError] = useState(false);

  useEffect(() => {
    if (bookingId) {
      getBookingData();
    }
  }, [bookingId]);

  const getBookingData = async () => {
    setIsLoading(true);
    try {
      const response = await ReviewServices.getBookingInfo(bookingId);
      setBooking(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  function confirmReview() {
    setIsLoading(true);
    const newReview = {
      booking_id: parseInt(bookingId),
      rating,
      reviewer_type: 'user',
      comment,
      review_code: token,
    };
    return ReviewServices.createReview(bookingId, newReview)
      .then(() => {
        setIsDone(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message || error);
        setReviewError(true);
        setIsLoading(false);
      });
  }

  return (
    <>
      <Helmet>
        <title>Review | Ameiz</title>
      </Helmet>
      <Box className={classes.header} />
      <Box sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start',
      }}
      >
        {isLoading && (
          <Container maxWidth="sm">
            <Box style={{ textAlign: 'center', marginTop: '24px' }}>
              <CircularProgress color="secondary" />
            </Box>
          </Container>
        )}
        {!isLoading && (
          <Container maxWidth="sm">
            {!isDone && !reviewError && (
              <>
                <Box className={classes.titleContainer}>
                  <Typography color="textPrimary" variant="h2">
                    Evalúa tu experiencia con&nbsp;
                    {booking?.professional?.user?.name[0].toUpperCase()
                      + booking?.professional?.user?.name.slice(1)}
                  </Typography>
                </Box>
                <div className={classes.ratingContainer}>
                  <Rating
                    name="hover-feedback"
                    value={rating}
                    precision={0.5}
                    onChange={(event, newValue) => {
                      setRating(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    size="large"
                  />
                  {rating !== null && <Box ml={2}>{labels[hover !== -1 ? hover : rating]}</Box>}
                </div>
                <Box className={classes.reviewComment}>
                  <TextField
                    size="large"
                    fullWidth
                    multiline
                    rows={6}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="¿Algo que te gustaría comentar?
                    Este mensaje será visible para la Ameizer y las clientas futuras que reserven con ella."
                  />
                </Box>
                <Box className={classes.buttonContainer}>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={!rating && !comment}
                    size="large"
                    onClick={confirmReview}
                    className={classes.button}
                  >
                    Confirmar
                  </Button>
                </Box>
              </>
            )}

            {isDone && !isLoading
              && (
                <Box className={classes.titleContainer}>
                  <Typography color="textPrimary" variant="h2">
                    ¡Muchas gracias&nbsp;
                    {booking?.shoppingCart?.user?.name[0].toUpperCase() + booking?.shoppingCart?.user?.name.slice(1)}
                    !
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Hemos registrado la evaluación que has realizado.
                  </Typography>
                </Box>
              )}

            {reviewError && !isLoading
              && (
                <Box className={classes.titleContainer}>
                  <Typography color="textPrimary" variant="h2">El servicio ya ha sido calificado.</Typography>
                </Box>
              )}

          </Container>
        )}
      </Box>
    </>
  );
};

export default ClientReview;
