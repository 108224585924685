import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Switch,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  TablePagination,
  Dialog,
  DialogContent,
  CircularProgress,
  withStyles,
  Grid,
  TextField,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCameraOutlined';
import { useState } from 'react';
import AdminServices from 'src/services/AdminServices';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import is from 'is_js';
import DeleteModal from 'src/components/comon/Alert';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const REQUIRED_FIELD_ERROR = 'This field is required';

const ProfessionalsListResults = ({
  professionals, handleClickOpenDeleteModal, handleChangeStatus, handleCompleteBoardingSteps,
}) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [currentViews, setViews] = useState([0, 20]);

  const handleChangeBoardingFlag = (event, professionalId) => {
    handleCompleteBoardingSteps(professionalId, event.target.checked);
  };

  const handleLimitChange = (event) => {
    setViews([currentViews[0], currentViews[0] + event.target.value]);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.info(event.target.value);

    setPage(newPage);
  };

  const handleNextButton = () => {
    setViews([currentViews[1], currentViews[1] + limit]);
  };

  const handleBackButton = () => {
    if (currentViews[0] - limit < 0) {
      setViews([0, limit]);
      return;
    }
    setViews([currentViews[0] - limit, currentViews[1] - limit]);
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Id
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Last Name
                </TableCell>
                <TableCell>
                  Phone
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Boarding Step
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Country
                </TableCell>
                <TableCell>
                  Priority
                </TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {professionals.sort((a, b) => a.id - b.id).slice(...currentViews).map((professional, i) => {
                return (
                  <RowItem
                    professional={professional}
                    handleChangeBoardingFlag={handleChangeBoardingFlag}
                    handleClickOpenDeleteModal={handleClickOpenDeleteModal}
                    handleChangeStatus={handleChangeStatus}
                    key={`professional_${i}`}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={professionals.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        labelDisplayedRows={() => `${currentViews[0]} - ${currentViews[1] > professionals.length
          ? professionals.length : currentViews[1]} of ${professionals.length}`}
        rowsPerPageOptions={[20, 50, 100]}
        backIconButtonProps={{ onClick: handleBackButton, disabled: currentViews[0] === 0 }}
        nextIconButtonProps={{ onClick: handleNextButton }}
      />
    </Card>
  );
};

function RowItem({
  professional, handleChangeBoardingFlag, handleClickOpenDeleteModal, handleChangeStatus,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [action, setAction] = useState(false);

  function fetchBlockedPeriods() {
    setLoading(true);
    return AdminServices.getProfessionalBlockedPeriods(professional.id)
      .then((response) => { console.log(response.data); setPeriods(response.data); })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }

  const DialogTitle = withStyles(styles)((props) => {
    const {
      children, classes, onClick, ...other
    } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        <Button className={classes.closeButton} onClick={onClick}>
          Add blocked period
        </Button>
      </MuiDialogTitle>
    );
  });

  return (
    <>
      {open && (
      <Dialog
        open={open}
        maxWidth={false}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="professional-dialog-title" onClick={() => setAction('add')}>Blocked Periods</DialogTitle>
        <DialogContent dividers>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              {loading && <CircularProgress />}
              {!loading
              && (
              <BlockedPeriodsList
                professional={professional}
                periods={periods}
                action={action}
                changeAction={setAction}
                refetch={() => fetchBlockedPeriods()}
              />
              )}
            </Box>
          </PerfectScrollbar>
        </DialogContent>
      </Dialog>
      )}
      <TableRow
        hover
      >
        <TableCell>
          {professional?.id}
        </TableCell>
        <TableCell>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {professional?.user.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          {professional?.user.lastname}
        </TableCell>
        <TableCell>
          {professional?.user.phone}
        </TableCell>
        <TableCell>
          {professional?.user.email}
        </TableCell>
        <TableCell>
          <Switch
            checked={professional?.boarding_step === 'completed'}
            onChange={(e) => { handleChangeBoardingFlag(e, professional.id, professional?.boarding_step); }}
            color="primary"
            name="boarding_step"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </TableCell>
        <TableCell>
          {professional?.status}
        </TableCell>
        <TableCell>
          {professional?.country?.name}
        </TableCell>
        <TableCell>
          {professional?.priority ? professional?.priority : 'null' }
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button
              onClick={() => { setOpen(true); fetchBlockedPeriods(); }}
              sx={{ mx: 1 }}
              variant="contained"
              style={{ color: '#FFF' }}
              size="small"
              color="success"
            >
              Blocked periods
            </Button>
            <Button
              value={professional.id}
              component={RouterLink}
              to={`/app/professional/${professional.id}/edit`}
              sx={{ mx: 1 }}
              color="primary"
              variant="contained"
              size="small"
            >
              Edit
            </Button>
            <Button
              color="warning"
              variant="contained"
              size="small"
              sx={{ mx: 1 }}
              onClick={() => handleClickOpenDeleteModal(professional.id)}
            >
              Remove
            </Button>
            <Tooltip title="Portfolio">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component={RouterLink}
                to={`/app/professional/${professional.id}/portfolio`}
              >
                <PhotoCamera />
              </IconButton>
            </Tooltip>
            <FormControl variant="outlined" style={{ minWidth: 120, marginLeft: '8px' }}>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={professional?.status}
                onChange={(event) => handleChangeStatus(event, professional.id)}
                label="Status"
              >
                {PROFESSIONAL_STATES.map((s, i) => <MenuItem key={`state_${i}`} value={s}>{s}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

function BlockedPeriodsList({
  professional, action, periods, changeAction, refetch,
}) {
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [periodToDelete, setPeriodToDelete] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  function handleCloseDeleteModal() {
    setPeriodToDelete({});
    setOpenDeleteModal(false);
  }

  function handleDeleteBlockedPeriod() {
    return AdminServices.deleteProfessionalBlockedPeriods(professional.id, periodToDelete.id)
      .then(() => {
        refetch();
        handleCloseDeleteModal();
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      {action === 'add' && (
      <AddBlockedPeriod
        onCreate={() => refetch()}
        onClose={() => changeAction(false)}
        professionalId={professional.id}
      />
      )}
      {action === 'edit' && (
      <EditBlockedPeriod
        onCreate={() => refetch()}
        onClose={() => { setSelectedPeriod({}); changeAction(false); }}
        professionalId={professional.id}
        period={selectedPeriod}
      />
      )}
      {openDeleteModal && (
      <DeleteModal
        handleClose={handleCloseDeleteModal}
        open={openDeleteModal}
        Agree={handleDeleteBlockedPeriod}
        title="Do you want to remove this blocked period ?"
        content={`ID: ${periodToDelete.id}`}
      />
      )}
      {periods.length === 0 && <Typography>Without blocked periods</Typography>}
      {periods.length > 0
      && (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              ID
            </TableCell>
            <TableCell>
              Comment
            </TableCell>
            <TableCell align="center">
              Date
            </TableCell>
            <TableCell align="center">
              All Day
            </TableCell>
            <TableCell align="center">
              Start time
            </TableCell>
            <TableCell align="center">
              End time
            </TableCell>
            <TableCell align="center">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periods.map((period, i) => {
            return (
              <TableRow
                key={`period_${i}`}
              >
                <TableCell>
                  {period.id}
                </TableCell>
                <TableCell>
                  {period.comment}
                </TableCell>
                <TableCell align="center">
                  {period.date}
                </TableCell>
                <TableCell align="center">
                  {period.all_day ? <CheckIcon color="primary" /> : <ClearIcon color="secondary" />}
                </TableCell>
                <TableCell align="center">
                  {period.start_time ? moment(period.start_time).utc().format('YYYY-MM-DD, HH:mm') : '-'}
                </TableCell>
                <TableCell align="center">
                  {period.end_time ? moment(period.end_time).utc().format('YYYY-MM-DD, HH:mm') : '-'}
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      sx={{ mx: 1 }}
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => { setSelectedPeriod(period); changeAction('edit'); }}
                    >
                      Edit
                    </Button>
                    <Button
                      color="warning"
                      variant="contained"
                      size="small"
                      sx={{ mx: 1 }}
                      onClick={() => { setPeriodToDelete(period); setOpenDeleteModal(true); }}
                    >
                      Remove
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      )}
      {}
    </>
  );
}

function AddBlockedPeriod({ professionalId, onCreate, onClose }) {
  const [loading, setLoading] = useState(false);

  function savePeriod(doc) {
    setLoading(true);
    return AdminServices.addProfessionalBlockedPeriods(professionalId, doc)
      .then(() => {
        setLoading(false);
        onClose();
        onCreate();
      })
      .catch((error) => console.log(error));
  }

  return (
    <Dialog
      open
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogTitle id="professional-dialog-title">Add blocked period</DialogTitle>
      <DialogContent dividers>
        {loading && <Box style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></Box>}
        {!loading && <BlockedPeriodForm onClose={onClose} onSave={savePeriod} />}
      </DialogContent>
    </Dialog>
  );
}

function EditBlockedPeriod({
  professionalId, onClose, period, onCreate,
}) {
  const [loading, setLoading] = useState(false);

  function savePeriod(doc) {
    setLoading(true);
    return AdminServices.editProfessionalBlockedPeriods(professionalId, doc.id, doc)
      .then(() => {
        setLoading(false);
        onClose();
        onCreate();
      })
      .catch((error) => console.log(error));
  }

  return (
    <Dialog
      open
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogTitle id="professional-dialog-title">Edit blocked period</DialogTitle>
      <DialogContent dividers>
        {loading && <Box style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></Box>}
        {!loading && <BlockedPeriodForm period={period} onClose={onClose} onSave={savePeriod} />}
      </DialogContent>
    </Dialog>
  );
}

function BlockedPeriodForm({ period = {}, onSave, onClose }) {
  const [changes, setChanges] = useState({});
  const [errors, setErrors] = useState(null);

  function onSubmit() {
    const doc = {
      ...period,
      ...changes,
    };
    if (!validateData(doc)) {
      return;
    }
    console.info(doc);
    onSave(doc);
  }

  function validateData(data) {
    let validated;
    const errorsAcum = {};

    if (!data.date) errorsAcum.date = REQUIRED_FIELD_ERROR;
    if (!data.comment) errorsAcum.comment = REQUIRED_FIELD_ERROR;
    if (!data.all_day) {
      if (!data.start_time) errorsAcum.start_time = REQUIRED_FIELD_ERROR;
      if (!data.end_time) errorsAcum.end_time = REQUIRED_FIELD_ERROR;
    }

    if (is.empty(errorsAcum)) validated = true;
    if (is.not.empty(errorsAcum)) {
      setErrors(errorsAcum);
      validated = false;
    }
    return validated;
  }

  function handleChange(prop, value) {
    setChanges({ ...changes, [prop]: value });
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <TextField
            fullWidth
            id="start"
            label="Comment"
            error={!!errors?.comment}
            helperText={errors?.comment}
            name="start_time"
            value={'comment' in changes ? changes.comment : period.comment || ''}
            onChange={
                      (event) => handleChange(
                        'comment', event.target.value)
                    }
            InputLabelProps={{ shrink: true }}
            required
            variant="outlined"
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            id="start"
            type="date"
            label="Date"
            error={!!errors?.date}
            helperText={errors?.date}
            name="start_time"
            value={'date' in changes ? changes.date : period.date || null}
            onChange={
                      (event) => handleChange(
                        'date', event.target.value)
                    }
            InputLabelProps={{ shrink: true }}
            required
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FormControlLabel
            control={(
              <Switch
                checked={'all_day' in changes ? changes.all_day : period.all_day || false}
                onChange={(event) => handleChange(
                  'all_day', event.target.checked)}
                color="primary"
              />
        )}
            label="All day"
          />
        </Grid>
        {('all_day' in changes ? !changes.all_day : !period.all_day) && (
        <>
          <Grid item md={6}>
            <TextField
              fullWidth
              id="start"
              type="time"
              disabled={!changes.date}
              label="Start Time"
              error={!!errors?.start_time}
              helperText={errors?.start_time}
              name="start_time"
              value={'start_time' in changes
                ? moment(changes.start_time).format('HH:mm')
                : period.start_time ? moment(period.start_time).format('HH:mm') : null}
              onChange={(event) => handleChange('start_time', `${changes.date}T${event.target.value}`)}
              InputLabelProps={{ shrink: true }}
              required
              variant="outlined"
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              id="start"
              type="time"
              disabled={!changes.date}
              label="End Time"
              error={!!errors?.end_time}
              helperText={errors?.end_time}
              name="end_time"
              value={'end_time' in changes
                ? moment(changes.end_time).format('HH:mm')
                : period.end_time ? moment(period.end_time).format('HH:mm') : null}
              onChange={(event) => handleChange('end_time', `${changes.date}T${event.target.value}`)}
              InputLabelProps={{ shrink: true }}
              required
              variant="outlined"
            />
          </Grid>
        </>
        )}
      </Grid>
      <Box style={{
        width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '15px 0px 0px 0px',
      }}
      >
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onClose()}
            style={{ margin: '0px 15px' }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ProfessionalsListResults;

const PROFESSIONAL_STATES = [
  'created',
  'accepted',
  'rejected',
];
