import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';

import Account from 'src/pages/auth/Account';
import Login from 'src/pages/auth/Login';
import Register from 'src/pages/auth/Register';
import ResetPassword from 'src/pages/auth/ResetPassword';
import ResetPasswordSuccess from 'src/pages/auth/ResetPasswordSuccess';

import NotFound from 'src/pages/NotFound';
import Settings from 'src/pages/Settings';

import Area from 'src/pages/area/AreaList';
import AreaAdd from 'src/pages/area/AreaAdd';
import AreaEdit from 'src/pages/area/AreaEdit';

import CategoryList from 'src/pages/category/CategoryList';
import CategoryAdd from 'src/pages/category/CategoryAdd';
import CategoryEdit from 'src/pages/category/CategoryEdit';
import ServicesList from 'src/pages/services/ServicesList';
import ServiceAdd from 'src/pages/services/ServicesAdd';
import ServicesEdit from 'src/pages/services/ServicesEdit';

import ProfessionalAdd from 'src/pages/professional/ProfessionalAdd';
import ProfessionalEdit from 'src/pages/professional/ProfessionalEdit';
import ProfessionalList from 'src/pages/professional/ProfessionalList';
import PortfolioEdit from 'src/pages/portfolio/PortfolioEdit';

import ServicesVariantList from 'src/pages/serviceVariants/ServicesVariantList';
import ServicesVariantAdd from 'src/pages/serviceVariants/ServicesVariantAdd';
import ServicesVariantEdit from 'src/pages/serviceVariants/ServicesVariantEdit';
import ServicesVariantAddAddon from 'src/pages/serviceVariants/ServicesVariantAddAddon';

import ServicesVariantAreaEdit from 'src/pages/serviceVariantArea/ServicesVariantAreaEdit';
import ServicesVariantAreaAdd from 'src/pages/serviceVariantArea/ServicesVariantAreaAdd';
import DlocalSecureCard from 'src/pages/payment-methods/DlocalSaveCard';
import SaveCardSuccess from 'src/pages/payment-methods/SaveCardSuccess';
import SaveCardFailed from 'src/pages/payment-methods/SaveCardFailed';

import PricingPeriodAdd from 'src/pages/precingPeriod/PricingPeriodAdd';
import PricingPeriodEdit from 'src/pages/precingPeriod/PricingPeriodEdit';

import BookingList from 'src/pages/booking/BookingList';
import BookingEdit from 'src/pages/booking/BookingEdit';
import BookingRequest from 'src/pages/booking/BookingRequest';

import PromocodeList from 'src/pages/promocodes/PromocodeList';
import PromocodeAdd from 'src/pages/promocodes/PromocodeAdd';
import PromocodeEdit from 'src/pages/promocodes/PromocodeEdit';

import AddonList from 'src/pages/addon/AddonList';
import AddonAdd from 'src/pages/addon/AddonAdd';
import AddonEdit from 'src/pages/addon/AddonEdit';

import ConfigValueList from 'src/pages/configValues/ConfigValueList';

import ClientReview from 'src/pages/review/ClientReview';
import ProfessionalsTracking from './pages/professional/ProfessionalsTracking';

import CustomerList from './pages/customers/CustomerList';
import CustomerEdit from './pages/customers/CustomerEdit';
import CustomerAdd from './pages/customers/CustomerAdd';
import CustomerAddressEdit from './pages/customers/CustomerAddressEdit';

import SettlementsList from './pages/settlements/SettlementsList';

import BannersList from './pages/banners/BannersList';
import BannerAdd from './pages/banners/BannerAdd';
import BannerEdit from './pages/banners/BannerEdit';

import ConversationList from './pages/conversations/ConversationsList';
import WalletsList from './pages/wallets/WalletList';
import CreateTransaction from './pages/transactions/CreateTransaction';
import TransactionsList from './pages/transactions/TransactionsList';
import Home from './pages/Home';
import ReviewsList from './pages/reviews/ReviewsList';
import PacksList from './pages/packs/PacksList';
import PackEdit from './pages/packs/PackEdit';
import PackCreate from './pages/packs/PackCreate';
import CustomerAddressAdd from './pages/customers/CustomerAddressAdd';
import PacksPurchasedList from './pages/packsPurchased/PacksPurchasedList';

const routes = (isLoggedIn) => [
  {
    path: 'app',
    // element: <DashboardLayout />,
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'home', element: <Home /> },
      { path: 'addon/edit/:addonId', element: <AddonEdit /> },
      { path: 'addon/add', element: <AddonAdd /> },
      { path: 'addons', element: <AddonList /> },
      { path: 'promocodes/:promocodeId/edit', element: <PromocodeEdit /> },
      { path: 'promocodes/add', element: <PromocodeAdd /> },
      { path: 'promocodes', element: <PromocodeList /> },
      { path: 'booking/:bookingId/edit', element: <BookingEdit /> },
      { path: 'booking/:bookingId/:date/request', element: <BookingRequest /> },
      { path: 'bookings', element: <BookingList /> },
      { path: 'variantarea/:variantarea/pricing-period/add', element: <PricingPeriodAdd /> },
      { path: 'variantarea/:variantarea/pricing-period/:pricingPeriodId/edit', element: <PricingPeriodEdit /> },
      { path: 'variant/:variantId/variant-area/add', element: <ServicesVariantAreaAdd /> },
      { path: 'variantarea/:variantarea/edit', element: <ServicesVariantAreaEdit /> },
      { path: 'variant/:variantId/add-addon', element: <ServicesVariantAddAddon /> },
      { path: 'service/:serviceId/variant/:variantId/addon/:addonId/edit', element: <ServicesVariantAddAddon /> },
      { path: 'service/:serviceId/variant/:variantId/edit', element: <ServicesVariantEdit /> },
      { path: 'service/variant', element: <ServicesVariantList /> },
      { path: 'service/variant/add', element: <ServicesVariantAdd /> },
      { path: 'professionals', element: <ProfessionalList /> },
      { path: 'professional/add', element: <ProfessionalAdd /> },
      { path: 'professional/:professionalId/edit', element: <ProfessionalEdit /> },
      { path: 'professional/:professionalId/portfolio', element: <PortfolioEdit /> },
      { path: 'professional/tracking', element: <ProfessionalsTracking /> },
      { path: 'service/edit/:id', element: <ServicesEdit /> },
      { path: 'service/add', element: <ServiceAdd /> },
      { path: 'service', element: <ServicesList /> },
      { path: 'categories/edit/:id', element: <CategoryEdit /> },
      { path: 'categories/add', element: <CategoryAdd /> },
      { path: 'categories', element: <CategoryList /> },
      { path: 'areas/add', element: <AreaAdd /> },
      { path: 'areas/edit/:id', element: <AreaEdit /> },
      { path: 'areas', element: <Area /> },
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'customer/add', element: <CustomerAdd /> },
      { path: 'customer/:customerid/edit', element: <CustomerEdit /> },
      { path: 'customer/:customerId/address/:addressId/edit', element: <CustomerAddressEdit /> },
      { path: 'customer/:customerId/address/add', element: <CustomerAddressAdd /> },
      { path: 'settlements', element: <SettlementsList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'banners', element: <BannersList /> },
      { path: 'banners/add', element: <BannerAdd /> },
      { path: 'banners/edit/:bannerId', element: <BannerEdit /> },
      { path: 'conversations/', element: <ConversationList /> },
      { path: 'wallets/', element: <WalletsList /> },
      { path: 'wallet/user/:userId/transactions', element: <TransactionsList /> },
      { path: 'wallet/user/:userId/transaction/add', element: <CreateTransaction /> },
      { path: 'config-values', element: <ConfigValueList /> },
      { path: 'reviews', element: <ReviewsList /> },
      { path: 'reviews/:id/edit', element: <ReviewsList /> },
      { path: 'packs', element: <PacksList /> },
      { path: 'packs/create', element: <PackCreate /> },
      { path: 'packs/:packId/edit', element: <PackEdit /> },
      { path: 'booking-packs', element: <PacksPurchasedList /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'reset-password/:reset_token', element: <ResetPassword /> },
      { path: 'reset-password-success', element: <ResetPasswordSuccess /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/home" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/review/:bookingId/:token', element: <ClientReview /> },
    ],
  },
  {
    path: 'process-payment',
    children: [
      { path: 'save-card-success', element: <SaveCardSuccess /> },
      { path: 'save-card-failed', element: <SaveCardFailed /> },
      { path: 'dlocal-secure-card/:auth_token', element: <DlocalSecureCard /> },
    ],
  },
];

export default routes;
