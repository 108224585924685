import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import is from 'is_js';

import PackDetails from 'src/components/packs/PackDetails';
import AdminServices from 'src/services/AdminServices';

const PackEdit = () => {
  const navigate = useNavigate();
  const { packId } = useParams();
  const [pack, setPack] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [itemOrder, setItemOrder] = useState(1);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    console.log('pack edit', pack);
  }, [pack]);

  useEffect(() => {
    if (packId) {
      getPackById();
    }
  }, [packId]);

  const getPackById = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getPackById(packId);
      const itemUpdated = response.data.items.map((p, i) => {
        const withOrder = { ...p, order: itemOrder + i, old: true };
        return withOrder;
      });
      const { items, ...rest } = response.data;
      setPack({ ...rest, items: itemUpdated });
      setIsLoad(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPack = async (changes, items, packAreas) => {
    setSaveLoading(true);
    const packUpdated = {
      ...changes,
      id: Number(packId),
    };
    const oldItems = items.filter((i) => i.old);
    // TODO
    // const newItems = items.filter((i) => !i.old);

    try {
      console.log('packUpdated', packUpdated);
      console.log('packId', packId);
      const packResponse = await AdminServices.updatePack(packUpdated, packUpdated.id);
      console.log('packResponse', packResponse.data);

      await Promise.all(oldItems.map(async (item, i) => {
        const {
          old, order, serviceVariant, service_variant_id, ...rest
        } = item;
        const newItem = service_variant_id ? { service_variant_id, ...rest } : { ...rest, serviceVariant };
        const response = await AdminServices.updatePackItem(item.id, newItem);
      }));
      // TODO
      // await Promise.all(newItems.map(async (item, i) => {
      //   const response = await AdminServices.createPackItem(item);
      // }));

      const oldAreas = packAreas.filter((a) => !a.viewId);
      const newAreas = packAreas.filter((a) => a.viewId);

      if (is.not.empty(oldAreas)) {
        await Promise.all(oldAreas.map(async (oldArea) => {
          const {
            area, area_id, image, ...rest
          } = oldArea;
          const isNewImage = image?.url ? null : image;
          const newArea = area_id ? { area_id, ...rest, isNewImage } : { ...rest, area, isNewImage };
          const oldAreasResponse = await AdminServices.updatePackArea(newArea, newArea.id);
          console.log('oldAreasResponse', oldAreasResponse.data);
        }));
      }

      if (is.not.empty(newAreas)) {
        await Promise.all(newAreas.map(async (area) => {
          const {
            viewId, order, ...rest
          } = area;
          const newArea = { ...rest, pack_id: packUpdated.id };
          const newAreasResponse = await AdminServices.createPackArea(newArea);
          console.log('newAreasResponse', newAreasResponse.data);
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      navigate('/app/packs');
    }
  };

  const handleDeletePackItem = async (packItemId) => {
    try {
      const response = await AdminServices.deletePackItem(packItemId);
      console.log('delete item', packItemId, response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePackArea = async (packAreaId) => {
    try {
      const response = await AdminServices.deletePackAreaItem(packAreaId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit Session Pack </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/packs">
              Session Packs
            </Link>
            <Typography color="textPrimary">Edit Session Pack</Typography>
          </Breadcrumbs>

          <Grid
            container
            spacing={3}
          >
            <Grid item lg={12} md={12} xs={12}>
              {isLoading && <Box style={{ textAlign: 'center' }}><CircularProgress /></Box>}
              {isLoad && <PackDetails pack={pack} handleEditPack={handleEditPack} loading={saveLoading} handleDeletePackItem={handleDeletePackItem} handleDeletePackArea={handleDeletePackArea} />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default PackEdit;
