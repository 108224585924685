import { Helmet } from 'react-helmet';
import { useState } from 'react';

import Service from 'src/services/authService';
import {
  Box, Button, Container, TextField, Typography,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router';

const ResetPassword = () => {
  const passwordValidation = (pass) => {
    if (pass.trim() === '') {
      return 'Ingresa tu nueva contraseña';
    }
    if (pass.trim().length < 6) {
      return 'La contraseña debe tener al menos 6 caracteres';
    }
    if (values.password !== values.repeatPassword) {
      return 'Las contraseñas ingresadas no son iguales';
    }
    return null;
  };

  const validate = { password: passwordValidation, repeatPassword: passwordValidation };
  const { reset_token } = useParams();
  const [values, setValues] = useState({ password: '', repeatPassword: '' });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    // validate the form
    const formValidation = Object.keys(values).reduce(
      (acc, key) => {
        const newError = validate[key](values[key]);
        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          },
        };
      }, {
        errors: { ...errors },
        touched: { ...touched },
      },
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (!Object.values(formValidation.errors).length
      && Object.values(formValidation.touched).length === Object.values(values).length // all fields were touched
      && Object.values(formValidation.touched).every((t) => t === true) // every touched field is true
    ) {
      Service.resetPassword(reset_token, values.password).then(() => {
        navigate('/reset-password-success', { replace: true });
      }).catch((e) => { alert('Error'); console.log(e); });
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password | Ameiz</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
      >
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">Reiniciar contraseña</Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Ingresa tu nueva contraseña para Ameiz
              </Typography>
            </Box>
            <TextField
              errors={errors}
              fullWidth
              helperText={touched.password && errors.password}
              label="Nueva contraseña"
              margin="normal"
              name="password"
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <TextField
              errors={errors}
              fullWidth
              helperText={touched.repeatPassword && errors.repeatPassword}
              label="Repite tu nueva contraseña"
              margin="normal"
              name="repeatPassword"
              onChange={handleChange}
              type="password"
              value={values.repeatPassword}
              variant="outlined"
            />
            {touched.password && (
            <Typography
              color="error"
              gutterBottom
              variant="subtitle1"
            >
              {errors.password}
            </Typography>
            )}
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Cambiar clave
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
