import { React, useState, useEffect } from 'react';
import {
  Marker, GoogleMap, LoadScript, Polygon,
} from '@react-google-maps/api';
import is from 'is_js';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const options = {
  fillColor: 'pink',
  fillOpacity: 0.4,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const onLoad = (polygon) => {
  console.log('polygon: ', polygon);
};

function ProfessionalMap({
  points, center, editMapOn, polygonSelected, handlePointsAdd, handleMovePointer, createNewPolygonOn, newPoints,
}) {
  const [localPolygonSelected, setLocalPolygonSelected] = useState(null);

  useEffect(() => {
    if (points) {
      setLocalPolygonSelected(null);
    }
  }, [points]);

  function transformPoints(points) {
    return points.map((point) => point.latlng);
  }

  const handleMapClick = (event) => {
    if (!createNewPolygonOn) return;
    handlePointsAdd(event);
  };

  const handleClickPolygon = (p, index) => {
    setLocalPolygonSelected(index);
    polygonSelected(p[0].id);
  };

  const handlePointMove = (e, i) => {
    handleMovePointer(e.latLng.lat(), e.latLng.lng(), i);
    console.log(e.latLng.lat(), e.latLng.lng(), i);
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        onClick={handleMapClick}
        id="marker-example"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
      >
        {is.not.empty(points) && points.map((p, i) => (
          <Polygon
            key={`poligon_${i}`}
            onLoad={onLoad}
            paths={transformPoints(p)}
            onClick={() => handleClickPolygon(p, i)}
            options={{
              fillColor: editMapOn ? i === localPolygonSelected ? '#FF0000' : '#ABFFAF' : '#ABFFAF',
              fillOpacity: 0.4,
              strokeColor: editMapOn ? i === localPolygonSelected ? '#FF0000' : '#00B008' : '#00B008',
              strokeOpacity: 1,
              strokeWeight: 2,
              clickable: editMapOn,
              draggable: false,
              editable: false,
              geodesic: false,
              zIndex: 1,
            }}
          />
        ))}

        {createNewPolygonOn && (
        <Polygon
          onLoad={onLoad}
          paths={transformPoints(newPoints)}
          options={options}
        />
        )}

        {createNewPolygonOn && newPoints.map((np, index) => (
          <Marker
            draggable
            key={np?.latlng?.lat + np?.latlng?.lng}
            position={np?.latlng}
            label={`Punto ${index}`}
            name={index}
            onDragEnd={(e) => { handlePointMove(e, index); }}
          />
        ))}

      </GoogleMap>
    </LoadScript>
  );
}

export default ProfessionalMap;
