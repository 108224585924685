import { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import {
  Container,
} from '@material-ui/core';

import ConversationResult from 'src/components/conversations/ConversationResult';
import ConversationsToolbar from 'src/components/conversations/ConversationsToolbar';
import { db } from 'src/utils/firebase';

function ConversationList() {
  const [conversations, setConversations] = useState({});
  const [unread, setUnread] = useState([]);
  const [filters, setFilters] = useState({ status: '' });

  useEffect(() => {
    Promise.all([getConversatoins(), getUnread()]).then(([convs, unreads]) => {
      setConversations(convs);
      const uKeys = Object.keys(unreads).filter((k) => !(k.includes('user')));
      const unreadMessages = uKeys.reduce((acc, key) => {
        const obj = unreads[key];
        const isRead = Object.values(obj).filter((item) => item.isRead === false);
        const includeId = isRead.map((item) => ({ ...item, professional_id: key }));
        return acc.concat(includeId);
      }, []);
      setUnread(unreadMessages);
    });
  }, []);

  function groupBy(list, keyName) {
    const map = new Map();
    list.forEach((item) => {
      const key = item[keyName];
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return Object.fromEntries(map);
  }

  function getUnread() {
    return db.ref('unread/').once('value')
      .then((snapshot) => {
        return snapshot.val();
      });
  }

  function getConversatoins() {
    return db.ref('new_chat/').once('value')
      .then((snapshot) => {
        const newChat = snapshot.val();
        const chats = [];
        for (const i in newChat) {
          for (const c in newChat[i]) {
            if (c !== 'client_name') {
              const messageList = Object.values(newChat[i][c]);
              chats.push({
                ...newChat[i][c],
                ameiz: i,
                ameizer: c,
                client_name: newChat[i].client_name,
                professional_name: messageList.find((m) => m.professional_name)?.professional_name,
              });
            }
          }
        }
        return groupBy(chats, 'ameizer');
      });
  }

  return (
    <Container maxWidth={false}>
      <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12, marginTop: 24 }}>
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">Conversations</Typography>
      </Breadcrumbs>
      <ConversationsToolbar handleFilter={(filter) => setFilters({ ...filters, ...filter })} filters={filters} />
      {Object.keys(conversations).filter((key) => {
        const data = conversations[key];
        const read = unread.find(
          (message) => message.professional_id === data[0].ameizer
          && data.find((d) => d.ameiz === `user_${message.user_id}`));
        return filters.status === 'unread' ? read : true;
      }).map((key) => {
        const data = conversations[key];
        return (<ConversationResult data={data} unreads={unread} key={key} />);
      })}
    </Container>
  );
}

export default ConversationList;
