import { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import is from 'is_js';
import AreaSelectTree from 'src/components/comon/AreaSelectTree';
import ServiceVariantTree from 'src/components/comon/ServiceVariantTree';
import Checkbox from '@material-ui/core/Checkbox';
import BlankProfile from 'src/assets/blank-profile.png';
import BlankIndentificationFront from 'src/assets/indentification_front.jpeg';
import BlankIndentificationBack from 'src/assets/indentification_back.jpeg';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import AdminServices from 'src/services/AdminServices';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Divider,
  Grid,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ProfessionalMap from 'src/components/professional/ProfessionalMap';
import CountrySelect from 'src/components/comon/CountrySelect';
import AreaListPoints from 'src/components/area/AreaListPoints';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { v4 as uuidv4 } from 'uuid';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}),
);

const ProfessionalDetails = ({
  handleSave, action, defaultValue, saveLoading, updateLoading, serviceVariants, errors,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [titles, setTitles] = useState('');
  const [professionalValues, setProfessionalValues] = useState({
    id: null,
    owns_transportation: false,
    work_on_holidays: false,
    boarding_step: 'completed',
    country_id: null,
    priority: null,
  });
  const [user, setUser] = useState({
    id: null,
    name: '',
    lastname: '',
    summary: '',
    identification_number: '',
    phone: '',
    authentication_provider_id: null,
    email: '',
    birthdate: '',
    password: '',
    repeat_password: '',
  });

  const [profileImage, setProfileImage] = useState(null);
  const [identificationFront, setIdentificationFront] = useState(null);
  const [identificationBack, setIdentificationBack] = useState(null);
  const [areaSelected, setAreaSelected] = useState([]);
  const [areas, setAreas] = useState([]);
  const [serviceVariantSelected, setServiceVariantSelected] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countrySelected, setCountrySelected] = useState(null);

  const [mapIsLoad, setMapIsLoad] = useState(false);
  const [points, setPoints] = useState([]);
  const [center, setCenter] = useState({ lat: -33.43774451396826, lng: -70.65101623535156 });
  const [editOn, setEditOn] = useState(false);

  const [editMapOn, setEditMapOn] = useState(false);
  const [polygonSelected, setPolygonSelected] = useState(null);

  const [dayOfWeek, setDayOfWeek] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [availabilities, setAvailabilities] = useState([]);

  const [newPoints, setNewPoints] = useState([]);
  const [createNewPolygonOn, setCreateNewPolygonOn] = useState(false);
  const [newPolygonList, setNewPolygonList] = useState([]);
  const [saveNewPolygonLoading, setSaveNewPolygonLoading] = useState(false);

  const [serviceVariantSorted, setserviceVariantSorted] = useState([]);

  useEffect(() => {
    if (is.not.empty(serviceVariants)) {
      const options = serviceVariants.map((option) => {
        return {
          serviceName: option.service.name,
          ...option,
        };
      });
      setserviceVariantSorted(options.sort((a, b) => -b.serviceName.localeCompare(a.serviceName)));
    }
  }, [serviceVariants]);

  const handleDeleteNewPoint = (indexPoint) => {
    const newPointsList = newPoints.filter((p) => (p !== indexPoint));
    setNewPoints(newPointsList);
  };

  const handleDeleteAllNewPoints = () => {
    setNewPoints([]);
  };

  const handlePointsAdd = (event) => {
    console.log('handlePointsAdd', event);
    setNewPoints(
      [...newPoints, { point: newPoints.length, latlng: { lat: event.latLng.lat(), lng: event.latLng.lng() } }]);
  };

  const handleSaveNewPolygon = async () => {
    setSaveNewPolygonLoading(true);

    if (action === 'edit') {
      console.log('newPoints', newPoints.map((p) => [p.latlng.lat, p.latlng.lng]));
      try {
        const newArea = {
          polygon: newPoints.map((p) => [p.latlng.lat, p.latlng.lng]),
          professional_id: professionalValues.id,
        };
        const response = await AdminServices.addProfessionalWorkingArea(professionalValues.id, newArea);
        setNewPoints([]);
        polygonsPolygonStringsToObject(newArea.polygon, response.data.id);
        setCreateNewPolygonOn(false);
      } catch (error) {
        console.log(error);
      } finally {
        setSaveNewPolygonLoading(false);
      }
    }

    if (action === 'add') {
      const newArea = {
        polygon: newPoints.map((p) => [p.latlng.lat, p.latlng.lng]),
        professional_id: null,
      };
      polygonsPolygonStringsToObject(newArea.polygon, uuidv4());
      setNewPoints([]);
      setNewPolygonList((currentValues) => [...currentValues, newArea]);
      setSaveNewPolygonLoading(false);
      setCreateNewPolygonOn(false);
    }
  };

  const handleAddAvailability = async () => {
    if (dayOfWeek, startTime, endTime) {
      const newAvailability = {
        viewId: uuidv4(),
        day_of_week: dayOfWeek + 1,
        start_time: `${startTime}:00`,
        end_time: `${endTime}:00`,
        professional_id: action === 'edit' ? professionalValues.id : null,
      };
      setAvailabilities((currentValues) => [...currentValues, newAvailability]);
      setDayOfWeek('');
      setStartTime('');
      setEndTime('');

      if (action === 'edit') {
        try {
          const response = await AdminServices.addProfessionalAvailability(professionalValues.id, newAvailability);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const deleteAvailability = async (id, type) => {
    let filteredList;
    if (type === 'new') {
      filteredList = availabilities.filter((a) => a.viewId !== id);
      setAvailabilities(filteredList);
    } else {
      filteredList = availabilities.filter((a) => a.id !== id);
      setAvailabilities(filteredList);
      try {
        const response = await AdminServices.deleteProfessionalAvailability(professionalValues.id, id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const {
        user, availabilities, workingAreas, images, ...rest
      } = defaultValue;
      setUser({
        id: user.id,
        name: user.name,
        lastname: user.lastname,
        summary: user.summary,
        identification_number: user.identification_number,
        phone: user.phone,
        authentication_provider_id: user.authentication_provider_id,
        email: user.email,
        birthdate: user.birthdate,
      });
      setProfessionalValues({
        id: rest.id,
        owns_transportation: rest.owns_transportation,
        work_on_holidays: rest.work_on_holidays,
        boarding_step: rest.boarding_step,
        country_id: rest?.country?.id,
        priority: rest?.priority,
      });
      if (rest.country) {
        setCountrySelected(rest.country);
      }
      if (user.avatar) setProfileImage(user.avatar.full_url);
      if (user.identification_attachment_front) setIdentificationFront(user.identification_attachment_front.url);
      if (user.identification_attachment_back) setIdentificationBack(user.identification_attachment_back.url);

      if (is.not.empty(workingAreas)) {
        for (const i in workingAreas) {
          polygonsPolygonStringsToObject(workingAreas[i].polygon, workingAreas[i].id);
        }
      }
      setMapIsLoad(true);

      if (is.not.empty(availabilities)) {
        setAvailabilities(availabilities);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValue) {
      const { professionalServiceVariants } = defaultValue;
      if (is.not.empty(professionalServiceVariants)) {
        const listOFSelected = professionalServiceVariants.map((pv) => pv.serviceVariant);
        const filteredList = [];
        for (const i in listOFSelected) {
          filteredList.push(serviceVariants.find((sv) => sv.id === listOFSelected[i].id));
        }
        const purgeList = filteredList.filter((item) => item);
        setServiceVariantSelected(purgeList);
      }
    }
  }, [serviceVariants]);

  useEffect(() => {
    getSelectData();
  }, []);

  const getSelectData = async () => {
    setIsLoading(true);
    await Promise.all([getAreaList(), getCountryList()]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (action === 'add') {
      setTitles('Add Professional');
    } else {
      setTitles('Edit Professional');
    }
  }, [action]);

  const getAreaList = async () => {
    try {
      const response = await AdminServices.getAreas();
      setAreas(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCountryList = async () => {
    try {
      const response = await AdminServices.getCountries();
      setCountries(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLocalSave = () => {
    const professionalData = {
      ...professionalValues,
      user,
      workingAreas: [...areaSelected, ...newPolygonList],
      serviceVariant: serviceVariantSelected,
      availability: availabilities,
    };
    if (action === 'add') {
      handleSave(professionalData);
    } else {
      handleSave({ ...professionalValues, user });
      setEditOn(false);
    }
  };

  const handleCheckValues = (event) => {
    setProfessionalValues({
      ...professionalValues,
      [event.target.name]: event.target.name === 'priority' ? Number(event.target.value) : event.target.checked,
    });
  };

  const handleChangeUser = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleAreaSlected = async (areasSelected, singleValue) => {
    setAreaSelected(areasSelected);

    if (areasSelected.find((a) => a.name === singleValue.props.value)) {
      const poly = areasSelected.find((a) => a.name === singleValue.props.value);
      const newArea = {
        polygon: poly.polygon,
        professional_id: professionalValues.id,
      };
      if (action === 'edit') {
        try {
          const response = await AdminServices.addProfessionalWorkingArea(professionalValues.id, newArea);
          polygonsPolygonStringsToObject(poly.polygon, response.data.id);
        } catch (error) {
          console.log(error);
        }
      } else {
        polygonsPolygonStringsToObject(poly.polygon, poly.id);
      }
    } else {
      // todo
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleUploadClick = (event) => {
    getBase64(event.target.files[0]).then((file64) => {
      if (action === 'add') {
        setProfessionalValues({
          ...professionalValues,
          [event.target.name]: {
            base64: file64,
            user_id: null,
            image_type: event.target.name === 'identification_attachment_front' ? 'front' : 'back',
          },
        });
      }

      if (action === 'edit') {
        const newIdentification = {
          base64: file64,
          user_id: user.id,
          image_type: event.target.name === 'identification_attachment_front' ? 'front' : 'back',
        };
        const identificationBackResponse = AdminServices.addProfessionalIdentification(user.id, newIdentification);
      }
    });
    if (event.target.name === 'identification_attachment_front') {
      setIdentificationFront(URL.createObjectURL(event.target.files[0]));
    } else {
      setIdentificationBack(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleAddProfileImage = async (event) => {
    getBase64(event.target.files[0]).then(async (file64) => {
      if (action === 'add') {
        setProfessionalValues({
          ...professionalValues,
          [event.target.name]: {
            base64: file64,
            image_type: event.target.files[0].type,
            user_id: null,
          },
        });
      }

      if (action === 'edit') {
        try {
          const newAvatar = {
            base64: file64,
            image_type: event.target.files[0].type,
            user_id: user.id,
          };
          const avatarResponse = await AdminServices.addProfessionalAvatar(user.id, newAvatar);
          console.log('avatarResponse', avatarResponse.data);
        } catch (error) {
          console.log(error);
        }
      }
    });
    setProfileImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleSelectServiceVariants = async (variants, newOption) => {
    setServiceVariantSelected(variants);
    if (action === 'edit') {
      if (serviceVariantSelected.find((item) => item.id === newOption.option.id)) {
        return AdminServices.deleteProfessionalServiceVariant(
          professionalValues.id, newOption.option.id);
      }
      return AdminServices.addProfessionalServiceVariant(
        professionalValues.id, { service_variant_id: newOption.option.id });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((currentValue) => !currentValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword((currentValue) => !currentValue);
  };

  const handleMouseDownRepeatPassword = (event) => {
    event.preventDefault();
  };

  const handleSelectCountry = (country) => {
    setCountrySelected(country);
    setProfessionalValues({
      ...professionalValues,
      country_id: country[0].id,
    });
  };

  function handleMovePointer(lat, lng, index) {
    const updated = newPoints.map((p) => {
      if (p.point === index) {
        return { point: index, latlng: { lat, lng } };
      }
      return p;
    });
    setNewPoints(updated);
  }

  async function polygonsPolygonStringsToObject(polygon, poligonId) {
    if (polygon) {
      const transformedPoints = polygon.map((point, index) => {
        return {
          // latlng: { lng: point[0], lat: point[1] },
          latlng: { lat: point[0], lng: point[1] },
          point: index,
          id: poligonId,
        };
      });
      setPoints((currentValues) => [...currentValues, transformedPoints]);
    }
  }

  if (isLoading) {
    return <Box style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></Box>;
  }

  const handleSelectMapCountry = (country) => {
    if (country) {
      setCenter(country[0].center);
    }
  };

  const handleDeletePolygon = async () => {
    if (action === 'edit') {
      if (polygonSelected) {
        const filteredList = points.filter((p) => p[0].id !== polygonSelected);
        return AdminServices.deleteProfessionalWorkingArea(professionalValues.id, polygonSelected)
          .then(() => {
            setPoints(filteredList);
            setPolygonSelected(null);
          })
          . catch((error) => {
            console.log(error);
          });
      }
    }

    if (action === 'add') {
      console.log('polygonSelected', polygonSelected);
      console.log('points', points);
      const filteredList = points.filter((p) => p[0].id !== polygonSelected);
      setPoints(filteredList);
      setPolygonSelected(null);
    }
  };

  return (
    <>
      <Card>
        <CardHeader subheader="The information can be edited" title={titles} />
        <Divider />
        <CardContent>
          <Grid container spacing={3} justify="center">
            <Grid item md={12} xs={12}>
              <Box
                style={{
                  backgroundImage: profileImage ? `url(${profileImage})` : `url(${BlankProfile})`,
                  backgroundSize: 'cover',
                  width: '120px',
                  height: '120px',
                  margin: '0 auto',
                  borderRadius: '8px',
                  border: '5px solid #ddd',
                }}
              >
                <input
                  accept="image/jpeg,image/png"
                  className={classes.input}
                  id="avatar"
                  type="file"
                  name="avatar"
                  onChange={handleAddProfileImage}
                />
                <label htmlFor="avatar">
                  <IconButton style={{ position: 'relative' }} component="span">
                    <AddAPhotoOutlinedIcon />
                  </IconButton>
                </label>
              </Box>
            </Grid>
            <Box boxShadow={5} style={{ padding: '25px', marginTop: '15px', marginLeft: '25px' }}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Name"
                    name="name"
                    value={user?.name}
                    error={!!errors?.name}
                    helperText={errors?.name}
                    onChange={handleChangeUser}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Last Name"
                    name="lastname"
                    value={user?.lastname}
                    error={!!errors?.lastname}
                    helperText={errors?.lastname}
                    onChange={handleChangeUser}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    disabled={action === 'edit' ? !editOn : false}
                    name="email"
                    placeholder="Email"
                    value={user?.email}
                    error={!!errors?.email}
                    helperText={errors?.email}
                    onChange={handleChangeUser}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="RUT"
                    disabled={action === 'edit' ? !editOn : false}
                    name="identification_number"
                    placeholder="RUT"
                    value={user?.identification_number}
                    error={!!errors?.identification_number}
                    helperText={errors?.identification_number}
                    onChange={handleChangeUser}
                    required
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                {action === 'add' && (
                <>
                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        required
                        fullWidth
                        disabled={action === 'edit' ? !editOn : false}
                        placeholder="Password"
                        label="Password"
                        name="password"
                        error={!!errors?.password}
                        helperText={errors?.password}
                        type={showPassword ? 'text' : 'password'}
                        value={user?.password}
                        onChange={handleChangeUser}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )}
                        labelWidth={70}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-repeat-password">Repeat Password</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-repeat-password"
                        required
                        fullWidth
                        disabled={action === 'edit' ? !editOn : false}
                        placeholder="Repeat Password"
                        error={!!errors?.repeat_password}
                        helperText={errors?.repeat_password}
                        label="Repeat Password"
                        name="repeat_password"
                        type={showRepeatPassword ? 'text' : 'password'}
                        value={user?.repeat_password}
                        onChange={handleChangeUser}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRepeatPassword}
                              onMouseDown={handleMouseDownRepeatPassword}
                              edge="end"
                            >
                              {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )}
                        labelWidth={70}
                      />
                    </FormControl>
                  </Grid>
                </>
                )}
                {action === 'add' && (
                  <>
                    <Grid item md={3} xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="Street"
                        name="street"
                        placeholder="Street"
                        value={user?.address}
                        onChange={handleChangeUser}
                        required
                        multiline
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="Number"
                        name="street_number"
                        placeholder="Number"
                        value={user?.street_number}
                        onChange={handleChangeUser}
                        required
                        multiline
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="Apartment/House"
                        name="street_extra_info"
                        placeholder="Apartment/House"
                        value={user?.street_extra_info}
                        onChange={handleChangeUser}
                        required
                        multiline
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="City"
                        name="street_city"
                        placeholder="City"
                        value={user?.street_city}
                        onChange={handleChangeUser}
                        required
                        multiline
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    id="date"
                    type="date"
                    label="Birthdate"
                    disabled={action === 'edit' ? !editOn : false}
                    error={!!errors?.birthdate}
                    helperText={errors?.birthdate}
                    name="birthdate"
                    value={user?.birthdate}
                    onChange={handleChangeUser}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : true}
                    label="Priority"
                    name="priority"
                    type="number"
                    value={professionalValues?.priority}
                    onChange={handleCheckValues}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    disabled={action === 'edit' ? !editOn : false}
                    placeholder="Phone"
                    value={user?.phone}
                    error={!!errors?.phone}
                    helperText={errors?.phone}
                    onChange={handleChangeUser}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <CountrySelect
                    countries={countries}
                    defaultareasSelected={countrySelected}
                    handleSelected={handleSelectCountry}
                    editOn={action === 'edit' ? !editOn : false}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Box style={{}}>
                    <label>
                      Own Transportation
                    </label>
                    <Checkbox
                      disabled={action === 'edit' ? !editOn : false}
                      name="owns_transportation"
                      checked={professionalValues?.owns_transportation}
                      onChange={handleCheckValues}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Box>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Box style={{}}>
                    <label>
                      Work on Holydays
                    </label>
                    <Checkbox
                      disabled={action === 'edit' ? !editOn : false}
                      name="work_on_holidays"
                      checked={professionalValues.work_on_holidays}
                      onChange={handleCheckValues}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    disabled={action === 'edit' ? !editOn : false}
                    label="Expirience Description"
                    name="summary"
                    placeholder="Description"
                    value={user?.summary}
                    onChange={handleChangeUser}
                    required
                    rows={4}
                    multiline
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              {action === 'edit' && (
              <Box style={{
                width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '15px 0px 0px 0px',
              }}
              >
                {editOn && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => { setEditOn(false); }}
                  style={{ margin: '0px 15px' }}
                >
                  Cancel
                </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => { editOn ? handleLocalSave() : setEditOn(true); }}
                >
                  {updateLoading ? <CircularProgress color="secondary" /> : editOn ? 'Save' : 'Edit'}
                </Button>
              </Box>
              )}
            </Box>
            <Box
              style={{
                marginTop: '25px', marginLeft: '25px', width: '100%',
              }}
            >
              <Grid container spacing={3} justify="center">
                <Grid item md={6} xs={12} justify="center">
                  <Box
                    style={{
                      backgroundImage: identificationFront
                        ? `url(${identificationFront})` : `url(${BlankIndentificationFront})`,
                      backgroundSize: 'cover',
                      width: '180px',
                      height: '110px',
                      borderRadius: '8px',
                      border: '5px solid #ddd',
                      margin: 'auto',
                    }}
                  >
                    <input
                      accept="image/jpeg,image/png"
                      className={classes.input}
                      id="identification_attachment_front"
                      type="file"
                      name="identification_attachment_front"
                      onChange={handleUploadClick}
                    />
                    <label htmlFor="identification_attachment_front">
                      <IconButton style={{ position: 'relative' }} component="span">
                        <AddAPhotoOutlinedIcon />
                      </IconButton>
                    </label>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12} justify="center">
                  <Box
                    style={{
                      backgroundImage: identificationBack
                        ? `url(${identificationBack})` : `url(${BlankIndentificationBack})`,
                      backgroundSize: 'cover',
                      width: '180px',
                      height: '110px',
                      borderRadius: '8px',
                      border: '5px solid #ddd',
                      margin: 'auto',
                    }}
                  >
                    <input
                      accept="image/jpeg,image/png"
                      className={classes.input}
                      id="identification_attachment_back"
                      type="file"
                      name="identification_attachment_back"
                      onChange={handleUploadClick}
                    />
                    <label htmlFor="identification_attachment_back">
                      <IconButton style={{ position: 'relative' }} component="span">
                        <AddAPhotoOutlinedIcon />
                      </IconButton>
                    </label>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                marginTop: '15px', marginLeft: '25px', width: '100%',
              }}
            >
              <Grid container spacing={3} style={{ marginTop: '25px', paddingLeft: '25px' }}>
                <Typography variant="h2" component="h3" style={{ marginBottom: '25px' }}>
                  Service Variants
                </Typography>
                <Autocomplete
                  id="professional-sv-select"
                  options={serviceVariantSorted}
                  groupBy={(option) => option.serviceName}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  defaultValue={serviceVariantSelected}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(_, newValue, __, details) => {
                    handleSelectServiceVariants(newValue, details);
                  }}
                  freeSolo
                  getOptionLabel={(option) => option.internal_name}
                  renderInput={(params) => <TextField {...params} label="Service Variant" variant="outlined" />}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.internal_name}
                    </>
                  )}
                />
              </Grid>
            </Box>
            <Box
              style={{
                marginTop: '15px', marginLeft: '25px', width: '100%',
              }}
            >
              <Grid container spacing={3}>
                <Box style={{ marginTop: '25px', paddingLeft: '25px' }}>
                  <Typography variant="h2" component="h3">
                    Working Areas
                  </Typography>
                </Box>
                <Grid item md={12} xs={12} justify="center">
                  <Box style={{}}>
                    <AreaSelectTree areas={areas} handelslected={handleAreaSlected} multiple />
                  </Box>
                </Grid>
                {(action === 'edit' ? mapIsLoad : true) && (
                <Grid spacing={3} container justify="center" style={{ marginTop: '25px', paddingLeft: '25px' }}>
                  <Grid item md={12} xs={12}>
                    <Box>
                      <CountrySelect
                        countries={countries}
                        handleSelected={handleSelectMapCountry}
                        editOn={false}
                        defaultareasSelected={countries[1]}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={10} md={10} xs={10} justify="center">
                    <Box style={{ marginBottom: '15px', textAlign: 'center' }}>
                      <ProfessionalMap
                        points={points}
                        center={center}
                        editMapOn={editMapOn}
                        polygonSelected={setPolygonSelected}
                        handlePointsAdd={handlePointsAdd}
                        handleMovePointer={handleMovePointer}
                        createNewPolygonOn={createNewPolygonOn}
                        newPoints={newPoints}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={2} md={2} xs={2}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      <Button onClick={() => { setEditMapOn(true); }}>
                        Edit Map
                      </Button>
                      <Button disabled={!editMapOn} onClick={() => { handleDeletePolygon(); }}>
                        Delete Polygon
                      </Button>
                      <Button disabled={!editMapOn} onClick={() => { setCreateNewPolygonOn(true); }}>
                        Create Polygon
                      </Button>
                      {createNewPolygonOn && (
                      <Button onClick={() => {
                        handleSaveNewPolygon();
                      }}
                      >
                        {saveNewPolygonLoading ? <CircularProgress size={25} /> : 'Save Polygon'}
                      </Button>
                      )}
                      <Button
                        disabled={!editMapOn}
                        onClick={() => {
                          setEditMapOn(false); setCreateNewPolygonOn(false); setPolygonSelected(null); setNewPoints([]);
                        }}
                      >
                        Cancel
                      </Button>
                      {createNewPolygonOn && (
                      <AreaListPoints
                        points={newPoints}
                        handleDeletePoint={handleDeleteNewPoint}
                        handleDeleteAllPoint={handleDeleteAllNewPoints}
                      />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                )}
              </Grid>
            </Box>
            <Box
              style={{
                marginTop: '15px', marginLeft: '25px', width: '100%', paddingLeft: '25px',
              }}
            >
              <Grid container spacing={3} style={{ marginTop: '25px' }}>
                <Box style={{ marginLeft: '25px' }}>
                  <Typography variant="h2" component="h3">
                    Availabilities
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', padding: '25px', width: '100%' }} boxShadow={3}>
                  <FormControl variant="outlined" style={{ margin: '0px 25px', width: '100%' }}>
                    <InputLabel id="dayOfWeek-select-label">Day of Week</InputLabel>
                    <Select
                      labelId="dayOfWeek-select-label"
                      id="dayOfWeek-select"
                      value={dayOfWeek}
                      onChange={(e) => setDayOfWeek(e.target.value)}
                      label="Day of Week"
                    >
                      {DAYS_OF_WEEK.map((d, i) => <MenuItem key={i} value={i}>{d}</MenuItem>)}
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined" style={{ margin: '0px 25px', width: '100%' }}>
                    <InputLabel id="satartTime-select-label">Start Time</InputLabel>
                    <Select
                      labelId="satartTime-select-label"
                      id="dayOfWeek-select"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      label="Start Time"
                    >
                      {TIMES.map((t, i) => <MenuItem key={`startTime_${i}`} value={t}>{t}</MenuItem>)}
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined" style={{ margin: '0px 25px', width: '100%' }}>
                    <InputLabel id="endTime-select-label">End Time</InputLabel>
                    <Select
                      labelId="endTime-select-label"
                      id="endTime-select"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      label="End Time"
                    >
                      {TIMES.map((t, i) => <MenuItem key={`endTime_${i}`} value={t}>{t}</MenuItem>)}
                    </Select>
                  </FormControl>

                  <Button color="primary" variant="contained" onClick={handleAddAvailability}>
                    Add
                  </Button>
                </Box>

                {is.not.empty(availabilities) && (
                <Box
                  style={{
                    display: 'flex', marginTop: '0px', flexWrap: 'wrap', padding: '25px', width: '100%',
                  }}
                  boxShadow={3}
                >
                  {availabilities.sort((a, b) => a.day_of_week - b.day_of_week).map((a, i) => {
                    const id = a.id ? a.id : a.viewId;
                    const type = a.id ? 'old' : 'new';
                    return (
                      <Card key={`availabilityCard_${i}`} style={{ margin: '15px' }}>
                        <CardHeader
                          action={(
                            <IconButton aria-label="settings" onClick={() => deleteAvailability(id, type)}>
                              <HighlightOffIcon />
                            </IconButton>
                      )}
                          title={DAYS_OF_WEEK[a.day_of_week - 1]}
                          subheader={`${a.end_time} / ${a.start_time}`}
                        />
                      </Card>
                    );
                  })}
                </Box>
                )}
              </Grid>
            </Box>
          </Grid>
        </CardContent>
        <Divider />
        {action === 'add' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            onClick={() => { handleLocalSave(); }}
            color="primary"
            variant="contained"
          >
            {saveLoading ? <CircularProgress color="secondary" /> : 'Save'}
          </Button>
        </Box>
        )}
      </Card>
    </>
  );
};

export default ProfessionalDetails;

const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const TIMES = [
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
];
