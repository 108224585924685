import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import axios from 'axios';

import CategoriesListResults from 'src/components/category/CategoriesListResult';
import CategoriesListToolbar from 'src/components/category/CategoriesToolBar';
import AuthService from 'src/services/authService';

const CategoriesList = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesAll, setCategoriesAll] = useState([]);

  const handlefilter = (filter) => {
    filter.length < 3
      ? setCategories(categoriesAll)
      : setCategories(categoriesAll.filter((category) => category.name.toLowerCase().match(filter.toLowerCase())));
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/category`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        setCategories(response.data);
        setCategoriesAll(response.data);
      });
  }, []);

  function handleDeleteCategory(value) {
    console.log(value);
    axios.delete(`${process.env.REACT_APP_MODERN_API_URL}/admin/category/${value}`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then(() => {
        setCategories(categories.filter((category) => category.id !== value));
      }).catch((e) => {
        console.log(e);
      });
  }
  return (
    <>
      <Helmet>
        <title> Categories | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Categories</Typography>
          </Breadcrumbs>
          <CategoriesListToolbar handlefilter={handlefilter} />
          <Box sx={{ pt: 3 }}>
            <CategoriesListResults categories={categories} handleDeleteCategory={handleDeleteCategory} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CategoriesList;
