import moment from 'moment';
import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import BookingPackEditModal from './BookingPackEditModal';
import AdminServices from '../../services/AdminServices';

const PacksPurchasedListResult = ({
  bookingsPacks, handleFilter, filters, isLoad, refresh,
}) => {
  const [bookingPackSelected, setBookingPackSelected] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (window.localStorage.getItem('userData')) {
      setUserData(JSON.parse(window.localStorage.getItem('userData')));
    }
  }, []);

  function handlePageFiltersChange(prop, value) {
    console.info(prop, value);
    handleFilter({ ...filters, [prop]: value });
  }

  const handleEditBookingPack = (values) => {
    setEditLoading(true);
    AdminServices.updatePackPurchased(values.id, {
      authorizer_user_id: userData?.user_id, payment_date: values?.payment_date, paymeny_status: values?.paymeny_status, user_email: values?.user_email ? values?.user_email.toLowerCase().trim() : null,
    })
      .then((resp) => { console.log(resp.data); setBookingPackSelected(null); })
      .catch((err) => console.log(err))
      .finally(() => setEditLoading(false));
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small">
            <BookingHead />
            <TableBody>
              {bookingsPacks.map((pack) => (
                <BookingPackBody key={pack.id} pack={pack} setBookingPackSelected={setBookingPackSelected} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        onPageChange={(_, page) => handlePageFiltersChange('page_number', page)}
        onRowsPerPageChange={(event) => handlePageFiltersChange('page_size', event.target.value)}
        page={filters.page_number}
        rowsPerPage={filters.page_size}
        rowsPerPageOptions={[20, 50, 100]}
        labelDisplayedRows={() => console.log('next page')}
        ActionsComponent={(props) => TablePaginationActions({ ...props, isLoad })}
      />
      {bookingPackSelected && (
        <BookingPackEditModal
          content={bookingPackSelected}
          handleClose={() => setBookingPackSelected(null)}
          open={bookingPackSelected}
          Agree={handleEditBookingPack}
          loading={editLoading}
        />
      )}
    </Card>
  );
};

function BookingHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        <TableCell align="center">
          Pack Purchased Id
        </TableCell>
        <TableCell align="center">
          Pack Name
        </TableCell>
        <TableCell align="center">
          Country
        </TableCell>
        <TableCell align="center">
          Created date
        </TableCell>
        <TableCell align="center">
          Payment date
        </TableCell>
        <TableCell align="center">
          Status
        </TableCell>
        <TableCell align="center">
          Sessions
        </TableCell>
        <TableCell align="center">
          Used Sessions
        </TableCell>
        <TableCell>
          User name
        </TableCell>
        <TableCell>
          User email
        </TableCell>
        <TableCell>
          User phone
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

function BookingPackBody({ pack, setBookingPackSelected }) {
  const [open, setOpen] = useState(false);

  const totalSessions = pack.bookingPackItems.reduce((acc, next) => {
    const result = acc + (Number(next.sessions) || 0);
    return result;
  }, 0);

  const totalUsedSessions = pack.bookingPackItems.reduce((acc, next) => {
    const result = acc + (Number(next.used_sessions) || 0);
    return result;
  }, 0);

  return (

    <>
      <TableRow
        hover
        key={pack.id}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {pack.bookingPackItems.length > 0 ? open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          {pack.id}
        </TableCell>
        <TableCell align="center">
          {pack.pack.packAreas ? pack.pack.packAreas[0].name : pack?.internal_name}
        </TableCell>
        <TableCell align="center">
          {pack?.country}
        </TableCell>
        <TableCell align="center">
          {pack.created_date ? moment(pack.created_date).utc().format('YYYY-MM-DD HH:mm') : '-'}
        </TableCell>
        <TableCell align="center">
          {pack.payment_date ? moment(pack.payment_date).utc().format('YYYY-MM-DD HH:mm') : '-'}
        </TableCell>
        <TableCell align="center">
          {pack?.paymeny_status}
        </TableCell>
        <TableCell align="center">
          {totalSessions}
        </TableCell>
        <TableCell align="center">
          {totalUsedSessions}
        </TableCell>
        <TableCell>
          {`${pack.shoppingCart.user.name} ${pack.shoppingCart.user.lastname}`}
        </TableCell>
        <TableCell>
          {pack.shoppingCart.user.email}
        </TableCell>
        <TableCell>
          {pack.shoppingCart.user?.phone}
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            onClick={() => setBookingPackSelected(pack)}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>

      {open
        ? (
          <TableRow>
            <TableCell colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
                <Box margin={1}>
                  {pack.bookingPackItems.map((packItem) => (
                    <Table size="small" key={`packItem_${packItem.id}`} aria-label="purchases">
                      {packItem.bookings.length > 0 && (
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              Pack Item Id
                            </TableCell>
                            <TableCell align="center">
                              Booking Id
                            </TableCell>
                            <TableCell align="center">
                              Booking Date
                            </TableCell>
                            <TableCell align="center">
                              SVA
                            </TableCell>
                            <TableCell align="center">
                              Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody>
                        {packItem.bookings.length > 0 && packItem.bookings.map((booking) => (
                          <TableRow key={`book_${booking.id}`}>
                            <TableCell align="center">
                              {packItem.id}
                            </TableCell>
                            <TableCell align="center">
                              {booking.id}
                            </TableCell>
                            <TableCell align="center">
                              {moment(booking.date).utc().format('YYYY-MM-DD HH:mm')}
                            </TableCell>
                            <TableCell align="center">
                              {booking.serviceVariantArea?.name ? booking.serviceVariantArea?.name : '-'}
                            </TableCell>
                            <TableCell align="center">
                              {booking.status}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ))}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )
        : null}
    </>
  );
}

function TablePaginationActions(props) {
  const {
    page, onPageChange, isLoad,
  } = props;
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!isLoad && <CircularProgress size={20} />}
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

export default PacksPurchasedListResult;
