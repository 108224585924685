import { useEffect, useRef, useState } from 'react';
import {
  Marker, GoogleMap, LoadScript, StandaloneSearchBox,
} from '@react-google-maps/api';
import { useParams } from 'react-router';
import AdminServices from 'src/services/AdminServices';
import {
  Button, ButtonGroup, Card, CardContent, Checkbox, FormControlLabel, Grid, TextField, Typography,
} from '@material-ui/core';

const containerStyle = {
  width: '100%',
  height: '400px',
};

function CustomerAddressEdit() {
  const { customerId, addressId } = useParams();
  const directionRef = useRef(null);
  const [address, setAddress] = useState({});
  const [savedAddress, setSavedAddress] = useState(false);
  const { coordinate } = address;
  const [googleInfo, setGoogleInfo] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchAddress();
  }, []);

  function fetchAddress() {
    return AdminServices.getUserAddresses(customerId)
      .then((response) => {
        const selectedAddress = response.data.find((a) => a.id === Number(addressId));
        console.log('address', selectedAddress);
        if (selectedAddress) {
          setSearch(selectedAddress.formatted_address);
          setSavedAddress(selectedAddress);
          setAddress(selectedAddress);
        }
      });
  }

  const onLoad = (ref) => {
    directionRef.current = ref;
  };

  function onPlacesChanged() {
    const addressFound = directionRef.current.getPlaces()[0];
    const lat = addressFound.geometry.location.lat();
    const lng = addressFound.geometry.location.lng();
    const updated = {
      address: addressFound.name,
      formatted_address: addressFound.formatted_address,
      country: (addressFound.address_components.find((item) => item.types.includes('country')) || {}).long_name || null,
      zip_code: (addressFound.address_components.find(
        (item) => item.types.includes('postal_code')) || {}).long_name || null,
      coordinate: {
        lat,
        lng,
      },
      has_parking: false,
      extra_info: '',
    };
    const googleObj = {
      results: [
        {
          geometry: {
            location: {
              lat,
              lng,
            },
          },
          formatted_address: addressFound.formatted_address,
          address_components: addressFound.address_components,
        },
      ],
    };
    setSearch(updated.formatted_address);
    setGoogleInfo(googleObj);
    onChange(updated);
  }

  function onChange(data) {
    setDirty(true);
    setAddress({ ...address, ...data });
  }

  function handleSave() {
    const data = {
      id: address.id,
      has_parking: address.has_parking || false,
      extra_info: address.extra_info,
      address: address.address,
      default: address.default,
      coordinate: address.coordinate,
      ...(Object.keys(googleInfo).length > 0 && { google_data: googleInfo }),
    };
    return AdminServices.updateUserAddress(customerId, addressId, data)
      .then((res) => {
        setSavedAddress(res.data);
        setAddress(res.data);
        setSearch(res.data.formatted_address);
        setDirty(false);
        alert('Success');
      });
  }

  function onCancel() {
    setAddress(savedAddress);
    setDirty(false);
    setSearch(savedAddress.formatted_address);
  }

  function onMapLoad() {
    setIsLoad(true);
  }

  return (
    <>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['places']}>
        <GoogleMap
          id="marker-example"
          onLoad={onMapLoad}
          mapContainerStyle={containerStyle}
          center={coordinate || { lat: -33.44842152938572, lng: -70.66407958679974 }}
          zoom={coordinate ? 16 : 12}
        >
          {coordinate && (
          <Marker
            position={coordinate}
          />
          )}
        </GoogleMap>
      </LoadScript>
      <Card>
        <CardContent>
          {isLoad
          && (
          <>
            <Typography sx={{ mb: 3 }}>
              Información de la dirección
            </Typography>
            <StandaloneSearchBox
              onLoad={onLoad}
              onPlacesChanged={
        onPlacesChanged
      }
            >
              <TextField
                type="text"
                label="Address"
                placeholder="Search direction"
                value={search || ''}
                onChange={(event) => setSearch(event.target.value)}
                style={{
                  boxSizing: 'border-box',
                  width: '100%',
                  textOverflow: 'ellipses',
                  marginBottom: '32px',
                }}
              />
            </StandaloneSearchBox>
            <Grid container spacing={3}>
              <Grid item md={8} xs={8}>
                <TextField
                  fullWidth
                  label="Extra info"
                  onChange={(event) => onChange({ extra_info: event.target.value })}
                  required
                  value={address.extra_info || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={address.has_parking}
                      name="has_parking"
                      onChange={(e) => onChange({ has_parking: e.target.checked })}
                    />
)}
                  label="Parking"
                />
              </Grid>
            </Grid>
            {dirty
            && (
              <ButtonGroup sx={{ mt: 3 }}>
                <Button onClick={handleSave} variant="contained">
                  Guardar
                </Button>
                <Button onClick={onCancel}>
                  Cancelar
                </Button>
              </ButtonGroup>
            )}
          </>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default CustomerAddressEdit;
