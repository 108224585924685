import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';

const SeviceVariantListResults = ({
  servicesVariant, handleDeleteServiceVariantArea, handleDeleteServiceVariant, handleCloneServiceVariantArea,
}) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [currentViews, setViews] = useState([0, 20]);

  const handleLimitChange = (event) => {
    setViews([currentViews[0], currentViews[0] + event.target.value]);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.info(event.target.value);

    setPage(newPage);
  };

  const handleNextButton = () => {
    setViews([currentViews[1], currentViews[1] + limit]);
  };

  const handleBackButton = () => {
    if (currentViews[0] - limit < 0) {
      setViews([0, limit]);
      return;
    }
    setViews([currentViews[0] - limit, currentViews[1] - limit]);
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow
                hover
              >
                <TableCell />
                <TableCell>
                  Variant Id
                </TableCell>
                <TableCell>
                  Variant Name
                </TableCell>
                <TableCell align="center">
                  Order
                </TableCell>
                <TableCell align="center">
                  Options
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                servicesVariant.slice(...currentViews).sort((a, b) => a.order - b.order).map((variant, i) => (
                  <ServiceVariantsRow
                    key={`variant_index_${i}`}
                    variant={variant}
                    handleDeleteServiceVariantArea={handleDeleteServiceVariantArea}
                    handleDeleteServiceVariant={handleDeleteServiceVariant}
                    handleCloneServiceVariantArea={handleCloneServiceVariantArea}
                  />
                ))
              }
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={servicesVariant.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        labelDisplayedRows={() => `${currentViews[0]} - ${currentViews[1] > servicesVariant.length
          ? servicesVariant.length : currentViews[1]} of ${servicesVariant.length}`}
        rowsPerPageOptions={[20, 50, 100]}
        backIconButtonProps={{ onClick: handleBackButton, disabled: currentViews[0] === 0 }}
        nextIconButtonProps={{ onClick: handleNextButton }}
      />
    </Card>
  );
};

function ServiceVariantsRow({
  variant, handleDeleteServiceVariantArea, handleDeleteServiceVariant, handleCloneServiceVariantArea,
}) {
  const [open, setOpen] = useState(false);
  const [openAddons, setOpenAddons] = useState(false);
  const [openAreas, setOpenAreas] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {(variant.serviceVariantAreas.length > 0 || variant.addOns.length > 0)
              ? open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
          </IconButton>
        </TableCell>
        <TableCell>
          {variant.id}
        </TableCell>
        <TableCell>
          {variant.internal_name}
        </TableCell>
        <TableCell align="center">
          {variant.order}
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              value={1}
              component={RouterLink}
              to={`/app/service/${variant.service.id}/variant/${variant.id}/edit`}
              sx={{ mx: 1 }}
              color="primary"
              variant="contained"
            >
              Edit Variant
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => { handleDeleteServiceVariant(variant.service.id, variant.id); }}
            >
              Remove Variant
            </Button>
            <Button
              sx={{ mx: 1 }}
              color="success"
              variant="contained"
              component={RouterLink}
              value={variant.id}
              to={`/app/variant/${variant.id}/variant-area/add`}

            >
              Add Variant Area
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      {variant.serviceVariantAreas.length > 0
        ? (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>

                {variant.serviceVariantAreas.length > 0 && (
                <Box style={{ margin: '12px 0px' }}>
                  <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpenAreas((currentValue) => !currentValue)}
                    >
                      {openAreas ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Typography variant="h6" gutterBottom component="div">
                      Variant Areas
                    </Typography>
                  </Box>
                  <Collapse in={openAreas} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Variant Area Id
                            </TableCell>
                            <TableCell>
                              Variant Area Name
                            </TableCell>
                            <TableCell>
                              cost
                            </TableCell>
                            <TableCell>
                              Price
                            </TableCell>
                            <TableCell>
                              Area Name
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                          variant.serviceVariantAreas.sort((a, b) => {
                            const textA = a.area?.name.toUpperCase();
                            const textB = b.area?.name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                          }).map((va) => (
                            <VariantAreasRow
                              variantId={variant.id}
                              key={va.id + variant.name}
                              va={va}
                              handleDeleteServiceVariantArea={handleDeleteServiceVariantArea}
                              handleCloneServiceVariantArea={handleCloneServiceVariantArea}
                            />
                          ))
                        }
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </Box>
                )}

                {variant.addOns.length > 0 && (
                <Box style={{ margin: '12px 0px' }}>
                  <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpenAddons((currentValue) => !currentValue)}
                    >
                      {openAddons ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Typography variant="h6" gutterBottom component="div">
                      Addons
                    </Typography>
                  </Box>
                  <Collapse in={openAddons} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Addon Id
                            </TableCell>
                            <TableCell>
                              Addons Name
                            </TableCell>
                            <TableCell>
                              Duration
                            </TableCell>
                            <TableCell>
                              Add-on Area
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                          variant.addOns.map((ad) => (
                            <VariantAdonsRows
                              key={ad.id + ad.internal_name}
                              ad={ad}
                              variantId={variant.id}
                              serviceId={variant.service.id}
                            />
                          )).sort((a, b) => a.order - b.order)
                        }
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </Box>
                )}

              </Collapse>
            </TableCell>
          </TableRow>
        )
        : null}
    </>
  );
}

function VariantAreasRow({
  va, handleDeleteServiceVariantArea, handleCloneServiceVariantArea, variantId,
}) {
  return (
    <TableRow>
      <TableCell>
        {va.id}
      </TableCell>
      <TableCell>
        {va.name}
      </TableCell>
      <TableCell>
        {va.cost}
      </TableCell>
      <TableCell>
        {va.price}
      </TableCell>
      <TableCell>
        {va.area ? va.area.name : 'Not Area Asociated'}
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            value={va.id}
            component={RouterLink}
            to={`/app/variantarea/${va.id}/edit`}
            sx={{ mx: 1 }}
            size="small"
            color="error"
            variant="contained"
          >
            Edit Variant Area
          </Button>
          <Button
            value={va.id}
            sx={{ mx: 1 }}
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => { handleCloneServiceVariantArea(va.id, variantId); }}
          >
            Clone Variant Area
          </Button>
          <Button
            color="warning"
            variant="contained"
            value={va.id}
            onClick={() => { handleDeleteServiceVariantArea(va.id); }}
          >
            Remove Variant Area
          </Button>
          <Button
            color="success"
            sx={{ mx: 1 }}
            variant="contained"
            component={RouterLink}
            to={`/app/variantarea/${va.id}/pricing-period/add?sva=${va.name}`}
            value={va.id}
          >
            Add Pricing Period
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
}

function VariantAdonsRows({ ad }) {
  return (
    <TableRow>
      <TableCell>
        {ad.id}
      </TableCell>
      <TableCell>
        {ad.internal_name}
      </TableCell>
      <TableCell>
        {ad.duration}
      </TableCell>
      <TableCell>
        <Tooltip title={getTooltipText(ad.addOnAreas)} arrow>
          <Box style={{
            backgroundColor: '#484848', borderRadius: '50%', width: '20px', height: '20px', textAlign: 'center',
          }}
          >
            <span style={{ color: '#FFF', margin: '0 auto' }}>{ad.addOnAreas.length}</span>
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            color="success"
            variant="contained"
            value={2}
          // todo
          // onClick={handleDelete}
          >
            Remove Addon
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
}

const getTooltipText = (addonArea) => (
  <Box>
    <List style={{ minWidth: '300px' }}>
      {addonArea && addonArea.map((a) => (
        <ListItem key={`addonArea_${a?.id}`}>
          Name:
          {a?.name}
          /Cost:
          {a.cost}
          /Price:
          {a.price}
        </ListItem>
      ))}
    </List>
  </Box>
);

export default SeviceVariantListResults;
