import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Box,
  Button,
  Grid,
  Card,
  FormControl,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  CircularProgress,
  Autocomplete,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import AdminServices from 'src/services/AdminServices';
import moment from 'moment';
import { debounce } from 'lodash';

const BOOKING_STATUS = [
  {
    value: 'searching',
    label: 'Searching (Esperando al Ameizer)',
  },
  {
    value: 'created',
    label: 'Created (No pagado)',
  },
  {
    value: 'accepted',
    label: 'Accepted (Aceptado por el Ameizer)',
  },
  {
    value: 'ameizer_paid',
    label: 'Paid (Pagado al Ameizer)',
  },
  {
    value: 'on_my_way',
    label: 'On the way (Ameizer en camino al servicio)',
  },
  {
    value: 'arrived_to_destination',
    label: 'Arrived to destination (Ameizer llego al servicio)',
  },
  {
    value: 'done',
    label: 'Done (Realizado)',
  },
  {
    value: 'cancelled',
    label: 'Cancelled (Cancelado)',
  },
  {
    value: 'refunded',
    label: 'Refunded (Devolución realizada)',
  },
];

const BookingListToolbar = ({
  handleFilter, handleClearFilter, refresh, isLoad, filters, setShowall, showall,
}) => {
  const [values, setValues] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const fromDateInput = useRef(null);
  const toDateInput = useRef(null);
  const [selectedStatus, setStatus] = useState([]);
  const request = debounce((value) => handleFilter(value), 500);

  const debounceRequest = useCallback((value) => request(value), []);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = async () => {
    try {
      const response = await AdminServices.getCountries();
      setCountryOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlechange = (prop, value) => {
    setValues({
      ...values,
      [prop]: value,
    });
    debounceRequest({ ...filters, [prop]: value });
  };

  const clearFilter = () => {
    fromDateInput.current.value = '';
    toDateInput.current.value = '';

    setStatus([]);
    setValues({});
    handleClearFilter();
  };

  function handleStatus(states) {
    setValues({
      ...values,
      status: states ? states.map((item) => item.value) : null,
    });
    debounceRequest({ ...filters, status: states ? states.map((item) => item.value) : null });
    setStatus(states || []);
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="From date"
              name="from"
              id="date-datetime"
              type="date"
              inputRef={fromDateInput}
              value={'from' in values ? moment(values.from).utc().format('YYYY-MM-DD') : null}
              onChange={(event) => handlechange(
                'from', moment(event.target.value).utc().startOf('day').toISOString())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search FROM date"
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="To date"
              name="to"
              id="date-datetime"
              type="date"
              inputRef={toDateInput}
              value={'to' in values ? moment(values.to).utc().format('YYYY-MM-DD') : null}
              onChange={(event) => handlechange(
                'to', moment(event.target.value).utc().endOf('day').toISOString(),
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search TO date"
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              id="booking-status"
              options={BOOKING_STATUS}
              fullWidth
              multiple
              disableCloseOnSelect
              value={selectedStatus || []}
              getOptionSelected={(option, value) => option.value === value.value}
              onChange={(_, value) => {
                handleStatus(value.length > 0 ? value : null);
              }}
              freeSolo
              getOptionLabel={(option) => option.label}
              renderInput={(params) => {
                return <TextField {...params} label="Status" variant="outlined" />;
              }}
              renderOption={(props, option) => (
                <MenuItem key={option.status} value={option.status} {...props}>
                  {option.label}
                </MenuItem>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="mutiple-country-id-label">Country</InputLabel>
              <Select
                key={values?.country}
                defaultValue={null}
                labelId="mutiple-country-id-label"
                id="mutiple-country-id"
                value={values?.country}
                onChange={(event) => handlechange('country', event.target.value)}
                label="Country"
                name="country"
              >
                <MenuItem key="country_null" value={null}>
                  NONE
                </MenuItem>
                {countryOptions.map((c, i) => (
                  <MenuItem key={`country_${i}`} value={c.name}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container md={12} xs={12} style={{ marginTop: '24px' }}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {!isLoad && <CircularProgress />}
              <Button
                sx={{ mx: 1 }}
                color="primary"
                onClick={() => {
                  setShowall(!showall);
                }}
                variant="outlined"
              >
                {showall ? 'Hide bookings with invalid date' : 'Show all'}
              </Button>
              <Button
                sx={{ mx: 1 }}
                color="primary"
                onClick={clearFilter}
                variant="outlined"
              >
                Clear Filter
              </Button>
              <Button
                sx={{ mx: 1 }}
                color="primary"
                onClick={refresh}
                variant="outlined"
              >
                Refresh
              </Button>
            </Box>
          </Grid>

          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              value={'user_email' in values ? values.user_email : ''}
              onChange={(e) => handlechange('user_email', e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search User"
              variant="outlined"
            />
          </Grid>

        </Grid>
      </CardContent>
    </Card>

  );
};

export default BookingListToolbar;
