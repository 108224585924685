import {
  Box, Card, Toolbar, Typography,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

function WalletCountryAmount({ totals }) {
  return (
    <Card sx={{ mb: 2 }}>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h4" id="tableTitle" component="div" sx={{ mt: 2 }}>
          Total credits by country
        </Typography>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {totals.map((total, i) => {
          return (
            <Toolbar key={`total_count_${i}`}>
              <Typography variant="h5" id="tableTitle" component="div" sx={{ mr: 1 }}>
                {`${total.currency}:`}
              </Typography>
              <Box display="flex" flex="1 1 auto" justifyContent="flex-end">
                <NumberFormat
                  value={total.total_balance}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                />
              </Box>
            </Toolbar>
          );
        })}
      </Box>
    </Card>
  );
}

export default WalletCountryAmount;
