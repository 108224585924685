import { useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import moment from 'moment';
import { debounce } from 'lodash';
import { NavLink as RouterLink } from 'react-router-dom';

const STATUS = [
  {
    value: 'active,paused',
    label: 'NONE',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'paused',
    label: 'Paused',
  },
];

function BannersToolbar({
  handleFiltersChange, filters, handleClearFilters, ...props
}) {
  const [values, setValues] = useState({});
  const fromDateInput = useRef(null);
  const toDateInput = useRef(null);
  const sources = { ...filters, ...values };
  const request = debounce((value) => handleFiltersChange(value), 500);

  const debounceRequest = useCallback((value) => request(value), []);

  const handlechange = (filter) => {
    setValues({ ...values, ...filter });
    debounceRequest({ ...filters, ...filter });
  };

  function handleClear() {
    setValues({});
    handleClearFilters();
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          component={RouterLink}
          to="/app/banners/add"
          color="primary"
          variant="contained"
        >
          Add banner
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextField
                  fullWidth
                  label="From date"
                  name="from"
                  id="date-datetime"
                  type="date"
                  inputRef={fromDateInput}
                  value={moment(sources?.from).format('YYYY-MM-DD')}
                  onChange={(event) => handlechange({ [event.target.name]: event.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search from date"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  label="To date"
                  name="to"
                  id="date-datetime"
                  type="date"
                  inputRef={toDateInput}
                  value={moment(sources?.to).format('YYYY-MM-DD')}
                  onChange={(event) => handlechange({ [event.target.name]: event.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search to date"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="mutiple-status-id-label">Status</InputLabel>
                  <Select
                    key={sources?.status}
                    labelId="mutiple-status-id-label"
                    id="mutiple-status-id"
                    value={sources?.status.join(',')}
                    defaultValue={null}
                    onChange={(event) => handlechange({ [event.target.name]: event.target.value.split(',') })}
                    label="Status"
                    name="status"
                  >
                    {STATUS.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel id="mutiple-sections-id-label">Sections</InputLabel>
                  <Select
                    key={sources?.sections}
                    labelId="mutiple-sections-id-label"
                    id="mutiple-sections-id"
                    value={sources?.sections.join(',')}
                    defaultValue={null}
                    onChange={(event) => handlechange({ [event.target.name]: event.target.value.split(',') })}
                    label="Sections"
                    name="sections"
                  >
                    <MenuItem key="none" value="home_images,home_text">
                      NONE
                    </MenuItem>
                    <MenuItem key="home_images" value="home_images">
                      Home Images
                    </MenuItem>
                    <MenuItem key="home_text" value="home_text">
                      Home Text
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <Box style={{
                  display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', height: '100%',
                }}
                >
                  {props.isLoading && <Box><CircularProgress /></Box>}
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    onClick={handleClear}
                    variant="outlined"
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default BannersToolbar;
