import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Box, Container, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import AdminServices from 'src/services/AdminServices';
import ConfigValues from 'src/components/configValues/ConfigValues';
import EditConfigValueModal from 'src/components/configValues/EditConfigValueModal';
import ConfigValuesToolbar from 'src/components/configValues/ConfigValuesToolbar';

const DEFAULT_FILTERS = {
  type: null,
  country_name: null,
  category: null,
};

const ConfigValueList = () => {
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
  const [configValues, setConfigValues] = useState([]);
  const [configValueToEdit, setConfigValueToEdit] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getConfigValuesList(filters);
  }, [filters]);

  const getConfigValuesList = async (filters) => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getConfigValues(filters);
      setConfigValues(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditConfigValue = async (configValueId, value) => {
    setEditLoading(true);
    const valueUpdated = {
      configValueId,
      value,
    };
    try {
      const response = await AdminServices.editConfigValue(configValueId, valueUpdated);
      getConfigValuesList();
    } catch (error) {
      console.log(error);
    } finally {
      setEditLoading(false);
      handleCloseEditModal();
    }
  };

  const handleCloseEditModal = () => {
    setConfigValueToEdit(null);
  };

  const handleFilter = (filterSelected) => {
    setFilters({ ...filters, ...filterSelected });
  };

  const handleClearFilter = () => {
    setFilters({
      type: null,
      country_name: null,
      category: null,
    });
  };

  return (
    <>
      <Helmet>
        <title> Config values | Ameiz </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Config values</Typography>
          </Breadcrumbs>
          <ConfigValuesToolbar
            handleFilter={handleFilter}
            filters={filters}
            handleClearFilter={handleClearFilter}
          />
          <Box sx={{ pt: 1 }}>
            {isLoading && <Box style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></Box>}
            {!isLoading && (
              <ConfigValues
                configValues={configValues}
                handleEditConfigValue={handleEditConfigValue}
                setConfigValueToEdit={setConfigValueToEdit}
              />
            )}
          </Box>
          <EditConfigValueModal
            content={configValueToEdit}
            open={configValueToEdit}
            handleClose={handleCloseEditModal}
            Agree={handleEditConfigValue}
            loading={editLoading}
          />
        </Container>
      </Box>
    </>
  );
};

export default ConfigValueList;
