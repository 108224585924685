import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import BookingListToolbar from 'src/components/booking/BookingToolBar';
import BookingListResult from 'src/components/booking/BookingListResult';
import AdminServices from 'src/services/AdminServices';

const DEFAULT_FILTERS = {
  from: null,
  to: null,
  page_number: 1,
  page_size: 20,
  country: null,
  showall: null,
  status: null,
  user_email: '',
};

const BookingList = () => {
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
  const [bookings, setBookings] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [showall, setShowall] = useState(false);

  useEffect(() => {
    fetchBookings(filters);
  }, [filters]);

  function fetchBookings(filters) {
    setIsLoad(false);
    return AdminServices.getBookings(filters)
      .then((res) => {
        setBookings(res.data);
        setIsLoad(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoad(true);
      });
  }

  const handleFilter = (filterSelected) => {
    setFilters({ ...filters, ...filterSelected });
  };

  const handleClearFilter = () => {
    setFilters({
      ...filters,
      from: null,
      to: null,
      country: null,
      showall: null,
      status: null,
      user_email: '',
    });
  };

  return (
    <>
      <Helmet>
        <title> Bookings | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>

          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Bookings</Typography>
          </Breadcrumbs>

          <BookingListToolbar
            setShowall={setShowall}
            showall={showall}
            handleFilter={handleFilter}
            handleClearFilter={handleClearFilter}
            refresh={() => fetchBookings(filters)}
            isLoad={isLoad}
            filters={filters}
          />
          <Box sx={{ pt: 3 }}>
            <BookingListResult
              handleFilter={handleFilter}
              isLoad={isLoad}
              filters={filters}
              bookings={showall ? bookings : bookings.filter((b) => b.date)}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BookingList;
