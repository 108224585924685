import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AuthService from 'src/services/authService';
import { useNavigate } from 'react-router';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import CategoryDetails from 'src/components/category/CategoriesDetails';
import AdminServices from 'src/services/AdminServices';

function CategoryEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const [defaultValue, setDefaultValue] = useState({});

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/category/${id}`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        setDefaultValue(response.data);
        console.log(response.data);
        setIsLoad(true);
      });
  }, []);

  function handleSave(details) {
    setIsSaving(true);

    const categoryUpdated = {
      ...details,
      description: details.description || null,
    };

    return AdminServices.updateCAtegory(id, categoryUpdated)
      .then(() => {
        setIsSaving(false);
        alert('success');
        navigate('/app/categories');
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
        alert('error');
      });
  }

  return (
    <>
      <Helmet>
        <title>Add Categorya </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/categories">
              Categories
            </Link>
            <Typography color="textPrimary">Edit category</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {isSaving ? <CircularProgress /> : isLoad ? (
                <CategoryDetails
                  handleSave={handleSave}
                  defaultValue={defaultValue}
                  action="edit"
                />
              ) : <></>}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CategoryEdit;
