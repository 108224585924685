import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState } from 'react';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import DeleteModal from 'src/components/comon/Alert';

const DELETE_MODAL_TITLE = 'Do you want to remove this addon ?';

const AreaListResults = ({ areas, handleDeleteArea, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [areaToDelete, setAreaToDelete] = useState(null);

  const handleDelete = (areaId) => {
    setOpen(true);
    setAreaToDelete(areaId);
  };

  function handleAcept() {
    setOpen(false);
    handleDeleteArea(areaToDelete);
  }

  const handleClose = () => {
    setOpen(false);
    setAreaToDelete(null);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Id
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Cost
                </TableCell>
                <TableCell>
                  Operative
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    Options
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {areas.map((Area) => (
                <TableRow
                  hover
                  key={Area.id}
                >
                  <TableCell>
                    {Area.id}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {Area.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {Area.transport_price}
                  </TableCell>

                  <TableCell>
                    {Area.active ? 'True' : 'False'}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        value={Area.id}
                        component={RouterLink}
                        to={`/app/areas/edit/${Area.id}`}
                        sx={{ mx: 1 }}
                        color="primary"
                        variant="contained"
                      >
                        Edit Poligon
                      </Button>
                      <Button
                        color="warning"
                        variant="contained"
                        onClick={() => { handleDelete(Area.id); }}
                      >
                        Remove Poligon
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <DeleteModal
        title={DELETE_MODAL_TITLE}
        content={`ID: ${areaToDelete}`}
        handleClose={handleClose}
        open={open}
        Agree={handleAcept}
        loading={false}
      />
    </Card>
  );
};

AreaListResults.propTypes = {
  areas: PropTypes.array.isRequired,
};

export default AreaListResults;
