import {
  Box, Breadcrumbs, Container, Typography, Link, Dialog, DialogContent,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReviewDetails from 'src/components/Reviews/ReviewDetails';
import ReviewsListResult from 'src/components/Reviews/ReviewsListResult';
import ReviewsToolbar from 'src/components/Reviews/ReviewsToolbar';
import AdminServices from 'src/services/AdminServices';

const DEFAULT_FILTERS = {
  page_number: 1,
  page_size: 20,
  email: '',
  booking_id: null,
};

function ReviewsList() {
  const [reviews, setReviews] = useState([]);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
  const [loading, setLoading] = useState(false);
  const [selectedReview, setSelected] = useState(false);

  useEffect(() => {
    fetchReviews(filters);
  }, [filters]);

  function fetchReviews(filters) {
    setLoading(true);
    AdminServices.getReviews(filters)
      .then((response) => {
        setReviews(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  const handleFilter = (filterSelected) => {
    setFilters({ ...filters, ...filterSelected });
  };

  const handleClearFilter = () => {
    setFilters({
      ...filters,
      email: '',
      booking_id: null,
    });
  };

  function handleCloseEditModal() {
    setSelected(false);
    fetchReviews(filters);
  }

  return (
    <>
      <Helmet>
        <title>Reviews | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Reviews</Typography>
          </Breadcrumbs>
          <ReviewsToolbar loading={loading} handlefilter={handleFilter} handleclearfilter={handleClearFilter} />
          <ReviewsListResult
            reviews={reviews}
            handleFilter={handleFilter}
            filters={filters}
            setSelected={setSelected}
            loading={loading}
          />
          <EditModal
            content={selectedReview}
            handleClose={handleCloseEditModal}
            open={selectedReview}
          />
        </Container>
      </Box>
    </>
  );
}

const EditModal = ({
  content, handleClose, open,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <ReviewDetails review={content} onclose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default ReviewsList;
