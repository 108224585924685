import {
  Box,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Typography,
  Grid,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Briefcase as BriefcaseIcon,
  BookOpen,
  Globe,
  File,
  Gift,
  User,
  CreditCard,
  Heart,
  MessageCircle,
  DollarSign,
  MapPin,
  MessageSquare,
  Package,
} from 'react-feather';
import { NavLink as RouterLink } from 'react-router-dom';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

function Home() {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (window.localStorage.getItem('userData')) {
      setUserData(JSON.parse(window.localStorage.getItem('userData')));
    }
  }, []);

  const items = [
    {
      href: '/app/addons',
      icon: BookOpen,
      title: 'Addons',
    },
    {
      href: '/app/bookings',
      icon: BookOpen,
      title: 'Bookings',
    },
    {
      href: '/app/reviews',
      icon: MessageSquare,
      title: 'Reviews',
    },
    {
      href: '/app/service/variant',
      icon: File,
      title: 'Service Variants',
    },
    {
      href: '/app/packs',
      icon: Package,
      title: 'Session Packs',
    },
    {
      href: '/app/booking-packs',
      icon: Package,
      title: 'Booking Packs',
    },
    {
      href: '/app/professionals',
      icon: UsersIcon,
      title: 'Professionals',
    },
    {
      href: '/app/professional/tracking',
      icon: MapPin,
      title: 'Professionals Tracking',
    },
    {
      href: '/app/service',
      icon: BarChartIcon,
      title: 'Services',
    },
    {
      href: '/app/categories',
      icon: BriefcaseIcon,
      title: 'Categories',
    },
    {
      href: '/app/areas',
      icon: Globe,
      title: 'Areas',
    },
    {
      href: '/404',
      icon: AlertCircleIcon,
      title: 'Error',
    },
    {
      href: '/app/promocodes',
      icon: Gift,
      title: 'Promocodes',
    },
    {
      href: '/app/customers',
      icon: User,
      title: 'Customers',
    },
    {
      href: '/app/settlements',
      icon: DollarSign,
      title: 'Payments',
    },
    {
      href: '/app/banners',
      icon: Heart,
      title: 'Banners',
    },
    {
      href: '/app/conversations',
      icon: MessageCircle,
      title: 'Conversations',
    },
    {
      href: '/app/wallets',
      icon: CreditCard,
      title: 'Wallets',
    },
    {
      href: '/app/config-values',
      icon: SettingsOutlinedIcon,
      title: 'Config values',
    },
  ];

  return (
    <Box sx={{
      height: '100%', width: '100%', alignItems: 'center', display: 'flex',
    }}
    >
      <Card style={{
        maxWidth: '1200px', margin: 'auto', padding: '20px', textAlign: 'center',
      }}
      >
        <CardContent>
          <Typography variant="h2">
            {`Hello${`, ${userData.name || 'there'}`} ${userData.lastname || ''}!`}
          </Typography>
          <Typography variant="h3" color="primary" style={{ marginBottom: '10px' }}>
            Available sections
          </Typography>
          <Divider />
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box sx={{
                maxHeight: '600px',
                overflowY: 'auto',
                marginTop: '10px',
              }}
              >
                <Box sx={{
                  display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'flex-start',
                }}
                >
                  {items.slice(0, 10).map((item) => {
                    const { icon: Icon } = item;
                    return (
                      <MenuItem
                        component={RouterLink}
                        to={item.href}
                        style={{ marginTop: '10px', justifyContent: 'center' }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Icon />
                          <Typography variant="button" style={{ marginLeft: '10px' }}>
                            {item.title}
                          </Typography>
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={{
                maxHeight: '600px',
                overflowY: 'auto',
                marginTop: '10px',
              }}
              >
                <Box sx={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                }}
                >
                  {items.slice(10).map((item) => {
                    const { icon: Icon } = item;
                    return (
                      <MenuItem
                        component={RouterLink}
                        to={item.href}
                        style={{ marginTop: '10px', justifyContent: 'center' }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Icon />
                          <Typography variant="button" style={{ marginLeft: '10px' }}>
                            {item.title}
                          </Typography>
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Home;
