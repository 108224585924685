import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import AuthService from 'src/services/authService';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import AreaDetails from 'src/components/area/add/AreaDetail';
import AreaPoligonMap from 'src/components/area/AreaPoligonMap';
import AreaListPoints from 'src/components/area/AreaListPoints';

import CountrySelect from 'src/components/comon/CountrySelect';
import AdminServices from 'src/services/AdminServices';

function AreaAdd() {
  const navigate = useNavigate();
  const [points, setPoints] = useState([]);
  const [center, setCenter] = useState({ lat: -33.43774451396826, lng: -70.65101623535156 });
  const [countries, setCountries] = useState([]);
  const defaultValue = {
    name: '',
    transport_price: 100,
    status: false,
    parent_id: '0',
  };
  const [relativeAreas, setRelativeAreas] = useState([]);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = async () => {
    try {
      const response = await AdminServices.getCountries();
      setCountries(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  function handleMovePoint(lat, lng, index) {
    const newpoints = points.map((p) => {
      if (p.point === index) {
        return { point: index, latlng: { lat, lng } };
      }
      return p;
    });
    setPoints(newpoints);
  }

  const handlePointsAdd = (event) => {
    setPoints([...points, { point: points.length, latlng: { lat: event.latLng.lat(), lng: event.latLng.lng() } }]);
  };

  function handleDeletePoint(indexPoint) {
    const newPoints = points.filter((p) => (p !== indexPoint));
    setPoints(newPoints);
  }

  const handleDeleteAllPoint = () => {
    setPoints([]);
  };

  // function polygosObjetToPolygonStrings (polygons){
  //   return polygons.map(point => `${point.latlng.lat},${point.latlng.lng}`).join(' ')
  // }

  function polygosObjetToPolygonStrings(polygons) {
    return polygons.map((point) => [point.latlng.lat, point.latlng.lng]);
  }

  function handleSave(details) {
    const sendPolygons = polygosObjetToPolygonStrings(points);
    console.log(sendPolygons);
    axios.post(`${process.env.REACT_APP_MODERN_API_URL}/admin/area/`,
      {
        name: details.name,
        transport_price: details.transport_price,
        status: details.status,
        parent_id: details.parent_id,
        polygon: sendPolygons,

      },
      {
        headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` },

      })
      .then(() => {
        alert('success');
        navigate('/app/areas', { replace: true });
      }).catch((e) => { console.log(e); });
    console.log(details);
    console.log(points);
  }

  const handleCountrySelected = (country) => {
    setCenter(country[0].center);
  };

  const handleRelativeAreas = (areas) => {
    const poligons = areas.map((a) => polygonsPolygonStringsToObject(a.polygon));
    setRelativeAreas(poligons);
  };

  function polygonsPolygonStringsToObject(polygons) {
    const transformedPoints = polygons.map((point, index) => ({
      latlng: { lat: point[0], lng: point[1] },
      point: index,
    }));
    return transformedPoints;
  }

  return (
    <>
      <Helmet>
        <title>Add Area </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/areas">
              Areas
            </Link>
            <Typography color="textPrimary">New Area</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <AreaDetails
                handleSave={handleSave}
                defaultValue={defaultValue}
                action="add"
                setRelativeAreas={handleRelativeAreas}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <CountrySelect countries={countries} handleSelected={handleCountrySelected} />
            </Grid>
            <Grid item lg={10} md={10} xs={10}>
              <AreaPoligonMap
                points={points}
                handleMovePoint={handleMovePoint}
                center={center}
                handleDeletePoint={handlePointsAdd}
                relativeAreas={relativeAreas}
              />
            </Grid>
            <Grid item lg={2} md={2} xs={2}>
              <AreaListPoints
                points={points}
                handleDeletePoint={handleDeletePoint}
                handleDeleteAllPoint={handleDeleteAllPoint}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default AreaAdd;
