import React from 'react';
import NumberFormat from 'react-number-format';

const NumberDisplay = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator
      decimalScale={0}
      fixedDecimalScale={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}

    />
  );
};

export default NumberDisplay;
