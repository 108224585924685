import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import moment from 'moment';
import { debounce } from 'lodash';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import AdminServices from 'src/services/AdminServices';

function TransactionsListToolbar({
  handleFilters, filters, handleClearFilters, ...props
}) {
  const { userId } = useParams();
  const [values, setValue] = useState({});
  const currentFilters = { ...filters, ...values };
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);

  const request = debounce((value) => handleFilters(value), 500);

  const debounceRequest = useCallback((value) => request(value), []);

  const handlechange = (filter) => {
    debounceRequest({ ...filters, ...filter });
    setValue({ ...filters, ...filter });
  };

  useEffect(() => { fetchUser(); }, [userId]);

  function handleClear() {
    setValue({});
    handleClearFilters();
  }

  function fetchUser() {
    setLoading(true);
    return AdminServices.getUser(userId)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  return (
    <Box>
      <Card>
        <CardContent>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            {loading && <CircularProgress size={20} />}
            {!loading && user.name ? (
              <Box>
                <Typography variant="h3">
                  {`${user.name} ${user.lastname}'s transactions`}
                </Typography>
                <Typography variant="overline">
                  {`User ID: ${user.id}`}
                </Typography>
              </Box>
            ) : <></>}
            <Button
              color="primary"
              variant="contained"
              sx={{ mx: 1 }}
              component={RouterLink}
              onClick={() => { console.log(`wallet/user/${userId}/transaction/add`); }}
              to={`/app/wallet/user/${userId}/transaction/add`}
            >
              Create Transaction
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextField
                  fullWidth
                  label="From date"
                  name="from"
                  id="date-datetime"
                  type="date"
                  value={moment(currentFilters?.from).format('YYYY-MM-DD')}
                  onChange={(event) => handlechange({ [event.target.name]: event.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search from date"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  label="To date"
                  name="to"
                  id="date-datetime"
                  type="date"
                  value={moment(currentFilters?.to).format('YYYY-MM-DD')}
                  onChange={(event) => handlechange({ [event.target.name]: event.target.value })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search to date"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs>
                <Box style={{
                  display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', height: '100%',
                }}
                >
                  {props.isLoading && <Box><CircularProgress /></Box>}
                  <Button
                    sx={{ mx: 1 }}
                    color="primary"
                    onClick={handleClear}
                    variant="outlined"
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default TransactionsListToolbar;
