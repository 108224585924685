import React from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import is from 'is_js';

const Balances = ({ balances, loadingBalance }) => {
  return (
    <>
      <Typography style={{ marginTop: 24 }} variant="h4">Balances:</Typography>
      <Card>
        <Box style={{
          display: 'flex', justifyContent: 'space-around', paddingTop: 12, paddingBottom: 12,
        }}
        >
          {loadingBalance && <CircularProgress />}
          {is.empty(balances) && <Typography>No balances available</Typography>}
          {balances && balances.map((b, i) => (
            <Box
              key={`balance_${i}`}
              style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              <Typography>
                {b.currency.symbol}
                {b.amount}
              </Typography>
              <Typography>
                {b.currency.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Card>
    </>
  );
};

export default Balances;
