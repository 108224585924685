import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableCell,
  Tooltip,
  IconButton,
  List,
  ListItem,
  Grid,
  Button,
  Divider,
  CardHeader,
  Typography,
  CardContent,
} from '@material-ui/core';
import AdminServices from 'src/services/AdminServices';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';

const CustomerBookings = ({ customerId }) => {
  const [bookings, setBookings] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [currentViews, setViews] = useState([0, 20]);

  const handleLimitChange = (event) => {
    setViews([currentViews[0], currentViews[0] + event.target.value]);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleNextButton = () => {
    setViews([currentViews[1], currentViews[1] + limit]);
  };

  const handleBackButton = () => {
    if (currentViews[0] - limit < 0) {
      setViews([0, limit]);
      return;
    }
    setViews([currentViews[0] - limit, currentViews[1] - limit]);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  useEffect(() => {
    console.log('bookings', bookings);
  }, []);

  useEffect(() => {
    getBookingList();
  }, []);

  const getBookingList = async () => {
    setIsLoad(false);
    try {
      const response = await AdminServices.getBookings({ user_id: customerId });
      setBookings(response.data);
      setIsLoad(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoad && bookings.length > 0 && (
      <Card>
        <CardHeader title="User bookings" />
        <Divider />
        <PerfectScrollbar>
          <Box sx={{ width: '100%' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell>
                    Cart Id
                  </TableCell>
                  <TableCell>
                    Book ID
                  </TableCell>
                  <TableCell>
                    Booked On
                  </TableCell>
                  <TableCell
                    key="date"
                    sortDirection={orderBy === 'date' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'date'}
                      direction={orderBy === 'date' ? order : 'asc'}
                      onClick={() => handleRequestSort('date')}
                    >
                      Booked Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                  <TableCell>
                    Ameizer
                  </TableCell>
                  <TableCell>
                    SVA
                  </TableCell>
                  <TableCell>
                    Addons
                  </TableCell>
                  <TableCell>
                    Qty
                  </TableCell>
                  <TableCell>
                    Country
                  </TableCell>
                  <TableCell>
                    Promocode
                  </TableCell>
                  <TableCell>
                    Addresses
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      Options
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.sort(
                  (a, b) => (order === 'asc'
                    ? new Date(a.date) - new Date(b.date)
                    : new Date(b.date) - new Date(a.date))).slice(...currentViews).map((booking) => (
                      <BookingBody key={booking.id} booking={booking} />
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={bookings.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          labelDisplayedRows={() => `${currentViews[0]} - ${currentViews[1] > bookings.length
            ? bookings.length : currentViews[1]} of ${bookings.length}`}
          rowsPerPageOptions={[20, 50, 100]}
          backIconButtonProps={{ onClick: handleBackButton, disabled: currentViews[0] === 0 }}
          nextIconButtonProps={{ onClick: handleNextButton }}
        />
      </Card>
      )}
      {!isLoad && (
        <Box style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {isLoad && bookings.length === 0 && (
        <Card>
          <CardHeader title="User bookings" />
          <Divider />
          <CardContent>
            <Typography
              style={{ textAlign: 'center' }}
            >
              Without bookings
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

function BookingBody({ booking }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        hover
        key={booking.id}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {booking.comment ? open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
          </IconButton>
        </TableCell>
        <TableCell>
          {booking.shoppingCart.id}
        </TableCell>
        <TableCell>
          {booking.id}
        </TableCell>
        <TableCell>
          {moment(booking.created_date).local().format('YYYY-MM-DD HH:mm')}
        </TableCell>
        <TableCell>
          {booking.date ? moment(booking.date).utc().format('YYYY-MM-DD HH:mm') : '-'}
        </TableCell>
        <TableCell>
          {booking.status}
        </TableCell>
        <TableCell>
          {booking.professional?.user?.name}
        </TableCell>
        <TableCell>
          {booking.serviceVariantArea?.name}
        </TableCell>
        <TableCell>
          {booking.bookingAddOns.length > 0 ? (
            <Tooltip title={tooltipText(booking.bookingAddOns)} arrow>
              <IconButton aria-label={booking.id} size="small">
                {booking.bookingAddOns.length}
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton aria-label={booking.id} size="small">
              {booking.bookingAddOns.length}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          {booking.quantity}
        </TableCell>
        <TableCell>
          {booking.address?.country?.substring(0, 3)}
        </TableCell>
        <TableCell>
          {booking.shoppingCart?.promocode_code ? booking?.shoppingCart?.promocode_code : '-'}
        </TableCell>
        <TableCell>
          {booking.address?.address}
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Button
                value={booking.id}
                component={RouterLink}
                onClick={() => { console.log(`booking/${booking.id}/edit`); }}
                to={`/app/booking/${booking.id}/edit`}
                color="primary"
                variant="contained"
              >
                Edit
              </Button>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Button
                color="success"
                value={booking.id}
                component={RouterLink}
                onClick={() => { console.log(`/app/booking/${booking.id}/${booking.date}/request`); }}
                to={`/app/booking/${booking.id}/${booking.date}/request`}
                variant="contained"
              >
                Req
              </Button>
            </Grid>
          </Grid>
        </TableCell>

      </TableRow>
      {booking.comment && open
        ? (
          <TableRow>
            <TableCell colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <TableCell align="center">
                    {booking.comment}
                  </TableCell>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )

        : null}

    </>
  );
}

const tooltipText = (addon) => {
  return (
    <Box>
      <List>
        {addon && addon.map((a, i) => <ListItem key={`addOnTooltip_${i}`}>{a.addOnArea.name}</ListItem>)}
      </List>
    </Box>
  );
};

export default CustomerBookings;
