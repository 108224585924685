// import React from 'react';
import PropTypes from 'prop-types';
import { React, useEffect } from 'react';
import {
  Marker, GoogleMap, LoadScript, Polygon,
} from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

// const center = { lat: -33.43774451396826, lng: -70.65101623535156 };
const options = {
  fillColor: 'pink',
  fillOpacity: 0.4,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const relativeAreasOptions = {
  fillColor: 'grey',
  fillOpacity: 0.4,
  strokeColor: 'grey',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const onLoad = (polygon) => {
  console.log('polygon: ', polygon);
};

function AreaPoligonMap({
  points, center, handleDeletePoint, handleMovePoint, multiPoligon = false, relativeAreas,
}) {
  useEffect(() => {
    console.log('points', points);
  }, [points]);

  function transformPoints(points) {
    return points.map((point) => point.latlng);
  }
  const handleMapClick = (event) => {
    handleDeletePoint(event);
  };
  function handleMarkerClik(index) {
    console.log(index);
  }
  const handleClickPolygon = (index) => {
    console.log('click polygon', index);
  };
  const handlePointMove = (e, i) => {
    handleMovePoint(e.latLng.lat(), e.latLng.lng(), i);
    console.log(e.latLng.lat(), e.latLng.lng(), i);
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    >
      <GoogleMap
        onClick={handleMapClick}
        id="marker-example"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        { /* Child components, such as markers, info windows, etc. */}
        {multiPoligon && points.map((p, i) => (
          <Polygon
            key={`poligon_${i}`}
            onLoad={onLoad}
            onClick={() => handleClickPolygon(i)}
            paths={transformPoints(p)}
            options={options}
          />
        ))}
        {relativeAreas && relativeAreas.map((p, i) => (
          <Polygon
            key={`relativeAreas_${i}`}
            onLoad={onLoad}
            // onClick={(e) => handleClickPolygon(i)}
            paths={transformPoints(p)}
            options={relativeAreasOptions}
          />
        ))}
        {!multiPoligon && (
        <Polygon
          onLoad={onLoad}
          paths={transformPoints(points)}
          options={options}
        />
        )}
        {true && points.map((point, index) => (
          <Marker
            draggable
            key={point?.latlng?.lat + point?.latlng?.lng}
            position={point?.latlng}
            label={`Punto ${index}`}
            name={index}
            onDragEnd={(e) => { handlePointMove(e, index); }}
            onClick={() => { handleMarkerClik(index); }}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
}
AreaPoligonMap.propTypes = {
  points: PropTypes.array,
};

export default AreaPoligonMap;
