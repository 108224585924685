// import { useState } from 'react';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const BookingListResult = ({ Request }) => (
  <Card>
    <PerfectScrollbar>
      <Box sx={{ minWidth: 1050 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                BR ID
              </TableCell>
              <TableCell>
                Created
              </TableCell>
              <TableCell>
                Activation
              </TableCell>
              <TableCell>
                Reply
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Last Name
              </TableCell>
              <TableCell>
                Professional ID
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Request.map((bookingRequest) => (
              <BookingRequestBody key={bookingRequest.id} bookingRequest={bookingRequest} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  </Card>
);

export default BookingListResult;

function BookingRequestBody({ bookingRequest }) {
  return (

    <>
      <TableRow hover key={bookingRequest.id}>
        <TableCell>
          {bookingRequest.id}
        </TableCell>
        <TableCell>
          {moment(bookingRequest.created_date).utc().format('DD-MM-YYYY HH:mm')}
        </TableCell>
        <TableCell>
          {moment(bookingRequest.activation_date).utc().format('DD-MM-YYYY HH:mm')}
        </TableCell>
        <TableCell>
          {moment(bookingRequest.reply_date).utc().format('DD-MM-YYYY HH:mm')}
        </TableCell>
        <TableCell>
          {bookingRequest.accepted === null ? 'Pending' : bookingRequest.accepted ? 'Accepted' : 'Rejected'}
        </TableCell>
        <TableCell>
          {bookingRequest.professional?.user?.name}
        </TableCell>
        <TableCell>
          {bookingRequest.professional?.user?.lastname}
        </TableCell>
        <TableCell>
          {bookingRequest?.professional?.id}
        </TableCell>
      </TableRow>
    </>
  );
}
