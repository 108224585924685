import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AuthService from 'src/services/authService';
import { useNavigate } from 'react-router';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import ServiceDetails from 'src/components/service/ServiceDetails';

function ServiceEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [defaultValue, setDefaultValue] = useState({});

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/service/${id}`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        setDefaultValue(response.data);
        console.log(response.data);
        setIsLoad(true);
      });
  }, []);

  function handleSave(details) {
    console.log(details);
    setIsSaving(true);
    axios.patch(`${process.env.REACT_APP_MODERN_API_URL}/admin/service/${id}`,
      details,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        setIsSaving(false);
        alert('Success');
        navigate('/app/service');
        console.log(response);
      }).catch((e) => {
        console.log(e);
        setIsSaving(false);
        alert('error');
      });
  }
  return (
    <>
      <Helmet>
        <title>Add Service </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/service">
              Services
            </Link>
            <Typography color="textPrimary">Edit Service</Typography>
          </Breadcrumbs>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              { isSaving ? <CircularProgress /> : isLoad ? (
                <ServiceDetails
                  handleSave={handleSave}
                  defaultValue={defaultValue}
                  action="add"
                />
              ) : <></>}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ServiceEdit;
