import { Helmet } from 'react-helmet';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import AuthService from 'src/services/authService';

import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import CategoryDetails from 'src/components/category/CategoriesDetails';

function CategoryAdd() {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const defaultValue = {
    name: '',
    description: '',
    web_image: `${process.env.PUBLIC_URL}/static/images/helps/empty.jpg`,
    mobile_image: `${process.env.PUBLIC_URL}/static/images/helps/empty.jpg`,
    order: '1',
  };

  function handleSave(details) {
    setIsSaving(true);
    axios.post(`${process.env.REACT_APP_MODERN_API_URL}/admin/category/`,
      {
        name: details.name,
        web_image: details.web_image,
        mobile_image: details.mobile_image,
        order: details.order,
        description: details.description,

      },
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        setIsSaving(false);
        console.log(response);
        alert('succes');
        navigate('/app/categories', { replace: true });
      }).catch((e) => {
        console.log(e);
        setIsSaving(false);
        alert('error');
      });
  }
  return (
    <>
      <Helmet>
        <title>Add Categorya </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/categories">
              Categories
            </Link>
            <Typography color="textPrimary">New category</Typography>
          </Breadcrumbs>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              { isSaving ? <CircularProgress /> : (
                <CategoryDetails
                  handleSave={handleSave}
                  defaultValue={defaultValue}
                  action="add"
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CategoryAdd;
