import { useEffect, useState } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const CountrySelect = ({
  countries, handleSelected, defaultareasSelected, multiple, editOn,
}) => {
  const [localCountries, setLocalAreas] = useState();
  const [isLoadlocalArea, setIsLoadlocalArea] = useState(false);

  const handleChange = (event) => {
    handleSelected(countries.filter((country) => event.target.value.includes(country.name)));
    setLocalAreas(event.target.value);
  };

  const handleSingleChange = (event) => {
    handleSelected(countries.filter((country) => event.target.value === country.name));
    setLocalAreas(event.target.value);
    console.log(event.target.value);
  };

  useEffect(() => {
    if (defaultareasSelected) {
      setLocalAreas(defaultareasSelected.name);
    } else {
      setLocalAreas([countries[0]]);
    }
    setIsLoadlocalArea(true);
  }, []);

  return (
    <>
      {multiple && isLoadlocalArea
        ? (
          <>
            <Select
              fullWidth
              disabled={!editOn}
              helperText="Asociated country"
              label="Asociated country"
              labelId="demo-mutiple-name-label"
              id="multiple-country-select"
              multiple
              value={localCountries}
              onChange={handleChange}
              input={<Input />}
              variant="outlined"
            >
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Select Country</FormHelperText>
          </>
        )
        : isLoadlocalArea
          ? (
            <>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="country-simple-select-outlined-label">Country</InputLabel>
                <Select
                  fullWidth
                  disabled={editOn}
                  labelId="country-simple-select-outlined-label"
                  id="country-simple-select-outlined"
                  value={localCountries}
                  onChange={handleSingleChange}
                  label="Country"
                >
                  {countries.map((country) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )
          : null}
    </>
  );
};
export default CountrySelect;
