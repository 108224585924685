import { Helmet } from 'react-helmet';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import AuthService from 'src/services/authService';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import ServiceDetails from 'src/components/service/ServiceDetails';

function ServiceAdd() {
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);
  function handleSave(details) {
    setIsSaving(true);

    axios.post(`${process.env.REACT_APP_MODERN_API_URL}/admin/service/`,
      {
        name: details.name,
        description: details.description,
        order: details.order,
        image: details.image,
        category_ids: details.category_ids,

      },
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then(() => {
        setIsSaving(false);
        alert('success');
        navigate('/app/service', { replace: true });
      }).catch((e) => {
        console.log(e);
        setIsSaving(false);
        alert('error');
      });
  }
  return (
    <>
      <Helmet>
        <title>Add Servicea </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/service">
              Services
            </Link>
            <Typography color="textPrimary">New Service</Typography>
          </Breadcrumbs>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              { isSaving ? <CircularProgress /> : <ServiceDetails handleSave={handleSave} action="add" />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ServiceAdd;
