import { useCallback, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { debounce } from 'lodash';

const ReviewsToolbar = ({
  handlefilter, handleclearfilter, loading,
}) => {
  const [filters, setFilters] = useState({});
  const request = debounce((value) => handlefilter(value), 500);
  const debounceRequest = useCallback((value) => request(value), []);

  const handlechange = (filter) => {
    setFilters({ ...filters, ...filter });
    debounceRequest({ ...filter });
  };

  function handleClear() {
    setFilters({});
    handleclearfilter();
  }

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextField
                fullWidth
                label="Email"
                value={filters.email || ''}
                onChange={(e) => handlechange({ email: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search by email"
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="Booking ID"
                value={filters.booking_id || ''}
                onChange={(e) => handlechange({ booking_id: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search booking ID"
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <Box style={{
                display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', height: '100%',
              }}
              >
                {loading && <Box><CircularProgress /></Box>}
                <Button
                  sx={{ mx: 1 }}
                  color="primary"
                  onClick={handleClear}
                  variant="outlined"
                >
                  Clear Filter
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReviewsToolbar;
