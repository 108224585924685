import { Helmet } from 'react-helmet';
import { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from 'react-router-dom';
import ServiceVariantListToolbar from 'src/components/serviceVariant/ServiceVariantToolBar';
import ServiceVariantListResults from 'src/components/serviceVariant/ServiceVariantListResult';
import AdminServices from 'src/services/AdminServices';
import DeleteModal from 'src/components/comon/Alert';
import { useNavigate } from 'react-router';
import Fuse from 'fuse.js';

const DELETE_MODAL_TITLE = 'Do you want to remove this variant ?';

const ServiceVariantList = () => {
  const navigate = useNavigate();
  const [serviceVariants, setServiceVariants] = useState([]);
  const [serviceVariantsAll, setServiceVariantsAll] = useState([]);
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const index = useMemo(() => {
    const docs = serviceVariantsAll;
    const options = {
      shouldSort: true,
      threashold: 0.2,
      useExtendedSearch: true,
      keys: ['internal_name', 'service.name'],
    };
    const myIndex = Fuse.createIndex(options.keys, docs);
    return new Fuse(docs, options, myIndex);
  }, [serviceVariantsAll]);

  useEffect(() => {
    if (state) {
      if (state.reload) {
        getServiceVariantList();
      }
    }
  }, [state]);

  useEffect(() => {
    getServiceVariantList();
  }, []);

  function handlefilter(filters) {
    const { service = '', area = '' } = filters;
    const queryFilter = service.split(' ').map((item) => `'${item}`).join(' ');
    if (service.length < 3 || !service) {
      const updated = filterByArea(serviceVariantsAll, area);
      setServiceVariants(updated);
    } else {
      const list = index.search(queryFilter).map((result) => result.item);
      const updated = filterByArea(list, area);
      setServiceVariants(updated);
    }
  }

  function filterByArea(list, filterValue) {
    if (!filterValue || filterValue.length < 3) {
      return list;
    }
    return list.map((variant) => ({
      ...variant,
      serviceVariantAreas: variant.serviceVariantAreas.filter(
        (variantArea) => variantArea?.area?.name.toLowerCase().match(filterValue.toLowerCase())),
    })).filter((sv) => sv.serviceVariantAreas.length > 0);
  }

  const getServiceVariantList = async (areaId = null) => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getServiceVariants(areaId);
      setServiceVariants(response.data);
      setServiceVariantsAll(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const [variantToDelete, setVariantToDelete] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenDeleteAlert = (serviceId, serviceVarianId) => {
    setVariantToDelete({
      serviceId,
      serviceVarianId,
    });
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  function handleDeleteServiceVariant() {
    return AdminServices.deleteServiceVariant(variantToDelete.serviceId, variantToDelete.serviceVarianId)
      .then(() => {
        setServiceVariants(serviceVariants.filter((Service) => Service.id !== variantToDelete.serviceVarianId));
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setOpenDeleteModal(false);
      });
  }

  function handleDeleteServiceVariantArea(serviceVariantAreaId) {
    return AdminServices.deleteServiceVariantArea(serviceVariantAreaId)
      .then(() => {
        getServiceVariantList();
      }).catch((error) => {
        console.log(error);
      });
  }

  const handleCloneServiceVariantArea = async (serviceVariantAreaId, variantId) => {
    try {
      const response1 = await AdminServices.getServiceVariantArea(serviceVariantAreaId);
      const clone = JSON.parse(JSON.stringify(response1.data));
      // let newImage;
      // if(clone.image.full_url) {
      //   imageToBase64(clone.image.full_url)
      //     .then((response) => {
      //       console.log("b64",response);
      //       newImage = response;
      //     }
      //   )
      //   .catch(
      //     (error) => {
      //       console.log(error);
      //     }
      //   )
      // }
      // clone.image = `data:image/jpeg;base64,${newImage}`
      delete clone.image;
      delete clone.id;
      clone.name += '- Cloned';
      clone.area_id = clone.area.id;
      delete clone.area;
      clone.active = false;
      clone.service_variant_id = variantId;
      console.log('cloned', clone);
      const response2 = await AdminServices.createServiceVariantArea(clone);
      navigate(`/app/variantarea/${response2.data.id}/edit`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Service Vriant | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Service variants</Typography>
          </Breadcrumbs>
          <ServiceVariantListToolbar
            handlefilter={(query) => handlefilter(query)}
            getServiceVariantList={getServiceVariantList}
          />
          <Box sx={{ pt: 3 }}>
            {isLoading && (
            <Box style={{ textAlign: 'center' }}>
              {' '}
              <CircularProgress />
            </Box>
            )}
            {!isLoading && (
            <ServiceVariantListResults
              servicesVariant={serviceVariants}
              handleDeleteServiceVariant={handleOpenDeleteAlert}
              handleDeleteServiceVariantArea={handleDeleteServiceVariantArea}
              handleCloneServiceVariantArea={handleCloneServiceVariantArea}
            />
            )}
          </Box>
        </Container>
        <DeleteModal
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          Agree={handleDeleteServiceVariant}
          title={DELETE_MODAL_TITLE}
          content={`ID: ${variantToDelete?.serviceVarianId}`}
        />
      </Box>
    </>
  );
};

export default ServiceVariantList;
