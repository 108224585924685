import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const EditModal = ({
  content, handleClose, open, Agree, loading,
}) => {
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (content) {
      setLocalValue(content?.value);
    }
  }, [content]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Edit config value</DialogTitle>
      <DialogContent dividers>
        <Box style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <TextField
            fullWidth={content?.type.includes('text')}
            id="standard-input"
            multiline={content?.type.includes('text')}
            label="Value"
            type={content?.type.includes('text') ? 'string' : 'number'}
            variant="standard"
            size="small"
            value={localValue}
            onChange={(e) => setLocalValue(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => Agree(content.id, localValue)} color="primary" autoFocus>
          {loading ? <CircularProgress color="secondary" /> : 'Accept'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
