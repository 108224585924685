import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
export const db = firebase.database();
