import {
  Marker, GoogleMap, LoadScript,
} from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

function BookingMap({ center }) {
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        id="marker-example"
        mapContainerStyle={containerStyle}
        center={center || { lat: -33.44842152938572, lng: -70.66407958679974 }}
        zoom={center ? 16 : 12}
      >
        {center && (
        <Marker
          position={center}
        />
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default BookingMap;
