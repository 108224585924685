import { useEffect, useState } from 'react';
import {
  makeStyles, createStyles,
} from '@material-ui/core/styles';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';

import AuthService from 'src/services/authService';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';

import AreaSelectTree from 'src/components/comon/AreaSelectTree';
import { useParams } from 'react-router-dom';
import VariantFrame from 'src/assets/variant_frame.png';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';

const states = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'Diable',
  },
];

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}),
);

const ServiceVariantAreasDetails = ({ handleSave, defaultValue, loading }) => {
  const classes = useStyles();
  const { variantId } = useParams();
  const [showImage, setShowImage] = useState(null);
  const [values, setValues] = useState({});
  const [titles, setTitles] = useState('Service Variant Areas');
  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_MODERN_API_URL}/admin/areas`,
      { headers: { Authorization: `Bearer ${AuthService.getCurrentToken()}` } })
      .then((response) => {
        console.log(response.data);
        setAreas(response.data);
        setIsLoad(true);
      });
  }, []);

  useEffect(() => {
    if (variantId) {
      setTitles(`Service Variant Areas ${variantId}`);
    }
    console.log(defaultValue);
    if (defaultValue) {
      setShowImage(defaultValue.image?.url);
      setValues({
        name: defaultValue.name,
        description: defaultValue.description,
        mobile_description: defaultValue.mobile_description,
        quantity_message: defaultValue.quantity_message,
        cost: defaultValue.cost,
        price: defaultValue.price,
        transport_price: defaultValue.transport_price,
        active: defaultValue.active,
        area_id: defaultValue?.area?.id,
        duration: defaultValue?.duration,
      });
      if (defaultValue.area) {
        setAreaSelected([defaultValue.area]);
      }
    }
  }, []);

  function checkRequired(values) {
    if (values.area_id) {
      return true;
    }
    return false;
  }

  const handleLocalSave = () => {
    if (checkRequired(values)) {
      handleSave(values);
    } else {
      alert('fill area');
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = new window.Image();
        image.src = e.target.result;

        image.onload = () => {
          const { height, width } = image;
          if (height !== 720 || width !== 1125) {
            alert('The measures must be Width = 1125px and Height = 720px');
            return false;
          }
          resolve(reader.result);
        };
      };
      reader.onerror = (error) => reject(error);
    });
  }

  function handelslectedarea(area) {
    console.log(area);
    setValues({
      ...values,
      area_id: area[0].id,
    });
    setAreaSelected([area[0]]);
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    getBase64(event.target.files[0]).then((file64) => {
      setValues({
        ...values,
        [event.target.name]: file64,
      });
      if (file64) {
        setShowImage(URL.createObjectURL(file));
      }
    });
    console.log(values);
  };

  return (
    <>
      <form>
        <Card>
          <CardHeader
            subheader="The information is asociates to variante "
            title={titles}
          />
          <Divider />
          {isLoad
            ? (
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      placeholder="Description"
                      onChange={handleChange}
                      required
                      rows={4}
                      multiline
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Mobile Description"
                      name="mobile_description"
                      placeholder="Mobile Description"
                      onChange={handleChange}
                      required
                      rows={4}
                      multiline
                      value={values.mobile_description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Quantity Message"
                      name="quantity_message"
                      value={values.quantity_message}
                      onChange={handleChange}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoad ? (
                      <AreaSelectTree
                        areas={areas}
                        handelslected={handelslectedarea}
                        defaultareasSelected={areaSelected}
                        multiple={false}
                      />
                    ) : null}
                  </Grid>
                  <Grid item md={3} xs={4}>
                    <TextField
                      fullWidth
                      label="Cost"
                      name="cost"
                      placeholder="Cost"
                      onChange={handleChange}
                      required
                      value={values.cost}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={4}>
                    <TextField
                      fullWidth
                      label="Price"
                      name="price"
                      placeholder="Price"
                      onChange={handleChange}
                      required
                      value={values.price}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={4}>
                    <TextField
                      fullWidth
                      label="Transport Price"
                      name="transport_price"
                      placeholder="Cost"
                      onChange={handleChange}
                      required
                      value={values.transport_price}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={4}>
                    <TextField
                      fullWidth
                      label="Duration"
                      name="duration"
                      placeholder="Duration"
                      onChange={handleChange}
                      required
                      value={values.duration}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item md={3} xs={4}>
                    <TextField
                      fullWidth
                      label="Select Status"
                      name="active"
                      placeholder="Select Status"
                      onChange={handleChange}
                      select
                      required
                      value={values.active}
                      variant="outlined"
                    >
                      {states.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid md={12} xs={12} container justify="center" style={{ marginTop: '32px' }}>
                    <Box
                      style={{
                        backgroundImage: showImage ? `url(${showImage})` : `url(${VariantFrame})`,
                        backgroundSize: 'cover',
                        width: '562.5px',
                        height: '364px',
                        borderRadius: '8px',
                        border: '5px solid #ddd',
                        margin: 'auto',
                      }}
                    >
                      <input
                        accept="image/jpeg,image/png"
                        className={classes.input}
                        id="image"
                        type="file"
                        name="image"
                        multiple
                        onChange={handleUploadClick}
                      />
                      <label htmlFor="image">
                        <IconButton style={{ position: 'relative' }} component="span">
                          <AddAPhotoOutlinedIcon />
                        </IconButton>
                      </label>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            )
            : null}
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2,
            }}
          >
            <Button
              onClick={handleLocalSave}
              color="primary"
              variant="contained"
            >
              {loading ? <CircularProgress color="secondary" /> : 'Save details'}
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ServiceVariantAreasDetails;
