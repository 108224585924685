import moment from 'moment';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';

import NumberFormat from 'react-number-format';
import AdminServices from 'src/services/AdminServices';

const SettlementsListResult = ({ data, handlefetch }) => {
  const [open, setOpen] = useState(false);
  const name = data[0].professional
    ? `${`${data[0].professional.user.name} ${data[0].professional.user.lastname}`}` : '-';
  const phone = data[0].professional ? data[0].professional.user.phone : '';

  const totalCost = data.reduce((acc, next) => {
    const result = acc + (Number(next.final_cost) || 0);
    return result;
  }, 0);

  const transportTotalCost = data.reduce((acc, next) => {
    const result = acc + ((Number(next.transport_price) * next.transport_percentage_cost) / 100 || 0);
    return result;
  }, 0);

  const totalTip = data.reduce((acc, next) => {
    const result = acc + (next.tip ? (Number(next.tip.amount) * 0.95) : 0);
    return result;
  }, 0);

  const servicesCost = data.reduce((acc, next) => {
    const result = acc + (Number(next.service_variant_total_cost || 0) + Number(next.total_addons_cost || 0));
    return result;
  }, 0);

  function handleFunding() {
    const bookings = data.map((item) => item.id);
    return AdminServices.funding({ bookings })
      .then(() => { alert('Done'); handlefetch(); })
      .catch((error) => console.log(error));
  }

  return (
    <Card sx={{ mb: 2 }}>
      <Box sx={{ minWidth: 1050 }}>
        <Toolbar>
          <IconButton sx={{ mr: 2 }} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography variant="h4" id="tableTitle" component="div">
            {`${name} ${phone}`}
            {' '}
            |
            Total cost:
            <NumberFormat value={servicesCost} displayType="text" thousandSeparator prefix="$" />
            {' '}
            |
            Transport cost:
            <NumberFormat value={transportTotalCost} displayType="text" thousandSeparator prefix="$" />
            {' '}
            |
            Total tips:
            <NumberFormat value={totalTip} displayType="text" thousandSeparator prefix="$" />
            {' '}
            |
            Total to pay:
            <NumberFormat value={totalCost + totalTip} displayType="text" thousandSeparator prefix="$" />
          </Typography>
          <Box display="flex" flex="1 1 auto" justifyContent="flex-end">
            <Button
              sx={{ mx: 1 }}
              color="primary"
              onClick={handleFunding}
              variant="outlined"
            >
              Pagar
            </Button>
          </Box>
        </Toolbar>
        {open
          && (
          <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Cart Id
                    </TableCell>
                    <TableCell>
                      Book ID
                    </TableCell>
                    <TableCell>
                      Booked By
                    </TableCell>
                    <TableCell>
                      Services
                    </TableCell>
                    <TableCell>
                      Booked On
                    </TableCell>
                    <TableCell>
                      Booked Date
                    </TableCell>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell>
                      Price
                    </TableCell>
                    <TableCell>
                      Cost
                    </TableCell>
                    <TableCell>
                      Transport
                    </TableCell>
                    <TableCell align="center">
                      Tip*95%
                    </TableCell>
                    <TableCell>
                      Total cost
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((book) => <BookingBody key={book.id} book={book} />)}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Collapse>
          )}
      </Box>
    </Card>
  );
};

function BookingBody({ book }) {
  const servicesCost = Number(book.service_variant_total_cost) + Number(book.total_addons_cost);

  return (
    <>
      <TableRow
        hover
        key={book.id}
      >
        <TableCell>
          {book?.shoppingCart.id}
        </TableCell>
        <TableCell>
          {book?.id}
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant="body2">
              {`${book?.shoppingCart?.user?.name} ${book?.shoppingCart?.user?.lastname}`}
            </Typography>
            <Typography variant="body2">{book?.shoppingCart?.user?.email}</Typography>
            <Typography variant="body2">{book?.shoppingCart?.user?.phone}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant="body2">
              {`${book?.serviceVariantArea?.name} x${book?.quantity} - `}
              <NumberFormat value={book?.service_variant_total_cost} displayType="text" thousandSeparator prefix="$" />
            </Typography>
            {(book?.bookingAddOns || []).map((addOn) => (
              <Typography variant="body2">
                {`${addOn?.addOnArea?.name} x${addOn?.quantity} - `}
                <NumberFormat value={book?.total_addons_cost} displayType="text" thousandSeparator prefix="$" />
              </Typography>
            ))}
          </Box>
        </TableCell>
        <TableCell>
          {moment(book?.created_date).utc().format('YYYY-MM-DD HH:mm')}
        </TableCell>
        <TableCell>
          {moment(book?.date).utc().format('YYYY-MM-DD HH:mm')}
        </TableCell>
        <TableCell>
          {book?.status}
        </TableCell>
        <TableCell>
          <NumberFormat value={book?.final_price} displayType="text" thousandSeparator prefix="$" />
        </TableCell>
        <TableCell>
          <NumberFormat value={servicesCost} displayType="text" thousandSeparator prefix="$" />
        </TableCell>
        <TableCell>
          <NumberFormat
            value={book?.transport_price ? (Number(book?.transport_price) * book?.transport_percentage_cost) / 100 : 0}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </TableCell>
        <TableCell align="center">
          {book?.tip ? <NumberFormat value={Number(book?.tip.amount) * 0.95} displayType="text" thousandSeparator prefix="$ " /> : '$ 0'}
        </TableCell>
        <TableCell>
          <NumberFormat value={Number(book?.final_cost) + (book?.tip ? (Number(book?.tip.amount) * 0.95) : 0)} displayType="text" thousandSeparator prefix="$" />
        </TableCell>
      </TableRow>
    </>
  );
}

export default SettlementsListResult;
