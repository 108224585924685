import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Button, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useNavigate } from 'react-router';

import PacksListResult from 'src/components/packs/PacksListResult';
import AdminServices from 'src/services/AdminServices';

const pack = {
  id: 0,
  items: [
    {
      id: 0,
      serviceVariant: {
        id: 0,
        order: 0,
        internal_name: 'string',
        serviceVariantAreas: [
          {
            id: 0,
            name: 'string',
            description: 'string',
            duration: 0,
            mobile_description: 'string',
            quantity_message: 'string',
            image: {
              id: 0,
              url: 'string',
              full_url: 'string',
              order: 0,
            },
            cost: 0,
            price: 0,
            transport_price: 0,
            area: {
              id: 0,
              name: 'string',
              short_name: 'string',
              transport_price: 0,
              polygon: [
                'string',
              ],
              children_title: 'string',
              children: [
                null,
              ],
              active: true,
              center: {
                lat: 0,
                lng: 0,
              },
              i18n: 'string',
              currencies: [
                {
                  id: 0,
                  name: 'string',
                  symbol: 'string',
                },
              ],
            },
            active: true,
          },
        ],
        addOns: [
          {
            id: 0,
            order: 0,
            duration: 0,
            internal_name: 'string',
            addOnAreas: [
              {
                id: 0,
                name: 'string',
                description: 'string',
                cost: 0,
                price: 0,
                area: {
                  id: 0,
                  name: 'string',
                  short_name: 'string',
                  transport_price: 0,
                  polygon: [
                    'string',
                  ],
                  children_title: 'string',
                  children: [
                    null,
                  ],
                  active: true,
                  center: {
                    lat: 0,
                    lng: 0,
                  },
                  i18n: 'string',
                  currencies: [
                    {
                      id: 0,
                      name: 'string',
                      symbol: 'string',
                    },
                  ],
                },
                active: true,
              },
            ],
            serviceVariants: [
              null,
            ],
          },
        ],
        service: {
          id: 0,
          name: 'string',
          description: 'string',
          order: 0,
          image: {
            id: 0,
            url: 'string',
            full_url: 'string',
            order: 0,
          },
          variants: [
            {
              id: 0,
              order: 0,
              duration: 0,
              service_variant_area_id: 0,
              name: 'string',
              description: 'string',
              mobile_description: 'string',
              quantity_message: 'string',
              image: {
                id: 0,
                url: 'string',
                full_url: 'string',
                order: 0,
              },
              cost: 0,
              price: 0,
              addOns: [
                {
                  id: 0,
                  name: 'string',
                  description: 'string',
                  order: 0,
                  duration: 0,
                  addon_area_id: 0,
                  cost: 0,
                  price: 0,
                },
              ],
              currency: 'string',
            },
          ],
          packs: [
            {
              id: 0,
              items: [
                {
                  id: 0,
                  serviceVariant: {
                    id: 0,
                    order: 0,
                    duration: 0,
                    service_variant_area_id: 0,
                    name: 'string',
                    description: 'string',
                    mobile_description: 'string',
                    quantity_message: 'string',
                    image: {
                      id: 0,
                      url: 'string',
                      full_url: 'string',
                      order: 0,
                    },
                    cost: 0,
                    price: 0,
                    addOns: [
                      {
                        id: 0,
                        name: 'string',
                        description: 'string',
                        order: 0,
                        duration: 0,
                        addon_area_id: 0,
                        cost: 0,
                        price: 0,
                      },
                    ],
                    currency: 'string',
                  },
                  sessions: 0,
                  price_percentage: 0,
                  cost_percentage: 0,
                },
              ],
            },
          ],
          categories: [
            {
              id: 0,
              name: 'string',
              web_image: {
                id: 0,
                url: 'string',
                full_url: 'string',
                order: 0,
              },
              mobile_image: {
                id: 0,
                url: 'string',
                full_url: 'string',
                order: 0,
              },
              order: 0,
              description: 'string',
              services: [
                null,
              ],
            },
          ],
        },
        countries: [
          {
            id: 0,
            name: 'string',
            serviceVariant: [
              null,
            ],
            area: {
              id: 0,
              name: 'string',
              short_name: 'string',
              transport_price: 0,
              polygon: [
                'string',
              ],
              children_title: 'string',
              children: [
                null,
              ],
              active: true,
              center: {
                lat: 0,
                lng: 0,
              },
              i18n: 'string',
              currencies: [
                {
                  id: 0,
                  name: 'string',
                  symbol: 'string',
                },
              ],
            },
          },
        ],
      },
      sessions: 0,
      price_percentage: 0,
      cost_percentage: 0,
    },
    {
      id: 0,
      serviceVariant: {
        id: 0,
        order: 0,
        internal_name: 'string',
        serviceVariantAreas: [
          {
            id: 0,
            name: 'string',
            description: 'string',
            duration: 0,
            mobile_description: 'string',
            quantity_message: 'string',
            image: {
              id: 0,
              url: 'string',
              full_url: 'string',
              order: 0,
            },
            cost: 0,
            price: 0,
            transport_price: 0,
            area: {
              id: 0,
              name: 'string',
              short_name: 'string',
              transport_price: 0,
              polygon: [
                'string',
              ],
              children_title: 'string',
              children: [
                null,
              ],
              active: true,
              center: {
                lat: 0,
                lng: 0,
              },
              i18n: 'string',
              currencies: [
                {
                  id: 0,
                  name: 'string',
                  symbol: 'string',
                },
              ],
            },
            active: true,
          },
        ],
        addOns: [
          {
            id: 0,
            order: 0,
            duration: 0,
            internal_name: 'string',
            addOnAreas: [
              {
                id: 0,
                name: 'string',
                description: 'string',
                cost: 0,
                price: 0,
                area: {
                  id: 0,
                  name: 'string',
                  short_name: 'string',
                  transport_price: 0,
                  polygon: [
                    'string',
                  ],
                  children_title: 'string',
                  children: [
                    null,
                  ],
                  active: true,
                  center: {
                    lat: 0,
                    lng: 0,
                  },
                  i18n: 'string',
                  currencies: [
                    {
                      id: 0,
                      name: 'string',
                      symbol: 'string',
                    },
                  ],
                },
                active: true,
              },
            ],
            serviceVariants: [
              null,
            ],
          },
        ],
        service: {
          id: 0,
          name: 'string',
          description: 'string',
          order: 0,
          image: {
            id: 0,
            url: 'string',
            full_url: 'string',
            order: 0,
          },
          variants: [
            {
              id: 0,
              order: 0,
              duration: 0,
              service_variant_area_id: 0,
              name: 'string',
              description: 'string',
              mobile_description: 'string',
              quantity_message: 'string',
              image: {
                id: 0,
                url: 'string',
                full_url: 'string',
                order: 0,
              },
              cost: 0,
              price: 0,
              addOns: [
                {
                  id: 0,
                  name: 'string',
                  description: 'string',
                  order: 0,
                  duration: 0,
                  addon_area_id: 0,
                  cost: 0,
                  price: 0,
                },
              ],
              currency: 'string',
            },
          ],
          packs: [
            {
              id: 0,
              items: [
                {
                  id: 0,
                  serviceVariant: {
                    id: 0,
                    order: 0,
                    duration: 0,
                    service_variant_area_id: 0,
                    name: 'string',
                    description: 'string',
                    mobile_description: 'string',
                    quantity_message: 'string',
                    image: {
                      id: 0,
                      url: 'string',
                      full_url: 'string',
                      order: 0,
                    },
                    cost: 0,
                    price: 0,
                    addOns: [
                      {
                        id: 0,
                        name: 'string',
                        description: 'string',
                        order: 0,
                        duration: 0,
                        addon_area_id: 0,
                        cost: 0,
                        price: 0,
                      },
                    ],
                    currency: 'string',
                  },
                  sessions: 0,
                  price_percentage: 0,
                  cost_percentage: 0,
                },
              ],
            },
          ],
          categories: [
            {
              id: 0,
              name: 'string',
              web_image: {
                id: 0,
                url: 'string',
                full_url: 'string',
                order: 0,
              },
              mobile_image: {
                id: 0,
                url: 'string',
                full_url: 'string',
                order: 0,
              },
              order: 0,
              description: 'string',
              services: [
                null,
              ],
            },
          ],
        },
        countries: [
          {
            id: 0,
            name: 'string',
            serviceVariant: [
              null,
            ],
            area: {
              id: 0,
              name: 'string',
              short_name: 'string',
              transport_price: 0,
              polygon: [
                'string',
              ],
              children_title: 'string',
              children: [
                null,
              ],
              active: true,
              center: {
                lat: 0,
                lng: 0,
              },
              i18n: 'string',
              currencies: [
                {
                  id: 0,
                  name: 'string',
                  symbol: 'string',
                },
              ],
            },
          },
        ],
      },
      sessions: 0,
      price_percentage: 0,
      cost_percentage: 0,
    },
  ],
};

const PacksList = () => {
  const navigate = useNavigate();
  const [packs, setPacks] = useState([]);

  useEffect(() => {
    getPackList();
  }, []);

  const getPackList = async () => {
    try {
      const response = await AdminServices.getPacks();
      console.log('getPacks', response.data);
      setPacks(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePack = async (packId) => {
    try {
      const response = await AdminServices.deletePack(packId);
      setPacks(packs.filter((p) => p.id !== packId));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Session packs | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>

          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
              <Link color="inherit" href="/">
                Home
              </Link>
              <Typography color="textPrimary">Packs</Typography>
            </Breadcrumbs>
            <Box>
              <Button
                variant="contained"
                onClick={() => navigate('/app/packs/create')}
              >
                New Pack
              </Button>
            </Box>
          </Box>

          <Box sx={{ pt: 3 }}>
            <PacksListResult packs={packs} handleDeletePack={handleDeletePack} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PacksList;
