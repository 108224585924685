import moment from 'moment';
import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
  NavLink as RouterLink,
} from 'react-router-dom';
import {
  Box,
  Card,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const BookingListResult = ({
  bookings, handleFilter, filters, isLoad,
}) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');

  function handlePageFiltersChange(prop, value) {
    console.info(prop, value);
    handleFilter({ ...filters, [prop]: value });
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  useEffect(() => {
    console.log('bookings', bookings);
  }, []);

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small">
            <BookingHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {bookings.sort(
                (a, b) => (order === 'asc'
                  ? new Date(a.date) - new Date(b.date)
                  : new Date(b.date) - new Date(a.date))).map((book) => (
                    <BookingBody key={book.id} book={book} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        onPageChange={(_, page) => handlePageFiltersChange('page_number', page)}
        onRowsPerPageChange={(event) => handlePageFiltersChange('page_size', event.target.value)}
        page={filters.page_number}
        rowsPerPage={filters.page_size}
        rowsPerPageOptions={[20, 50, 100]}
        labelDisplayedRows={() => console.log('next page')}
        ActionsComponent={(props) => TablePaginationActions({ ...props, isLoad })}
      />
    </Card>
  );
};

function TablePaginationActions(props) {
  const {
    page, onPageChange, isLoad,
  } = props;
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!isLoad && <CircularProgress size={20} />}
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

function BookingBody({ book }) {
  const [open, setOpen] = useState(false);

  return (

    <>
      <TableRow
        hover
        key={book.id}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {book.comment ? open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
          </IconButton>
        </TableCell>
        <TableCell>
          {book.shoppingCart.id}
        </TableCell>
        <TableCell>
          {book.id}
        </TableCell>
        <TableCell>
          {`${book.shoppingCart.user.name} 
          ${book.shoppingCart.user.lastname} ${book.shoppingCart.user.email} ${book.shoppingCart.user.phone}`}
        </TableCell>
        <TableCell>
          {moment(book.created_date).local().format('YYYY-MM-DD HH:mm')}
        </TableCell>
        <TableCell>
          {book.date ? moment(book.date).utc().format('YYYY-MM-DD HH:mm') : '-'}
        </TableCell>
        <TableCell>
          {book.status}
        </TableCell>
        <TableCell align="center">
          {book.professional?.user?.name ? `${book.professional?.user?.name} ${book.professional?.user?.lastname}` : '-'}
        </TableCell>
        <TableCell>
          {book.serviceVariantArea?.name}
        </TableCell>
        <TableCell>
          {book.bookingAddOns.length > 0 ? (
            <Tooltip title={tooltipText(book.bookingAddOns)} arrow>
              <IconButton aria-label={book.id} size="small">
                {book.bookingAddOns.length}
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton aria-label={book.id} size="small">
              {book.bookingAddOns.length}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          {book.quantity}
        </TableCell>
        <TableCell>
          {book.address?.country?.substring(0, 3)}
        </TableCell>
        <TableCell>
          {book.shoppingCart?.promocode_code ? book?.shoppingCart?.promocode_code : '-'}
        </TableCell>
        <TableCell>
          {book.address?.address}
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Button
                value={book.id}
                component={RouterLink}
                onClick={() => { console.log(`booking/${book.id}/edit`); }}
                to={`/app/booking/${book.id}/edit`}
                color="primary"
                variant="contained"
              >
                Edit
              </Button>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Button
                color="success"
                value={book.id}
                component={RouterLink}
                onClick={() => { console.log(`/app/booking/${book.id}/${book.date}/request`); }}
                to={`/app/booking/${book.id}/${book.date}/request`}
                variant="contained"
              >
                Req
              </Button>
            </Grid>
          </Grid>
        </TableCell>

      </TableRow>
      {book.comment && open
        ? (
          <TableRow>
            <TableCell colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <TableCell align="center">
                    {book.comment}
                  </TableCell>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )

        : null}

    </>
  );
}

function BookingHead({ orderBy, order, onRequestSort }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        <TableCell>
          Cart Id
        </TableCell>
        <TableCell>
          Book ID
        </TableCell>
        <TableCell>
          Booked By
        </TableCell>
        <TableCell>
          Booked On
        </TableCell>
        <TableCell
          key="date"
          sortDirection={orderBy === 'date' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'date'}
            direction={orderBy === 'date' ? order : 'asc'}
            onClick={() => onRequestSort('date')}
          >
            Booked Date
          </TableSortLabel>
        </TableCell>
        <TableCell>
          Status
        </TableCell>
        <TableCell align="center">
          Ameizer
        </TableCell>
        <TableCell>
          SVA
        </TableCell>
        <TableCell>
          Addons
        </TableCell>
        <TableCell>
          Qty
        </TableCell>
        <TableCell>
          Country
        </TableCell>
        <TableCell>
          Promocode
        </TableCell>
        <TableCell>
          Addresses
        </TableCell>
        <TableCell>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            Options
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const tooltipText = (addon) => {
  return (
    <Box>
      <List>
        {addon && addon.map((a, i) => <ListItem key={`addOnTooltip_${i}`}>{a.addOnArea.name}</ListItem>)}
      </List>
    </Box>
  );
};

export default BookingListResult;
