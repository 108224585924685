import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import PricingPeriodDetail from 'src/components/pricingPeriod/PrecingPeriodDetails';
import AdminServices from 'src/services/AdminServices';

function PricingAdd() {
  const navigate = useNavigate();
  const { variantarea } = useParams();
  const queryP = new URLSearchParams(useLocation().search).get('sva');

  const handleSave = async (details) => {
    if (details.cost && details.price) {
      const newPricingPeriod = {
        active: details.active,
        cost: Number(details.cost),
        from: details.from,
        price: Number(details.price),
        to: details.to,
        service_variant_area_id: variantarea,
        day_number: details.day_number,
        start_time: details.start_time,
        end_time: details.end_time,
      };
      console.log('newPricingPeriod', newPricingPeriod);
      try {
        const response = await AdminServices.createServiceVarianAreaPricingPeriod(variantarea, newPricingPeriod);
        navigate('/app/service/variant', { replace: true });
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('Fill required items');
    }
  };

  return (
    <>
      <Helmet>
        <title>Add Pricing Period </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/service/variant">
              Service variants
            </Link>
            <Typography color="textPrimary">Add Pricing Period</Typography>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <PricingPeriodDetail handleSave={handleSave} defaultValue={null} svaName={queryP} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PricingAdd;
