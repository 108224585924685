import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import is from 'is_js';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import ProfessionalDetails from 'src/components/professional/ProfessionalDetails';
import AdminServices from 'src/services/AdminServices';

const REQUIRED_FIELD_ERROR = 'This field is required';

function ProfessionalAdd() {
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [serviceVariants, setServiceVariants] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    console.log('errors', errors);
  }, [errors]);

  useEffect(() => {
    getServiceVariantList();
  }, []);

  const handleSave = async (professionalData) => {
    setErrors(null);
    console.log('professionalData', professionalData);
    console.log('SAVE 2');

    const {
      workingAreas,
      serviceVariant,
      availability,
      avatar,
      identification_attachment_front,
      identification_attachment_back,
      ...restOfProperties
    } = professionalData;

    if (validateProfessionalUserData(restOfProperties.user)) {
      setSaveLoading(true);

      try {
        // create professional
        const response = await AdminServices.createProfessional(restOfProperties);

        // post avatar
        if (avatar) {
          const newAvatar = {
            ...avatar,
            user_id: response.data.user.id,
          };
          const avatarResponse = await AdminServices.addProfessionalAvatar(response.data.user.id, newAvatar);
        }

        // post working areas
        if (workingAreas) {
          for (const i in workingAreas) {
            if (workingAreas[i].id) {
              const newArea = {
                area_id: workingAreas[i].id,
                professional_id: response.data.id,
              };
              const workingAreaResponse = await AdminServices.addProfessionalWorkingArea(response.data.id, newArea);
            } else {
              const newArea = {
                ...workingAreas[i],
                professional_id: response.data.id,
              };
              const workingAreaResponse2 = await AdminServices.addProfessionalWorkingArea(response.data.id, newArea);
            }
          }
        }

        // post service variants
        if (serviceVariant) {
          for (const i in serviceVariant) {
            const newVariant = {
              service_variant_id: serviceVariant[i].id,
              professional_id: response.data.id,
            };
            const serviceVariantResponse = await AdminServices
              .addProfessionalServiceVariant(response.data.id, newVariant);
          }
        }

        // post identifications
        if (identification_attachment_front) {
          const identificationFrontResponse = await AdminServices
            .addProfessionalIdentification(response.data.user.id, identification_attachment_front);
        }
        if (identification_attachment_back) {
          const identificationBackResponse = await AdminServices
            .addProfessionalIdentification(response.data.user.id, identification_attachment_back);
        }

        // post availabilities
        if (is.not.empty(availability)) {
          for (const i in availability) {
            const newAvailability = {
              ...availability[i],
              professional_id: response.data.id,
            };
            const availabilityResponse = await AdminServices
              .addProfessionalAvailability(response.data.id, newAvailability);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSaveLoading(false);
        navigate('/app/professionals');
      }
    }
  };

  const getServiceVariantList = async () => {
    setIsLoad(false);
    try {
      const response = await AdminServices.getServiceVariants();
      setServiceVariants(response.data);
      setIsLoad(true);
    } catch (e) {
      console.log(e);
    }
  };

  const validateProfessionalUserData = (data) => {
    let validated;
    const errorsAcum = {};

    if (!data.name) errorsAcum.name = REQUIRED_FIELD_ERROR;
    if (!data.lastname) errorsAcum.lastname = REQUIRED_FIELD_ERROR;
    if (!data.email) errorsAcum.email = REQUIRED_FIELD_ERROR;
    if (data.email) errorsAcum.email = emailValidation(data.email);
    if (!data.identification_number) errorsAcum.identification_number = REQUIRED_FIELD_ERROR;
    if (!data.password) errorsAcum.password = REQUIRED_FIELD_ERROR;
    if (data.password) errorsAcum.password = passwordValidation(data.password);
    if (!data.repeat_password) errorsAcum.repeat_password = REQUIRED_FIELD_ERROR;
    if (data.repeat_password && data.password) {
      if (data.password !== data.repeat_password) errorsAcum.repeat_password = 'Passwords do not match';
    }
    if (!data.birthdate) errorsAcum.birthdate = REQUIRED_FIELD_ERROR;
    if (!data.phone) errorsAcum.phone = REQUIRED_FIELD_ERROR;

    if (errorsAcum.email === 'ok') {
      delete errorsAcum.email;
    }
    if (errorsAcum.password === 'ok') {
      delete errorsAcum.password;
    }

    if (is.empty(errorsAcum)) validated = true;
    if (is.not.empty(errorsAcum)) {
      setErrors(errorsAcum);
      validated = false;
    }

    return validated;
  };

  const emailValidation = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return 'ok';
    }

    if (email.trim() === '') {
      return 'Email is required';
    }

    return 'Please enter a valid email';
  };

  const passwordValidation = (password) => {
    if (password.trim() === '') {
      return 'password is required';
    }
    if (password.trim().length < 6) {
      return 'password needs to be at least six characters';
    }
    return 'ok';
  };

  return (
    <>
      <Helmet>
        <title>Add Professional </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/professionals">
              Professional
            </Link>
            <Typography color="textPrimary">New Professional</Typography>
          </Breadcrumbs>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              {isLoad ? (
                <ProfessionalDetails
                  errors={errors}
                  serviceVariants={serviceVariants}
                  handleSave={handleSave}
                  saveLoading={saveLoading}
                  action="add"
                />
              ) : <Box><CircularProgress /></Box>}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ProfessionalAdd;
