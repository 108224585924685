import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, AccordionDetails, AccordionSummary, Accordion, IconButton, Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  imageListRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: '#fff',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

const PortfolioDetails = ({ portfolio, handleDeletePhoto, handleOpenAddImageModal }) => {
  const classes = useStyles();

  return (
    <>
      {portfolio.map((p, i) => (
        <Accordion key={`portfolio_${i}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{p.category.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.imageListRoot}>
              <ImageList className={classes.imageList} cols={4}>
                {portfolio[i].images.map((item) => (
                  <ImageListItem key={item.id}>
                    <img src={item.url} alt={item.order} />
                    <ImageListItemBar
                      title={`ID: ${item.id}`}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                      actionIcon={
                        (
                          <IconButton
                            onClick={() => handleDeletePhoto(item.id, p.category.id)}
                            aria-label={`star ${item.order}`}
                            color="secondary"
                          >
                            <DeleteForeverRoundedIcon />
                          </IconButton>
                        )
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
            <Box style={{
              width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '24px',
            }}
            >
              <Button
                variant="contained"
                onClick={() => handleOpenAddImageModal(p.category)}
              >
                Add photo
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default PortfolioDetails;
