/* global google */
import {
  Typography, CircularProgress, Box,
} from '@material-ui/core';
import {
  GoogleMap, InfoWindow, LoadScript, Marker, Polyline,
} from '@react-google-maps/api';
import { useEffect, useMemo, useState } from 'react';
import AdminServices from 'src/services/AdminServices';
import moment from 'moment';

const containerStyle = {
  width: '100%',
  height: '700px',
};

function ProfessionalsTrackingMap({ countryId, activeProfessionals }) {
  const [countryInfo, setCounrtyInfo] = useState({});
  const [loading, setLoading] = useState(false);

  function getCountryDetails(id) {
    setLoading(true);
    return AdminServices.getArea(id)
      .then((response) => {
        setCounrtyInfo(response.data);
        setLoading(false);
      });
  }

  useEffect(() => {
    getCountryDetails(countryId);
  }, [countryId]);

  const center = countryInfo?.center || { lat: -33.44842152938572, lng: -70.66407958679974 };

  return (
    <>
      {loading && <CircularProgress />}
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          onClick={() => {}}
          id="professionals-tracking-map"
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
        >
          {activeProfessionals.length > 0 && activeProfessionals.map((locationInfo) => {
            return <MapMarker key={locationInfo.id} locationInfo={locationInfo} />;
          })}
        </GoogleMap>
      </LoadScript>
    </>
  );
}

function MapMarker({ locationInfo }) {
  const currentLocation = useMemo(
    () => ({ lat: locationInfo.location?.latitude, lng: locationInfo.location?.longitude }),
    [locationInfo]);
  const destinationLocation = useMemo(
    () => ({ lat: locationInfo.destinationCoordinates?.latitude, lng: locationInfo.destinationCoordinates?.longitude }),
    [locationInfo]);
  const [open, setOpen] = useState(false);
  const [professionalData, setProfessionalData] = useState({});
  const [loading, setLoading] = useState(false);
  const directionsService = new google.maps.DirectionsService();
  const [directions, setDirections] = useState(false);

  function handleOpen() {
    const ID = locationInfo.id.replace('professional_', '');
    setLoading(true);
    setOpen(true);
    return AdminServices.getProfessional(ID)
      .then((response) => {
        setProfessionalData(response.data);
        setLoading(false);
      });
  }

  function calcRoute() {
    if (!locationInfo.destinationCoordinates) {
      return;
    }
    const request = {
      origin: currentLocation,
      destination: destinationLocation,
      travelMode: 'DRIVING',
    };
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        const coords = result.routes[0].overview_path;
        setDirections({ coords });
      }
    });
  }

  useEffect(() => { calcRoute(); }, [currentLocation]);

  const svgIcon = {
    // eslint-disable-next-line
    path: 'M12,2C8.14,2,5,5.14,5,9c0,5.25,7,13,7,13s7-7.75,7-13C19,5.14,15.86,2,12,2z M12,4c1.1,0,2,0.9,2,2c0,1.11-0.9,2-2,2 s-2-0.89-2-2C10,4.9,10.9,4,12,4z M12,14c-1.67,0-3.14-0.85-4-2.15c0.02-1.32,2.67-2.05,4-2.05s3.98,0.73,4,2.05 C15.14,13.15,13.67,14,12,14z',
    fillColor: '#3f51b5',
    scale: 2,
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    anchor: new google.maps.Point(14, 22),
  };

  return (

    <>
      {directions && (
      <Polyline
        path={directions.coords}
        geodesic
        options={{
          strokeColor: '#757de8',
          strokeOpacity: 0.8,
          strokeWeight: 5,
          clickable: true,
        }}
      />
      )}
      <Marker
        position={currentLocation}
        onClick={handleOpen}
        icon={svgIcon}
      >
        {open && (
        <InfoWindow onCloseClick={() => setOpen(false)}>
          <Box sx={{ padding: 1 }}>
            {loading && <CircularProgress size={20} />}
            {!loading && (
            <>
              <Typography>
                {`Professional ID: ${professionalData.id}`}
              </Typography>
              <Typography>
                {`Name: ${professionalData?.user?.name} ${professionalData?.user?.lastname}`}
              </Typography>
              <Typography color="primary">
                {`Last update: ${moment(new Date(locationInfo.time)).fromNow()}`}
              </Typography>
            </>
            )}
          </Box>
        </InfoWindow>
        )}
      </Marker>
      {locationInfo.destinationCoordinates && (
      <Marker
        position={destinationLocation}
        onClick={handleOpen}
      >
        {open && (
        <InfoWindow onCloseClick={() => setOpen(false)}>
          <Box sx={{ padding: 1 }}>
            <Typography>
              Destination!
            </Typography>
          </Box>
        </InfoWindow>
        )}
        </Marker>
      )}
    </>
  );
}

export default ProfessionalsTrackingMap;
