import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  NavLink as RouterLink,
} from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';

const BannersListResult = ({ banners = [], filters, handleFiltersChange }) => {
  const [orderBy, setOrderBy] = useState(null);
  const [order, setorder] = useState('desc');
  const [list, setList] = useState(banners);

  const handleRequestSort = (property) => {
    const newOrder = orderBy === property ? order === 'desc' ? 'asc' : 'desc' : 'desc';
    const updatedList = list.sort((a, b) => sortTable(a, b, property, newOrder));
    setList(updatedList);
    setorder(newOrder);
    setOrderBy(property);
  };

  function handlePageFiltersChange(prop, value) {
    console.info(prop, value);
    handleFiltersChange({ ...filters, [prop]: value });
  }

  function sortTable(a, b, property, order) {
    const propA = property.includes('date') ? new Date(a[property]) : a[property];
    const propB = property.includes('date') ? new Date(b[property]) : b[property];
    if (order === 'desc') {
      return propA - propB;
    }
    return propB - propA;
  }

  useEffect(() => {
    if (banners.length === 0) {
      return;
    }
    if (orderBy) {
      const updatedList = banners.sort((a, b) => sortTable(a, b, orderBy, order));
      setList(updatedList);
      return;
    }
    setList(banners);
  }, [banners]);

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell
                  key="id"
                  sortDirection={order}
                >
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={orderBy === 'id' ? order : 'desc'}
                    onClick={() => handleRequestSort('id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Title
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  Start Date
                </TableCell>
                <TableCell
                  key="end_date"
                  sortDirection={order}
                >
                  <TableSortLabel
                    active={orderBy === 'end_date'}
                    direction={orderBy === 'end_date' ? order : 'desc'}
                    onClick={() => handleRequestSort('end_date')}
                  >
                    End Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  Section
                </TableCell>
                <TableCell align="center">
                  Status
                </TableCell>
                <TableCell align="center">
                  Areas
                </TableCell>
                <TableCell align="center">
                  Order
                </TableCell>
                <TableCell align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((banner, i) => (
                <TableRow key={`bannerRow_${i}`} hover>
                  <TableCell component="th" scope="row" align="center">
                    {banner.id}
                  </TableCell>
                  <TableCell>
                    {banner.title}
                  </TableCell>
                  <TableCell>
                    {banner.description}
                  </TableCell>
                  <TableCell>
                    {moment(banner.start_date).utc().format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(banner.end_date).utc().format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell align="center">
                    {banner.section}
                  </TableCell>
                  <TableCell align="center">
                    {banner.status}
                  </TableCell>
                  <TableCell align="center">
                    {(banner.areas || []).map((area) => {
                      return `${area.name} | `;
                    })}
                  </TableCell>
                  <TableCell align="center">
                    {banner.order || ''}
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        value={banner.id}
                        component={RouterLink}
                        to={`/app/banners/edit/${banner.id}`}
                        sx={{ mx: 1 }}
                        style={{ color: '#FFF' }}
                        size="small"
                        color="success"
                        variant="contained"
                      >
                        Edit
                      </Button>
                      {/* <Button
                        value={banner.id}
                        sx={{ mx: 1 }}
                        size="small"
                        color="error"
                        onClick={() => handleClickOpenDeleteModal(banner.id)}
                        variant="contained"
                      >
                        Delete
                      </Button> */}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        page={filters.page_number - 1}
        onPageChange={(_, page) => handlePageFiltersChange('page_number', page + 1)}
        onRowsPerPageChange={(event) => handlePageFiltersChange('page_size', event.target.value)}
        rowsPerPage={filters.page_size}
        rowsPerPageOptions={[5, 10, 25]}
        labelDisplayedRows={() => console.log('next page')}
      />
    </Card>
  );
};

export default BannersListResult;
