import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Box,
  Card,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

const AreaListPoints = ({ points, handleDeletePoint, handleDeleteAllPoint }) => {
  const [localPoints, setLocalPoints] = useState([]);
  useEffect(() => {
    setLocalPoints(points);
  });

  function handleDeleteClick(indexPoint) {
    handleDeletePoint(indexPoint);
  }
  function handleDeleteAll() {
    handleDeleteAllPoint();
  }
  return (
    <Card>
      <PerfectScrollbar height>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Button
                    color="warning"
                    variant="contained"
                        // onClick={handleDelete(Area.id)}
                    onClick={handleDeleteAll}
                  >
                    <DeleteIcon />
                    All
                  </Button>
                </TableCell>
                <TableCell>
                  Points
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {localPoints.map((point, index) => (
                <TableRow
                  hover
                  key={point?.latlng?.lng}
                // selected={selectedPoints.indexOf(index) !== -1}
                >
                  <TableCell padding="checkbox">
                    <DeleteIcon
                      data-point={point?.latlng?.lat}
                      onClick={() => handleDeleteClick(point)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {`Point ${index}`}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

AreaListPoints.propTypes = {
  points: PropTypes.array.isRequired,
  // setPoints: PropTypes.any
};

export default AreaListPoints;
