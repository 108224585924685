import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import BookingPackListToolbar from 'src/components/packsPurchased/BookingPackListToolBar';
import PacksPurchasedListResult from 'src/components/packsPurchased/PacksPurchasedListResult';
import AdminServices from 'src/services/AdminServices';

const DEFAULT_FILTERS = {
  country: null,
  pending_sessions: null,
  pack_name: null,
  user_email: null,
  user_id: null,
  page_number: 1,
  page_size: 20,
  from: null,
  to: null,
};

const PacksPurchasedList = () => {
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
  const [bookingsPacks, setBookingsPacks] = useState([]);
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    fetchPacks(filters);
  }, [filters]);

  function fetchPacks(filters) {
    setIsLoad(false);
    return AdminServices.getPackPurchased(filters)
      .then((res) => {
        setBookingsPacks(res.data);
        setIsLoad(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoad(true);
      });
  }

  const handleFilter = (filterSelected) => {
    setFilters({ ...filters, ...filterSelected });
  };

  const handleClearFilter = () => {
    setFilters({
      country: null,
      pending_sessions: null,
      pack_name: null,
      user_email: null,
      user_id: null,
      page_number: 1,
      page_size: 20,
      from: null,
      to: null,
    });
  };

  return (
    <>
      <Helmet>
        <title> Booking Packs | Ameiz</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>

          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">Booking Packs</Typography>
          </Breadcrumbs>
          <BookingPackListToolbar
            handleFilter={handleFilter}
            handleClearFilter={handleClearFilter}
            refresh={() => fetchPacks(filters)}
            isLoad={isLoad}
            filters={filters}
          />
          <Box sx={{ pt: 3 }}>
            <PacksPurchasedListResult
              handleFilter={handleFilter}
              isLoad={isLoad}
              filters={filters}
              refresh={() => fetchPacks(filters)}
              bookingsPacks={bookingsPacks}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PacksPurchasedList;
