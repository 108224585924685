import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import CustomerDetails from 'src/components/customer/CustomerDetails';

const CustomerEdit = () => {
  const { customerid } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});

  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = async () => {
    const url = `${process.env.REACT_APP_MODERN_API_URL}/user/${customerid}`;
    try {
      const response = await axios.get(url);
      console.log('user', response.data);
      setDefaultValue(response.data);
      setIsLoad(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit User</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Breadcrumbs aria-label="booking-breadcrumb" style={{ marginBottom: 12 }}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/app/customers">
              Customers
            </Link>
            <Typography color="textPrimary">Edit customer</Typography>
          </Breadcrumbs>
          <Grid
            container
            spacing={3}
          >
            <Grid item xs>
              {isLoad ? <CustomerDetails action="edit" currentInfo={defaultValue} /> : (
                <Box style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CustomerEdit;
